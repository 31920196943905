import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Store } from '..'
import AuthStore from '../Auth'
import { IFindResponse } from 'utils/api'
import * as Config from '../../config'
import {
  ISelfServiceUpdateResponse,
  ISelfServiceImageUploadResponse,
  ISelfServiceRequest,
  RequestType,
  ISelfServiceGetHighestIdResponse,
  AdminActionType,
  RequestStatus,
  RequestComment,
  ISelfServiceAdminDeleteResponse,
  ISelfServiceAdminExpirationCheckResponse,
  ISelfServiceAdminReAssignResponse
} from 'models/SelfServiceRequest'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import { trackException, trackSelfServiceEvent } from 'utils/tracking'
import { AdsResult } from 'models/AdsResult'
import { FeaturedResult } from 'models/FeaturedResult'
import { renewAuthorizationToken } from 'authentication/token'
import {
  INotificatonRequest,
  getNotificationRequest
} from 'models/NotificationTypes'
import { createRandomBoundryString } from 'utils/string'
import {
  IExpirationAlertConfiguration,
  IExpirationAlertRequest,
  IExpiredRequest
} from 'models/ExpirationAlerts'
import dayjs from 'dayjs'
import { ISelfServiceTrackingEvent } from 'models/SelfServiceTrackingEvent'
import { maxSelfServicePageLoadingDepth } from 'constants/constants'
import { cleanUpRequestItem } from 'utils/admin/selfServiceUtils'
import { DidYouMeanItem } from 'models/DidYouMean'

export enum SelfServiceActionTypes {
  FETCH_MYREQUESTS_REQUEST = 'selfservice/FETCH_MYREQUESTS_REQUEST',
  FETCH_MYREQUESTS_SUCCESS = 'selfservice/FETCH_MYREQUESTS_SUCCESS',
  FETCH_MYREQUESTS_FAILED = 'selfservice/FETCH_MYREQUESTS_FAILED',
  FETCH_ALLREQUESTS_REQUEST = 'selfservice/FETCH_ALLREQUESTS_REQUEST',
  FETCH_ALLREQUESTS_SUCCESS = 'selfservice/FETCH_ALLREQUESTS_SUCCESS',
  FETCH_ALLREQUESTS_FAILED = 'selfservice/FETCH_ALLREQUESTS_FAILED',
  UPSERT_MY_REQUEST = 'selfservice/UPSERT_MY_REQUEST',
  UPSERT_MY_REQUEST_SUCCESS = 'selfservice/UPSERT_MY_REQUEST_SUCCESS',
  UPSERT_MY_REQUEST_FAILED = 'selfservice/UPSERT_MY_REQUEST_FAILED',
  UPDATE_REQUESTS_SUCCESS = 'selfservice/UPDATE_REQUESTS_SUCCESS',
  START_UPDATE_REQUESTS = 'selfservice/START_UPDATE_REQUESTS'
}

// **** GET MY REQUESTS **** //

export type IFetchMyRequestsRequest = Action<SelfServiceActionTypes>
export interface IFetchMyRequestsSuccess
  extends Action<SelfServiceActionTypes> {
  payload: {
    myRequests: ISelfServiceRequest[]
  }
}
export type IFetchMyRequestsFailure = Action<SelfServiceActionTypes>

export const fetchMyRequestsRequest = (): IFetchMyRequestsRequest => ({
  type: SelfServiceActionTypes.FETCH_MYREQUESTS_REQUEST
})
export const fetchMyRequestsSuccess = (
  myRequests: ISelfServiceRequest[]
): IFetchMyRequestsSuccess => ({
  type: SelfServiceActionTypes.FETCH_MYREQUESTS_SUCCESS,
  payload: { myRequests }
})

export const fetchMyRequestsFailure = (): IFetchMyRequestsFailure => ({
  type: SelfServiceActionTypes.FETCH_MYREQUESTS_FAILED
})

export const fetchMyRequestsResults = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchMyRequestsRequest())
    try {
      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/getmyrequests?upn=${ESSettingsGlobalVariables.getUPN()}`

      const initialResponse = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let myRequests: ISelfServiceRequest[] = []

      myRequests = await fetchResultsWithContinueToken(
        myRequests,
        initialResponse,
        apiUrl,
        esToken,
        'GetMyRequests'
      )

      dispatch(fetchMyRequestsSuccess(myRequests))
    } catch (error) {
      dispatch(fetchMyRequestsFailure())
    }
  }
}

// **** GET ALL REQUESTS **** //
export type IFetchAllRequestsRequest = Action<SelfServiceActionTypes>
export interface IFetchAllRequestsSuccess
  extends Action<SelfServiceActionTypes> {
  payload: {
    allRequests: ISelfServiceRequest[]
  }
}
export type IFetchAllRequestsFailure = Action<SelfServiceActionTypes>

export const fetchAllRequestsRequest = (): IFetchAllRequestsRequest => ({
  type: SelfServiceActionTypes.FETCH_ALLREQUESTS_REQUEST
})
export const fetchAllRequestsSuccess = (
  allRequests: ISelfServiceRequest[]
): IFetchAllRequestsSuccess => ({
  type: SelfServiceActionTypes.FETCH_ALLREQUESTS_SUCCESS,
  payload: { allRequests }
})

export const fetchAllRequestsFailure = (): IFetchAllRequestsFailure => ({
  type: SelfServiceActionTypes.FETCH_ALLREQUESTS_FAILED
})

export const fetchAllRequestsResults = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchAllRequestsRequest())
    try {
      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/getallrequests?upn=${ESSettingsGlobalVariables.getUPN()}`

      const initialResponse = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let allRequests: ISelfServiceRequest[] = []

      allRequests = await fetchResultsWithContinueToken(
        allRequests,
        initialResponse,
        apiUrl,
        esToken,
        'GetAllRequests'
      )

      dispatch(fetchAllRequestsSuccess(allRequests))
    } catch (error) {
      dispatch(fetchAllRequestsFailure())
    }
  }
}

export const getAllRequests = (): ThunkAction<
  Promise<ISelfServiceRequest[]>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return []
      }

      const apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/getallrequests?upn=${ESSettingsGlobalVariables.getUPN()}`

      const initialResponse = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let allRequests: ISelfServiceRequest[] = []

      allRequests = await fetchResultsWithContinueToken(
        allRequests,
        initialResponse,
        apiUrl,
        esToken,
        'GetAllRequests'
      )

      return allRequests
    } catch (error) {
      return []
    }
  }
}

const fetchResultsWithContinueToken = async (
  results: ISelfServiceRequest[],
  initialResponse: Response,
  apiUrl: string,
  accessToken: string,
  trackingType: string,
  depth = 0
): Promise<ISelfServiceRequest[]> => {
  let response: IFindResponse

  if (initialResponse && initialResponse.ok) {
    response = {
      hasError: false,
      responseJSON: await initialResponse.json()
    }
  } else {
    response = {
      hasError: true
    }
  }

  if (
    !response ||
    response.hasError ||
    !response.responseJSON ||
    !response.responseJSON.Documents ||
    response.responseJSON.Documents.length < 1
  ) {
    return results
  }

  results = [...results, ...response.responseJSON.Documents]

  const continueToken = initialResponse.headers
    ? initialResponse.headers.get('x-ms-continuation')
    : null

  const newDepth = depth + 1

  if (!continueToken || newDepth > maxSelfServicePageLoadingDepth) {
    return results
  }

  trackSelfServiceEvent({
    type: trackingType,
    continueToken: continueToken,
    currentLength: results.length
  })

  try {
    const continueResponse = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${accessToken}`,
        'x-ms-continuation': continueToken
      }
    })

    return await fetchResultsWithContinueToken(
      results,
      continueResponse,
      apiUrl,
      accessToken,
      trackingType,
      newDepth
    )
  } catch (error) {
    trackSelfServiceEvent({
      type: trackingType,
      continueToken: continueToken,
      currentLength: results.length,
      hasError: true,
      exception: (error as any).message
    })
  }

  return results
}

const uploadImage = async (
  requestItem: ISelfServiceRequest,
  authToken: string,
  imagePreview: string,
  fileName: string,
  baseEvent: ISelfServiceTrackingEvent
) => {
  const event: ISelfServiceTrackingEvent = {
    ...baseEvent,
    additionalInfo: {
      action: 'UploadImage',
      fileName: fileName
    }
  }

  try {
    let apiUrl = `${
      Config.APIM_BASE_URL
    }selfserviceapi/postimageupload?upn=${ESSettingsGlobalVariables.getUPN()}&requestUpn=${
      requestItem.upn
    }&id=${requestItem.id}&fileName=${fileName}`

    trackSelfServiceEvent(event)

    const boundry = createRandomBoundryString(10)
    const formData = `--${boundry}\r\nContent-Disposition: form-data; name=""; filename="${fileName}"\r\nContent-Type: image/png\r\n\r\n${imagePreview}\r\n--${boundry}--\r\n`

    const data = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': `multipart/form-data; boundary=${boundry}`,
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${authToken}`
      },
      body: formData
    })

    if (data.status === 200) {
      trackSelfServiceEvent({ ...event, hasError: false })
      return {
        hasError: false,
        errorMessage: '',
        etag: data.headers ? data.headers.get('etag') : null,
        imageError: false
      }
    } else {
      trackSelfServiceEvent({
        ...event,
        hasError: true,
        exception: `Error uploading the image: ${data.status}`
      })
      return {
        hasError: false,
        errorMessage: 'Error uploading the image',
        status: data.status,
        imageError: true
      }
    }
  } catch (error) {
    trackSelfServiceEvent({
      ...event,
      hasError: true,
      exception: `Error uploading the image: ${(error as any).message}`
    })
    return {
      hasError: false,
      errorMessage: 'Error uploading the image',
      imageError: true
    }
  }
}

// **** UPSERT MY REQUESTS **** //
export const upsertMyRequest = (
  requestOriginal: ISelfServiceRequest,
  notificationRequest: INotificatonRequest,
  imageUpdated = false
): ThunkAction<
  Promise<ISelfServiceUpdateResponse>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    const request = Object.assign({}, requestOriginal)

    const baseEvent: ISelfServiceTrackingEvent = {
      requestType: request.requestType,
      id: request.id,
      _rid: request._rid ? request._rid : '',
      action:
        notificationRequest && notificationRequest.notificationType
          ? notificationRequest.notificationType.toString()
          : 'SaveDraft'
    }

    try {
      const etag = request._etag ? request._etag : ''
      const imagePreview = request.imagePreview
      const imageFileName = request.imageFileName

      cleanUpRequestItem(request)

      // Set image if cleared
      if (imageUpdated && request.requestType === RequestType.AdWord) {
        request.imagePreview = ''
        request.imageFileName = ''
      }

      let apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/upsertmyrequest?upn=${ESSettingsGlobalVariables.getUPN()}`

      if (
        request.upn !== ESSettingsGlobalVariables.getUPN() &&
        request.id !== 'new'
      ) {
        apiUrl = `${
          Config.APIM_BASE_URL
        }selfserviceapi/upsertdeputyrequest?upn=${ESSettingsGlobalVariables.getUPN()}&id=${
          request.id
        }&requestUpn=${request.upn}`
        baseEvent.asDeputy = true
      }

      trackSelfServiceEvent(baseEvent)

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: true,
          exception: 'Auth token is empty'
        })

        return {
          hasError: true,
          errorMessage: 'Auth token is empty'
        }
      }

      const utf8Bytes = encodeURIComponent(JSON.stringify(request)).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode(Number('0x' + p1))
        }
      )

      const headers: HeadersInit = new Headers()
      headers.set('accept', 'application/json')
      headers.set('content-type', 'application/json')
      headers.set(
        'Ocp-Apim-Subscription-Key',
        `${Config.OCP_APIM_SUBSCRIPTION_KEY}`
      )
      headers.set('Authorization', `Bearer ${esToken}`)
      if (request.id !== 'new') {
        headers.set('if-match', etag)
      }

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body:
          Config.LOCAL_DEVELOPMENT.toString() === 'true'
            ? JSON.stringify({
                content: request,
                notificationRequest: notificationRequest
              })
            : JSON.stringify({
                content: `${btoa(utf8Bytes).replace(/=/g, '{eq}')}`,
                notificationRequest: notificationRequest
              })
      })
      if (data.status !== 200 && data.status !== 201) {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: true,
          exception: `Error updating the request: ${data.status}`
        })
        return {
          hasError: true,
          errorMessage: 'Error updating the request',
          status: data.status
        }
      }

      if (request.id === 'new') {
        const responseItem = await data.json()
        requestOriginal.id = responseItem.id
        request.id = responseItem.id
        requestOriginal._rid = responseItem._rid
        request._rid = responseItem._rid

        baseEvent.id = responseItem.id
        baseEvent._rid = responseItem._rid
      }

      trackSelfServiceEvent({
        ...baseEvent,
        hasError: false
      })

      if (
        imageUpdated &&
        imagePreview &&
        imageFileName &&
        request.requestType === RequestType.AdWord
      ) {
        const response = await uploadImage(
          request,
          esToken,
          imagePreview,
          imageFileName,
          baseEvent
        )

        if (response.imageError) {
          response.etag = data.headers ? data.headers.get('etag') : null
          requestOriginal.imagePreview = ''
          requestOriginal.imageFileName = ''
        }

        return response
      } else {
        return {
          hasError: false,
          errorMessage: '',
          etag: data.headers ? data.headers.get('etag') : null
        }
      }
    } catch (error) {
      trackSelfServiceEvent({
        ...baseEvent,
        hasError: true,
        exception: `Unexpected error updating the request: ${
          (error as any).message
        }`
      })
      return {
        hasError: true,
        errorMessage: 'Unexpected error'
      }
    }
  }
}

export const deleteMyRequest = (
  request: ISelfServiceRequest
): ThunkAction<
  Promise<ISelfServiceUpdateResponse>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    const baseEvent: ISelfServiceTrackingEvent = {
      requestType: request.requestType,
      id: request.id,
      _rid: request._rid ? request._rid : '',
      action: 'DeleteRequest',
      asDeputy: request.upn !== ESSettingsGlobalVariables.getUPN()
    }

    try {
      trackSelfServiceEvent(baseEvent)

      const apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/deletemyrequest?upn=${ESSettingsGlobalVariables.getUPN()}&id=${
        request.id
      }&requestUpn=${request.upn}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: true,
          exception: `Auth token is empty`
        })
        return {
          hasError: true,
          errorMessage: 'Auth token is empty'
        }
      }

      const data = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 200 && data.status !== 204) {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: true,
          exception: `Error deleting the request: ${data.status}`
        })
        return {
          hasError: true,
          errorMessage: 'Error updating the request',
          status: data.status
        }
      }

      trackSelfServiceEvent({
        ...baseEvent,
        hasError: false
      })

      return {
        hasError: false,
        errorMessage: ''
      }
    } catch (error) {
      trackSelfServiceEvent({
        ...baseEvent,
        hasError: true,
        exception: `Unexpected error deleting the request: ${
          (error as any).message
        }`
      })
      return {
        hasError: true,
        errorMessage: 'Unexpected error'
      }
    }
  }
}

// **** UPSERT ADMIN REQUEST *** //
const getNextId = async (
  requestType: RequestType,
  token: string
): Promise<ISelfServiceGetHighestIdResponse> => {
  try {
    const apiUrl = `${
      Config.APIM_BASE_URL
    }selfserviceapi/gethighestid?requesttype=${requestType}&upn=${ESSettingsGlobalVariables.getUPN()}`

    const data = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${token}`
      }
    })

    if (data.status !== 200) {
      return {
        hasError: true,
        errorMessage: 'Error in get highest id request',
        newId: ''
      }
    }

    const result = await data.json()

    if (!result || !result.newId) {
      return {
        hasError: false,
        errorMessage: 'Error in data after get highest id',
        newId: ''
      }
    }

    return {
      hasError: false,
      errorMessage: '',
      newId: result.newId
    }
  } catch (error) {
    return {
      hasError: true,
      errorMessage: 'Error in upsert featured result action',
      error: error,
      newId: ''
    }
  }
}

const upsertImage = async (
  fileName: string,
  fileContent: string,
  overWriteImage: boolean,
  token: string
): Promise<ISelfServiceImageUploadResponse> => {
  try {
    const apiUrl = `${
      Config.APIM_BASE_URL
    }translationsapi/upsertimage?filename=${fileName}${
      overWriteImage ? '&overwriteimage=true' : ''
    }`

    const boundry = createRandomBoundryString(10)
    const formData = `--${boundry}\r\nContent-Disposition: form-data; name=""; filename="${fileName}"\r\nContent-Type: image/png\r\n\r\n${fileContent}\r\n--${boundry}--\r\n`

    // Get and check authentication token
    const data = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': `multipart/form-data; boundary=${boundry}`,
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${token}`
      },
      body: formData
    })
    if (data.status !== 200 && data.status !== 201) {
      if (data.status === 405) {
        return {
          hasError: true,
          errorMessage: 'Error in upsert image, image already exists',
          url: ''
        }
      } else if (data.status === 406) {
        return {
          hasError: true,
          errorMessage: 'Error in upsert image, image validation failed',
          url: ''
        }
      } else {
        return {
          hasError: true,
          errorMessage: 'EError in data object after image upload',
          url: ''
        }
      }
    }

    const result = await data.json()

    if (!result || !result.url) {
      return {
        hasError: true,
        errorMessage: 'Error in getting documents out of image upsert',
        url: ''
      }
    }

    return {
      hasError: false,
      errorMessage: '',
      url: result.url
    }
  } catch (error) {
    return {
      hasError: true,
      errorMessage: 'Error in upsert image action',
      url: '',
      error: error
    }
  }
}

const upsertAdWord = async (
  adword: AdsResult,
  token: string
): Promise<ISelfServiceUpdateResponse> => {
  try {
    const apiUrl = `${Config.APIM_BASE_URL}translationsapi/upserttranslation?docId=${adword.id}&language=${adword.language}&type=Translations-Ads`

    const utf8Bytes = encodeURIComponent(JSON.stringify(adword)).replace(
      /%([0-9A-F]{2})/g,
      function (match, p1) {
        return String.fromCharCode(Number('0x' + p1))
      }
    )

    const data = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${token}`
      },
      body: '{"content":"' + btoa(utf8Bytes) + '"}'
    })

    if (data.status !== 200 && data.status !== 201) {
      return {
        hasError: true,
        errorMessage: 'Error in data object after upserting adword'
      }
    }

    return {
      hasError: false,
      errorMessage: ''
    }
  } catch (error) {
    return {
      hasError: true,
      errorMessage: 'Error in upsert adword action',
      error: error
    }
  }
}

const deleteAdWord = async (
  translationId: string,
  translationLanguage: string,
  token: string
): Promise<ISelfServiceUpdateResponse> => {
  try {
    let apiUrl = `${Config.APIM_BASE_URL}translationsapi/deletetranslation?docId=${translationId}&language=${translationLanguage}&type=Translations-Ads`

    const data = await fetch(apiUrl, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${token}`
      }
    })
    if (data.status !== 204 && data.status !== 200) {
      return {
        hasError: true,
        errorMessage: 'Error in data object after deleting translation'
      }
    }

    if (translationLanguage === 'en') {
      // Get all translations from search index by id
      apiUrl = `${Config.APIM_BASE_URL}searchapi/getadwords?docId=${translationId}`
      const ads = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${token}`
        }
      })
      if (ads && ads.status === 200) {
        const items = (await ads.json()).value
        for (let i = 0; i < items.length; i++) {
          apiUrl = `${Config.APIM_BASE_URL}searchapi/deletesearchindexentry?docId=${items[i].rid}&datasource=kpmg-find-adwords-index`
          await fetch(apiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${token}`
            }
          })
        }
      }
    }

    return {
      hasError: false,
      errorMessage: ''
    }
  } catch (error) {
    return {
      hasError: true,
      errorMessage: 'Error in delete adword action',
      error: error
    }
  }
}

const upsertFeaturedResult = async (
  featuredResult: FeaturedResult,
  token: string
): Promise<ISelfServiceUpdateResponse> => {
  try {
    const apiUrl = `${Config.APIM_BASE_URL}featuredresultsapi/upsertfeaturedresults?docId=${featuredResult.id}`

    const utf8Bytes = encodeURIComponent(
      JSON.stringify(featuredResult)
    ).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(Number('0x' + p1))
    })

    const data = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${token}`
      },
      body: '{"content":"' + btoa(utf8Bytes) + '"}'
    })

    if (data.status !== 200 && data.status !== 201) {
      return {
        hasError: true,
        errorMessage: 'Error in data object after upserting featured result'
      }
    }

    return {
      hasError: false,
      errorMessage: ''
    }
  } catch (error) {
    return {
      hasError: true,
      errorMessage: 'Error in upsert featured result action',
      error: error
    }
  }
}

const deleteFeaturedResult = async (
  featuredResultId: string,
  token: string
): Promise<ISelfServiceUpdateResponse> => {
  try {
    let apiUrl = `${Config.APIM_BASE_URL}featuredresultsapi/deletefeaturedresults?docId=${featuredResultId}`

    const data = await fetch(apiUrl, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${token}`
      }
    })
    if (data.status !== 204) {
      return {
        hasError: true,
        errorMessage: 'Error in data object after deleting featured result'
      }
    }

    // Delete featured result from search index
    apiUrl = `${Config.APIM_BASE_URL}searchapi/getfeaturedresults?docId=${featuredResultId}`
    const featuredResults = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${token}`
      }
    })
    if (featuredResults && featuredResults.status === 200) {
      const items = (await featuredResults.json()).value
      for (let i = 0; i < items.length; i++) {
        apiUrl = `${Config.APIM_BASE_URL}searchapi/deletesearchindexentry?docId=${items[i].rid}&datasource=kpmg-find-featured-results-index`
        await fetch(apiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${token}`
          }
        })
      }
    }

    return {
      hasError: false,
      errorMessage: ''
    }
  } catch (error) {
    return {
      hasError: true,
      errorMessage: 'Error in delete featured result action',
      error
    }
  }
}

const upsertDidYouMean = async (
  didYouMeanItem: DidYouMeanItem,
  token: string
): Promise<ISelfServiceUpdateResponse> => {
  try {
    const dymItem = JSON.parse(JSON.stringify(didYouMeanItem))
    if (dymItem.tableData) {
      delete dymItem.tableData
    }
    if (dymItem.draft) {
      delete dymItem.draft
    }

    const apiUrl = `${Config.APIM_BASE_URL}autosuggestapi/upsertdidyoumeanitem?docId=${dymItem.id}`

    const utf8Bytes = encodeURIComponent(JSON.stringify(dymItem)).replace(
      /%([0-9A-F]{2})/g,
      function (match, p1) {
        return String.fromCharCode(Number('0x' + p1))
      }
    )

    const data = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${token}`
      },
      body: '{"content":"' + btoa(utf8Bytes) + '"}'
    })

    if (data.status !== 200 && data.status !== 201) {
      return {
        hasError: true,
        errorMessage:
          'Error in data object after upsert spelling suggestion result'
      }
    }

    return {
      hasError: false,
      errorMessage: ''
    }
  } catch (error) {
    return {
      hasError: true,
      errorMessage: 'Error in upsert spelling suggestion result action',
      error
    }
  }
}

const deleteDidYouMean = async (
  didYouMeanId: string,
  token: string
): Promise<ISelfServiceUpdateResponse> => {
  try {
    const apiUrl = `${Config.APIM_BASE_URL}autosuggestapi/deletedidyoumeanitem?docId=${didYouMeanId}`

    const data = await fetch(apiUrl, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${token}`
      }
    })
    if (data.status !== 204) {
      return {
        hasError: true,
        errorMessage:
          'Error in data object after deleting spelling suggestion result'
      }
    }

    return {
      hasError: false,
      errorMessage: ''
    }
  } catch (error) {
    return {
      hasError: true,
      errorMessage: 'Error in delete spelling suggestion result action',
      error
    }
  }
}

export const upsertAdminRequest = (
  request: ISelfServiceRequest,
  adminActionType: AdminActionType,
  latestComment?: RequestComment,
  imageUpdated = false
): ThunkAction<
  Promise<ISelfServiceUpdateResponse>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    let notificationRequest: INotificatonRequest = {
      notificationType: null,
      sendNotification: false,
      latestComment: null
    }

    const isRequestOncePublishedUnChanged = request.oncePublished

    switch (adminActionType) {
      case AdminActionType.Publish:
        notificationRequest = getNotificationRequest(
          request,
          RequestStatus.Published
        )
        request.status = RequestStatus.Published
        request.oncePublished = true
        break
      case AdminActionType.Decline:
        notificationRequest = getNotificationRequest(
          request,
          RequestStatus.Declined
        )
        request.status = RequestStatus.Declined
        break
      case AdminActionType.Clarification:
        notificationRequest = getNotificationRequest(
          request,
          RequestStatus.Clarification
        )
        request.status = RequestStatus.Clarification
        break
      case AdminActionType.UnPublish:
        notificationRequest = getNotificationRequest(request, 'UnPublish')
        request.status =
          request.status === RequestStatus.Published
            ? RequestStatus.Submitted
            : request.status
        request.oncePublished = false
        break
      case AdminActionType.Comment:
        notificationRequest = getNotificationRequest(request, 'NewComment')
        break
    }

    const baseEvent: ISelfServiceTrackingEvent = {
      requestType: request.requestType,
      id: request.id,
      _rid: request._rid ? request._rid : '',
      action:
        notificationRequest && notificationRequest.notificationType
          ? notificationRequest.notificationType.toString()
          : 'Undefined'
    }

    try {
      trackSelfServiceEvent(baseEvent)

      const etag = request._etag ? request._etag : ''
      const imagePreview = request.imagePreview
      const imageFileName = request.imageFileName

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: true,
          exception: 'Auth token is empty'
        })
        return {
          hasError: true,
          errorMessage: 'Auth token is empty'
        }
      }

      if (adminActionType === AdminActionType.Publish) {
        if (
          request.requestType === RequestType.AdWord &&
          imagePreview &&
          imageFileName
        ) {
          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'UpsertImage',
              fileName: imageFileName
            }
          })

          // Upload the image and update the ads
          const upsertImageResponse: ISelfServiceImageUploadResponse =
            await upsertImage(imageFileName, imagePreview, true, esToken)

          if (upsertImageResponse.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Image upload failed: ${upsertImageResponse.errorMessage}`,
              additionalInfo: {
                action: 'UpsertImage',
                fileName: imageFileName
              }
            })
            return {
              hasError: true,
              errorMessage: 'Image upload failed'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'UpsertImage',
              fileName: imageFileName,
              urlResponse: upsertImageResponse.url
            }
          })

          const adWords = request.itemData as AdsResult[]
          adWords.forEach((ad: AdsResult) => {
            ad.image = upsertImageResponse.url
          })
        }

        // get and set the item ids in case not published once
        if (
          !isRequestOncePublishedUnChanged &&
          request.requestType !== RequestType.SpellingSuggestion
        ) {
          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'GetNextId'
            }
          })

          const responseHighestId = await getNextId(
            request.requestType,
            esToken
          )

          if (responseHighestId.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error getting the next free id: ${responseHighestId.errorMessage}`,
              additionalInfo: {
                action: 'GetNextId'
              }
            })

            return {
              hasError: true,
              errorMessage: 'Error getting the next free id'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'GetNextId',
              nextId: responseHighestId.newId
            }
          })

          if (request.requestType === RequestType.FeaturedResult) {
            const featuredResult = request.itemData as FeaturedResult
            featuredResult.id = responseHighestId.newId
          } else if (request.requestType === RequestType.AdWord) {
            const adWords = request.itemData as AdsResult[]
            adWords.forEach((ad: AdsResult) => {
              ad.id = responseHighestId.newId
            })
          }
        }

        // Upload the Adword or feature result into cosmosdb
        if (request.requestType === RequestType.FeaturedResult) {
          // Mark featured result as created through request
          const featuredResult = request.itemData as FeaturedResult
          const featuredResultToSave = Object.assign({}, featuredResult)
          featuredResultToSave.requestId = request.id
          featuredResultToSave.requestUser = request.upn

          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'UpsertFeatureResult',
              id: featuredResult.id
            }
          })

          const upsertFeatureResultResponse = await upsertFeaturedResult(
            featuredResultToSave,
            esToken
          )

          if (upsertFeatureResultResponse.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error publishing the feature result: ${upsertFeatureResultResponse.errorMessage}`,
              additionalInfo: {
                action: 'UpsertFeatureResult',
                id: featuredResult.id
              }
            })

            return {
              hasError: true,
              errorMessage: 'Error publishing the feature result'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'UpsertFeatureResult',
              id: featuredResult.id
            }
          })
        } else if (request.requestType === RequestType.AdWord) {
          const adWords = request.itemData as AdsResult[]
          const promiseArray: Array<Promise<ISelfServiceUpdateResponse>> = []

          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'UpsertAdWord',
              id: adWords[0].id
            }
          })

          adWords.forEach((ad: AdsResult) => {
            // Mark adword as created through request
            const adToSave = Object.assign({}, ad)
            adToSave.requestId = request.id
            adToSave.requestUser = request.upn

            promiseArray.push(upsertAdWord(adToSave, esToken))
          })

          const promiseResponse = await Promise.all(promiseArray)

          const errorResponseUpdate = promiseResponse.find(
            (response: ISelfServiceUpdateResponse) => response.hasError
          )
          if (errorResponseUpdate && errorResponseUpdate.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error publishing the adwords ${errorResponseUpdate.errorMessage}`,
              additionalInfo: {
                action: 'UpsertAdWord',
                id: adWords[0].id
              }
            })
            return {
              hasError: true,
              errorMessage: 'Error publishing the adwords'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'UpsertAdWord',
              id: adWords[0].id
            }
          })
        } else if (request.requestType === RequestType.SpellingSuggestion) {
          const spellingSuggestion = request.itemData as DidYouMeanItem
          const promiseArray: Array<Promise<ISelfServiceUpdateResponse>> = []

          if (!spellingSuggestion.id) {
            spellingSuggestion.id = crypto.randomUUID()
          }

          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'UpsertSpellingSuggestion',
              id: spellingSuggestion.id
            }
          })

          // Mark spellingSuggestion as created through request
          const spellingSuggestionToSave = Object.assign({}, spellingSuggestion)
          spellingSuggestionToSave.requestId = request.id
          spellingSuggestionToSave.requestUser = request.upn

          promiseArray.push(upsertDidYouMean(spellingSuggestionToSave, esToken))

          const promiseResponse = await Promise.all(promiseArray)

          const errorResponseUpdate = promiseResponse.find(
            (response: ISelfServiceUpdateResponse) => response.hasError
          )
          if (errorResponseUpdate && errorResponseUpdate.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error publishing spelling suggestion ${errorResponseUpdate.errorMessage}`,
              additionalInfo: {
                action: 'UpsertSpellingSuggestion',
                id: spellingSuggestion.id
              }
            })
            return {
              hasError: true,
              errorMessage: 'Error publishing spelling suggestion'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'UpsertSpellingSuggestion',
              id: spellingSuggestion.id
            }
          })
        }
      }

      if (adminActionType === AdminActionType.UnPublish) {
        if (request.requestType === RequestType.AdWord) {
          const adWords = request.itemData as AdsResult[]
          const promiseArray: Array<Promise<ISelfServiceUpdateResponse>> = []

          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'DeleteAdWords',
              id: adWords[0].id
            }
          })

          adWords.forEach((ad: AdsResult) => {
            promiseArray.push(deleteAdWord(ad.id, ad.language, esToken))
          })

          const promiseResponse = await Promise.all(promiseArray)

          const errorResponseDelete = promiseResponse.find(
            (response: ISelfServiceUpdateResponse) => response.hasError
          )
          if (errorResponseDelete && errorResponseDelete.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error unpublishing the adword: ${errorResponseDelete.errorMessage}`,
              additionalInfo: {
                action: 'DeleteAdWords',
                id: adWords[0].id
              }
            })
            return {
              hasError: true,
              errorMessage: 'Error unpublishing the adword'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'DeleteAdWords',
              id: adWords[0].id
            }
          })
        } else if (request.requestType === RequestType.FeaturedResult) {
          const featuredResult = request.itemData as FeaturedResult

          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'DeleteFeaturedResult',
              id: featuredResult.id
            }
          })

          const delteFeatureResultResponse = await deleteFeaturedResult(
            featuredResult.id,
            esToken
          )

          if (delteFeatureResultResponse.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error unpublishing the feature result: ${delteFeatureResultResponse.errorMessage}`,
              additionalInfo: {
                action: 'DeleteFeaturedResult',
                id: featuredResult.id
              }
            })

            return {
              hasError: true,
              errorMessage: 'Error unpublishing the feature result'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'DeleteFeaturedResult',
              id: featuredResult.id
            }
          })
        } else if (request.requestType === RequestType.SpellingSuggestion) {
          const spellingSuggestionResult = request.itemData as DidYouMeanItem

          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'DeleteSpellingSuggestion',
              id: spellingSuggestionResult.id
            }
          })

          const deleteSpellingSuggestionResponse = await deleteDidYouMean(
            spellingSuggestionResult.id,
            esToken
          )

          if (deleteSpellingSuggestionResponse.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error unpublishing spelling suggestion: ${deleteSpellingSuggestionResponse.errorMessage}`,
              additionalInfo: {
                action: 'DeleteSpellingSuggestion',
                id: spellingSuggestionResult.id
              }
            })

            return {
              hasError: true,
              errorMessage: 'Error unpublishing spelling suggestion'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'DeleteSpellingSuggestion',
              id: spellingSuggestionResult.id
            }
          })
        }
      }

      const requestToSend = Object.assign({}, request)
      cleanUpRequestItem(requestToSend)

      // Set image if cleared
      if (imageUpdated && requestToSend.requestType === RequestType.AdWord) {
        requestToSend.imagePreview = ''
        requestToSend.imageFileName = ''
      }

      if (
        notificationRequest.sendNotification &&
        latestComment &&
        latestComment.text
      ) {
        notificationRequest.latestComment = latestComment.text
      }

      const apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/upsertadminrequest?upn=${ESSettingsGlobalVariables.getUPN()}&requestupn=${
        requestToSend.upn
      }`

      const utf8Bytes = encodeURIComponent(
        JSON.stringify(requestToSend)
      ).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(Number('0x' + p1))
      })

      const headers: HeadersInit = new Headers()
      headers.set('accept', 'application/json')
      headers.set('content-type', 'application/json')
      headers.set(
        'Ocp-Apim-Subscription-Key',
        `${Config.OCP_APIM_SUBSCRIPTION_KEY}`
      )
      headers.set('Authorization', `Bearer ${esToken}`)
      headers.set('if-match', etag)

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body:
          Config.LOCAL_DEVELOPMENT.toString() === 'true'
            ? JSON.stringify({
                content: requestToSend,
                notificationRequest: notificationRequest
              })
            : JSON.stringify({
                content: `${btoa(utf8Bytes).replace(/=/g, '{eq}')}`,
                notificationRequest: notificationRequest
              })
      })
      if (data.status !== 200 && data.status !== 201) {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: true,
          exception: `Error updating the request: ${data.status}`
        })
        return {
          hasError: true,
          errorMessage: 'Error updating the request',
          status: data.status
        }
      }

      // Set image if cleared
      if (
        imageUpdated &&
        imagePreview &&
        imageFileName &&
        requestToSend.requestType === RequestType.AdWord
      ) {
        const response = await uploadImage(
          requestToSend,
          esToken,
          imagePreview,
          imageFileName,
          baseEvent
        )

        if (response.imageError) {
          response.etag = data.headers ? data.headers.get('etag') : null
          request.imagePreview = ''
          request.imageFileName = ''
        }

        return response
      } else {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: false
        })
        return {
          hasError: false,
          errorMessage: '',
          etag: data.headers ? data.headers.get('etag') : null
        }
      }
    } catch (error) {
      trackSelfServiceEvent({
        ...baseEvent,
        hasError: true,
        exception: `Unexpected error updating the admin request: ${
          (error as any).message
        }`
      })
      return {
        hasError: true,
        errorMessage: 'Unexpected error'
      }
    }
  }
}

const toDataURL = (url: string): Promise<string> => {
  return fetch(url, { mode: 'no-cors' })
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result as string)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )
}

export const mapAdminRequest = (
  request: ISelfServiceRequest
): ThunkAction<
  Promise<ISelfServiceUpdateResponse>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      cleanUpRequestItem(request)

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return {
          hasError: true,
          errorMessage: 'Auth token is empty'
        }
      }

      if (
        request.requestType === RequestType.AdWord &&
        request.imagePreview &&
        request.imageFileName
      ) {
        try {
          // Get the image and update the request
          const url = request.imagePreview.startsWith('http')
            ? request.imagePreview
            : `${Config.APIM_BASE_URL.replace('/apim/', '')}${
                request.imagePreview
              }`

          const imageResult = await toDataURL(url)

          if (imageResult && imageResult.startsWith('data:image')) {
            request.imagePreview = imageResult.replace('data:', '')
          } else {
            request.imagePreview = ''
            request.imageFileName = ''
          }
        } catch {
          request.imagePreview = ''
          request.imageFileName = ''
        }
      }

      // Upload the Adword or feature result into cosmosdb
      if (request.requestType === RequestType.FeaturedResult) {
        // Mark featured result as created through request
        const featuredResult = request.itemData as FeaturedResult
        const featuredResultToSave = Object.assign({}, featuredResult)
        featuredResultToSave.requestId = request.id
        featuredResultToSave.requestUser = request.upn

        delete featuredResultToSave.draft

        const upsertFeatureResultResponse = await upsertFeaturedResult(
          featuredResultToSave,
          esToken
        )

        if (upsertFeatureResultResponse.hasError) {
          trackException(
            upsertFeatureResultResponse.errorMessage,
            upsertFeatureResultResponse.error
              ? upsertFeatureResultResponse.error
              : new Error(upsertFeatureResultResponse.errorMessage)
          )
          return {
            hasError: true,
            errorMessage: 'Error publishing the feature result'
          }
        }
      } else if (request.requestType === RequestType.AdWord) {
        const adWords = request.itemData as AdsResult[]
        const promiseArray: Array<Promise<ISelfServiceUpdateResponse>> = []

        adWords.forEach((ad: AdsResult) => {
          // Mark adword as created through request
          const adToSave = Object.assign({}, ad)
          adToSave.requestId = request.id
          adToSave.requestUser = request.upn

          delete adToSave.childs
          delete adToSave.draft
          delete adToSave.foundBySearchTerm

          promiseArray.push(upsertAdWord(adToSave, esToken))
        })

        const promiseResponse = await Promise.all(promiseArray)

        const errorResponseUpdate = promiseResponse.find(
          (response: ISelfServiceUpdateResponse) => response.hasError
        )
        if (errorResponseUpdate && errorResponseUpdate.hasError) {
          trackException(
            errorResponseUpdate.errorMessage,
            errorResponseUpdate.error
              ? errorResponseUpdate.error
              : new Error(errorResponseUpdate.errorMessage)
          )
          return {
            hasError: true,
            errorMessage: 'Error publishing the adwords'
          }
        }
      }

      let notificationRequest: INotificatonRequest = {
        notificationType: null,
        sendNotification: false,
        latestComment: null
      }

      const apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/upsertadminrequest?upn=${ESSettingsGlobalVariables.getUPN()}&requestupn=${
        request.upn
      }`

      const utf8Bytes = encodeURIComponent(JSON.stringify(request)).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode(Number('0x' + p1))
        }
      )

      const headers: HeadersInit = new Headers()
      headers.set('accept', 'application/json')
      headers.set('content-type', 'application/json')
      headers.set(
        'Ocp-Apim-Subscription-Key',
        `${Config.OCP_APIM_SUBSCRIPTION_KEY}`
      )
      headers.set('Authorization', `Bearer ${esToken}`)
      headers.set('if-match', 'isNewItem')

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body:
          Config.LOCAL_DEVELOPMENT.toString() === 'true'
            ? JSON.stringify({
                content: request,
                notificationRequest: notificationRequest
              })
            : JSON.stringify({
                content: `${btoa(utf8Bytes).replace(/=/g, '{eq}')}`,
                notificationRequest: notificationRequest
              })
      })
      if (data.status !== 200 && data.status !== 201) {
        return {
          hasError: true,
          errorMessage: 'Error updating the request',
          status: data.status
        }
      }

      return {
        hasError: false,
        errorMessage: '',
        etag: data.headers ? data.headers.get('etag') : null
      }
    } catch (error) {
      return {
        hasError: true,
        errorMessage: 'Unexpected error'
      }
    }
  }
}

export const deleteAdminRequest = (
  deleteItem: ISelfServiceRequest
): ThunkAction<
  Promise<ISelfServiceAdminDeleteResponse>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    const baseEvent: ISelfServiceTrackingEvent = {
      requestType: deleteItem.requestType,
      id: deleteItem.id,
      _rid: deleteItem._rid ? deleteItem._rid : '',
      action: 'DeleteRequest'
    }

    try {
      trackSelfServiceEvent(baseEvent)

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: true,
          exception: `Auth token is empty`
        })

        return {
          hasError: true,
          errorMessage: 'Auth token is empty',
          requestId: deleteItem.id,
          requestUpn: deleteItem.upn
        }
      }

      if (deleteItem.oncePublished) {
        if (deleteItem.requestType === RequestType.AdWord) {
          const adWords = deleteItem.itemData as AdsResult[]
          const promiseArray: Array<Promise<ISelfServiceUpdateResponse>> = []

          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'DeleteAdWord',
              id: adWords[0].id
            }
          })

          adWords.forEach((ad: AdsResult) => {
            promiseArray.push(deleteAdWord(ad.id, ad.language, esToken))
          })

          const promiseResponse = await Promise.all(promiseArray)

          const errorResponseDelete = promiseResponse.find(
            (response: ISelfServiceUpdateResponse) => response.hasError
          )
          if (errorResponseDelete && errorResponseDelete.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              additionalInfo: {
                action: 'DeleteAdWord',
                hasError: true,
                exception: errorResponseDelete.errorMessage,
                id: adWords[0].id
              }
            })

            return {
              hasError: true,
              errorMessage: 'Error deleting the adwords',
              requestId: deleteItem.id,
              requestUpn: deleteItem.upn
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'DeleteAdWord',
              id: adWords[0].id
            }
          })
        } else if (deleteItem.requestType === RequestType.FeaturedResult) {
          const featuredResult = deleteItem.itemData as FeaturedResult
          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'DeleteFeaturedResult',
              id: featuredResult.id
            }
          })

          const delteFeatureResultResponse = await deleteFeaturedResult(
            featuredResult.id,
            esToken
          )

          if (delteFeatureResultResponse.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: delteFeatureResultResponse.errorMessage,
              additionalInfo: {
                action: 'DeleteFeaturedResult',
                id: featuredResult.id
              }
            })

            return {
              hasError: true,
              errorMessage: 'Error deleting the feature result',
              requestId: deleteItem.id,
              requestUpn: deleteItem.upn
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'DeleteFeaturedResult',
              id: featuredResult.id
            }
          })
        }
      }

      const apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/deleteadminrequest?upn=${ESSettingsGlobalVariables.getUPN()}&id=${
        deleteItem.id
      }&requestupn=${deleteItem.upn}`

      const data = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 200 && data.status !== 204) {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: true,
          exception: `Error deleting the admin request: ${data.status}`
        })
        return {
          hasError: true,
          errorMessage: 'Error updating the request',
          status: data.status,
          requestId: deleteItem.id,
          requestUpn: deleteItem.upn
        }
      }

      trackSelfServiceEvent({
        ...baseEvent,
        hasError: false
      })

      return {
        hasError: false,
        errorMessage: '',
        requestId: deleteItem.id,
        requestUpn: deleteItem.upn
      }
    } catch (error) {
      trackSelfServiceEvent({
        ...baseEvent,
        hasError: true,
        exception: `Unexpected error deleting the admin request: ${
          (error as any).message
        }`
      })
      return {
        hasError: true,
        errorMessage: 'Unexpected error',
        requestId: deleteItem.id,
        requestUpn: deleteItem.upn
      }
    }
  }
}

const groupBy = (items: any[], key: string) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  )

const sendExpiredNotification = async (
  expiredNotification: IExpirationAlertRequest,
  esToken: string
): Promise<ISelfServiceUpdateResponse> => {
  try {
    const apiUrl = `${
      Config.APIM_BASE_URL
    }selfserviceapi/postexpirednotification?upn=${ESSettingsGlobalVariables.getUPN()}`

    const headers: HeadersInit = new Headers()
    headers.set('accept', 'application/json')
    headers.set('content-type', 'application/json')
    headers.set(
      'Ocp-Apim-Subscription-Key',
      `${Config.OCP_APIM_SUBSCRIPTION_KEY}`
    )
    headers.set('Authorization', `Bearer ${esToken}`)

    const utf8Bytes = encodeURIComponent(
      JSON.stringify(expiredNotification)
    ).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(Number('0x' + p1))
    })

    const data = await fetch(apiUrl, {
      method: 'POST',
      headers: headers,
      body:
        Config.LOCAL_DEVELOPMENT.toString() === 'true'
          ? JSON.stringify({
              content: expiredNotification
            })
          : JSON.stringify({
              content: `${btoa(utf8Bytes).replace(/=/g, '{eq}')}`
            })
    })

    if (data.status !== 200) {
      return {
        hasError: true,
        errorMessage: 'Error sending the expired notification',
        status: data.status
      }
    }

    return {
      hasError: false,
      errorMessage: ''
    }
  } catch {
    return {
      hasError: true,
      errorMessage: 'Unexpected error sending expired notification'
    }
  }
}

const generateExpiredNotifications = (
  allRequests: ISelfServiceRequest[],
  expirationConfig: IExpirationAlertConfiguration
): IExpirationAlertRequest[] => {
  const requestsToCheck = allRequests.filter((req: ISelfServiceRequest) => {
    return req.oncePublished && req.status !== RequestStatus.ToBeDeleted
  })

  const expiredNotificationsToSend: IExpirationAlertRequest[] = []
  // Generate the required notifications
  const requestsGroupedByUser = groupBy(requestsToCheck, 'upn')

  Object.keys(requestsGroupedByUser).forEach((key: string) => {
    const expirationAlertRequest: IExpirationAlertRequest = {
      upn: key,
      requestsToExpire: [],
      expirationConfig: expirationConfig
    }

    let requests: ISelfServiceRequest[] = requestsGroupedByUser[key]

    // filter by type
    if (expirationConfig.notificationsFor !== 'all') {
      requests = requests.filter((req: ISelfServiceRequest) => {
        if (expirationConfig.notificationsFor === 'adword') {
          return req.requestType === RequestType.AdWord
        } else {
          return req.requestType === RequestType.FeaturedResult
        }
      })
    }

    requests.forEach((req: ISelfServiceRequest) => {
      let referenceDaysNumber = 1
      switch (expirationConfig.expirationInterval) {
        case 'month':
          referenceDaysNumber = -30
          break
        case 'week':
          referenceDaysNumber = -7
          break
        default:
          referenceDaysNumber = -1
          break
      }

      if (req.endDate) {
        const diff = dayjs().diff(new Date(req.endDate), 'days', true)
        const expiredRequest: IExpiredRequest = {
          id: req.id,
          createdBy: req.createdBy,
          title: req.title,
          status: req.status,
          requestType: req.requestType,
          endDate: req.endDate
        }

        if (
          !expirationConfig.sendNotificationForExpired &&
          Math.ceil(diff) >= referenceDaysNumber &&
          diff < 1
        ) {
          expirationAlertRequest.requestsToExpire.push(expiredRequest)
        } else if (
          expirationConfig.sendNotificationForExpired &&
          Math.ceil(diff) >= referenceDaysNumber
        ) {
          expirationAlertRequest.requestsToExpire.push(expiredRequest)
        }
      }
    })

    if (expirationAlertRequest.requestsToExpire.length > 0) {
      expiredNotificationsToSend.push(expirationAlertRequest)
    }
  })

  return expiredNotificationsToSend
}

export const runAdminExpirationCheck = (
  expirationConfig: IExpirationAlertConfiguration
): ThunkAction<
  Promise<ISelfServiceAdminExpirationCheckResponse>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return {
          hasError: true,
          errorMessage: 'Auth token is empty'
        }
      }

      // Get all requests
      const apiUrlAllRequests = `${
        Config.APIM_BASE_URL
      }selfserviceapi/getallrequests?upn=${ESSettingsGlobalVariables.getUPN()}`

      const initialResponse = await fetch(apiUrlAllRequests, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!initialResponse || !initialResponse.ok) {
        return {
          hasError: true,
          errorMessage: 'Error getting the latest all requests',
          status: initialResponse.status
        }
      }

      let allRequests: ISelfServiceRequest[] = []

      allRequests = await fetchResultsWithContinueToken(
        allRequests,
        initialResponse,
        apiUrlAllRequests,
        esToken,
        'GetAllRequests'
      )

      const expiredNotificationsToSend = generateExpiredNotifications(
        allRequests,
        expirationConfig
      )

      if (expiredNotificationsToSend.length < 1) {
        return {
          hasError: false,
          noNotifications: true,
          errorMessage: ''
        }
      }

      const promiseArray: Array<
        Promise<ISelfServiceAdminExpirationCheckResponse>
      > = []

      expiredNotificationsToSend.forEach(
        (expiredNotification: IExpirationAlertRequest) => {
          promiseArray.push(
            sendExpiredNotification(expiredNotification, esToken)
          )
        }
      )

      const promiseResponse = await Promise.all(promiseArray)

      const errorResponseNotification = promiseResponse.find(
        (response: ISelfServiceAdminExpirationCheckResponse) =>
          response.hasError
      )
      const successReponseNotification = promiseResponse.find(
        (response: ISelfServiceAdminExpirationCheckResponse) =>
          !response.hasError
      )

      return {
        hasError: !!errorResponseNotification && !successReponseNotification,
        hasPartialError:
          !!errorResponseNotification && !!successReponseNotification,
        errorMessage: 'Error sending the expiration notifications'
      }
    } catch {
      return {
        hasError: true,
        errorMessage: 'Unexpected error running the expiration check'
      }
    }
  }
}

/** Admin action reassign items */

export interface IUpdateRequestSuccess extends Action<SelfServiceActionTypes> {
  payload: {
    newRequests: ISelfServiceRequest[]
    deletedRequests: ISelfServiceRequest[]
  }
}

export const updateRequestSuccess = (
  newRequests: ISelfServiceRequest[],
  deletedRequests: ISelfServiceRequest[]
): IUpdateRequestSuccess => ({
  type: SelfServiceActionTypes.UPDATE_REQUESTS_SUCCESS,
  payload: { newRequests, deletedRequests }
})

export type IStartUpdateRequests = Action<SelfServiceActionTypes>
export const startUpdateRequests = (): IStartUpdateRequests => ({
  type: SelfServiceActionTypes.START_UPDATE_REQUESTS
})

const callAdminCreateRequest = async (
  newRequestItem: ISelfServiceRequest,
  esToken: string,
  sendNotifications: boolean
): Promise<ISelfServiceUpdateResponse> => {
  const request = Object.assign({}, newRequestItem)

  const baseEvent: ISelfServiceTrackingEvent = {
    requestType: request.requestType,
    id: request.id,
    _rid: request._rid ? request._rid : '',
    action: 'CreateRequest'
  }

  try {
    const imagePreview = request.imagePreview
    const imageFileName = request.imageFileName

    cleanUpRequestItem(request)

    // Set image if cleared
    if (request.requestType === RequestType.AdWord) {
      request.imagePreview = ''
      request.imageFileName = ''
    }

    let apiUrl = `${
      Config.APIM_BASE_URL
    }selfserviceapi/postcreateadminrequest?upn=${ESSettingsGlobalVariables.getUPN()}&requestupn=${
      request.upn
    }`

    trackSelfServiceEvent(baseEvent)

    if (esToken === '') {
      trackSelfServiceEvent({
        ...baseEvent,
        hasError: true,
        exception: 'Auth token is empty'
      })

      return {
        hasError: true,
        errorMessage: 'Auth token is empty'
      }
    }

    const utf8Bytes = encodeURIComponent(JSON.stringify(request)).replace(
      /%([0-9A-F]{2})/g,
      function (match, p1) {
        return String.fromCharCode(Number('0x' + p1))
      }
    )

    const headers: HeadersInit = new Headers()
    headers.set('accept', 'application/json')
    headers.set('content-type', 'application/json')
    headers.set(
      'Ocp-Apim-Subscription-Key',
      `${Config.OCP_APIM_SUBSCRIPTION_KEY}`
    )
    headers.set('Authorization', `Bearer ${esToken}`)

    const data = await fetch(apiUrl, {
      method: 'POST',
      headers: headers,
      body:
        Config.LOCAL_DEVELOPMENT.toString() === 'true'
          ? JSON.stringify({
              content: request
            })
          : JSON.stringify({
              content: `${btoa(utf8Bytes).replace(/=/g, '{eq}')}`
            })
    })

    if (data.status !== 200 && data.status !== 201) {
      trackSelfServiceEvent({
        ...baseEvent,
        hasError: true,
        exception: `Error updating the request: ${data.status}`
      })
      return {
        hasError: true,
        errorMessage: 'Error updating the request',
        status: data.status
      }
    }

    const responseItem = await data.json()
    newRequestItem.id = responseItem.id
    request.id = responseItem.id
    newRequestItem._rid = responseItem._rid
    request._rid = responseItem._rid

    baseEvent.id = responseItem.id
    baseEvent._rid = responseItem._rid

    trackSelfServiceEvent({
      ...baseEvent,
      hasError: false
    })

    try {
      if (sendNotifications === true) {
        // Get all user requests
        const apiUrl = `${
          Config.APIM_BASE_URL
        }selfserviceapi/postrequestnotification?upn=${ESSettingsGlobalVariables.getUPN()}&displayname=${ESSettingsGlobalVariables.getDisplayName()}`

        const utf8Bytes = encodeURIComponent(JSON.stringify(request)).replace(
          /%([0-9A-F]{2})/g,
          function (match, p1) {
            return String.fromCharCode(Number('0x' + p1))
          }
        )

        const requestNotificationResponse = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          },
          body:
            Config.LOCAL_DEVELOPMENT.toString() === 'true'
              ? JSON.stringify({
                  content: request
                })
              : JSON.stringify({
                  content: `${btoa(utf8Bytes).replace(/=/g, '{eq}')}`
                })
        })

        if (!requestNotificationResponse || !requestNotificationResponse.ok) {
          trackException(
            'Error in generating request on behalf of a user notification',
            new Error(
              'Error in generating request on behalf of a user notification'
            )
          )
        }
      }
    } catch (error) {
      trackException(
        'Error in generating request on behalf of a user notification',
        error
      )
    }

    if (
      imagePreview &&
      imageFileName &&
      request.requestType === RequestType.AdWord
    ) {
      const response = await uploadImage(
        request,
        esToken,
        imagePreview,
        imageFileName,
        baseEvent
      )

      if (response.imageError) {
        response.etag = data.headers ? data.headers.get('etag') : null
        newRequestItem.imagePreview = ''
        newRequestItem.imageFileName = ''
      }

      return response
    } else {
      return {
        hasError: false,
        errorMessage: '',
        etag: data.headers ? data.headers.get('etag') : null
      }
    }
  } catch (error) {
    trackSelfServiceEvent({
      ...baseEvent,
      hasError: true,
      exception: `Unexpected error updating the request: ${
        (error as any).message
      }`
    })
    return {
      hasError: true,
      errorMessage: 'Unexpected error'
    }
  }
}

const callDeleteAdminRequest = async (
  deleteItem: ISelfServiceRequest,
  esToken: string
): Promise<ISelfServiceAdminDeleteResponse> => {
  const baseEvent: ISelfServiceTrackingEvent = {
    requestType: deleteItem.requestType,
    id: deleteItem.id,
    _rid: deleteItem._rid ? deleteItem._rid : '',
    action: 'DeleteRequest'
  }

  try {
    trackSelfServiceEvent(baseEvent)

    const apiUrl = `${
      Config.APIM_BASE_URL
    }selfserviceapi/deleteadminrequest?upn=${ESSettingsGlobalVariables.getUPN()}&id=${
      deleteItem.id
    }&requestupn=${deleteItem.upn}`

    const data = await fetch(apiUrl, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${esToken}`
      }
    })

    if (data.status !== 200 && data.status !== 204) {
      trackSelfServiceEvent({
        ...baseEvent,
        hasError: true,
        exception: `Error deleting the admin request: ${data.status}`
      })
      return {
        hasError: true,
        errorMessage: 'Error updating the request',
        status: data.status,
        requestId: deleteItem.id,
        requestUpn: deleteItem.upn
      }
    }

    trackSelfServiceEvent({
      ...baseEvent,
      hasError: false
    })

    return {
      hasError: false,
      errorMessage: '',
      requestId: deleteItem.id,
      requestUpn: deleteItem.upn
    }
  } catch (error) {
    trackSelfServiceEvent({
      ...baseEvent,
      hasError: true,
      exception: `Unexpected error deleting the admin request: ${
        (error as any).message
      }`
    })
    return {
      hasError: true,
      errorMessage: 'Unexpected error',
      requestId: deleteItem.id,
      requestUpn: deleteItem.upn
    }
  }
}

export const reAssignRequests = (
  reAssignItems: ISelfServiceRequest[],
  upn: string,
  displayName: string
): ThunkAction<
  Promise<ISelfServiceAdminReAssignResponse>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    const reAssignResponse: ISelfServiceAdminReAssignResponse = {
      hasError: false,
      hasPartialError: false,
      addedItems: [],
      deletedItems: [],
      failedItems: []
    }

    const globalReAssignEvent: ISelfServiceTrackingEvent = {
      action: 'ReAssignRequest',
      additionalInfo: {
        newupn: upn,
        itemsToChange: reAssignItems.map((request: ISelfServiceRequest) => {
          return { id: request.id, _rid: request._rid }
        }),
        failedItems: []
      }
    }

    trackSelfServiceEvent(globalReAssignEvent)
    dispatch(startUpdateRequests())

    // Get and check authentication token
    const aadInfo = AuthStore.selectors.getAADInfo(getState())
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )
    if (esToken !== aadInfo.accessToken) {
      AuthStore.actions.setAuthToken(esToken)
    }

    for (const requestItem of reAssignItems) {
      try {
        if (
          requestItem.requestType !== RequestType.AdWord &&
          requestItem.requestType !== RequestType.FeaturedResult
        ) {
          continue
        }

        const newRequest = Object.assign({}, requestItem)

        newRequest.createdBy = displayName
        newRequest.upn = upn
        newRequest.id = 'new'

        const response: ISelfServiceUpdateResponse =
          await callAdminCreateRequest(newRequest, esToken, false)

        newRequest._etag = response.etag ? response.etag : ''

        if (!response.hasError) {
          reAssignResponse.addedItems.push(newRequest)

          const deleteResponse = await callDeleteAdminRequest(
            requestItem,
            esToken
          )

          if (!deleteResponse.hasError) {
            reAssignResponse.deletedItems.push(requestItem)

            if (newRequest.oncePublished) {
              const baseEvent: ISelfServiceTrackingEvent = {
                requestType: newRequest.requestType,
                id: newRequest.id,
                _rid: newRequest._rid ? newRequest._rid : '',
                action: 'ReAssignRequest'
              }

              if (newRequest.requestType === RequestType.FeaturedResult) {
                // Mark featured result as created through request
                const featuredResult = newRequest.itemData as FeaturedResult
                const featuredResultToSave = Object.assign({}, featuredResult)
                featuredResultToSave.requestId = newRequest.id
                featuredResultToSave.requestUser = newRequest.upn

                trackSelfServiceEvent({
                  ...baseEvent,
                  additionalInfo: {
                    action: 'UpsertFeatureResult',
                    id: featuredResult.id
                  }
                })

                const upsertFeatureResultResponse = await upsertFeaturedResult(
                  featuredResultToSave,
                  esToken
                )

                if (upsertFeatureResultResponse.hasError) {
                  trackSelfServiceEvent({
                    ...baseEvent,
                    hasError: true,
                    exception: `Error publishing the feature result: ${upsertFeatureResultResponse.errorMessage}`,
                    additionalInfo: {
                      action: 'UpsertFeatureResult',
                      id: featuredResult.id
                    }
                  })

                  reAssignResponse.hasPartialError = true
                  reAssignResponse.failedItems.push({
                    id: requestItem.id,
                    upn: requestItem.upn,
                    _rid: requestItem._rid
                  })
                }

                trackSelfServiceEvent({
                  ...baseEvent,
                  hasError: false,
                  additionalInfo: {
                    action: 'UpsertFeatureResult',
                    id: featuredResult.id
                  }
                })
              } else if (newRequest.requestType === RequestType.AdWord) {
                const adWords = newRequest.itemData as AdsResult[]
                const promiseArray: Array<Promise<ISelfServiceUpdateResponse>> =
                  []

                trackSelfServiceEvent({
                  ...baseEvent,
                  additionalInfo: {
                    action: 'UpsertAdWord',
                    id: adWords[0].id
                  }
                })

                adWords.forEach((ad: AdsResult) => {
                  // Mark adword as created through request
                  const adToSave = Object.assign({}, ad)
                  adToSave.requestId = newRequest.id
                  adToSave.requestUser = newRequest.upn

                  promiseArray.push(upsertAdWord(adToSave, esToken))
                })

                const promiseResponse = await Promise.all(promiseArray)

                const errorResponseUpdate = promiseResponse.find(
                  (response: ISelfServiceUpdateResponse) => response.hasError
                )
                if (errorResponseUpdate && errorResponseUpdate.hasError) {
                  trackSelfServiceEvent({
                    ...baseEvent,
                    hasError: true,
                    exception: `Error publishing the adwords ${errorResponseUpdate.errorMessage}`,
                    additionalInfo: {
                      action: 'UpsertAdWord',
                      id: adWords[0].id
                    }
                  })

                  reAssignResponse.hasPartialError = true
                  reAssignResponse.failedItems.push({
                    id: requestItem.id,
                    upn: requestItem.upn,
                    _rid: requestItem._rid
                  })
                }

                trackSelfServiceEvent({
                  ...baseEvent,
                  hasError: false,
                  additionalInfo: {
                    action: 'UpsertAdWord',
                    id: adWords[0].id
                  }
                })
              }
            }
          } else {
            reAssignResponse.hasPartialError = true
            reAssignResponse.failedItems.push({
              id: requestItem.id,
              upn: requestItem.upn,
              _rid: requestItem._rid
            })
          }
        } else {
          reAssignResponse.hasPartialError = true
          reAssignResponse.failedItems.push({
            id: requestItem.id,
            upn: requestItem.upn,
            _rid: requestItem._rid
          })
        }
      } catch {
        reAssignResponse.hasPartialError = true
        reAssignResponse.failedItems.push({
          id: requestItem.id,
          upn: requestItem.upn,
          _rid: requestItem._rid
        })
      }
    }

    reAssignResponse.hasError = false

    dispatch(
      updateRequestSuccess(
        reAssignResponse.addedItems,
        reAssignResponse.deletedItems
      )
    )

    if (
      reAssignResponse.hasPartialError &&
      reAssignResponse.failedItems.length === reAssignItems.length
    ) {
      reAssignResponse.hasError = true
    }

    globalReAssignEvent.hasError =
      reAssignResponse.hasPartialError || reAssignResponse.hasError
    globalReAssignEvent.additionalInfo.failedItems =
      reAssignResponse.failedItems.map((failedRequest) => {
        return {
          id: failedRequest.id,
          _rid: failedRequest._rid
        }
      })

    trackSelfServiceEvent(globalReAssignEvent)

    try {
      // If not all reassigned requests have errors
      if (!reAssignResponse.hasError) {
        // Get all user requests
        const apiUrl = `${
          Config.APIM_BASE_URL
        }selfserviceapi/postreassignnotification?upn=${ESSettingsGlobalVariables.getUPN()}&displayname=${ESSettingsGlobalVariables.getDisplayName()}`

        const utf8Bytes = encodeURIComponent(
          JSON.stringify(reAssignResponse.addedItems)
        ).replace(/%([0-9A-F]{2})/g, function (match, p1) {
          return String.fromCharCode(Number('0x' + p1))
        })

        const reassignNotificationResponse = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          },
          body:
            Config.LOCAL_DEVELOPMENT.toString() === 'true'
              ? JSON.stringify({
                  content: reAssignResponse.addedItems
                })
              : JSON.stringify({
                  content: `${btoa(utf8Bytes).replace(/=/g, '{eq}')}`
                })
        })

        if (!reassignNotificationResponse || !reassignNotificationResponse.ok) {
          trackException(
            'Error in generating reassign requests notification',
            new Error('Error in generating reassign requests notification')
          )
        }
      }
    } catch (error) {
      trackException(
        'Error in generating reassign requests notification',
        error
      )
    }

    return reAssignResponse
  }
}

export const createAdminRequest = (
  referenceRowData: ISelfServiceRequest
): ThunkAction<
  Promise<ISelfServiceUpdateResponse>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    const baseEvent: ISelfServiceTrackingEvent = {
      requestType: referenceRowData.requestType,
      id: referenceRowData.id,
      _rid: referenceRowData.id,
      action: 'Create Admin Request'
    }

    try {
      trackSelfServiceEvent(baseEvent)
      dispatch(startUpdateRequests())

      const imagePreview = referenceRowData.imagePreview
      const imageFileName = referenceRowData.imageFileName

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: true,
          exception: 'Auth token is empty'
        })
        return {
          hasError: true,
          errorMessage: 'Auth token is empty'
        }
      }

      if (referenceRowData.status === RequestStatus.Published) {
        if (
          referenceRowData.requestType === RequestType.AdWord &&
          imagePreview &&
          imageFileName
        ) {
          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'UpsertImage',
              fileName: imageFileName
            }
          })

          // Upload the image and update the ads
          const upsertImageResponse: ISelfServiceImageUploadResponse =
            await upsertImage(imageFileName, imagePreview, true, esToken)

          if (upsertImageResponse.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Image upload failed: ${upsertImageResponse.errorMessage}`,
              additionalInfo: {
                action: 'UpsertImage',
                fileName: imageFileName
              }
            })
            return {
              hasError: true,
              errorMessage: 'Image upload failed'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'UpsertImage',
              fileName: imageFileName,
              urlResponse: upsertImageResponse.url
            }
          })

          const adWords = referenceRowData.itemData as AdsResult[]
          adWords.forEach((ad: AdsResult) => {
            ad.image = upsertImageResponse.url
          })
        }

        // get and set the item ids in case not published once
        if (referenceRowData.requestType !== RequestType.SpellingSuggestion) {
          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'GetNextId'
            }
          })

          const responseHighestId = await getNextId(
            referenceRowData.requestType,
            esToken
          )

          if (responseHighestId.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error getting the next free id: ${responseHighestId.errorMessage}`,
              additionalInfo: {
                action: 'GetNextId'
              }
            })

            return {
              hasError: true,
              errorMessage: 'Error getting the next free id'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'GetNextId',
              nextId: responseHighestId.newId
            }
          })

          if (referenceRowData.requestType === RequestType.FeaturedResult) {
            const featuredResult = referenceRowData.itemData as FeaturedResult
            featuredResult.id = responseHighestId.newId
          } else if (referenceRowData.requestType === RequestType.AdWord) {
            const adWords = referenceRowData.itemData as AdsResult[]
            adWords.forEach((ad: AdsResult) => {
              ad.id = responseHighestId.newId
            })
          }
        }

        // Upload the Adword or feature result into cosmosdb
        if (referenceRowData.requestType === RequestType.FeaturedResult) {
          // Mark featured result as created through request
          const featuredResult = referenceRowData.itemData as FeaturedResult
          const featuredResultToSave = Object.assign({}, featuredResult)
          featuredResultToSave.requestId = referenceRowData.id
          featuredResultToSave.requestUser = referenceRowData.upn

          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'UpsertFeatureResult',
              id: featuredResult.id
            }
          })

          const upsertFeatureResultResponse = await upsertFeaturedResult(
            featuredResultToSave,
            esToken
          )

          if (upsertFeatureResultResponse.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error publishing the feature result: ${upsertFeatureResultResponse.errorMessage}`,
              additionalInfo: {
                action: 'UpsertFeatureResult',
                id: featuredResult.id
              }
            })

            return {
              hasError: true,
              errorMessage: 'Error publishing the feature result'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'UpsertFeatureResult',
              id: featuredResult.id
            }
          })
        } else if (referenceRowData.requestType === RequestType.AdWord) {
          const adWords = referenceRowData.itemData as AdsResult[]
          const promiseArray: Array<Promise<ISelfServiceUpdateResponse>> = []

          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'UpsertAdWord',
              id: adWords[0].id
            }
          })

          adWords.forEach((ad: AdsResult) => {
            // Mark adword as created through request
            const adToSave = Object.assign({}, ad)
            adToSave.requestId = referenceRowData.id
            adToSave.requestUser = referenceRowData.upn

            promiseArray.push(upsertAdWord(adToSave, esToken))
          })

          const promiseResponse = await Promise.all(promiseArray)

          const errorResponseUpdate = promiseResponse.find(
            (response: ISelfServiceUpdateResponse) => response.hasError
          )
          if (errorResponseUpdate && errorResponseUpdate.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error publishing the adwords ${errorResponseUpdate.errorMessage}`,
              additionalInfo: {
                action: 'UpsertAdWord',
                id: adWords[0].id
              }
            })
            return {
              hasError: true,
              errorMessage: 'Error publishing the adwords'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'UpsertAdWord',
              id: adWords[0].id
            }
          })
        } else if (
          referenceRowData.requestType === RequestType.SpellingSuggestion
        ) {
          const spellingSuggestion = referenceRowData.itemData as DidYouMeanItem
          const promiseArray: Array<Promise<ISelfServiceUpdateResponse>> = []

          if (!spellingSuggestion.id) {
            spellingSuggestion.id = crypto.randomUUID()
          }

          trackSelfServiceEvent({
            ...baseEvent,
            additionalInfo: {
              action: 'UpsertSpellingSuggestion',
              id: spellingSuggestion.id
            }
          })

          // Mark spellingSuggestion as created through request
          const spellingSuggestionToSave = Object.assign({}, spellingSuggestion)
          spellingSuggestionToSave.requestId = referenceRowData.id
          spellingSuggestionToSave.requestUser = referenceRowData.upn

          promiseArray.push(upsertDidYouMean(spellingSuggestionToSave, esToken))

          const promiseResponse = await Promise.all(promiseArray)

          const errorResponseUpdate = promiseResponse.find(
            (response: ISelfServiceUpdateResponse) => response.hasError
          )
          if (errorResponseUpdate && errorResponseUpdate.hasError) {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: `Error publishing spelling suggestion ${errorResponseUpdate.errorMessage}`,
              additionalInfo: {
                action: 'UpsertSpellingSuggestion',
                id: spellingSuggestion.id
              }
            })
            return {
              hasError: true,
              errorMessage: 'Error publishing spelling suggestion'
            }
          }

          trackSelfServiceEvent({
            ...baseEvent,
            hasError: false,
            additionalInfo: {
              action: 'UpsertSpellingSuggestion',
              id: spellingSuggestion.id
            }
          })
        }
      }

      const response: ISelfServiceUpdateResponse = await callAdminCreateRequest(
        referenceRowData,
        esToken,
        true
      )

      referenceRowData._etag = response.etag ? response.etag : ''

      if (!response.hasError) {
        dispatch(updateRequestSuccess([referenceRowData], []))
      }

      trackSelfServiceEvent({
        ...baseEvent,
        hasError: response.hasError,
        exception: response.errorMessage
      })

      return response
    } catch (error) {
      trackSelfServiceEvent({
        ...baseEvent,
        hasError: true,
        exception: `Unexpected error updating the admin request: ${
          (error as any).message
        }`
      })
      return {
        hasError: true,
        errorMessage: 'Unexpected error'
      }
    }
  }
}
export type SelfServiceActions =
  | IFetchMyRequestsRequest
  | IFetchMyRequestsSuccess
  | IFetchMyRequestsFailure
