import { IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  checkIsValidDidYouMeanItem,
  editFormErrorKeys
} from 'utils/admin/adminFormUtils'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import createDOMPurify from 'dompurify'
import { ISelfServiceRequest, RequestStatus } from 'models/SelfServiceRequest'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { DidYouMeanItem } from 'models/DidYouMean'

export interface IEditFormSpellingSuggestionRequestProps {
  item: ISelfServiceRequest
  setIsValid: (isValid: boolean) => void
}

export default function EditFormSpellingSuggestionRequest(
  props: IEditFormSpellingSuggestionRequestProps
): JSX.Element {
  const { item, setIsValid } = props

  const rowData: DidYouMeanItem = item.itemData as DidYouMeanItem
  const DOMPurify = createDOMPurify(window)
  const classes = getStylesAdminForm()
  const [errorState, setErrorState] = useState<IErrorListObject[]>([])
  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())

  const hasChanges =
    item.status === RequestStatus.Submitted &&
    item.historyData?.data !== undefined

  const [showHistory, setShowHistory] = useState(hasChanges)

  const validateItem = (): void => {
    let errorList: IErrorListObject[] = checkIsValidDidYouMeanItem(rowData)

    setIsValid(!errorList.find((error: IErrorListObject) => error.error.error))
    setErrorState(errorList)
  }

  useEffect(() => {
    validateItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getError = (key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error && errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }

  const getHistoryData = (key: string): string | undefined => {
    if (
      !item.historyData ||
      item.historyData.isNewItem ||
      !item.historyData.data
    ) {
      return undefined
    }

    const historyData = item.historyData.data as any

    return historyData[key]
  }

  return (
    <>
      {hasChanges && (
        <div className={classes.hideHistoryButtonContainer}>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              setShowHistory(!showHistory)
            }}
            className={classes.hideHistoryButton}
          >
            {showHistory ? (
              <>
                <div style={{ marginRight: '5px' }}>Hide History</div>
                <ArrowForwardIosIcon fontSize="inherit" />
              </>
            ) : (
              <>
                <ArrowBackIosIcon fontSize="inherit" />
                <div style={{ marginLeft: '5px' }}>Show History</div>
              </>
            )}
          </IconButton>
        </div>
      )}
      <div className={classes.historyContainer}>
        <TextField
          variant="standard"
          id="term-required"
          className={classes.tab_item_request}
          label="Term *"
          defaultValue={rowData.term}
          onChange={(event) => {
            rowData.term = event.target.value
              ? DOMPurify.sanitize(event.target.value, {
                  USE_PROFILES: { html: false }
                })
              : event.target.value

            validateItem()
          }}
          error={getError(editFormErrorKeys.original).error}
          helperText={getError(editFormErrorKeys.original).helperText}
          InputLabelProps={{
            shrink: true
          }}
          key={`term_${currentFormState}`}
          onBlur={() => {
            setCurrentFormState(new Date().getTime())
          }}
        />

        {hasChanges &&
          showHistory &&
          (getHistoryData('term') !== undefined ? (
            <TextField
              variant="standard"
              id="term-history"
              className={classes.tab_item_history}
              defaultValue={getHistoryData('term')}
              disabled={true}
              InputLabelProps={{
                shrink: true
              }}
            />
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      <div className={classes.historyContainer}>
        <TextField
          variant="standard"
          id="description"
          className={classes.tab_item_request}
          label="Description"
          defaultValue={rowData.description}
          onChange={(event) => {
            rowData.description = event.target.value
              ? DOMPurify.sanitize(event.target.value, {
                  USE_PROFILES: { html: false }
                })
              : event.target.value

            validateItem()
          }}
          error={getError(editFormErrorKeys.original).error}
          helperText={getError(editFormErrorKeys.original).helperText}
          InputLabelProps={{
            shrink: true
          }}
          key={`description_${currentFormState}`}
          onBlur={() => {
            setCurrentFormState(new Date().getTime())
          }}
        />

        {hasChanges &&
          showHistory &&
          (getHistoryData('description') !== undefined ? (
            <TextField
              variant="standard"
              id="description-history"
              className={classes.tab_item_history}
              defaultValue={getHistoryData('description')}
              disabled={true}
              InputLabelProps={{
                shrink: true
              }}
            />
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
    </>
  )
}
