import React from 'react'
import Rating from '@mui/lab/Rating'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { Column } from '@material-table/core'
import createDOMPurify from 'dompurify'
import {
  supportedLanguages,
  SupportedLanguage
} from 'constants/supportedLanguages'
import { capitalizeFirstLetter, stripHtml } from 'utils/string'
import { AdsResult } from 'models/AdsResult'
import { Add } from '@mui/icons-material'
import AdminTableTooltipClip from 'components/admin/common/AdminTableTooltipClip'
import AdminTableTooltipTitle from 'components/admin/common/AdminTableTooltipTitle'
import { dateFormatOptions } from 'constants/constants'
import { findDefaultFilterComponent } from 'utils/admin/adminFormUtils'
import { getVerticalByKey } from 'constants/supportedVerticals'
import * as Config from '../../../config'

const DOMPurify = createDOMPurify(window)

/**
 * Generate adWords columns list
 * @param changes AdminItemChange list
 * @returns columns list
 */
export const adWordColumns = (): Column<AdsResult>[] => {
  const classes = getStylesAdminSettings()
  return [
    {
      title: 'Id',
      field: 'id',
      type: 'string',
      filtering: false,
      width: 40,
      cellStyle: {
        maxWidth: 40,
        width: 40
      },
      headerStyle: {
        maxWidth: 40,
        width: 40
      },
      customSort: (a: AdsResult, b: AdsResult) =>
        parseInt(a.id) - parseInt(b.id)
    },
    {
      title: 'Language',
      field: 'language',
      filtering: false,
      type: 'string',
      width: 80,
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      render: (rowData: AdsResult) => {
        let allLanguages = true

        const adChilds = rowData.childs
        supportedLanguages.forEach((supportedLanguage: SupportedLanguage) => {
          if (rowData.language !== supportedLanguage.locale) {
            if (
              !adChilds ||
              !adChilds.find(
                (adChild: AdsResult) =>
                  adChild.language === supportedLanguage.locale
              )
            ) {
              allLanguages = false
            }
          }
        })

        return (
          <div className={classes.chipContainer}>
            {allLanguages ? (
              <>
                <AdminTableTooltipClip
                  size="small"
                  label={'All'}
                  maxWidth={90}
                />
              </>
            ) : (
              <>
                <AdminTableTooltipClip
                  size="small"
                  label={rowData.language}
                  maxWidth={90}
                />
                {adChilds && adChilds.length > 0
                  ? adChilds.map((adChild: AdsResult, index: number) => (
                      <AdminTableTooltipClip
                        size="small"
                        label={adChild.language}
                        maxWidth={90}
                        key={index}
                      />
                    ))
                  : ''}
              </>
            )}
          </div>
        )
      }
    },
    {
      title: 'Title',
      field: 'title',
      type: 'string',
      filtering: false,
      render: (rowData: AdsResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          unsafeHTML={`${rowData.draft ? '<i><b>(Draft)</b></i> ' : ''}${
            rowData.title ? DOMPurify.sanitize(rowData.title) : ''
          }`}
          maxLine={4}
        />
      ),
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      }
    },
    {
      title: 'Description',
      field: 'text',
      type: 'string',
      render: (rowData: AdsResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.text ? DOMPurify.sanitize(stripHtml(rowData.text)) : ''}
          maxLine={2}
        />
      ),
      filtering: false
    },
    {
      title: 'Link',
      field: 'link',
      type: 'string',
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      render: (rowData: AdsResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.link}
          maxLine={1}
        />
      ),
      filtering: false
    },
    {
      title: 'Link Text',
      field: 'link_text',
      type: 'string',
      filtering: false,
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      render: (rowData: AdsResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.link_text}
          maxLine={1}
        />
      )
    },
    {
      title: 'Start',
      field: 'start',
      type: 'date',
      filtering: false,
      width: 80,
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      render: (rowData: AdsResult) => {
        if (rowData.start) {
          let parsedDate = new Date(rowData.start)
          const timeOffsetInMS = parsedDate.getTimezoneOffset() * 60000 * -1
          parsedDate.setTime(parsedDate.getTime() - timeOffsetInMS)

          return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
            parsedDate
          )
        }

        return ''
      }
    },
    {
      title: 'End',
      field: 'end',
      type: 'date',
      filtering: false,
      width: 80,
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      render: (rowData: AdsResult) => {
        if (rowData.end) {
          let parsedDate = new Date(rowData.end)
          const timeOffsetInMS = parsedDate.getTimezoneOffset() * 60000 * -1
          parsedDate.setTime(parsedDate.getTime() - timeOffsetInMS)

          return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
            parsedDate
          )
        }

        return ''
      }
    },
    {
      title: 'Site Url',
      field: 'site_url',
      type: 'string',
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      render: (rowData: AdsResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.site_url}
          maxLine={1}
        />
      ),
      filtering: false
    },
    {
      title: 'Image',
      field: 'image',
      type: 'string',
      render: (rowData: AdsResult) =>
        rowData.image ? (
          <img
            src={
              rowData.image.startsWith('/externalContent/adsmedia/')
                ? `${Config.APIM_BASE_URL.replace('/apim/', '')}${
                    rowData.image
                  }`
                : rowData.image
            }
            alt="ad url"
            style={{
              width: 80,
              height: 40
            }}
          />
        ) : (
          ''
        ),
      filtering: false,
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      }
    },
    {
      title: 'Rank',
      field: 'rank',
      type: 'numeric',
      align: 'left',
      width: 120,
      cellStyle: {
        minWidth: 120
      },
      headerStyle: {
        minWidth: 120
      },
      render: (rowData: AdsResult) => (
        <div style={{ display: 'flex' }}>
          <Rating value={rowData.rank} readOnly size="small" />
          {rowData.rank > 5 && <Add style={{ height: '18px' }} />}
        </div>
      ),
      filtering: false
    },
    {
      title: 'Sources',
      field: 'sources',
      type: 'string',
      render: (rowData: AdsResult) => {
        const isAllSet = !!rowData.sources.find(
          (item: string) => item.toLocaleLowerCase() === 'all'
        )

        let showSourcesList = rowData.sources
          .filter((item: string) => item.toLocaleLowerCase() !== 'all')
          .slice(0, isAllSet ? 2 : 3)
        showSourcesList = showSourcesList.map((ds: string) => {
          return ds
        })
        let preFix = ''

        if (isAllSet) {
          showSourcesList = ['All', ...showSourcesList]
          preFix = '!= '
        }

        return (
          <div className={classes.chipContainer}>
            {rowData.sources
              ? showSourcesList.map((source: string, index: number) => (
                  <AdminTableTooltipClip
                    label={
                      source.toLocaleLowerCase() !== 'all'
                        ? preFix + getVerticalByKey(source)
                        : getVerticalByKey(source)
                    }
                    maxWidth={90}
                    variant="outlined"
                    key={index}
                  />
                ))
              : ''}
          </div>
        )
      },
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Countries',
      field: 'countries',
      type: 'string',
      render: (rowData: AdsResult) => {
        const isAllSet = !!rowData.countries.find(
          (item: string) => item.toLocaleLowerCase() === 'all'
        )

        let showCountriesList = rowData.countries
          .filter((item: string) => item.toLocaleLowerCase() !== 'all')
          .slice(0, isAllSet ? 2 : 3)
        let preFix = ''

        if (isAllSet) {
          showCountriesList = ['All', ...showCountriesList]
          preFix = '!= '
        }

        if (!rowData.countries || rowData.countries.length === 0)
          showCountriesList.push('All')

        return (
          <div className={classes.chipContainer}>
            {rowData.countries
              ? showCountriesList.map((country: string, index: number) => (
                  <AdminTableTooltipClip
                    label={
                      country.toLocaleLowerCase() !== 'all'
                        ? preFix + capitalizeFirstLetter(country)
                        : capitalizeFirstLetter(country)
                    }
                    maxWidth={90}
                    variant="outlined"
                    key={index}
                  />
                ))
              : ''}
          </div>
        )
      },
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Match Type',
      field: 'match_type',
      type: 'string',
      render: (rowData: AdsResult) => (
        <AdminTableTooltipClip
          color="primary"
          label={rowData.match_type ? rowData.match_type : 'Contains'}
          variant={'outlined'}
          maxWidth={90}
        />
      ),
      width: 90,
      cellStyle: {
        maxWidth: 90
      },
      headerStyle: {
        maxWidth: 90
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Functions',
      field: 'functions',
      type: 'string',
      render: (rowData: AdsResult) => {
        const isAllSet = !!rowData.functions.find(
          (item: string) => item.toLocaleLowerCase() === 'all'
        )

        let showFunctionsList = rowData.functions
          .filter((item: string) => item.toLocaleLowerCase() !== 'all')
          .slice(0, isAllSet ? 2 : 3)
        let preFix = ''

        if (isAllSet) {
          showFunctionsList = ['All', ...showFunctionsList]
          preFix = '!= '
        }

        if (!rowData.functions || rowData.functions.length === 0)
          showFunctionsList.push('All')

        return (
          <div className={classes.chipContainer}>
            {rowData.functions
              ? showFunctionsList.map((fn: string, index: number) => (
                  <AdminTableTooltipClip
                    label={
                      fn.toLocaleLowerCase() !== 'all'
                        ? preFix + capitalizeFirstLetter(fn)
                        : capitalizeFirstLetter(fn)
                    }
                    maxWidth={90}
                    variant="outlined"
                    key={index}
                  />
                ))
              : ''}
          </div>
        )
      },
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Search Terms',
      field: 'search_terms',
      type: 'string',
      render: (rowData: AdsResult) => (
        <div className={classes.chipContainer}>
          {rowData.search_terms
            ? rowData.search_terms
                .slice(0, 3)
                .map((searchTerm: string, index: number) => (
                  <AdminTableTooltipClip
                    label={searchTerm}
                    maxWidth={90}
                    variant="outlined"
                    key={index}
                  />
                ))
            : ''}
        </div>
      ),
      filtering: false,
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      }
    },
    {
      title: 'Flavour',
      field: 'flavour',
      type: 'string',
      render: (rowData: AdsResult) => (
        <AdminTableTooltipClip
          color="primary"
          label={rowData.flavour ? rowData.flavour : 'small'}
          maxWidth={80}
          variant="outlined"
        />
      ),
      width: 90,
      cellStyle: {
        maxWidth: 90
      },
      headerStyle: {
        maxWidth: 90
      },
      customFilterAndSearch: (value: string, rowData: AdsResult) => {
        if (!value) return true

        if (!rowData.flavour && 'small'.startsWith(value.toLowerCase())) {
          return true
        } else {
          return rowData.flavour
            ? rowData.flavour.startsWith(value.toLowerCase())
            : false
        }
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Scope',
      field: 'scope',
      type: 'string',
      render: (rowData: AdsResult) => (
        <AdminTableTooltipClip
          color="primary"
          label={rowData.scope ? rowData.scope : 'All'}
          maxWidth={80}
          variant="outlined"
        />
      ),
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      customFilterAndSearch: (value: string, rowData: AdsResult) => {
        if (!value) return true

        if (!rowData.scope && 'all'.startsWith(value.toLowerCase())) {
          return true
        } else {
          return rowData.scope
            ? rowData.scope.toLowerCase().startsWith(value.toLowerCase())
            : false
        }
      },
      filterComponent: findDefaultFilterComponent
    }
  ]
}
