import React from 'react'
import { store } from './store/configureStore'
import { Provider } from 'react-redux'
import UserSettings from 'components/UserSettings'
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider
} from '@mui/material/styles'
import { MsalProvider } from '@azure/msal-react'
import { authProvider } from 'authentication/authProvider'

function App() {
  const theme = createTheme({
    palette: {
      primary: { main: '#004e98' },
      secondary: { main: '#545454' }
    },
    typography: {
      fontFamily: `Arial, sans-serif`
    }
  })

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MsalProvider instance={authProvider}>
            <UserSettings authProvider={authProvider} />
          </MsalProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  )
}

export default App
