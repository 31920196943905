import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material'
import { ISelfServiceRequest, RequestComment } from 'models/SelfServiceRequest'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import createDOMPurify from 'dompurify'
import { useDebounce } from 'utils/debounce'

export interface IEditFormCommentDialogProps {
  item: ISelfServiceRequest
  showCommentDialog: boolean
  closeCommentDialog: () => void
  submitNewComment: (newComment: RequestComment | null) => void
  hasActiveChanges: boolean
}

export default function EditFormCommentDialog(
  props: IEditFormCommentDialogProps
): JSX.Element {
  const {
    item,
    showCommentDialog,
    closeCommentDialog,
    submitNewComment,
    hasActiveChanges
  } = props
  const DOMPurify = createDOMPurify(window)
  const classes = getStylesAdminForm()
  const intl = useIntl()

  const initialComment = (): RequestComment => {
    const maxId =
      item.comments && item.comments.length > 0
        ? item.comments.reduce(
            (max, comment) => (comment.id > max ? comment.id : max),
            item.comments[0].id
          )
        : 0

    return {
      id: maxId + 1,
      text: '',
      modifiedDate: new Date().toISOString(),
      modifiedBy: ESSettingsGlobalVariables.getUPN(),
      modifiedByDisplayName: ESSettingsGlobalVariables.getDisplayName()
    }
  }

  const [newComment] = useState<RequestComment>(initialComment())
  const [commentText, setCommentText] = useState<string>('')
  const debouncedCommentText = useDebounce(commentText, 300)

  return (
    <Dialog
      open={showCommentDialog}
      onClose={closeCommentDialog}
      aria-labelledby="confirmation-modal"
      aria-describedby="confirmation-modal-description"
    >
      <DialogContent>
        <div>
          {hasActiveChanges ? (
            <FormattedMessage
              id="form_button_comment_title"
              defaultMessage="Optionally, leave a comment to resolve the pending clarification."
            />
          ) : (
            <FormattedMessage
              id="form_button_comment_title_required"
              defaultMessage="Leave a comment to resolve the pending clarification."
            />
          )}
        </div>
        <TextField
          variant="outlined"
          size="small"
          id="newcomment"
          label={intl.formatMessage({
            id: 'form_button_comment_label',
            defaultMessage: 'Comment'
          })}
          defaultValue={''}
          onChange={(event) => {
            const newTextValue = DOMPurify.sanitize(event.target.value, {
              USE_PROFILES: { html: false }
            })
            newComment.text = newTextValue
            setCommentText(newTextValue)
          }}
          InputLabelProps={{
            shrink: true
          }}
          maxRows={5}
          multiline={true}
          className={classes.dialog_comment_textfield}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            if (!hasActiveChanges && !newComment.text) return

            submitNewComment(newComment.text ? newComment : null)
          }}
          color="primary"
          disabled={!hasActiveChanges && !debouncedCommentText}
        >
          <FormattedMessage
            id="form_button_resolve_clarification"
            defaultMessage="Resolve Clarification"
          />
        </Button>
        <Button onClick={closeCommentDialog} color="primary">
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
