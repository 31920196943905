import { Store } from '..'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'

const selectors = {
  getSubscriptions: (state: Store): INewsLetterSubscription[] =>
    state.newsLetterSubscriptions.subscriptions,
  hasSubscriptionsError: (state: Store): boolean =>
    state.newsLetterSubscriptions.hasSubscriptionsError,
  hasSubscriptionsBeenFetched: (state: Store): boolean =>
    state.newsLetterSubscriptions.hasSubscriptionsBeenFetched,
  hasChangesError: (state: Store): boolean =>
    state.newsLetterSubscriptions.hasChangesError
}

export default selectors
