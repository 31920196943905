import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesAdminSettings(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    scrollfix: {
      '& div > div:nth-child(2) > div  > div': {
        overflowY: 'unset!important'
      },
      '& div > div:nth-child(2) ': {
        overflowX: 'unset!important'
      },
      display: 'table !important',
      width: '100%'
    },
    container: {
      display: 'flex',
      flex: 1,
      flexGrow: 0,
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '85px',
      marginBottom: '80px',
      '& .MuiPaper-root': {
        boxShadow: 'none'
      }
    },
    chipContainer: {
      '& > *': {
        margin: theme.spacing(0.5)
      }
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      '& > h2': {
        margin: 0,
        fontSize: 18
      }
    },
    information: {
      marginTop: 10,
      color: 'red',
      fontSize: 13
    },
    logo: {
      width: 20,
      height: 20,
      marginRight: 8,
      [theme.breakpoints.down(500)]: {
        display: 'none'
      }
    },
    button: {
      margin: 8
    },
    rowEllipsis: {
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',

      '& >div': {
        whiteSpace: 'pre-wrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    },
    loadingSpinner: {
      margin: '100px auto'
    },
    loadingSpinnerContainer: {
      width: '100%',
      display: 'flex'
    }
  }))
}
