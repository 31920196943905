import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterButton(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    rootLayout: {
      lineHeight: '13px',
      alignItems: 'flex-start',
      WebkitUserSelect: 'none',
      msUserSelect: 'none',
      marginRight: '16px',
      marginBottom: 'auto',
      flex: '1',
      flexGrow: 1,
      MozUserSelect: 'none',
      cursor: 'pointer'
    },
    rootInactive: {
      color: '#5F6368',
      alignSelf: 'flex-end'
    },
    rootActive: {
      color: '#005eb8 !important'
    },
    rootNoResults: {
      color: '#dfe1e5'
    },

    icon: {
      marginRight: 5,
      height: 16,
      width: 16,
      marginTop: '1px',
      fontSize: '16px'
    },
    linkText: {
      whiteSpace: 'pre',
      fontSize: 13
    },
    FilterLink: {},
    underBar: {
      backgroundColor: '#005eb8',
      height: '3px',
      width: '100%',
      marginTop: 12
    },
    loadingSpinner: {
      marginTop: '2px'
    }
  }))
}
