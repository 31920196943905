import { renewAuthorizationToken } from 'authentication/token'
import * as Config from '../config'
import { trackException } from 'utils/tracking'
import { IItemMetrics } from 'models/SelfServiceRequest'

const failedRequest = {
  TimesShown: 0,
  TimesClicked: 0,
  CTR: 0,
  Loaded: false,
  Fetching: false
}
export const getItemMetrics = async (
  aadInfo: any,
  type: 'adwords' | 'featuredresults',
  title: string[]
): Promise<IItemMetrics> => {
  try {
    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    if (esToken === '') {
      return failedRequest
    }

    //?type=${type}&title=${title}
    const apiUrl = `${Config.APIM_BASE_URL}appinsightsapi/postsettingsmetrics`

    const postValues = {
      type: type,
      title: title
    }

    const data = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${esToken}`
      },
      body: JSON.stringify(postValues)
    })

    if (data.status !== 200) {
      trackException(
        'Error in fetching item metrics',
        new Error('Error in fetching item metrics')
      )
      return failedRequest
    }

    const result = await data.json()

    let myRequests: IItemMetrics = failedRequest

    if (result.TimesShown && result.TimesClicked && result.CTR)
      myRequests = {
        TimesShown: result.TimesShown,
        TimesClicked: result.TimesClicked,
        CTR: result.CTR,
        Loaded: true,
        Fetching: false
      }

    return myRequests
  } catch (error) {
    trackException('Error in fetching item metrics', error)
    return failedRequest
  }
}
