import { initialUserSettingState, IUserSetting } from 'models/UserSettings'
import { Reducer } from 'redux'
import {
  UserSettingsActions,
  UserSettingsActionTypes,
  IFetchUserSettingsSuccess,
  IUpsertUserSettingsSuccess,
  IFetchUserSettingsRequest,
  IFetchConfigurationSuccess
} from './actions'
import { ISettingsConfiguration } from 'models/SettingsConfiguration'

export interface IUserSettingsStore {
  userSettings: IUserSetting
  hasUserSettingsBeenFetched: boolean
  hasError: boolean
  initialLoaded: boolean
  upn: string
  displayName: string
  pictureUrl: string
  settingsConfiguration: ISettingsConfiguration
}

const initialState: IUserSettingsStore = {
  userSettings: initialUserSettingState(),
  hasUserSettingsBeenFetched: false,
  hasError: false,
  initialLoaded: false,
  upn: '',
  displayName: '',
  pictureUrl: '',
  settingsConfiguration: {
    DisableMetricsDashboard: false,
    DisableNewsLetterSubscription: false
  }
}

const reducers: Reducer<IUserSettingsStore, UserSettingsActions> = (
  state: IUserSettingsStore = initialState,
  action: UserSettingsActions
) => {
  switch (action.type) {
    case UserSettingsActionTypes.FETCH_USERSETTINGS_REQUEST:
      const { upn: initialUpn, displayName: initialDisplayName } = (
        action as IFetchUserSettingsRequest
      ).payload
      return {
        ...state,
        userSettings: initialUserSettingState(),
        hasUserSettingsBeenFetched: false,
        hasError: false,
        initialLoaded: false,
        upn: initialUpn,
        displayName: initialDisplayName
      }
    case UserSettingsActionTypes.FETCH_USERSETTINGS_FAILURE:
      return {
        ...state,
        hasUserSettingsBeenFetched: true,
        hasError: true,
        initialLoaded: false
      }
    case UserSettingsActionTypes.FETCH_USERSETTINGS_SUCCESS:
      const { response, pictureUrl } = (action as IFetchUserSettingsSuccess)
        .payload

      return {
        ...state,
        hasUserSettingsBeenFetched: true,
        userSettings: response,
        pictureUrl: pictureUrl,
        hasError: false,
        initialLoaded: true
      }
    case UserSettingsActionTypes.FETCH_USERSETTINGS_NOTFOUND:
      return {
        ...state,
        hasUserSettingsBeenFetched: true,
        hasError: false,
        initialLoaded: true
      }
    case UserSettingsActionTypes.UPSERT_USERSETTINGS_SUCCESS:
      const { upSertUserSettingsResponse } = (
        action as IUpsertUserSettingsSuccess
      ).payload

      return {
        ...state,
        userSettings: {
          ...upSertUserSettingsResponse,
          OrgLastVisit: state.userSettings.OrgLastVisit
        }
      }
    case UserSettingsActionTypes.FETCH_SETTINGS_CONFIGURATION_SUCCESS:
      const { settingsConfigurationResult } = (
        action as IFetchConfigurationSuccess
      ).payload

      return {
        ...state,
        settingsConfiguration: settingsConfigurationResult
      }
    default:
      return state
  }
}

export default reducers
