import { Reducer } from 'redux'
import {
  IDeleteSearchTipSuccess,
  IFetchSearchTipsAllSuccess,
  IUpsertSearchTipSuccess,
  SearchTipsActionTypes,
  SearchTipsActions
} from './actions'
import { ISearchTip } from 'models/SearchTip'

export interface ISearchTipsStore {
  searchTips: ISearchTip[]
  hasBeenFetched: boolean
  hasError: boolean
  hasChangesError: boolean
}

const initialState: ISearchTipsStore = {
  searchTips: [],
  hasBeenFetched: false,
  hasError: false,
  hasChangesError: false
}

const reducers: Reducer<ISearchTipsStore, SearchTipsActions> = (
  state: ISearchTipsStore = initialState,
  action: SearchTipsActions
) => {
  switch (action.type) {
    case SearchTipsActionTypes.FETCH_SEARCH_TIPS_ALL_FAILURE:
      return {
        ...state,
        hasBeenFetched: true,
        hasError: true
      }
    case SearchTipsActionTypes.FETCH_SEARCH_TIPS_ALL_REQUEST:
      return {
        ...state,
        searchTips: [],
        hasBeenFetched: false,
        hasError: false,
        hasChangesError: false
      }

    case SearchTipsActionTypes.FETCH_SEARCH_TIPS_ALL_SUCCESS:
      const { searchTipsAllResponse } = (action as IFetchSearchTipsAllSuccess)
        .payload

      return {
        ...state,
        searchTips: searchTipsAllResponse,
        hasBeenFetched: true,
        hasError: false
      }

    case SearchTipsActionTypes.UPSERT_SEARCH_TIP_SUCCESS:
      const { upsertedSearchTip } = (action as IUpsertSearchTipSuccess).payload
      const searchTipsChanged = state.searchTips
      if (searchTipsChanged.length > 0) {
        const tIndex = searchTipsChanged.findIndex(
          (t: any) =>
            t.id === upsertedSearchTip.id &&
            t.language === upsertedSearchTip.language
        )
        if (tIndex >= 0) {
          searchTipsChanged[tIndex] = upsertedSearchTip
        } else {
          searchTipsChanged.unshift(upsertedSearchTip)
        }
      }
      return {
        ...state,
        searchTips: searchTipsChanged,
        hasChangesError: false
      }
    case SearchTipsActionTypes.CHANGE_SEARCH_TIP_FAILURE:
      return {
        ...state,
        hasChangesError: true
      }
    case SearchTipsActionTypes.DELETE_SEARCH_TIP_SUCCESS:
      const { searchTipId, searchTipLanguage } = (
        action as IDeleteSearchTipSuccess
      ).payload
      const allSearchTips = state.searchTips
      if (allSearchTips.length > 0) {
        const tIndex = allSearchTips.findIndex(
          (t: any) => t.id === searchTipId && t.language === searchTipLanguage
        )
        if (tIndex >= 0) {
          allSearchTips.splice(tIndex, 1)
        }
      }
      return {
        ...state,
        searchTips: allSearchTips,
        hasChangesError: false
      }
    default:
      return state
  }
}

export default reducers
