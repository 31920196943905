import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesAdminForm(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    dialogContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        marginBottom: 10
      }
    },
    adFormDialog: {
      minWidth: 600
    },
    radioButtonGroup: {
      marginBottom: '1em',
      borderRadius: '4px',
      padding: '0 10px'
    },
    errorRadioBoxHelpText: {
      paddingLeft: '4px',
      paddingBottom: '1em',
      color: 'red'
    },

    tab_select: {
      minWidth: 'auto!important',
      '& > .MuiTab-wrapper': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap'
      }
    },
    tab_panel: {
      padding: 0
    },
    tab_item: {
      width: '100%',
      marginBottom: 10
    },
    tab_item_request: {
      width: '100%'
    },
    tab_item_history: {
      marginTop: '16px',
      marginLeft: '20px',
      width: '100%'
    },
    tab_item_history_select: {
      marginLeft: '20px',
      width: '100%',
      marginBottom: '0px !important'
    },
    dialog_comment_textfield: {
      width: '100%',
      marginTop: '15px'
    },
    detailSelectionContainer: {
      display: 'flex',
      fontSize: '13px',
      alignItems: 'center',
      cursor: 'pointer'
    },
    detailSelectionIcon: {
      height: '15px'
    },
    detailSelectionText: {
      marginLeft: '5px'
    },
    dialogHeader: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    form_control: {
      width: '100%',
      padding: '0px'
    },
    historyContainer: {
      display: 'flex'
    },
    hideHistoryButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    hideHistoryButton: {
      fontSize: '14px'
    },
    dialogTitleContainer: {
      position: 'relative'
    },
    dialogShareIcon: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    selectChipArea: {
      width: '100%',
      borderRadius: '4px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      minHeight: '123px'
    },
    selectChipAreaError: {
      border: '1px solid #d32f2f'
    },
    selectChipInnerArea: {
      padding: '10px',
      marginRight: '45px'
    },
    addKeyWordField: {
      width: '100%'
    },
    addKeyWordFieldAddButton: {
      cursor: 'pointer'
    },
    keyWordChip: {
      marginRight: '5px',
      marginTop: '5px'
    },
    keyWordChipWarning: {
      border: '1px solid rgba(234,170,0,1)'
    },
    keyWordChipError: {
      border: '1px solid #d32f2f'
    },
    languageLabelContainer: {
      display: 'flex',
      alignItems: 'center'
    }
  }))
}
