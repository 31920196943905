import { Store } from '..'
import { IUpsertImageResult } from './actions'

const selectors = {
  getSettingsTranslations: (state: Store): any =>
    state.translations.settingsTranslations,
  getAllAdsTranslations: (state: Store): any =>
    state.translations.allAdsTranslations,
  getAllPopupsTranslations: (state: Store): any =>
    state.translations.allPopupsTranslations,
  getAllTranslations: (state: Store): any =>
    state.translations.allGlobalTranslations,
  getAllOITranslations: (state: Store): any =>
    state.translations.allGlobalOITranslations,
  getAllSettingsTranslations: (state: Store): any =>
    state.translations.allSettingsTranslations,
  hasTranslationsBeenFetched: (state: Store): any =>
    state.translations.hasTranslationsBeenFetched,
  hasAllTranslationsBeenFetched: (state: Store): any =>
    state.translations.hasAllTranslationsBeenFetched,
  hasOITranslationsBeenFetched: (state: Store): any =>
    state.translations.hasOITranslationsBeenFetched,
  hasAllAdTranslationsBeenFetched: (state: Store): any =>
    state.translations.hasAllAdTranslationsBeenFetched,
  hasAllPopupTranslationsBeenFetched: (state: Store): any =>
    state.translations.hasAllPopupTranslationsBeenFetched,
  hasAllSettingsTranslationsBeenFetched: (state: Store): any =>
    state.translations.hasAllSettingsTranslationsBeenFetched,
  hasError: (state: Store): any => state.translations.hasError,
  hasChangesBeenApplied: (state: Store): any =>
    state.translations.hasChangesBeenApplied,
  upsertImageUrl: (state: Store): IUpsertImageResult | null =>
    state.translations.upsertImageUrl,
  hasChangesError: (state: Store): any => state.translations.hasChangesError,
  validTranslations: (state: Store): any =>
    state.translations.validTranslations,
  hasUpdateVersionError: (state: Store): any =>
    state.translations.hasUpdateVersionError,
  hasUpsertImageError: (state: Store): any =>
    state.translations.hasUpsertImageError,
  upsertImageExists: (state: Store): any =>
    state.translations.upsertImageExists,
  upsertImageValidation: (state: Store): any =>
    state.translations.upsertImageValidation,
  getPopupTranslationsMaxId: (state: Store): any =>
    state.translations.popupTranslationsMaxId
}

export default selectors
