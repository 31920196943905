import React, { useRef, useState } from 'react'
import { CardMedia } from '@mui/material'
import MaterialTable from '@material-table/core'
import { Edit } from '@mui/icons-material'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import Logo from 'images/find_logo.png'
import AdToolBar from '../common/AdToolBar'
import { hasDateExpired } from 'utils/date'
import { FeaturedResult } from 'models/FeaturedResult'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import { ExportCsv } from '@material-table/exporters'
import { parseFeaturedResultsDataToExport } from 'utils/admin/adminExportUtils'
import { tableIcons } from 'utils/admin/adminSettingsUtils'
import { featuredResultColumns } from './FeaturedTableColumns'
import { TableStateFeaturedResults } from 'models/TableStates'

export interface IFeaturedResultsTableProps {
  state: TableStateFeaturedResults
  handleRowDelete: any
  handleRowUpdate: any
  handleRowAdd: any
  handleFormOpen: any
}

export default function FeaturedResultsTable(
  props: IFeaturedResultsTableProps
): JSX.Element {
  const {
    state,
    handleRowDelete,
    handleRowUpdate,
    handleRowAdd,
    handleFormOpen
  } = props

  const classes = getStylesAdminSettings()

  const [isFilterSet, setIsFilterSet] = useState(false)

  //force browser scrollbars
  const rootElement = document.getElementById('root')
  if (rootElement) rootElement.style.flexDirection = 'unset'

  const dataColumnsList = useRef(featuredResultColumns())

  return (
    <>
      <MaterialTable
        title={
          <>
            <div className={classes.header}>
              <CardMedia image={Logo} className={classes.logo} />
              <h2>Featured Results Configuration</h2>
            </div>
            <div className={classes.information}>
              Any changes being made to the Featured Results using this
              configuration screen are common for OI and Find applications.
            </div>
          </>
        }
        icons={tableIcons}
        columns={dataColumnsList.current}
        data={state.data as FeaturedResult[]}
        options={{
          rowStyle: (data: FeaturedResult) => ({
            fontSize: 12,
            backgroundColor:
              data.BestBetEndDate && hasDateExpired(data.BestBetEndDate)
                ? '#BBB'
                : 'inherit'
          }),
          addRowPosition: 'first',
          pageSize: 25,
          pageSizeOptions: [25, 50, 75],
          tableLayout: 'auto',
          filtering: true,
          emptyRowsWhenPaging: false,
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (cols, datas) => {
                if (isFilterSet) {
                  const exportList: FeaturedResult[] = []

                  datas.forEach((exportFeaturedResult: FeaturedResult) => {
                    exportList.push(exportFeaturedResult)
                  })

                  ExportCsv(
                    cols,
                    parseFeaturedResultsDataToExport(exportList),
                    'FeaturedResults',
                    ';'
                  )
                } else {
                  ExportCsv(
                    cols,
                    parseFeaturedResultsDataToExport(
                      state.data as FeaturedResult[]
                    ),
                    'FeaturedResults',
                    ';'
                  )
                }
              }
            }
          ],
          exportAllData: true
        }}
        localization={{
          body: {
            editRow: {
              deleteText:
                'Are you sure you want to delete this row? Please remember to publish!'
            }
          }
        }}
        editable={{
          isDeleteHidden: (rowData) => {
            return !!rowData.requestId
          },
          onRowDelete: handleRowDelete
        }}
        actions={[
          (rowData) => ({
            icon: Edit,
            onClick: (
              event: any,
              rowData: FeaturedResult | FeaturedResult[]
            ) => {
              if (!(rowData instanceof Array)) {
                handleFormOpen(rowData)
              }
            },
            tooltip: !rowData.requestId
              ? 'Edit'
              : `Edit Featured Result through "All Requests" view (Id: "${rowData.requestId}", Created by: "${rowData.requestUser}").`,
            disabled: !!rowData.requestId
          })
        ]}
        components={{
          Toolbar: (props: any) => (
            <AdToolBar
              {...{
                ...props,
                handleRowAdd: handleRowAdd,
                handleRowUpdate: handleRowUpdate,
                addButtonText: 'Add Featuredresult',
                adminSettingType: adminSettingTypes.featuredresults
              }}
            />
          )
        }}
        onFilterChange={(filters: any) => {
          setIsFilterSet(filters.length > 0)
        }}
      />
    </>
  )
}
