import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip
} from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { getStylesRequests } from 'styles/requests/requests'

export const DropDownFilter = (props: any) => {
  const [filters, setFilters] = useState<string[]>([])

  const classes = getStylesRequests()
  const intl = useIntl()

  const statesToFilter = props.options

  const [overflowing, setOverflowing] = useState(false)
  const titleItemRef = useRef(null)

  const isCssOverFlowing = (element: HTMLElement) => {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    )
  }

  const updateSize = () => {
    if (titleItemRef && titleItemRef.current) {
      const isOverflowing = isCssOverFlowing(titleItemRef.current)
      setOverflowing(isOverflowing)
    }
  }

  useEffect(() => {
    updateSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const onSelectAll = () => {
    setFilters(statesToFilter)
    props.onFilterChanged(props.columnDef.tableData.id, statesToFilter)
  }
  const onClearAll = () => {
    setFilters([])
    props.onFilterChanged(props.columnDef.tableData.id, [])
  }

  return (
    <div style={{ width: '200px' }}>
      <FormControl fullWidth>
        <Select
          id="filterSelect"
          multiple={props.multiple}
          displayEmpty
          value={filters}
          variant={'standard'}
          onChange={(event: SelectChangeEvent<string[]>) => {
            const newFilterValue: string[] =
              typeof event.target.value === 'string'
                ? event.target.value.split(',')
                : (event.target.value as string[])

            let includesUndefined =
              newFilterValue &&
              newFilterValue.length === 1 &&
              !newFilterValue[0]

            if (includesUndefined || !newFilterValue) {
              return
            }

            setFilters(
              newFilterValue.filter((f) => f !== 'Clear' && f !== 'All')
            )
            props.onFilterChanged(
              props.columnDef.tableData.id,
              newFilterValue.filter((f) => f !== 'Clear' && f !== 'All')
            )
          }}
          renderValue={(_selected) => {
            const selected = _selected.filter((item) => !!item)

            if (selected.length === 0) {
              return (
                <div style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                  {intl.formatMessage({
                    id: 'table_column_status_filter_all',
                    defaultMessage: 'All'
                  })}
                </div>
              )
            }

            return (
              <Tooltip
                title={selected
                  .map((filterValue: string) => {
                    return intl.formatMessage({
                      id: `table_column_status_filter_${filterValue.toLowerCase()}`,
                      defaultMessage: filterValue
                    })
                  })
                  .join(', ')}
                disableHoverListener={!overflowing}
              >
                <div
                  ref={titleItemRef}
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}
                >
                  {selected
                    .map((filterValue: string) => {
                      return intl.formatMessage({
                        id: `table_column_status_filter_${filterValue.toLowerCase()}`,
                        defaultMessage: filterValue
                      })
                    })
                    .join(', ')}
                </div>
              </Tooltip>
            )
          }}
          className={classes.filterSelect}
        >
          <div className={classes.dropdownItemHeader}>
            <MenuItem
              key={'Clear'}
              value={'Clear'}
              className={classes.filterClearButton}
              disabled={filters.length === 0}
              onClick={() => {
                onClearAll()
              }}
            >
              <ListItemText
                primary={intl.formatMessage({
                  id: `table_column_status_filter_clear`,
                  defaultMessage: 'Clear'
                })}
              />
            </MenuItem>
            {props.multiple ? (
              <MenuItem
                key={'All'}
                value={'All'}
                className={classes.filterClearButton}
                disabled={filters.length === statesToFilter.length}
                onClick={() => {
                  onSelectAll()
                }}
              >
                <ListItemText
                  primary={intl.formatMessage({
                    id: `table_column_status_filter_all`,
                    defaultMessage: 'All'
                  })}
                />
              </MenuItem>
            ) : null}
          </div>

          {statesToFilter.map((filterValue: string) => {
            const filterTranslation = intl.formatMessage({
              id: `table_column_status_filter_${filterValue.toLowerCase()}`,
              defaultMessage: filterValue
            })

            return (
              <MenuItem key={filterValue} value={filterValue}>
                <Checkbox checked={filters.indexOf(filterValue) > -1} />
                <ListItemText primary={filterTranslation} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}
