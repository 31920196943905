import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import Ads from 'components/contents/resultpages/Ads'
import { FormattedMessage } from 'react-intl'
import {
  AdminActionType,
  defaultSelfServiceRequest,
  ISelfServiceRequest,
  ISelfServiceUpdateResponse,
  RequestComment,
  RequestStatus,
  RequestType
} from 'models/SelfServiceRequest'
import EditFormAdWordRequest from '../requestForms/EditFormAdWordRequest'
import { AdsResult, intialAdsResult } from 'models/AdsResult'
import EditFormFeatureResultRequest from '../requestForms/EditFormFeatureResultRequest'
import SelfServiceStore from 'store/SelfService'
import { connect } from 'react-redux'
import {
  generateShareMail,
  updateBaseProperties,
  updateComments
} from 'utils/admin/selfServiceUtils'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import createDOMPurify from 'dompurify'
import CommentAreaForm from '../requestForms/CommentAreaForm'
import EditFormSpellingSuggestionRequest from '../requestForms/EditFormSpellingSuggestionRequest'
import { Share } from '@mui/icons-material'

interface IStatusChangeConfirmDialogType {
  show: boolean
  actionType: AdminActionType | null
}

export interface IEditFormRequestBaseProps {
  rowData: ISelfServiceRequest
  isOpen: boolean
  isNewItem: boolean
  handleClose: () => void
  handleRowUpdate: (newData: ISelfServiceRequest) => void
  toggleSnackbarVisibility: (visible: boolean) => void
  setSnackbarMessage: (message: string) => void
}

type IEditFormRequestProps = ReturnType<typeof mapDispatchToProps> &
  IEditFormRequestBaseProps

function EditFormRequestBase(props: IEditFormRequestProps): JSX.Element {
  const {
    rowData,
    isOpen,
    isNewItem,
    handleClose,
    handleRowUpdate,
    upsertAdminRequest,
    toggleSnackbarVisibility,
    setSnackbarMessage
  } = props

  const [isValid, setIsValid] = useState(false)
  const [showAdsPreview, setShowAdsPreview] = useState(false)
  const [statusChangeConfirmDialog, setStatusChangeConfirmDialog] =
    useState<IStatusChangeConfirmDialogType>({
      show: false,
      actionType: null
    })
  const [referenceRowData, setRefereneRowData] = useState<ISelfServiceRequest>(
    defaultSelfServiceRequest
  )
  const [newComment, setNewComment] = useState<RequestComment>({
    id: 1,
    text: '',
    modifiedDate: new Date().toISOString(),
    modifiedBy: ESSettingsGlobalVariables.getUPN(),
    modifiedByDisplayName: ESSettingsGlobalVariables.getDisplayName()
  })
  const [hasImageChange, setHasImageChange] = useState(false)
  const [tabIndex, setTabIndex] = React.useState(0)

  const classes = getStylesAdminForm()
  const DOMPurify = createDOMPurify(window)

  useEffect(() => {
    const deepCopy = JSON.parse(
      JSON.stringify(rowData ? rowData : defaultSelfServiceRequest)
    )
    setRefereneRowData(deepCopy)

    const maxId =
      rowData.comments && rowData.comments.length > 0
        ? rowData.comments.reduce(
            (max, comment) => (comment.id > max ? comment.id : max),
            rowData.comments[0].id
          )
        : 0

    setNewComment({
      id: maxId + 1,
      text: '',
      modifiedDate: new Date().toISOString(),
      modifiedBy: ESSettingsGlobalVariables.getUPN(),
      modifiedByDisplayName: ESSettingsGlobalVariables.getDisplayName()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const preCheckSave = (adminActionType: AdminActionType) => {
    if (!newComment || !newComment.text) {
      setStatusChangeConfirmDialog({
        show: true,
        actionType: adminActionType
      })
    } else {
      saveItem(adminActionType)
    }
  }

  const saveItem = (adminActionType: AdminActionType) => {
    const orgRequestItem = Object.assign({}, referenceRowData)

    if (referenceRowData.requestType === RequestType.AdWord) {
      const adItemList = referenceRowData.itemData as AdsResult[]
      const newItemList: AdsResult[] = []
      adItemList.forEach((item: AdsResult) => {
        newItemList.push({
          ...adItemList[0],
          ...{
            language: item.language,
            title: item.title ? item.title : adItemList[0].title,
            text: item.text ? item.text : adItemList[0].text,
            link_text: item.link_text
              ? item.link_text
              : adItemList[0].link_text,
            countries:
              item.countries.length === 0 ? ['All'] : adItemList[0].countries,
            functions:
              item.functions.length === 0 ? ['All'] : adItemList[0].functions
          }
        })
      })

      referenceRowData.itemData = newItemList
    }

    referenceRowData.modifiedDate = new Date().toISOString()
    referenceRowData.modifiedBy = ESSettingsGlobalVariables.getDisplayName()

    updateBaseProperties(referenceRowData)
    updateComments(referenceRowData, newComment)

    upsertAdminRequest(
      referenceRowData,
      adminActionType,
      hasImageChange,
      newComment
    ).then((upsertResponse: ISelfServiceUpdateResponse) => {
      if (!upsertResponse.hasError) {
        if (upsertResponse.etag) {
          referenceRowData._etag = upsertResponse.etag
        }

        handleRowUpdate(referenceRowData)

        if (upsertResponse.imageError) {
          setSnackbarMessage('The image upload failed.')
        } else {
          switch (adminActionType) {
            case AdminActionType.Clarification:
              setSnackbarMessage(
                'Successfully requested clarification for the request.'
              )
              break
            case AdminActionType.Decline:
              setSnackbarMessage('Successfully declined the request.')
              break
            case AdminActionType.Publish:
              setSnackbarMessage('Successfully published the request.')
              break
            case AdminActionType.UnPublish:
              setSnackbarMessage('Successfully unpublished the request.')
              break
          }
        }

        toggleSnackbarVisibility(true)
      } else {
        setRefereneRowData(orgRequestItem)
        if (upsertResponse.status === 412) {
          setSnackbarMessage(
            'Save Conflict: Your changes conflict with those made concurrently by another user. If you want your changes to be applied, refresh the page and resubmit your changes.'
          )
          toggleSnackbarVisibility(true)
        } else {
          setSnackbarMessage('There was an error updating the request.')
          toggleSnackbarVisibility(true)
        }
      }
    })
  }

  const getDialogTitle = (): string => {
    switch (referenceRowData.requestType) {
      case RequestType.AdWord:
        return isNewItem ? 'New Adword' : 'Edit Adword'
      case RequestType.FeaturedResult:
        return isNewItem ? 'New Featured Result' : 'Edit Featured Result'
      case RequestType.SpellingSuggestion:
        return isNewItem
          ? "New 'Did You Mean' Suggestion"
          : "Edit 'Did You Mean' Suggestion"
    }
  }

  const getDialogContent = () => {
    switch (referenceRowData.requestType) {
      case RequestType.AdWord:
        return (
          <>
            <EditFormAdWordRequest
              item={referenceRowData}
              isNewItem={isNewItem}
              setItemList={updateAdWordItemDataInRequest}
              setIsValid={setIsValid}
              setHasImageChange={setHasImageChange}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
            <CommentAreaForm item={referenceRowData} newComment={newComment} />
          </>
        )
      case RequestType.FeaturedResult:
        return (
          <>
            <EditFormFeatureResultRequest
              item={referenceRowData}
              isNewItem={isNewItem}
              setIsValid={setIsValid}
            />
            <CommentAreaForm item={referenceRowData} newComment={newComment} />
          </>
        )
      case RequestType.SpellingSuggestion:
        return (
          <EditFormSpellingSuggestionRequest
            item={referenceRowData}
            setIsValid={setIsValid}
          />
        )
      default:
        return <></>
    }
  }

  const updateAdWordItemDataInRequest = (newList: AdsResult[]) => {
    referenceRowData.itemData = newList
    setRefereneRowData(referenceRowData)
  }

  const getPreviewAdItem = (): AdsResult => {
    if (
      referenceRowData.itemData &&
      Array.isArray(referenceRowData.itemData) &&
      referenceRowData.itemData.length >= tabIndex + 1
    ) {
      const ad = Object.assign(
        {},
        (referenceRowData.itemData as AdsResult[])[tabIndex]
      )

      ad.image = referenceRowData.imagePreview
        ? `data:${referenceRowData.imagePreview}`
        : ''

      return ad
    } else {
      return intialAdsResult
    }
  }

  const handleSharing = (rowData: ISelfServiceRequest): void => {
    const mailInfo = generateShareMail(rowData)
    window.location.href = `mailto:?body=${mailInfo.body}&subject=${mailInfo.subject}`
  }

  return (
    <>
      <Dialog
        open={isOpen && rowData !== null}
        onClose={(event: object, reason: string) => {
          if (reason !== 'backdropClick') handleClose()
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="confirmation-modal-title">
          <div className={classes.dialogTitleContainer}>
            <div>{getDialogTitle()}</div>
            <div>
              <Tooltip title={'Share'}>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    handleSharing(referenceRowData)
                  }}
                  className={classes.dialogShareIcon}
                >
                  <Share fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContentContainer}>
          {getDialogContent()}
        </DialogContent>
        <DialogActions>
          {referenceRowData.requestType === RequestType.AdWord && (
            <Button
              color="secondary"
              onClick={() => {
                setShowAdsPreview(true)
              }}
            >
              Preview
            </Button>
          )}
          {referenceRowData.oncePublished && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                saveItem(AdminActionType.UnPublish)
              }}
              className={classes.button}
            >
              Unpublish
            </Button>
          )}
          {referenceRowData.status !== RequestStatus.Published && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                saveItem(AdminActionType.Publish)
              }}
              className={classes.button}
              disabled={!isValid}
            >
              Publish
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => {
              preCheckSave(AdminActionType.Decline)
            }}
            disabled={
              referenceRowData.status === RequestStatus.Declined ||
              referenceRowData.status === RequestStatus.ToBeDeleted ||
              referenceRowData.status === RequestStatus.Published
            }
          >
            Decline
          </Button>
          <Button
            color="primary"
            onClick={() => {
              preCheckSave(AdminActionType.Clarification)
            }}
            disabled={
              referenceRowData.status === RequestStatus.Declined ||
              referenceRowData.status === RequestStatus.Published
            }
          >
            Clarification
          </Button>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {referenceRowData.requestType === RequestType.AdWord &&
        showAdsPreview && (
          <Dialog
            open={showAdsPreview}
            onClose={handleClose}
            aria-labelledby="confirmation-modal"
            aria-describedby="confirmation-modal-description"
          >
            <DialogContent style={{ marginLeft: '-16px' }}>
              <Ads adItem={getPreviewAdItem()} isRequestPreview={true} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowAdsPreview(false)} color="primary">
                <FormattedMessage id="close" defaultMessage="Close" />
              </Button>
            </DialogActions>
          </Dialog>
        )}
      {statusChangeConfirmDialog.show && (
        <Dialog
          open={statusChangeConfirmDialog.show}
          onClose={handleClose}
          aria-labelledby="confirmation-modal"
          aria-describedby="confirmation-modal-description"
        >
          <DialogContent>
            <div>{`Optionally leave a comment in the request item.`}</div>
            <TextField
              variant="standard"
              id="newcomment"
              label="Leave a comment"
              defaultValue={''}
              onChange={(event) => {
                newComment.text = DOMPurify.sanitize(event.target.value, {
                  USE_PROFILES: { html: false }
                })
              }}
              InputLabelProps={{
                shrink: true
              }}
              maxRows={5}
              multiline={true}
              className={classes.dialog_comment_textfield}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                if (statusChangeConfirmDialog.actionType) {
                  saveItem(statusChangeConfirmDialog.actionType)
                }
                setStatusChangeConfirmDialog({
                  show: false,
                  actionType: null
                })
              }}
              color="primary"
            >
              {statusChangeConfirmDialog.actionType ===
              AdminActionType.Clarification
                ? 'Request Clarification'
                : 'Decline'}
            </Button>
            <Button
              onClick={() =>
                setStatusChangeConfirmDialog({
                  show: false,
                  actionType: null
                })
              }
              color="primary"
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    upsertAdminRequest: (
      request: ISelfServiceRequest,
      adminActionType: AdminActionType,
      imageUpdated: boolean,
      latestComment?: RequestComment
    ) =>
      dispatch(
        SelfServiceStore.actions.upsertAdminRequest(
          request,
          adminActionType,
          latestComment,
          imageUpdated
        )
      )
  }
}

export default connect(null, mapDispatchToProps)(EditFormRequestBase)
