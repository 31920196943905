import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesDefaultFormField(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    defaultFormFieldContainer: {
      width: '100%'
    },
    defaultFormFieldHeader: {
      marginBottom: '7px'
    },
    defaultFormFieldInput: {
      marginBottom: '5px'
    },
    defaultFormFieldError: {
      color: '#d32f2f',
      fontWeight: 400,
      fontSize: '14px',
      marginBottom: '5px'
    },
    headerLabel: {},
    headerDescription: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 400,
      fontSize: '14px'
    }
  }))
}
