import { dateFormatOptions } from 'constants/constants'
import { FeaturedResult } from 'models/FeaturedResult'
import * as React from 'react'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTile'
import { getStylesFeaturedResultPreview } from 'styles/contents/resultpages/FeaturedResultPreview'
import { stripHtml, truncate } from 'utils/string'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

interface IFeaturedResultProps {
  result: FeaturedResult
}

const FeaturedResultPreview: React.FunctionComponent<IFeaturedResultProps> = (
  props
) => {
  const { result } = props
  const DOMPurify = createDOMPurify(window)
  const classes = getStylesFeaturedResultPreview()

  let body = ''
  let dateValue = result.BestBetLastModified
    ? result.BestBetLastModified
    : result.BestBetStartDate
  if (dateValue) {
    body += new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
      new Date(DOMPurify.sanitize(dateValue))
    )
  }

  if (result.BestBetDescription) {
    body += `${body ? ' - ' : ''}${stripHtml(
      DOMPurify.sanitize(result.BestBetDescription)
    )}`
  }

  function generateBreadCrumb(): string {
    try {
      let cleanedURL = result.BestBetUrl

      let filteredDomain
      //find & remove protocol (http, ftp, etc.) and get hostname
      cleanedURL.indexOf('//') > -1
        ? (filteredDomain = cleanedURL.split('/')[2])
        : (filteredDomain = cleanedURL.split('/')[0])
      //find & remove port number
      filteredDomain = filteredDomain.split(':')[0]
      //find & remove "?"
      filteredDomain = filteredDomain.split('?')[0]
      filteredDomain = filteredDomain.replace('www.', '')
      return truncate(filteredDomain, 79)
    } catch (error) {
      return truncate(result.BestBetUrl, 79)
    }
  }

  return (
    <div className={classes.featuredResult}>
      <div style={{ display: 'flex' }}>
        <div className={classes.breadcrump}>
          <b className={classes.featured}>
            {'Featured: '}
            &nbsp;
          </b>
        </div>
        <div className={classes.urlBreadcrumbs}>{generateBreadCrumb()}</div>
      </div>
      <a
        className={classes.link}
        href={DOMPurify.sanitize(result.BestBetUrl)}
        target={'_blank'}
        rel="noreferrer"
      >
        <div className={`${classes.title}`}>
          <TooltipTitle
            title={
              result.BestBetTitle ? DOMPurify.sanitize(result.BestBetTitle) : ''
            }
            singleLine={true}
          />
        </div>
      </a>

      <div className={classes.body}>
        {body && (
          <HTMLEllipsis
            style={{
              whiteSpace: 'pre-wrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
            basedOn="words"
            unsafeHTML={body}
            maxLine="3"
          />
        )}
      </div>
    </div>
  )
}

export default FeaturedResultPreview
