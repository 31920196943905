import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material'
import { IExpirationAlertConfiguration } from 'models/ExpirationAlerts'
import { ISelfServiceAdminExpirationCheckResponse } from 'models/SelfServiceRequest'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { runAdminExpirationCheck } from 'store/SelfService/actions'
import { getStylesAdminAlertConfiguration } from 'styles/admin/AdminAlertConfiguration'

interface IStatusClipRequestProps {
  setShowAlertSettings: (showAlert: boolean) => void
  showAlertSettings: boolean
  toggleSnackbarVisibility: (visible: boolean) => void
  setSnackbarMessage: (message: string) => void
}

type AdminAlertConfigurationProps = ReturnType<typeof mapDispatchToProps> &
  IStatusClipRequestProps

function AdminAlertConfiguration(
  props: AdminAlertConfigurationProps
): JSX.Element {
  const {
    showAlertSettings,
    setShowAlertSettings,
    runAdminExpirationCheck,
    toggleSnackbarVisibility,
    setSnackbarMessage
  } = props
  const [expirationInverval, setExpirationInterval] = useState('month')
  const [sendNotificationsForExpired, setSendNotificationsForExpired] =
    useState(false)
  const [notificationFor, setNotificationsFor] = useState('all')
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const classes = getStylesAdminAlertConfiguration()

  const handleAlertConfirm = () => {
    setShowConfirmDialog(true)
  }

  const handleAlertSend = () => {
    setShowConfirmDialog(false)
    const expirationConfig: IExpirationAlertConfiguration = {
      expirationInterval: expirationInverval,
      sendNotificationForExpired: sendNotificationsForExpired,
      notificationsFor: notificationFor
    }

    setLoading(true)

    runAdminExpirationCheck(expirationConfig).then(
      (response: ISelfServiceAdminExpirationCheckResponse) => {
        setLoading(false)

        if (response.noNotifications) {
          setSnackbarMessage('No expired notifications found.')
        } else if (!response.hasError && !response.hasPartialError) {
          setSnackbarMessage('Successfully generated expired notifications.')
        } else if (response.hasPartialError) {
          setSnackbarMessage(
            'Partial error generating the expired notifications.'
          )
        } else if (response.hasError) {
          setSnackbarMessage('Error generating the expired notifications.')
        }

        toggleSnackbarVisibility(true)
      }
    )
  }

  return (
    <>
      {showAlertSettings && (
        <Dialog
          open={showAlertSettings}
          onClose={() => setShowAlertSettings(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="admin-alert-settings-tile">
            {'Configure and send expiration alerts'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ position: 'relative' }}>
                <Grid container>
                  <Grid item xs={4}>
                    <FormControl>
                      <FormLabel>Expires in</FormLabel>
                      <RadioGroup
                        value={expirationInverval}
                        onChange={(ev, value) => setExpirationInterval(value)}
                      >
                        <FormControlLabel
                          value="month"
                          control={<Radio />}
                          label="a month"
                        />
                        <FormControlLabel
                          value="week"
                          control={<Radio />}
                          label="a week"
                        />
                        <FormControlLabel
                          value="day"
                          control={<Radio />}
                          label="a day"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl>
                      <FormLabel>Send notifications for expired</FormLabel>
                      <RadioGroup
                        value={sendNotificationsForExpired}
                        onChange={(ev, value) =>
                          setSendNotificationsForExpired(value === 'true')
                        }
                      >
                        <FormControlLabel
                          value={'false'}
                          control={<Radio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={'true'}
                          control={<Radio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl>
                      <FormLabel>Send notifications for types</FormLabel>
                      <RadioGroup
                        value={notificationFor}
                        onChange={(ev, value) => setNotificationsFor(value)}
                      >
                        <FormControlLabel
                          value={'all'}
                          control={<Radio />}
                          label="All"
                        />
                        <FormControlLabel
                          value={'featuredresult'}
                          control={<Radio />}
                          label="Featured Result"
                        />
                        <FormControlLabel
                          value={'adword'}
                          control={<Radio />}
                          label="AdWord"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                {loading && (
                  <div className={classes.overlaySpinner}>
                    <div className={classes.overlaySpinnerText}>Running</div>
                    <CircularProgress size={80} />
                  </div>
                )}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowAlertSettings(false)}
              color="primary"
              autoFocus
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleAlertConfirm()}
              color="primary"
              disabled={loading}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showConfirmDialog && (
        <Dialog
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
        >
          <DialogTitle id="admin_change_confirm_dialog">
            {'Confirm sending out expiration notifications!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to generate and send out all notifcations
              for expiring items?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowConfirmDialog(false)}
              color="primary"
              autoFocus
            >
              No
            </Button>
            <Button onClick={() => handleAlertSend()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    runAdminExpirationCheck: (
      expirationConfig: IExpirationAlertConfiguration
    ) => dispatch(runAdminExpirationCheck(expirationConfig))
  }
}

export default connect(null, mapDispatchToProps)(AdminAlertConfiguration)
