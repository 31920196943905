export interface SupportedLanguage {
  name: string
  translationId: string
  locale: string
}
export const supportedLanguages: SupportedLanguage[] = [
  {
    translationId: 'english',
    name: 'English',
    locale: 'en'
  },
  {
    translationId: 'german',
    name: 'German',
    locale: 'de'
  },
  {
    translationId: 'japanese',
    name: 'Japanese',
    locale: 'ja-JP'
  },
  {
    translationId: 'chinese',
    name: 'Chinese',
    locale: 'zh'
  },
  {
    translationId: 'french',
    name: 'French',
    locale: 'fr'
  },
  {
    translationId: 'spanish',
    name: 'Spanish',
    locale: 'es'
  },
  {
    translationId: 'italian',
    name: 'Italian',
    locale: 'it'
  },
  {
    translationId: 'korean',
    name: 'Korean',
    locale: 'ko-KR'
  },
  {
    translationId: 'russian',
    name: 'Russian',
    locale: 'ru'
  },
  {
    translationId: 'portuguese_(brazil)',
    name: 'Portuguese (Brazil)',
    locale: 'pt-BR'
  }
]
