import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField
} from '@mui/material'
import { WarningTwoTone } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import AdminImageUpload from '../common/AdminImageUpload'
import { countriesISO } from 'constants/countriesISO'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import {
  SupportedFunction,
  supportedFunctions
} from 'constants/supportedFunctions'
import {
  renderSelect,
  editFormErrorKeys,
  checkIsValidPopup
} from 'utils/admin/adminFormUtils'
import createDOMPurify from 'dompurify'
import { TabContext, TabPanel } from '@mui/lab'
import {
  PopupDisplayType,
  PopupDisplayTypes,
  PopupResult
} from 'models/PopupResult'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { capitalizeFirstLetter } from 'utils/string'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import {
  ISupportedVertical,
  supportedVerticals
} from 'constants/supportedVerticals'

export interface EditFormPopupsProps {
  itemList: PopupResult[]
  isNewItem: boolean
  setIsValid: (isValid: boolean) => void
  changedLanguages: string[]
  setChangedLanguages: (languages: string[]) => void
  setItemList: (newItems: PopupResult[]) => void
  tabIndex: number
  setTabIndex: (index: number) => void
}

export default function EditFormPopups(
  props: EditFormPopupsProps
): JSX.Element {
  const {
    itemList,
    isNewItem,
    setIsValid,
    changedLanguages,
    setChangedLanguages,
    setItemList,
    tabIndex,
    setTabIndex
  } = props

  const DOMPurify = createDOMPurify(window)
  const [sources, setSources] = useState(
    itemList[0]?.sources.map((dsKey: string) => {
      return {
        key: capitalizeFirstLetter(dsKey),
        name: capitalizeFirstLetter(dsKey)
      }
    })
  )

  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())
  const [countries, setCountries] = useState(itemList[0]?.countries)
  const [functions, setFunctions] = useState(itemList[0]?.functions)
  const [scope, setScope] = useState(
    itemList[0] && itemList[0].scope ? itemList[0].scope : 'All'
  )
  const [availableSources, setAvailableSources] = useState<
    ISupportedVertical[]
  >([])
  const [loaded, setLoaded] = useState(false)
  const classes = getStylesAdminForm()

  const [errorState, setErrorState] = useState<IErrorListObject[]>([])

  const [countryList, setCountryList] = useState<string[]>([])
  const [functionList, setFunctionList] = useState<string[]>([])

  // timezone into start date
  const parsedStartDate =
    itemList[0] && itemList[0].dateStart
      ? new Date(itemList[0].dateStart)
      : null
  let startDateString
  if (parsedStartDate) {
    const timeOffsetInMS = parsedStartDate.getTimezoneOffset() * 60000 * -1
    parsedStartDate.setTime(parsedStartDate.getTime() - timeOffsetInMS)
    startDateString = parsedStartDate.toISOString()
  }
  // timezone into end date
  const parsedEndDate =
    itemList[0] && itemList[0].dateEnd ? new Date(itemList[0].dateEnd) : null
  let endDateString
  if (parsedEndDate) {
    const timeOffsetInMS = parsedEndDate.getTimezoneOffset() * 60000 * -1
    parsedEndDate.setTime(parsedEndDate.getTime() - timeOffsetInMS)
    endDateString = parsedEndDate.toISOString()
  }

  const [startDate, setStartDate] = useState<Date | null | undefined>(
    startDateString ? new Date(startDateString) : null
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    endDateString ? new Date(endDateString) : null
  )

  useEffect(() => {
    setItemList(createMissingLanguages(itemList) as PopupResult[])
    validateItem()
    updateAvailableSources()
    setLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loaded) {
      updateAvailableSources()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope])

  ///error
  const getError = (locale: string, key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error &&
        errorItem.locale === locale &&
        errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }
  const hasLocaleError = (locale: string, key: string[]) => {
    for (let index = 0; index < key.length; index++) {
      const error = getError(locale, key[index])
      if (error.error) return true
    }

    return false
  }

  const validateItem = (): void => {
    let allValidated = true
    let errorList: IErrorListObject[] = []
    const enItem = getCurrentObjectByLanguage('en')
    if (!enItem) {
      allValidated = false
      return
    }

    supportedLanguages.forEach((lanuage: SupportedLanguage) => {
      const item = getCurrentObjectByLanguage(lanuage.locale)
      if (!item) {
        allValidated = false
        return
      }

      errorList = [
        ...errorList,
        ...checkIsValidPopup(item, enItem as PopupResult)
      ]

      if (!allValidated) return
    })

    setIsValid(!errorList.find((error: IErrorListObject) => error.error.error))
    setErrorState(errorList)
  }

  const validateItemAndCheckLanguages = (checkLanguages = true): void => {
    validateItem()
    if (checkLanguages) {
      const changedLanguages: string[] = []

      supportedLanguages.forEach((lang: SupportedLanguage) => {
        const foundLanguage = changedLanguages.find(
          (locale: string) => locale === lang.locale
        )

        if (!foundLanguage) {
          changedLanguages.push(lang.locale)
        }
      })

      setChangedLanguages([...changedLanguages, ...changedLanguages])
    }
  }

  const updateAvailableSources = () => {
    let functionList = supportedFunctions.map(
      (item: SupportedFunction) => item.name
    )
    let countryList = countriesISO.map((item) => item.Name)
    let sourcelist = supportedVerticals.filter(
      (f: ISupportedVertical) => !f.key.startsWith('Office365_')
    )

    sourcelist = [{ key: 'Landing_Page', name: 'Landing Page' }, ...sourcelist]

    setAvailableSources(sourcelist)

    setFunctionList(functionList)
    setCountryList(countryList)
  }

  const getCurrentObjectByLanguage = (locale: string) => {
    const localeChild = itemList.find(
      (item: PopupResult) => item.language === locale
    )

    if (localeChild) {
      return localeChild
    }
  }

  const createMissingLanguages = (itemListToCheck: PopupResult[]) => {
    const changedItems: string[] = []
    const newItemList: PopupResult[] = []

    supportedLanguages.forEach((lang: SupportedLanguage) => {
      const localeChild = itemListToCheck.find(
        (item: PopupResult) => item.language === lang.locale
      )

      if (localeChild) {
        newItemList.push(localeChild)
      } else {
        const id = itemList[0].id

        const newChild = JSON.parse(JSON.stringify(itemListToCheck[0]))
        if (newChild.isNewItem) {
          delete newChild.isNewItem
        }
        newChild.language = lang.locale
        newChild.id = id.toString()

        newItemList.push(newChild)
        changedItems.push(lang.locale)
      }
    })

    setChangedLanguages([...changedLanguages, ...changedItems])
    return newItemList
  }

  const handleLanguageItemChange = (
    oldItem: PopupResult,
    newItem: PopupResult
  ) => {
    if (
      !changedLanguages.find(
        (changedLanguages: string) => changedLanguages === newItem.language
      )
    )
      setChangedLanguages([...changedLanguages, ...[newItem.language]])

    const changedItems: string[] = []
    const newItemList: PopupResult[] = []
    itemList.forEach((item: PopupResult) => {
      if (oldItem.language === 'en' && oldItem.language !== item.language) {
        let itemUpdated = false

        //update popups
        const targetNewItem = newItem as PopupResult
        const targetItem = item as PopupResult

        if (
          oldItem.title === item.title &&
          targetNewItem.title !== targetItem.title
        ) {
          item.title = newItem.title
          itemUpdated = true
        }

        if (
          oldItem.content === targetItem.content &&
          targetNewItem.content !== targetItem.content
        ) {
          targetItem.content = targetNewItem.content
          itemUpdated = true
        }

        if (
          oldItem.button_text === targetItem.button_text &&
          targetNewItem.button_text !== targetItem.button_text
        ) {
          targetItem.button_text = targetNewItem.button_text
          itemUpdated = true
        }

        if (itemUpdated) {
          changedItems.push(item.language)
        }
      }
      newItemList.push(item)
    })

    changedItems.push(newItem.language)

    setChangedLanguages([...changedLanguages, ...changedItems])
    setItemList(newItemList)
  }

  return (
    <>
      {itemList && itemList.length > 0 && loaded && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={(event: any, newValue: number): void => {
                setTabIndex(newValue)
              }}
              variant="scrollable"
              aria-label="languages"
              scrollButtons={true}
            >
              {supportedLanguages.map(
                (lanuage: SupportedLanguage, index: number) => (
                  <Tab
                    className={classes.tab_select}
                    label={
                      <>
                        {changedLanguages.find(
                          (cL: string) => cL === lanuage.locale
                        ) ? (
                          <i>
                            <b>(Draft)&nbsp;</b>
                          </i>
                        ) : (
                          ''
                        )}
                        {hasLocaleError(lanuage.locale, [
                          editFormErrorKeys.title,
                          editFormErrorKeys.linkText,
                          editFormErrorKeys.btnText
                        ]) && <WarningTwoTone style={{ color: 'red' }} />}
                        <span>{lanuage.name}</span>
                      </>
                    }
                    key={index}
                  />
                )
              )}
            </Tabs>
          </Box>
          <TabContext value={tabIndex + ''}>
            {supportedLanguages.map(
              (lanuage: SupportedLanguage, languageIndex: number) => {
                const currentSelectedObject = getCurrentObjectByLanguage(
                  lanuage.locale
                ) as PopupResult

                return (
                  <TabPanel
                    value={languageIndex + ''}
                    className={classes.tab_panel}
                    key={languageIndex}
                  >
                    <TextField
                      variant="standard"
                      required
                      id="id-required"
                      className={classes.tab_item}
                      label="Id"
                      defaultValue={currentSelectedObject.id}
                      disabled
                    />
                    <TextField
                      variant="standard"
                      id="title-required"
                      className={classes.tab_item}
                      label="Title *"
                      defaultValue={currentSelectedObject.title}
                      onChange={(event) => {
                        const oldItem = Object.assign({}, currentSelectedObject)
                        currentSelectedObject.title = event.target.value
                          ? DOMPurify.sanitize(event.target.value, {
                              USE_PROFILES: { html: false }
                            })
                          : event.target.value
                        handleLanguageItemChange(oldItem, currentSelectedObject)
                        validateItem()
                      }}
                      onBlur={() => {
                        setCurrentFormState(new Date().getTime())
                      }}
                      error={
                        getError(lanuage.locale, editFormErrorKeys.title).error
                      }
                      helperText={
                        getError(lanuage.locale, editFormErrorKeys.title)
                          .helperText
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      key={`title_${currentFormState}`}
                    />

                    <TextField
                      variant="standard"
                      multiline
                      id="text"
                      className={classes.tab_item}
                      label="Content"
                      defaultValue={currentSelectedObject.content}
                      rows={5}
                      onChange={(event) => {
                        const oldItem = Object.assign({}, currentSelectedObject)
                        currentSelectedObject.content = event.target.value
                          ? DOMPurify.sanitize(event.target.value, {
                              USE_PROFILES: { html: true },
                              ADD_ATTR: ['target']
                            })
                          : event.target.value
                        handleLanguageItemChange(oldItem, currentSelectedObject)
                      }}
                      onBlur={() => {
                        setCurrentFormState(new Date().getTime())
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      key={`desc_${currentFormState}`}
                    />

                    <TextField
                      variant="standard"
                      id="link-text"
                      className={classes.tab_item}
                      label="Button Text *"
                      defaultValue={currentSelectedObject.button_text}
                      onChange={(event) => {
                        const oldItem = Object.assign({}, currentSelectedObject)
                        currentSelectedObject.button_text = event.target.value
                          ? DOMPurify.sanitize(event.target.value, {
                              USE_PROFILES: { html: false }
                            })
                          : event.target.value
                        handleLanguageItemChange(oldItem, currentSelectedObject)
                        validateItem()
                      }}
                      onBlur={() => {
                        setCurrentFormState(new Date().getTime())
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={
                        getError(lanuage.locale, editFormErrorKeys.btnText)
                          .error
                      }
                      helperText={
                        getError(lanuage.locale, editFormErrorKeys.btnText)
                          .helperText
                      }
                      key={`link_text_${currentFormState}`}
                    />
                  </TabPanel>
                )
              }
            )}
          </TabContext>

          <TextField
            variant="standard"
            id="link"
            label="Button Link"
            defaultValue={itemList[0].button_link}
            onChange={(event) => {
              itemList[0].button_link = event.target.value
                ? DOMPurify.sanitize(event.target.value, {
                    USE_PROFILES: { html: false }
                  }).replace(/ /g, '%20')
                : event.target.value
              validateItemAndCheckLanguages()
            }}
            onBlur={(event) => {
              validateItemAndCheckLanguages()
            }}
            InputLabelProps={{
              shrink: true
            }}
            error={getError(itemList[0].language, editFormErrorKeys.url).error}
            helperText={
              getError(itemList[0].language, editFormErrorKeys.url).helperText
            }
          />

          <AdminImageUpload
            editProps={itemList[0]}
            onchange={validateItemAndCheckLanguages}
          />
          <FormControl
            component="fieldset"
            error={
              getError(itemList[0].language, editFormErrorKeys.display).error
            }
            className={classes.radioButtonGroup}
            style={
              getError(itemList[0].language, editFormErrorKeys.display).error
                ? { border: '1px solid red' }
                : {}
            }
            disabled={!(isNewItem || itemList[0].isNewItem)}
          >
            <RadioGroup
              aria-label="display"
              name="display"
              value={itemList[0].display}
              onChange={(event) => {
                itemList[0].display = event.target.value as PopupDisplayType
                validateItemAndCheckLanguages()
              }}
            >
              <FormControlLabel
                value={PopupDisplayTypes.oncePerUser}
                control={<Radio />}
                label="Once per user"
              />

              <FormControlLabel
                value={PopupDisplayTypes.onEverySession}
                control={<Radio />}
                label="On every session"
              />

              {itemList[0].display === PopupDisplayTypes.onEverySession && (
                <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={'Start *'}
                      format="MM/dd/yyyy"
                      value={startDate || null}
                      onChange={(date: Date | null) => {
                        let dateString = null
                        if (date && date.toString() !== 'Invalid Date') {
                          const day = date.getDate()
                          const month = date.getMonth() + 1
                          const year = date.getFullYear()
                          dateString =
                            year.toString() +
                            '-' +
                            month.toString().padStart(2, '0') +
                            '-' +
                            day.toString().padStart(2, '0') +
                            'T00:00:00.000Z'
                        }

                        itemList[0].dateStart = dateString
                        setStartDate(date ? date : null)
                        validateItemAndCheckLanguages()
                      }}
                      onYearChange={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          const transformValue = (popperRoot[0] as HTMLElement)
                            .style.transform
                          setTimeout(() => {
                            ;(popperRoot[0] as HTMLElement).style.transform =
                              transformValue
                          }, 10)
                        }
                      }}
                      onClose={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          ;(popperRoot[0] as HTMLElement).style.display = 'none'
                        }
                      }}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: classes.datePicker
                        }
                      }}
                      slots={{
                        textField: (textFieldProps) => (
                          <TextField
                            {...textFieldProps}
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              error: getError(
                                itemList[0].language,
                                editFormErrorKeys.onceEverySession
                              ).error
                            }}
                          />
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={'End *'}
                      format="MM/dd/yyyy"
                      value={endDate || null}
                      onChange={(date: Date | null) => {
                        let dateString = null
                        if (date && date.toString() !== 'Invalid Date') {
                          const day = date.getDate()
                          const month = date.getMonth() + 1
                          const year = date.getFullYear()
                          dateString =
                            year.toString() +
                            '-' +
                            month.toString().padStart(2, '0') +
                            '-' +
                            day.toString().padStart(2, '0') +
                            'T23:59:59.000Z'
                        }

                        itemList[0].dateEnd = dateString
                        setEndDate(date ? date : null)
                        validateItemAndCheckLanguages()
                      }}
                      onYearChange={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          const transformValue = (popperRoot[0] as HTMLElement)
                            .style.transform
                          setTimeout(() => {
                            ;(popperRoot[0] as HTMLElement).style.transform =
                              transformValue
                          }, 10)
                        }
                      }}
                      onClose={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          ;(popperRoot[0] as HTMLElement).style.display = 'none'
                        }
                      }}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: classes.datePicker
                        }
                      }}
                      slots={{
                        textField: (textFieldProps) => (
                          <TextField
                            {...textFieldProps}
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              error: getError(
                                itemList[0].language,
                                editFormErrorKeys.onceEverySession
                              ).error
                            }}
                          />
                        )
                      }}
                    />
                  </LocalizationProvider>
                  {getError(
                    itemList[0].language,
                    editFormErrorKeys.onceEverySession
                  ).helperText && (
                    <FormHelperText className={classes.errorRadioBoxHelpText}>
                      {
                        getError(
                          itemList[0].language,
                          editFormErrorKeys.onceEverySession
                        ).helperText
                      }
                    </FormHelperText>
                  )}
                </>
              )}

              <FormControlLabel
                value={PopupDisplayTypes.oncePerInterval}
                control={<Radio />}
                label="Once per interval"
              />
              {itemList[0].display === PopupDisplayTypes.oncePerInterval && (
                <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={'Start *'}
                      format="MM/dd/yyyy"
                      value={startDate || null}
                      onChange={(date: Date | null) => {
                        let dateString = null
                        if (date && date.toString() !== 'Invalid Date') {
                          const day = date.getDate()
                          const month = date.getMonth() + 1
                          const year = date.getFullYear()
                          dateString =
                            year.toString() +
                            '-' +
                            month.toString().padStart(2, '0') +
                            '-' +
                            day.toString().padStart(2, '0') +
                            'T00:00:00.000Z'
                        }

                        itemList[0].dateStart = dateString
                        setStartDate(date ? date : null)
                        validateItemAndCheckLanguages()
                      }}
                      onYearChange={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          const transformValue = (popperRoot[0] as HTMLElement)
                            .style.transform
                          setTimeout(() => {
                            ;(popperRoot[0] as HTMLElement).style.transform =
                              transformValue
                          }, 10)
                        }
                      }}
                      onClose={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          ;(popperRoot[0] as HTMLElement).style.display = 'none'
                        }
                      }}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: classes.datePicker
                        }
                      }}
                      slots={{
                        textField: (textFieldProps) => (
                          <TextField
                            {...textFieldProps}
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              error: getError(
                                itemList[0].language,
                                editFormErrorKeys.oncePerInterval
                              ).error
                            }}
                          />
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={'End *'}
                      format="MM/dd/yyyy"
                      value={endDate || null}
                      onChange={(date: Date | null) => {
                        let dateString = null
                        if (date && date.toString() !== 'Invalid Date') {
                          const day = date.getDate()
                          const month = date.getMonth() + 1
                          const year = date.getFullYear()
                          dateString =
                            year.toString() +
                            '-' +
                            month.toString().padStart(2, '0') +
                            '-' +
                            day.toString().padStart(2, '0') +
                            'T23:59:59.000Z'
                        }

                        itemList[0].dateEnd = dateString
                        setEndDate(date ? date : null)
                        validateItemAndCheckLanguages()
                      }}
                      onYearChange={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          const transformValue = (popperRoot[0] as HTMLElement)
                            .style.transform
                          setTimeout(() => {
                            ;(popperRoot[0] as HTMLElement).style.transform =
                              transformValue
                          }, 10)
                        }
                      }}
                      onClose={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          ;(popperRoot[0] as HTMLElement).style.display = 'none'
                        }
                      }}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: classes.datePicker
                        }
                      }}
                      slots={{
                        textField: (textFieldProps) => (
                          <TextField
                            {...textFieldProps}
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              error: getError(
                                itemList[0].language,
                                editFormErrorKeys.oncePerInterval
                              ).error
                            }}
                          />
                        )
                      }}
                    />
                  </LocalizationProvider>

                  <TextField
                    variant="standard"
                    type="number"
                    required
                    id="interval-required"
                    label="Interval (Hours)"
                    defaultValue={itemList[0].intervalHours}
                    onChange={(event) => {
                      itemList[0].intervalHours = event.target.value
                      validateItemAndCheckLanguages()
                    }}
                    error={
                      getError(
                        itemList[0].language,
                        editFormErrorKeys.oncePerInterval
                      ).error
                    }
                    helperText={
                      getError(
                        itemList[0].language,
                        editFormErrorKeys.oncePerInterval
                      ).helperText
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </>
              )}
            </RadioGroup>

            {getError(itemList[0].language, editFormErrorKeys.display)
              .helperText && (
              <FormHelperText className={classes.errorRadioBoxHelpText}>
                {
                  getError(itemList[0].language, editFormErrorKeys.display)
                    .helperText
                }
              </FormHelperText>
            )}
          </FormControl>
          {renderSelect(
            scope,
            setScope,
            ['OI', 'KPMGFind', 'All'],
            false,
            'Scope',
            'scope',
            itemList[0],
            isNewItem,
            validateItemAndCheckLanguages
          )}
          {renderSelect(
            sources,
            setSources,
            availableSources,
            true,
            'Sources *',
            'sources',
            itemList[0],
            isNewItem,
            () => {
              validateItemAndCheckLanguages()
            },
            false,
            getError(itemList[0].language, editFormErrorKeys.source)
          )}
          {renderSelect(
            countries,
            setCountries,
            countryList,
            true,
            'Countries',
            'countries',
            itemList[0],
            isNewItem,
            validateItemAndCheckLanguages
          )}
          {renderSelect(
            functions,
            setFunctions,
            functionList,
            true,
            'Functions',
            'functions',
            itemList[0],
            isNewItem,
            validateItemAndCheckLanguages,
            true
          )}
        </>
      )}
    </>
  )
}
