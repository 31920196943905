import React from 'react'
import { MTableToolbar } from '@material-table/core'
import { Button, Tooltip } from '@mui/material'
import { RequestType } from 'models/SelfServiceRequest'
import { getStylesRequests } from 'styles/requests/requests'
import { AddBox } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { SettingMenuTypes } from 'models/SettingMenuTypes'
import { useIntl } from 'react-intl'

export interface IMyRequestTableToolbarProps {
  toolbarprops: any
  handleRowAdd: (item: RequestType) => void
  setShowSettings: (settingsType: null | SettingMenuTypes) => void
}

export default function MyRequestTableToolbar(
  props: IMyRequestTableToolbarProps
): JSX.Element {
  const { toolbarprops, handleRowAdd, setShowSettings } = props

  const classes = getStylesRequests()
  const intl = useIntl()

  return (
    <div className={classes.toolbarContainer}>
      <MTableToolbar {...toolbarprops} />
      <div className="MuiToolbar-regular">
        <span className={classes.buttonWrapper}>
          <Tooltip
            title={intl.formatMessage({
              id: 'table_toolbar_deputy_tooltip',
              defaultMessage: 'Add new deputies'
            })}
            aria-label={'Add new deputies'}
          >
            <Button
              onClick={() => {
                setShowSettings(SettingMenuTypes.Deputy)
              }}
              className={classes.buttonAdd}
            >
              <div
                className={classes.buttonText}
                style={{ marginRight: '10px' }}
              >
                {intl.formatMessage({
                  id: 'table_toolbar_deputy',
                  defaultMessage: 'Add Deputies'
                })}
              </div>
              <AddIcon className={classes.icon} />
            </Button>
          </Tooltip>
        </span>
        <span className={classes.buttonWrapper}>
          <Tooltip
            title={intl.formatMessage({
              id: 'table_toolbar_request_adword_tooltip',
              defaultMessage: 'Request new Adword'
            })}
            aria-label={'Request new Adword'}
          >
            <Button
              onClick={() => {
                handleRowAdd(RequestType.AdWord)
              }}
              className={classes.buttonAdd}
            >
              <AddBox className={classes.icon} />
              <div className={classes.buttonText}>
                {intl.formatMessage({
                  id: 'table_toolbar_request_adword',
                  defaultMessage: 'New Adword'
                })}
              </div>
            </Button>
          </Tooltip>
        </span>
        <span className={classes.buttonWrapper}>
          <Tooltip
            title={intl.formatMessage({
              id: 'table_toolbar_request_featured_tooltip',
              defaultMessage: 'Request new Featured Result'
            })}
            aria-label={'Request new Featured Result'}
          >
            <Button
              onClick={() => {
                handleRowAdd(RequestType.FeaturedResult)
              }}
              className={classes.buttonAdd}
            >
              <AddBox className={classes.icon} />
              <div className={classes.buttonText}>
                {intl.formatMessage({
                  id: 'table_toolbar_request_featured',
                  defaultMessage: 'New Featured Result'
                })}
              </div>
            </Button>
          </Tooltip>
        </span>
        <span className={classes.buttonWrapper}>
          <Tooltip
            title={intl.formatMessage({
              id: 'table_toolbar_request_spelling_suggestion_tooltip',
              defaultMessage: "Request new 'Did You Mean' Suggestion"
            })}
            aria-label={"Request new 'Did You Mean' Suggestion"}
          >
            <Button
              onClick={() => {
                handleRowAdd(RequestType.SpellingSuggestion)
              }}
              className={classes.buttonAdd}
            >
              <AddBox className={classes.icon} />
              <div className={classes.buttonText}>
                {intl.formatMessage({
                  id: 'table_toolbar_request_spelling_suggestion',
                  defaultMessage: "New 'Did You Mean' Suggestion"
                })}
              </div>
            </Button>
          </Tooltip>
        </span>
      </div>
    </div>
  )
}
