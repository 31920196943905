import { Column } from '@material-table/core'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import AdminTableTooltipTitle from '../common/AdminTableTooltipTitle'
import React from 'react'
import {
  SingleTranslationResult,
  TranslationResult
} from 'models/TranslationResult'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import { stripHtml } from 'utils/string'
import { findDefaultFilterComponent } from 'utils/admin/adminFormUtils'
import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'

/**
 * Generate popup columns list
 * @param changes AdminItemChange list
 * @returns columns list
 */
export const translationsTableColumns = (
  isOiTranslation: boolean
): Column<TranslationResult>[] => {
  const classes = getStylesAdminSettings()
  const columnDataTranslations: Column<TranslationResult>[] = []
  if (isOiTranslation) {
    columnDataTranslations.push({
      title: 'Scope',
      field: 'scope',
      type: 'string',
      cellStyle: {
        maxWidth: 50
      },
      headerStyle: {
        maxWidth: 50
      },
      filtering: true,
      filterComponent: (props) => (
        <FormControl fullWidth>
          <Select
            id="filterSelect"
            multiple={false}
            variant={'standard'}
            defaultValue="All"
            onChange={(event: SelectChangeEvent<string>) => {
              const newFilterValue = event.target.value

              props.onFilterChanged(
                (props.columnDef as any).tableData.id,
                newFilterValue
              )
            }}
          >
            {['All', 'OI', 'Teams'].map((filterValue: string) => {
              return (
                <MenuItem key={filterValue} value={filterValue}>
                  <ListItemText primary={filterValue} />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      ),
      render: (rowData: TranslationResult) => {
        return <div>{rowData.scope ? rowData.scope : 'All'}</div>
      },
      customFilterAndSearch: (
        value: any,
        rowData: TranslationResult
      ): boolean => {
        if (!value || value.length === 0 || value === 'All') return true

        return rowData.scope === value || !rowData.scope
      },
      searchable: false
    })
  }

  columnDataTranslations.push({
    title: 'Key',
    field: 'key',
    type: 'string',
    cellStyle: {
      maxWidth: 200
    },
    headerStyle: {
      maxWidth: 200
    },
    render: (rowData: TranslationResult) => (
      <AdminTableTooltipTitle
        className={classes.rowEllipsis}
        basedOn="words"
        unsafeHTML={`${rowData.draft ? '<i><b>(Draft)</b></i> ' : ''}${
          rowData.key ? rowData.key.replaceAll('_', '_<wbr>') : ''
        }`}
        maxLine={3}
      />
    ),
    filtering: false
  })

  supportedLanguages.forEach((lang: SupportedLanguage) => {
    const columnOptions: Column<TranslationResult> = {
      title: lang.name,
      field: 'value',
      type: 'string',
      filtering: true,
      filterComponent: findDefaultFilterComponent,
      customSort: (a: TranslationResult, b: TranslationResult) => {
        const firstTranslation =
          a.translations[
            a.translations.findIndex(
              (translation: any) => translation.language === lang.locale
            )
          ]
        const secondTranslation =
          b.translations[
            b.translations.findIndex(
              (translation: any) => translation.language === lang.locale
            )
          ]

        if (
          firstTranslation &&
          firstTranslation.value &&
          secondTranslation &&
          secondTranslation.value
        ) {
          return firstTranslation.value.localeCompare(secondTranslation.value)
        }

        return 0
      },
      render: (rowData: TranslationResult) => {
        const translationItem = rowData.translations.find(
          (translation) => translation.language === lang.locale
        )

        const shownValue =
          translationItem && translationItem.value
            ? stripHtml(translationItem.value)
            : ''
        return (
          <AdminTableTooltipTitle
            className={classes.rowEllipsis}
            basedOn="words"
            text={shownValue}
            maxLine={1}
          />
        )
      },
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      }
    }

    columnOptions.customFilterAndSearch = (
      value: any,
      rowData: TranslationResult
    ): boolean => {
      if (!value || !rowData || !rowData.translations) return true

      const searchTermFoundInTranslations = rowData.translations.some(
        (t: SingleTranslationResult) =>
          t.value
            ? t.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
            : false
      )

      return searchTermFoundInTranslations
    }

    columnDataTranslations.push(columnOptions)
  })

  return columnDataTranslations
}
