// KPMG Find - Settings - Configuration Variables Local
export const ACTIVE_DIRECTORY_CLIENT_ID = '%%%AADClientID%%%'
export const ACTIVE_DIRECTORY_AUTHORITY = '%%%AADAuthority%%%'
export const ACTIVE_DIRECTORY_SCOPE = '%%%AADScope%%%'
export const APIM_BASE_URL = '%%%ApimBaseUrl%%%'
export const OCP_APIM_SUBSCRIPTION_KEY = '%%%OcpApimSubscriptionKey%%%'
export const APP_INSIGHTS_INTRUMENTATION_KEY =
  '%%%AppInsightsInstrumentationKey%%%'
export const APP_URL = '%%%AppUrl%%%'
export const LOCAL_DEVELOPMENT = 'false'
export const AUTHORIZATION_SECURITY_GROUP = '%%%AuthorizationSecurityGroup%%%'
export const GLOBAL_OI_TOKEN_ENDPOINT = '%%%KPMG_GLOBAL_OI_TOKEN_ENDPOINT%%%'
export const BUILD_NUMBER = '%%%BuildNumber%%%'

// export const ACTIVE_DIRECTORY_CLIENT_ID = '70d1d59a-0342-48c0-a068-9e95422c31be'
// export const ACTIVE_DIRECTORY_AUTHORITY =
//   'https://login.microsoftonline.com/07957fd7-77e5-4597-be21-4fcbc87e451a'
// export const ACTIVE_DIRECTORY_SCOPE =
//   'api://70d1d59a-0342-48c0-a068-9e95422c31be/KPMGFind'
// export const APIM_BASE_URL = 'http://localhost:5000/'
// export const OCP_APIM_SUBSCRIPTION_KEY = '00000000-0000-0000-0000-000000000000'
// export const APP_INSIGHTS_INTRUMENTATION_KEY = ''
// export const APP_URL = ''
// export const LOCAL_DEVELOPMENT = 'true'
// export const AUTHORIZATION_SECURITY_GROUP =
//   'cb926f20-5126-4c1f-abc5-63e71617c568'
// export const GLOBAL_OI_TOKEN_ENDPOINT = '%%%KPMG_GLOBAL_OI_TOKEN_ENDPOINT%%%'
// export const BUILD_NUMBER = '1.0.0.0'
