import { AnyAction, Action } from 'redux'
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Store } from 'store'
import * as Config from '../../config'
import { trackEvents, trackException } from 'utils/tracking'
import {
  getGraphAuthToken,
  renewAuthorizationToken
} from 'authentication/token'
import { IAdminPagesAuthorization } from 'utils/authorization'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'

export enum AuthActionTypes {
  SET_AUTH_TOKEN = 'auth/SET_AUTH_TOKEN',
  SET_AAD_INFO = 'auth/SET_AAD_INFO',
  FETCH_ADMIN_PAGES_AUTHORIZATION_REQUEST = 'auth/FETCH_ADMIN_PAGES_AUTHORIZATION_REQUEST',
  FETCH_ADMIN_PAGES_AUTHORIZATION_SUCCESS = 'auth/FETCH_ADMIN_PAGES_AUTHORIZATION_SUCCESS',
  FETCH_ADMIN_PAGES_AUTHORIZATION_NOTFOUND = 'auth/FETCH_ADMIN_PAGES_AUTHORIZATION_NOTFOUND',
  FETCH_ADMIN_PAGES_AUTHORIZATION_FAILURE = 'auth/FETCH_ADMIN_PAGES_AUTHORIZATION_FAILURE'
}

export interface ISetAuthToken extends Action<AuthActionTypes> {
  payload: {
    authToken: string
  }
}

export interface ISetAADInfo extends Action<AuthActionTypes> {
  payload: {
    instance: IPublicClientApplication
    accounts: AccountInfo[]
  }
}

export type IFetchAdminPagesAuthorizationRequest = Action<AuthActionTypes>
export interface IFetchAdminPagesAuthorizationSuccess
  extends Action<AuthActionTypes> {
  payload: {
    adminPagesAuthorization: IAdminPagesAuthorization
  }
}
export type IFetchAdminPagesAuthorizationNotFound = Action<AuthActionTypes>
export type IFetchAdminPagesAuthorizationFailure = Action<AuthActionTypes>

export const setAuthToken = (authToken: string): ISetAuthToken => ({
  type: AuthActionTypes.SET_AUTH_TOKEN,
  payload: { authToken }
})
export const setAADInfo = (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
): ISetAADInfo => ({
  type: AuthActionTypes.SET_AAD_INFO,
  payload: { instance, accounts }
})
export const fetchAdminPagesAuthorizationRequest =
  (): IFetchAdminPagesAuthorizationRequest => ({
    type: AuthActionTypes.FETCH_ADMIN_PAGES_AUTHORIZATION_REQUEST
  })
export const fetchAdminPagesAuthorizationSuccess = (
  adminPagesAuthorization: IAdminPagesAuthorization
): IFetchAdminPagesAuthorizationSuccess => ({
  type: AuthActionTypes.FETCH_ADMIN_PAGES_AUTHORIZATION_SUCCESS,
  payload: { adminPagesAuthorization }
})
export const fetchAdminPagesAuthorizationNotFound = (
  response: any = {}
): IFetchAdminPagesAuthorizationNotFound => ({
  type: AuthActionTypes.FETCH_ADMIN_PAGES_AUTHORIZATION_NOTFOUND
})
export const fetchAdminPagesAuthorizationFailure =
  (): IFetchAdminPagesAuthorizationFailure => ({
    type: AuthActionTypes.FETCH_ADMIN_PAGES_AUTHORIZATION_FAILURE
  })

export const fetchAdminPagesAuthorization = (
  upn: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchAdminPagesAuthorizationRequest())
    try {
      trackEvents('getAdminPagesAuthorization', { upn: upn })

      // Get and check authentication token
      const aadInfo = getState().auth.aad
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )

      if (esToken !== aadInfo.accessToken) {
        setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }
      const graphToken = await getGraphAuthToken(
        aadInfo.instance,
        aadInfo.accounts
      )

      const requestBody = {
        groupIds: [Config.AUTHORIZATION_SECURITY_GROUP]
      }
      const apiUrl = Config.APIM_BASE_URL + 'msgraphapi/postmembergroups'
      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${graphToken}`,
          JWTToken: esToken
        },
        body: JSON.stringify(requestBody)
      })

      if (!data || !data.ok) {
        dispatch(fetchAdminPagesAuthorizationFailure())
        trackException(
          'Error in fetching admin pages authorization request',
          new Error(
            'Error in data object after fetching admin pages authorization request'
          )
        )
        return
      } else if (data.status === 404) {
        dispatch(fetchAdminPagesAuthorizationNotFound())
        return
      }

      const results = await data.json()

      if (!results || !results.value) {
        dispatch(fetchAdminPagesAuthorizationFailure())
        trackException(
          'Error in fetching admin pages authorization request',
          new Error(
            'Error in data object after fetching admin pages authorization request'
          )
        )
        return
      }
      const result: IAdminPagesAuthorization = results
      dispatch(fetchAdminPagesAuthorizationSuccess(result))
    } catch (error) {
      dispatch(fetchAdminPagesAuthorizationFailure())
      trackException(
        'Error in fetching admin pages authorization action',
        error
      )
    }
  }
}

export type AuthActions =
  | ISetAuthToken
  | IFetchAdminPagesAuthorizationSuccess
  | ISetAADInfo
