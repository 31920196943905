import { Box, Tab, Tabs, TextField } from '@mui/material'
import { WarningTwoTone } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import AdminImageUpload from '../common/AdminImageUpload'
import { countriesISO } from 'constants/countriesISO'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import {
  SupportedFunction,
  supportedFunctions
} from 'constants/supportedFunctions'
import {
  convertToArray,
  renderSelect,
  editFormErrorKeys,
  checkIsValidAd
} from 'utils/admin/adminFormUtils'
import createDOMPurify from 'dompurify'
import { TabContext, TabPanel } from '@mui/lab'
import { AdsResult } from '../../../models/AdsResult'
import ConfirmationModal from 'components/contents/common/Dialog'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { createDefaultDate } from 'utils/date'
import { filterSearchTermInput } from 'utils/admin/adminContentQuality'
import { capitalizeFirstLetter } from 'utils/string'
import { AdminItemChange } from 'models/AdminItemChange'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import {
  ISupportedVertical,
  supportedVerticals
} from 'constants/supportedVerticals'

export interface IEditFormAdsProps {
  itemList: AdsResult[]
  isNewItem: boolean
  setIsValid: (isValid: boolean) => void
  changedLanguages: string[]
  setChangedLanguages: (languages: string[]) => void
  setItemList: (newItems: AdsResult[]) => void
  allAds: AdsResult[]
  changes: AdminItemChange[]
  tabIndex: number
  setTabIndex: (index: number) => void
}

export default function EditFormAds(props: IEditFormAdsProps): JSX.Element {
  const {
    itemList,
    isNewItem,
    setIsValid,
    changedLanguages,
    setChangedLanguages,
    setItemList,
    allAds,
    changes,
    tabIndex,
    setTabIndex
  } = props
  const DOMPurify = createDOMPurify(window)

  // timezone into start date
  const parsedStartDate =
    itemList[0] && itemList[0].start ? new Date(itemList[0].start) : null
  let startDateString
  if (parsedStartDate) {
    const timeOffsetInMS = parsedStartDate.getTimezoneOffset() * 60000 * -1
    parsedStartDate.setTime(parsedStartDate.getTime() - timeOffsetInMS)
    startDateString = parsedStartDate.toISOString()
  }
  // timezone into end date
  const parsedEndDate =
    itemList[0] && itemList[0].end ? new Date(itemList[0].end) : null
  let endDateString
  if (parsedEndDate) {
    const timeOffsetInMS = parsedEndDate.getTimezoneOffset() * 60000 * -1
    parsedEndDate.setTime(parsedEndDate.getTime() - timeOffsetInMS)
    endDateString = parsedEndDate.toISOString()
  }

  const [startDate, setStartDate] = useState<Date | null | undefined>(
    startDateString ? new Date(startDateString) : null
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    endDateString ? new Date(endDateString) : null
  )

  const [sources, setSources] = useState(
    itemList[0]?.sources.map((dsKey: string) => {
      return {
        key: capitalizeFirstLetter(dsKey),
        name: capitalizeFirstLetter(dsKey)
      }
    })
  )

  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())
  const [countries, setCountries] = useState(itemList[0]?.countries)
  const [functions, setFunctions] = useState(itemList[0]?.functions)
  const [matchType, setMatchType] = useState(
    itemList[0] && itemList[0].match_type ? itemList[0].match_type : ''
  )
  const [flavour, setFlavour] = useState(
    itemList[0] && itemList[0].flavour ? itemList[0].flavour : 'small'
  )
  const [scope, setScope] = useState(
    itemList[0] && itemList[0].scope ? itemList[0].scope : 'All'
  )
  const [availableSources, setAvailableSources] = useState<
    ISupportedVertical[]
  >([])
  const [loaded, setLoaded] = useState(false)
  const classes = getStylesAdminForm()

  const [errorState, setErrorState] = useState<IErrorListObject[]>([])

  const [countryList, setCountryList] = useState<string[]>([])
  const [functionList, setFunctionList] = useState<string[]>([])

  const [showRankChanges, setShowRankChanges] = useState(false)
  const [rank, setRank] = useState<number>(
    itemList[0] && itemList[0].rank ? itemList[0].rank : 0
  )

  const [rankAuto, setRankAuto] = useState<number>(0)
  useEffect(() => {
    setItemList(createMissingLanguages(itemList) as AdsResult[])
    validateItem()
    updateAvailableSources(itemList)
    setLoaded(true)

    if (!endDate && isNewItem) {
      const dateString = createDefaultDate()
      setEndDate(new Date(dateString))
      itemList[0].end = dateString
      validateItemAndCheckLanguages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loaded) {
      updateAvailableSources(itemList)

      if (scope === 'KPMGFind' && itemList[0].site_url) {
        itemList[0].site_url = ''
        validateItemAndCheckLanguages()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope])

  //error
  const getError = (locale: string, key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error &&
        errorItem.locale === locale &&
        errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }
  const hasLocaleError = (locale: string, key: string[]) => {
    for (let index = 0; index < key.length; index++) {
      const error = getError(locale, key[index])
      if (error.error) return true
    }

    return false
  }

  const validateItem = (): void => {
    let allValidated = true
    let errorList: IErrorListObject[] = []
    const enItem = getCurrentObjectByLanguage('en')
    if (!enItem) {
      allValidated = false
      return
    }

    supportedLanguages.forEach((lanuage: SupportedLanguage) => {
      const item = getCurrentObjectByLanguage(lanuage.locale)
      if (!item) {
        allValidated = false
        return
      }

      errorList = [
        ...errorList,
        ...checkIsValidAd(item, enItem as AdsResult, false)
      ]

      if (!allValidated) return
    })

    setIsValid(!errorList.find((error: IErrorListObject) => error.error.error))
    setErrorState(errorList)
  }

  const validateItemAndCheckLanguages = (checkLanguages = true): void => {
    validateItem()
    if (checkLanguages) {
      const changedLanguages: string[] = []

      supportedLanguages.forEach((lang: SupportedLanguage) => {
        const foundLanguage = changedLanguages.find(
          (locale: string) => locale === lang.locale
        )

        if (!foundLanguage) {
          changedLanguages.push(lang.locale)
        }
      })

      setChangedLanguages([...changedLanguages, ...changedLanguages])
    }
  }

  const updateAvailableSources = (items: AdsResult[]) => {
    let functionList = supportedFunctions.map(
      (item: SupportedFunction) => item.name
    )
    let countryList = countriesISO.map((item) => item.Name)
    let sourcelist = supportedVerticals

    sourcelist = [{ key: 'All', name: 'All' }, ...sourcelist]
    countryList = ['All', ...countryList]
    functionList = ['All', ...functionList]

    setAvailableSources(sourcelist)

    setFunctionList(functionList)
    setCountryList(countryList)
  }

  const getCurrentObjectByLanguage = (locale: string) => {
    const localeChild = itemList.find(
      (item: AdsResult) => item.language === locale
    )

    if (localeChild) {
      return localeChild
    }
  }

  const createMissingLanguages = (itemListToCheck: AdsResult[]) => {
    const changedItems: string[] = []
    const newItemList: AdsResult[] = []

    supportedLanguages.forEach((lang: SupportedLanguage) => {
      const localeChild = itemListToCheck.find(
        (item: AdsResult) => item.language === lang.locale
      )

      if (localeChild) {
        newItemList.push(localeChild)
      } else {
        const id = itemList[0].id

        const newChild = JSON.parse(JSON.stringify(itemListToCheck[0]))
        newChild.language = lang.locale
        newChild.id = id.toString()

        newItemList.push(newChild)
        changedItems.push(lang.locale)
      }
    })

    setChangedLanguages([...changedLanguages, ...changedItems])
    return newItemList
  }

  const handleLanguageItemChange = (oldItem: AdsResult, newItem: AdsResult) => {
    if (
      !changedLanguages.find(
        (changedLanguages: string) => changedLanguages === newItem.language
      )
    )
      setChangedLanguages([...changedLanguages, ...[newItem.language]])

    const changedItems: string[] = []
    const newItemList: AdsResult[] = []
    itemList.forEach((item: AdsResult) => {
      if (oldItem.language === 'en' && oldItem.language !== item.language) {
        let itemUpdated = false
        //update ads

        const targetNewItem = newItem as AdsResult
        const targetItem = item as AdsResult
        if (
          oldItem.title === item.title &&
          targetNewItem.title !== targetItem.title
        ) {
          item.title = newItem.title
          itemUpdated = true
        }
        if (
          oldItem.text === targetItem.text &&
          targetNewItem.text !== targetItem.text
        ) {
          targetItem.text = targetNewItem.text
          itemUpdated = true
        }

        if (
          oldItem.link_text === targetItem.link_text &&
          targetNewItem.link_text !== targetItem.link_text
        ) {
          targetItem.link_text = targetNewItem.link_text
          itemUpdated = true
        }

        if (itemUpdated) {
          changedItems.push(item.language)
        }
      }
      newItemList.push(item)
    })

    changedItems.push(newItem.language)

    setChangedLanguages([...changedLanguages, ...changedItems])
    setItemList(newItemList)
  }

  /**
   * increase rank value if another ad exists with same source, searchterm and language
   * @param adResult adItem to be updated
   */
  const checkDefaultRankValue = (adResult: AdsResult) => {
    let countExistingAdCriterias = 0
    allAds.forEach((item: AdsResult) => {
      if (
        item.language === adResult.language &&
        (item.sources.some((source: string) =>
          adResult.sources
            .map((nSource: string) => nSource.toLowerCase())
            .includes(source.toLocaleLowerCase())
        ) ||
          (item.sources.length === 0 && adResult.sources.length === 0)) &&
        (item.search_terms.some((searchTerm: string) =>
          adResult.search_terms
            .map((nTerms: string) => nTerms.toLowerCase())
            .includes(searchTerm.toLocaleLowerCase())
        ) ||
          (item.search_terms.length === 0 &&
            adResult.search_terms.length === 0))
      ) {
        if (
          item.rank !== undefined &&
          item.rank !== null &&
          item.rank >= countExistingAdCriterias
        ) {
          countExistingAdCriterias = item.rank + 1
        }
      }
    })

    changes.forEach((change: any) => {
      const item: AdsResult = change.changes
      if (
        item.language === adResult.language &&
        (item.sources.some((source: string) =>
          adResult.sources.includes(source)
        ) ||
          (item.sources.length === 0 && adResult.sources.length === 0)) &&
        (item.search_terms.some((searchTerm: string) =>
          adResult.search_terms.includes(searchTerm)
        ) ||
          (item.search_terms.length === 0 &&
            adResult.search_terms.length === 0))
      ) {
        if (
          item.rank !== undefined &&
          item.rank !== null &&
          item.rank >= countExistingAdCriterias
        ) {
          countExistingAdCriterias = item.rank + 1
        }
      }
    })

    if (
      countExistingAdCriterias !== adResult.rank &&
      (adResult.rank === 0 || rank === rankAuto)
    ) {
      adResult.rank = countExistingAdCriterias
      setRank(adResult.rank)
      setRankAuto(adResult.rank)
      setShowRankChanges(true)
    }
  }

  return (
    <>
      {itemList && itemList.length > 0 && loaded && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={(event: any, newValue: number): void => {
                setTabIndex(newValue)
              }}
              variant="scrollable"
              aria-label="languages"
              scrollButtons={true}
            >
              {supportedLanguages.map(
                (lanuage: SupportedLanguage, index: number) => (
                  <Tab
                    className={classes.tab_select}
                    label={
                      <>
                        {changedLanguages.find(
                          (cL: string) => cL === lanuage.locale
                        ) ? (
                          <i>
                            <b>(Draft)&nbsp;</b>
                          </i>
                        ) : (
                          ''
                        )}
                        {hasLocaleError(lanuage.locale, [
                          editFormErrorKeys.title,
                          editFormErrorKeys.linkText,
                          editFormErrorKeys.btnText
                        ]) && <WarningTwoTone style={{ color: 'red' }} />}
                        <span>{lanuage.name}</span>
                      </>
                    }
                    key={index}
                  />
                )
              )}
            </Tabs>
          </Box>
          <TabContext value={tabIndex + ''}>
            {supportedLanguages.map(
              (lanuage: SupportedLanguage, languageIndex: number) => {
                const currentSelectedObject = getCurrentObjectByLanguage(
                  lanuage.locale
                ) as AdsResult

                return (
                  <TabPanel
                    value={languageIndex + ''}
                    className={classes.tab_panel}
                    key={languageIndex}
                  >
                    <TextField
                      variant="standard"
                      required
                      id="id-required"
                      className={classes.tab_item}
                      label="Id"
                      defaultValue={currentSelectedObject.id}
                      disabled
                    />
                    <TextField
                      variant="standard"
                      id="title-required"
                      className={classes.tab_item}
                      label="Title *"
                      defaultValue={currentSelectedObject.title}
                      onChange={(event) => {
                        const oldItem = Object.assign({}, currentSelectedObject)

                        currentSelectedObject.title = event.target.value
                          ? DOMPurify.sanitize(event.target.value, {
                              USE_PROFILES: { html: false }
                            })
                          : event.target.value
                        handleLanguageItemChange(oldItem, currentSelectedObject)
                        validateItem()
                      }}
                      onBlur={() => {
                        setCurrentFormState(new Date().getTime())
                      }}
                      error={
                        getError(lanuage.locale, editFormErrorKeys.title).error
                      }
                      helperText={
                        getError(lanuage.locale, editFormErrorKeys.title)
                          .helperText
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      key={`title_${currentFormState}`}
                    />
                    <TextField
                      variant="standard"
                      className={classes.tab_item}
                      multiline
                      id="text"
                      label="Description *"
                      defaultValue={currentSelectedObject.text}
                      rows={5}
                      onChange={(event) => {
                        const oldItem = Object.assign({}, currentSelectedObject)
                        currentSelectedObject.text = event.target.value
                          ? DOMPurify.sanitize(event.target.value, {
                              USE_PROFILES: { html: true },
                              ADD_ATTR: ['target']
                            })
                          : event.target.value
                        handleLanguageItemChange(oldItem, currentSelectedObject)
                        validateItem()
                      }}
                      onBlur={() => {
                        setCurrentFormState(new Date().getTime())
                      }}
                      error={
                        getError(lanuage.locale, editFormErrorKeys.description)
                          .error
                      }
                      helperText={
                        getError(lanuage.locale, editFormErrorKeys.description)
                          .helperText
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      key={`desc_${currentFormState}`}
                    />
                    <TextField
                      variant="standard"
                      id="link-text"
                      className={classes.tab_item}
                      label="Link Text"
                      defaultValue={currentSelectedObject.link_text}
                      onChange={(event) => {
                        const oldItem = Object.assign({}, currentSelectedObject)
                        currentSelectedObject.link_text = event.target.value
                          ? DOMPurify.sanitize(event.target.value, {
                              USE_PROFILES: { html: false }
                            })
                          : event.target.value
                        handleLanguageItemChange(oldItem, currentSelectedObject)
                        validateItem()
                      }}
                      onBlur={(event) => {
                        setCurrentFormState(new Date().getTime())
                        validateItem()
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={
                        getError(lanuage.locale, editFormErrorKeys.linkText)
                          .error
                      }
                      helperText={
                        getError(lanuage.locale, editFormErrorKeys.linkText)
                          .helperText
                      }
                      key={`linke_text_${currentFormState}`}
                    />
                  </TabPanel>
                )
              }
            )}
          </TabContext>

          <TextField
            variant="standard"
            id="link"
            label="Link"
            defaultValue={itemList[0].link}
            onChange={(event) => {
              itemList[0].link = event.target.value
                ? DOMPurify.sanitize(event.target.value.replace(/ /g, '%20'))
                : event.target.value
              validateItemAndCheckLanguages()
            }}
            onBlur={(event) => {
              validateItem()
            }}
            InputLabelProps={{
              shrink: true
            }}
            error={
              getError(itemList[0].language, editFormErrorKeys.link).error ||
              getError(itemList[0].language, editFormErrorKeys.url).error
            }
            helperText={
              getError(itemList[0].language, editFormErrorKeys.link)
                .helperText ||
              getError(itemList[0].language, editFormErrorKeys.url).helperText
            }
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={'Start *'}
              format="MM/dd/yyyy"
              value={startDate || null}
              onChange={(date: Date | null) => {
                let dateString = null
                if (date && date.toString() !== 'Invalid Date') {
                  const day = date.getDate()
                  const month = date.getMonth() + 1
                  const year = date.getFullYear()
                  dateString =
                    year.toString() +
                    '-' +
                    month.toString().padStart(2, '0') +
                    '-' +
                    day.toString().padStart(2, '0') +
                    'T00:00:00.000Z'
                }

                itemList[0].start = dateString
                setStartDate(date ? date : null)

                validateItemAndCheckLanguages()
              }}
              onYearChange={() => {
                const popperRoot = document.querySelectorAll(
                  '[class*=MuiPickersPopper-root'
                )
                if (popperRoot.length > 0) {
                  const transformValue = (popperRoot[0] as HTMLElement).style
                    .transform
                  setTimeout(() => {
                    ;(popperRoot[0] as HTMLElement).style.transform =
                      transformValue
                  }, 10)
                }
              }}
              onClose={() => {
                const popperRoot = document.querySelectorAll(
                  '[class*=MuiPickersPopper-root'
                )
                if (popperRoot.length > 0) {
                  ;(popperRoot[0] as HTMLElement).style.display = 'none'
                }
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  className: classes.datePicker
                }
              }}
              slots={{
                textField: (textFieldProps) => (
                  <TextField
                    {...textFieldProps}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={
                      getError(
                        itemList[0].language,
                        editFormErrorKeys.startDate
                      ).error
                    }
                    helperText={
                      getError(
                        itemList[0].language,
                        editFormErrorKeys.startDate
                      ).helperText
                    }
                  />
                )
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={'End *'}
              format="MM/dd/yyyy"
              value={endDate || null}
              onChange={(date: Date | null) => {
                let dateString = null
                if (date && date.toString() !== 'Invalid Date') {
                  const day = date.getDate()
                  const month = date.getMonth() + 1
                  const year = date.getFullYear()
                  dateString =
                    year.toString() +
                    '-' +
                    month.toString().padStart(2, '0') +
                    '-' +
                    day.toString().padStart(2, '0') +
                    'T23:59:59.000Z'
                }

                itemList[0].end = dateString
                setEndDate(date ? date : null)

                validateItemAndCheckLanguages()
              }}
              onYearChange={() => {
                const popperRoot = document.querySelectorAll(
                  '[class*=MuiPickersPopper-root'
                )
                if (popperRoot.length > 0) {
                  const transformValue = (popperRoot[0] as HTMLElement).style
                    .transform
                  setTimeout(() => {
                    ;(popperRoot[0] as HTMLElement).style.transform =
                      transformValue
                  }, 10)
                }
              }}
              onClose={() => {
                const popperRoot = document.querySelectorAll(
                  '[class*=MuiPickersPopper-root'
                )
                if (popperRoot.length > 0) {
                  ;(popperRoot[0] as HTMLElement).style.display = 'none'
                }
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  className: classes.datePicker
                }
              }}
              slots={{
                textField: (textFieldProps) => (
                  <TextField
                    {...textFieldProps}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={
                      getError(itemList[0].language, editFormErrorKeys.endDate)
                        .error
                    }
                    helperText={
                      getError(itemList[0].language, editFormErrorKeys.endDate)
                        .helperText
                    }
                  />
                )
              }}
            />
          </LocalizationProvider>

          {scope !== 'KPMGFind' && (
            <TextField
              variant="standard"
              id="site_url"
              label="Site Url"
              defaultValue={itemList[0].site_url}
              onChange={(event) => {
                itemList[0].site_url = event.target.value
                  ? DOMPurify.sanitize(event.target.value.replace(/ /g, '%20'))
                  : event.target.value
                validateItemAndCheckLanguages()
              }}
              onBlur={(event) => {
                validateItem()
              }}
              InputLabelProps={{
                shrink: true
              }}
              error={
                getError(itemList[0].language, editFormErrorKeys.site_url).error
              }
              helperText={
                getError(itemList[0].language, editFormErrorKeys.site_url)
                  .helperText
              }
            />
          )}

          <AdminImageUpload
            editProps={itemList[0]}
            onchange={validateItemAndCheckLanguages}
            error={getError(itemList[0].language, editFormErrorKeys.image)}
          />
          <TextField
            variant="standard"
            id="rank"
            label="Rank"
            value={rank}
            onChange={(event) => {
              itemList[0].rank = parseInt(event.target.value)
              setRank(itemList[0].rank)
              validateItemAndCheckLanguages()
            }}
            type="number"
            error={getError(itemList[0].language, editFormErrorKeys.rank).error}
            helperText={
              getError(itemList[0].language, editFormErrorKeys.rank).helperText
            }
            InputLabelProps={{
              shrink: true
            }}
          />

          {renderSelect(
            scope,
            setScope,
            ['OI', 'KPMGFind', 'All'],
            false,
            'Scope',
            'scope',
            itemList[0],
            isNewItem,
            validateItemAndCheckLanguages
          )}
          {renderSelect(
            sources,
            setSources,
            availableSources,
            true,
            'Sources *',
            'sources',
            itemList[0],
            isNewItem,
            () => {
              validateItemAndCheckLanguages()
              checkDefaultRankValue(itemList[0])
            },
            false,
            getError(itemList[0].language, editFormErrorKeys.source)
          )}
          {renderSelect(
            countries,
            setCountries,
            countryList,
            true,
            'Countries',
            'countries',
            itemList[0],
            isNewItem,
            validateItemAndCheckLanguages
          )}
          {renderSelect(
            functions,
            setFunctions,
            functionList,
            true,
            'Functions',
            'functions',
            itemList[0],
            isNewItem,
            validateItemAndCheckLanguages,
            true
          )}

          <TextField
            variant="standard"
            multiline
            id="search_terms"
            label="Search terms (provide a comma separated list)"
            defaultValue={itemList[0].search_terms}
            rows={2}
            onChange={(event) => {
              event.target.value = filterSearchTermInput(event.target.value)
              itemList[0].search_terms = event.target.value
                ? convertToArray(DOMPurify.sanitize(event.target.value))
                : []
            }}
            onBlur={(event) => {
              checkDefaultRankValue(itemList[0])
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
          {renderSelect(
            flavour,
            setFlavour,
            ['large', 'small'],
            false,
            'Flavour',
            'flavour',
            itemList[0],
            isNewItem,
            validateItemAndCheckLanguages
          )}
          {renderSelect(
            matchType,
            setMatchType,
            ['Contains', 'Exact'],
            false,
            'Match Type',
            'match_type',
            itemList[0],
            isNewItem,
            validateItemAndCheckLanguages,
            true
          )}
        </>
      )}
      <ConfirmationModal
        handleConfirm={() => {
          setShowRankChanges(false)
        }}
        isModalOpen={showRankChanges}
        title={'Rank value has been changed'}
        message={'The rank value was changed automatically'}
        hideCancelButton={true}
        confirmBtnText={'ok'}
        cancelBtnText={''}
      />
    </>
  )
}
