import React, { useState } from 'react'
import Button from '@mui/material/Button'
import { MTableToolbar } from '@material-table/core'
import { Tooltip } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { getStylesAdToolbar } from 'styles/admin/AdToolbar'
import AdBulkUpload from '../adwords/AdBulkUpload'
import { AddBox } from '@mui/icons-material'
import TranslationBulkUpload from '../translations/TranslationBulkUpload'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import FeaturedBulkUpload from '../featuredResults/FeaturedBulkUpload'

export interface IAdToolBarProps {
  addButtonText: string
  handleRowAdd: any
  handleRowUpdate: any
  disableBulkUpload: boolean
  originalData: any
  adminSettingType: adminSettingTypes
}

export default function AdToolBar(props: IAdToolBarProps): JSX.Element {
  const [open, setOpen] = useState(false)

  const classes = getStylesAdToolbar()
  return (
    <>
      <div className={classes.container}>
        <MTableToolbar {...props} />
        <div className="MuiToolbar-regular">
          <span className={classes.buttonWrapper}>
            <Tooltip
              title={props.addButtonText ? props.addButtonText : 'Add AdWord'}
              aria-label={
                props.addButtonText ? props.addButtonText : 'Add AdWord'
              }
            >
              <Button
                disableRipple={true}
                className={classes.button}
                onClick={() => {
                  props.handleRowAdd()
                }}
              >
                <AddBox className={classes.icon} />
              </Button>
            </Tooltip>
          </span>
        </div>
        {!props.disableBulkUpload && (
          <div className="MuiToolbar-regular" style={{ paddingRight: '8px' }}>
            <span className={classes.buttonWrapper}>
              <Tooltip title="Bulk upload" aria-label="Bulk upload">
                <Button
                  disableRipple={true}
                  className={classes.button}
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  <CloudUploadIcon className={classes.icon} />
                </Button>
              </Tooltip>
            </span>
          </div>
        )}
      </div>
      {!props.disableBulkUpload &&
        props.adminSettingType === adminSettingTypes.ads && (
          <AdBulkUpload
            open={open}
            setOpen={setOpen}
            handleRowUpdate={props.handleRowUpdate}
            tableData={props.originalData}
          />
        )}
      {!props.disableBulkUpload &&
        props.adminSettingType === adminSettingTypes.translations && (
          <TranslationBulkUpload
            open={open}
            setOpen={setOpen}
            handleRowUpdate={props.handleRowUpdate}
            tableData={props.originalData}
          />
        )}
      {!props.disableBulkUpload &&
        props.adminSettingType === adminSettingTypes.oitranslations && (
          <TranslationBulkUpload
            open={open}
            setOpen={setOpen}
            handleRowUpdate={props.handleRowUpdate}
            tableData={props.originalData}
          />
        )}
      {!props.disableBulkUpload &&
        props.adminSettingType === adminSettingTypes.settingstranslations && (
          <TranslationBulkUpload
            open={open}
            setOpen={setOpen}
            handleRowUpdate={props.handleRowUpdate}
            tableData={props.originalData}
          />
        )}
      {!props.disableBulkUpload &&
        props.adminSettingType === adminSettingTypes.featuredresults && (
          <FeaturedBulkUpload
            open={open}
            setOpen={setOpen}
            handleRowUpdate={props.handleRowUpdate}
            tableData={props.originalData}
          />
        )}
    </>
  )
}
