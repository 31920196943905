import { Column } from '@material-table/core'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import {
  findDefaultFilterComponent,
  findItemChild
} from 'utils/admin/adminFormUtils'
import AdminTableTooltipClip from '../common/AdminTableTooltipClip'
import AdminTableTooltipTitle from '../common/AdminTableTooltipTitle'
import React from 'react'
import createDOMPurify from 'dompurify'
import { ISearchTip } from 'models/SearchTip'

const DOMPurify = createDOMPurify(window)

/**
 * Generate search tips columns list
 * @param changes AdminItemChange list
 * @returns columns list
 */
export const searchTipsColumns = (data: ISearchTip[]): Column<ISearchTip>[] => {
  const classes = getStylesAdminSettings()
  return [
    {
      title: 'Id',
      field: 'id',
      type: 'string',
      filtering: false,
      cellStyle: {
        maxWidth: 40,
        width: 40
      },
      headerStyle: {
        maxWidth: 40,
        width: 40
      },
      width: 40,
      customSort: (a: ISearchTip, b: ISearchTip) =>
        parseInt(a.id) - parseInt(b.id)
    },
    {
      title: 'Language',
      field: 'language',
      filtering: false,
      type: 'string',
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      width: 80,
      render: (rowData: ISearchTip) => {
        let allLanguages = true

        const searchTipChilds = findItemChild(rowData, data)
        supportedLanguages.forEach((supportedLanguage: SupportedLanguage) => {
          if (rowData.language !== supportedLanguage.locale) {
            if (
              !searchTipChilds.find(
                (stChild: ISearchTip) =>
                  stChild.language === supportedLanguage.locale
              )
            ) {
              allLanguages = false
            }
          }
        })

        return (
          <div className={classes.chipContainer}>
            {allLanguages ? (
              <>
                <AdminTableTooltipClip
                  size="small"
                  label={'All'}
                  maxWidth={90}
                />
              </>
            ) : (
              <>
                <AdminTableTooltipClip
                  size="small"
                  label={rowData.language}
                  maxWidth={90}
                />
                {searchTipChilds.length > 0
                  ? searchTipChilds.map(
                      (stChild: ISearchTip, index: number) => (
                        <AdminTableTooltipClip
                          size="small"
                          label={stChild.language}
                          maxWidth={90}
                          key={index}
                        />
                      )
                    )
                  : ''}
              </>
            )}
          </div>
        )
      }
    },
    {
      title: 'Title',
      field: 'title',
      type: 'string',
      filtering: false,
      render: (rowData: ISearchTip) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          unsafeHTML={`${rowData.draft ? '<i><b>(Draft)</b></i> ' : ''}${
            rowData.title ? DOMPurify.sanitize(rowData.title) : ''
          }`}
          maxLine={4}
        />
      ),
      width: 250,
      cellStyle: {
        maxWidth: 250
      },
      headerStyle: {
        maxWidth: 250
      }
    },
    {
      title: 'Description',
      field: 'description',
      type: 'string',
      render: (rowData: ISearchTip) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          unsafeHTML={
            rowData.description ? DOMPurify.sanitize(rowData.description) : ''
          }
          maxLine={2}
        />
      ),
      filtering: false,
      width: 500,
      cellStyle: {
        maxWidth: 500
      },
      headerStyle: {
        maxWidth: 500
      }
    },
    {
      title: 'Image',
      field: 'image',
      type: 'string',
      cellStyle: {
        maxWidth: 300
      },
      headerStyle: {
        maxWidth: 300
      },
      width: 300,
      render: (rowData: ISearchTip) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.image ? rowData.image : ''}
          maxLine={1}
        />
      ),
      filtering: false
    },
    {
      title: 'Link',
      field: 'link',
      type: 'string',
      cellStyle: {
        maxWidth: 300
      },
      headerStyle: {
        maxWidth: 300
      },
      width: 300,
      render: (rowData: ISearchTip) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.link ? rowData.link : ''}
          maxLine={1}
        />
      ),
      filtering: false
    },
    {
      title: 'Order',
      field: 'order',
      type: 'numeric',
      align: 'left',
      cellStyle: {
        minWidth: 100
      },
      headerStyle: {
        minWidth: 100
      },
      width: 100,
      render: (rowData: ISearchTip) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.order ? rowData.order.toString() : ''}
          maxLine={1}
        />
      ),
      filtering: false
    },
    {
      title: 'Active',
      field: 'isActive',
      type: 'string',
      render: (rowData: ISearchTip) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.isActive ? 'Yes' : 'No'}
          maxLine={1}
        />
      ),
      filterComponent: findDefaultFilterComponent,
      customFilterAndSearch: (value: string, rowData: ISearchTip) => {
        if ('yes'.startsWith(value.toLocaleLowerCase()) && rowData.isActive)
          return true

        if ('no'.startsWith(value.toLocaleLowerCase()) && !rowData.isActive)
          return true

        return false
      },
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      }
    },
    {
      title: 'Datasource',
      field: 'dataSource',
      type: 'string',
      render: (rowData: ISearchTip) => (
        <AdminTableTooltipClip
          color="primary"
          label={rowData.dataSource ? rowData.dataSource : 'None'}
          variant={'outlined'}
          maxWidth={120}
        />
      ),
      width: 150,
      cellStyle: {
        maxWidth: 150
      },
      headerStyle: {
        maxWidth: 150
      },
      customFilterAndSearch: (value: string, rowData: ISearchTip) => {
        if (!value) return true
        if (!rowData.dataSource && 'none'.startsWith(value.toLowerCase())) {
          return true
        } else {
          return rowData.dataSource
            ? rowData.dataSource.toLowerCase().startsWith(value.toLowerCase())
            : false
        }
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Scope',
      field: 'scope',
      type: 'string',
      render: (rowData: ISearchTip) => (
        <AdminTableTooltipClip
          color="primary"
          label={rowData.scope ? rowData.scope : 'All'}
          variant={'outlined'}
          maxWidth={120}
        />
      ),
      width: 150,
      cellStyle: {
        maxWidth: 150
      },
      headerStyle: {
        maxWidth: 150
      },
      customFilterAndSearch: (value: string, rowData: ISearchTip) => {
        if (!value) return true

        if (!rowData.scope && 'all'.startsWith(value.toLowerCase())) {
          return true
        } else {
          return rowData.scope
            ? rowData.scope.toLowerCase().startsWith(value.toLowerCase())
            : false
        }
      },
      filterComponent: findDefaultFilterComponent
    }
  ]
}
