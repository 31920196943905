export interface DidYouMeanItem {
  id: string
  term: string
  description: string
  draft?: boolean
  requestId?: string
  requestUser?: string
}

export const initialDidYouMean: DidYouMeanItem = {
  id: '',
  term: '',
  description: ''
}
