import React from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getStylesVersionUpdate } from 'styles/contents/VersionUpdate'
import { PopupResult } from 'models/PopupResult'
import createDOMPurify from 'dompurify'
import * as Config from '../../config'

export interface IPopupProps {
  popupResult?: PopupResult
  open: boolean
  onClose: () => void
}

const Popup = (props: IPopupProps): any => {
  const { popupResult, open, onClose } = props
  const DOMPurify = createDOMPurify(window)
  const classes = getStylesVersionUpdate()

  return !open || !popupResult ? null : (
    <div className={classes.container}>
      <Box className={classes.slideContainer}>
        <div
          className={classes.img}
          style={
            popupResult && popupResult.image
              ? {
                  backgroundImage: `url(${DOMPurify.sanitize(
                    popupResult.image.startsWith('/externalContent/adsmedia/')
                      ? `${Config.APIM_BASE_URL.replace('/apim/', '')}${
                          popupResult.image
                        }`
                      : popupResult.image
                  )}?version=${new Date().getTime().toString()})`,
                  flex: '0 0 284px'
                }
              : {}
          }
        >
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Box
          className={classes.content}
          style={
            popupResult && popupResult.image
              ? {
                  maxHeight: '350px'
                }
              : { maxHeight: '634px', paddingTop: 0 }
          }
        >
          <Typography variant="h5" className={classes.title}>
            {popupResult
              ? DOMPurify.sanitize(popupResult.title, {
                  USE_PROFILES: { html: false }
                })
              : ''}
          </Typography>
          <Box
            className={classes.contentContainer}
            dangerouslySetInnerHTML={{
              __html: popupResult
                ? DOMPurify.sanitize(popupResult.content, {
                    USE_PROFILES: { html: true },
                    ADD_ATTR: ['target']
                  })
                : ''
            }}
          />

          <Box className={classes.getStartedButtonContainer}>
            <Button
              id={'btn-popup-01'}
              variant="contained"
              size="large"
              color="primary"
              className={classes.getStartedButton}
              onClick={() => {
                if (popupResult && popupResult.button_link)
                  window.open(
                    DOMPurify.sanitize(popupResult.button_link, {
                      USE_PROFILES: { html: false }
                    }),
                    '_blank'
                  )

                onClose()
              }}
            >
              {popupResult
                ? DOMPurify.sanitize(popupResult.button_text, {
                    USE_PROFILES: { html: false }
                  })
                : ''}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default Popup
