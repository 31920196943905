export const getCurrentScope = (): string => {
  const urlSpl =
    window && window.location && window.location.hash
      ? window.location.hash.split('/')
      : []

  const scope = urlSpl.length > 1 ? urlSpl[urlSpl.length - 2] : ''

  return scope
}
