import MaterialTable, { MTableBodyRow } from '@material-table/core'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  SnackbarCloseReason,
  Tooltip
} from '@mui/material'
import {
  AdminActionType,
  IExportItem,
  ISelfServiceAdminDeleteResponse,
  ISelfServiceRequest,
  ISelfServiceUpdateResponse,
  RequestStatus,
  RequestType
} from 'models/SelfServiceRequest'
import React, { SyntheticEvent, useRef } from 'react'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SelfServiceStore from 'store/SelfService'
import { getStylesRequests } from 'styles/requests/requests'
import { tableIcons } from 'utils/admin/adminSettingsUtils'
import { Delete, Edit, Publish, Share } from '@mui/icons-material'
import EditFormRequestBase from './EditFormAllRequestBase'
import { allRequestTableColumns } from './allRequestsColumns'
import CloseIcon from '@mui/icons-material/Close'
import { TableStateAllRequests } from 'models/TableStates'
import UserSettingsStore from 'store/UserSettings'
import SettingsNotification from 'components/contents/common/SettingsNotification'
import {
  createUPNHash,
  findDuplicates,
  exportColumns,
  parseRequestsToExport,
  validateRequestForChangesOrExpired,
  updateBaseProperties,
  generateShareMail
} from 'utils/admin/selfServiceUtils'
import DuplicateDialog from 'components/admin/common/DuplicateDialog'
import { ExportCsv } from '@material-table/exporters'
import AdminAlertConfiguration from 'components/admin/common/AdminAlertConfiguration'
import AllRequestTableToolbar from './AllRequestTableToolbar'
import {
  AdsResultHistory,
  FeaturedResultHistory
} from 'models/SelfServiceHistory'
import { AdsResult } from 'models/AdsResult'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount'
import AdminReAssignConfiguration from 'components/admin/common/AdminReAssignConfiguration'
import AdminCreateRequest from 'components/admin/common/AdminCreateRequest'

export interface AllRequestsLocalProps {}

type AllRequestsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  AllRequestsLocalProps

function AllRequests(props: AllRequestsProps): JSX.Element {
  const {
    fetchAllRequestsResults,
    hasAllRequestsBeenFetched,
    allRequests,
    deleteAdminRequest,
    userSettings,
    hasUserSettingsBeenFetched,
    upsertAdminRequest,
    hasAllRequestsBeenUpdated
  } = props

  const classes = getStylesRequests()
  const [isLoading, setIsLoading] = useState(true)

  const [state, setState] = useState<TableStateAllRequests>({
    data: []
  })

  const [currentRow, setCurrentRow] = useState<ISelfServiceRequest | null>(null)
  const [formOpen, setFormOpen] = useState(false)
  const [createNewItem, setCreateNewItem] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [showExpiredNotification, setShowExpiredNotification] = useState(false)
  const [showDuplicates, setShowDuplicates] =
    useState<ISelfServiceRequest | null>(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [showAlerts, setShowAlerts] = useState(false)
  const [showCreateRequestDialog, setShowCreateRequestDialog] = useState(false)
  const [reAssignDialogOpen, setReAssignDialogOpen] = useState(false)

  const itemsToDelete = useRef<ISelfServiceRequest[] | null>(null)
  const itemsToReAssign = useRef<ISelfServiceRequest[] | null>(null)

  const expiredNotificationOnceShown = useRef(false)

  const loadData = () => {
    fetchAllRequestsResults()
  }

  useEffect(() => {
    if (hasAllRequestsBeenFetched) {
      setIsLoading(false)
      const tableData = generateAllData()
      setState({
        data: tableData
      })

      if (window.location.hash && window.location.hash.indexOf('?') !== -1) {
        const urlParams = new URLSearchParams(
          window.location.hash.split('?')[1]
        )

        const id = urlParams.get('id')
        const upn = urlParams.get('upn')

        if (id && upn) {
          const item = tableData.find(
            (item: ISelfServiceRequest) =>
              item.id === id && upn === createUPNHash(item.upn)
          )

          if (item) {
            handleFormOpen(item)
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAllRequestsBeenFetched])

  useEffect(() => {
    if (hasAllRequestsBeenUpdated) {
      const tableData = generateAllData()
      setState({
        data: tableData
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAllRequestsBeenUpdated])

  useEffect(() => {
    if (hasAllRequestsBeenFetched) {
      setState({
        data: generateAllData()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserSettingsBeenFetched])

  const generateAllData = () => {
    // don't generate notifications in case they are not enabled in the usersettings
    if (!hasUserSettingsBeenFetched || !userSettings.EnableNotifications) {
      const result = allRequests.map((request: ISelfServiceRequest) => {
        delete request.hasChanged
        delete request.expiresSoon
        delete request.hasCommentChanges
        return request
      })

      findDuplicates(result)
      return result
    }

    let expiredRequests = false
    const result = allRequests.map((request: ISelfServiceRequest) => {
      validateRequestForChangesOrExpired(
        request,
        hasUserSettingsBeenFetched,
        userSettings
      )

      if (request.expiresSoon) expiredRequests = true

      return request
    })

    if (expiredRequests) {
      setShowExpiredNotification(true)
    }

    findDuplicates(result)
    return result
  }

  useEffect(() => {
    setIsLoading(true)
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const quickPublishItem = (referenceRowData: ISelfServiceRequest) => {
    const orgRequestItem = Object.assign({}, referenceRowData)

    if (referenceRowData.requestType === RequestType.AdWord) {
      const adItemList = referenceRowData.itemData as AdsResult[]
      const newItemList: AdsResult[] = []
      adItemList.forEach((item: AdsResult) => {
        newItemList.push({
          ...adItemList[0],
          ...{
            language: item.language,
            title: item.title ? item.title : adItemList[0].title,
            text: item.text ? item.text : adItemList[0].text,
            link_text: item.link_text
              ? item.link_text
              : adItemList[0].link_text,
            countries:
              item.countries.length === 0 ? ['All'] : adItemList[0].countries,
            functions:
              item.functions.length === 0 ? ['All'] : adItemList[0].functions
          }
        })
      })

      referenceRowData.itemData = newItemList
    }

    referenceRowData.modifiedDate = new Date().toISOString()
    referenceRowData.modifiedBy = ESSettingsGlobalVariables.getDisplayName()

    updateBaseProperties(referenceRowData)

    upsertAdminRequest(referenceRowData).then(
      (upsertResponse: ISelfServiceUpdateResponse) => {
        if (!upsertResponse.hasError) {
          if (upsertResponse.etag) {
            referenceRowData._etag = upsertResponse.etag
          }

          handleRowUpdate(referenceRowData)

          setSnackbarMessage('Successfully quick published the request.')

          toggleSnackbarVisibility(true)
        } else {
          referenceRowData = orgRequestItem
          if (upsertResponse.status === 412) {
            setSnackbarMessage(
              'Save Conflict: Your changes conflict with those made concurrently by another user. If you want your changes to be applied, refresh the page and resubmit your changes.'
            )
            toggleSnackbarVisibility(true)
          } else {
            setSnackbarMessage('There was an error quick publish the request.')
            toggleSnackbarVisibility(true)
          }
        }
      }
    )
  }

  const handleRowUpdate = (newData: ISelfServiceRequest) => {
    setState((prevState: any) => {
      const data: ISelfServiceRequest[] = [...prevState.data]
      newData.hasChanged = false
      let foundIndex = -1
      data.forEach((requestItem: ISelfServiceRequest, index: number) => {
        if (requestItem.id === newData.id && requestItem.upn === newData.upn) {
          foundIndex = index
          return false
        }
        return true
      })

      if (foundIndex === -1) {
        data.unshift(newData)
      } else {
        data[foundIndex] = newData
      }

      findDuplicates(data)

      return { ...prevState, data }
    })

    setCurrentRow(null)
    setFormOpen(false)
    setCreateNewItem(false)

    if (window.location.hash && window.location.hash.indexOf('?') !== -1) {
      window.history.pushState(
        {},
        document.title,
        window.location.hash.split('?')[0]
      )
    }
  }

  const handleRowRemoveChangeNotification = (newData: ISelfServiceRequest) => {
    if (!newData.hasChanged) return

    setState((prevState: any) => {
      const data: ISelfServiceRequest[] = [...prevState.data]
      newData.hasChanged = false
      let foundIndex = -1
      data.forEach((requestItem: ISelfServiceRequest, index: number) => {
        if (requestItem.id === newData.id && requestItem.upn === newData.upn) {
          foundIndex = index
          return false
        }
        return true
      })

      if (foundIndex === -1) {
        data.unshift(newData)
      } else {
        data[foundIndex] = newData
      }

      return { ...prevState, data }
    })
  }

  const handleRowRemoveCommentChangeNotification = (
    newData: ISelfServiceRequest
  ) => {
    if (!newData.hasCommentChanges) return

    setState((prevState: any) => {
      const data: ISelfServiceRequest[] = [...prevState.data]
      newData.hasCommentChanges = false
      let foundIndex = -1
      data.forEach((requestItem: ISelfServiceRequest, index: number) => {
        if (requestItem.id === newData.id && requestItem.upn === newData.upn) {
          foundIndex = index
          return false
        }
        return true
      })

      if (foundIndex === -1) {
        data.unshift(newData)
      } else {
        data[foundIndex] = newData
      }

      return { ...prevState, data }
    })
  }

  const handleRowDelete = () => {
    return new Promise<void>(async (resolve) => {
      if (itemsToDelete.current) {
        const promiseArray: Array<Promise<ISelfServiceAdminDeleteResponse>> = []

        itemsToDelete.current.forEach(
          (requestToDelete: ISelfServiceRequest) => {
            promiseArray.push(deleteAdminRequest(requestToDelete))
          }
        )

        const promiseResponse = await Promise.all(promiseArray)

        const someItemsDeleted = promiseResponse.some(
          (response: ISelfServiceAdminDeleteResponse) => {
            return !response.hasError
          }
        )
        const someItemsHasError = promiseResponse.some(
          (response: ISelfServiceAdminDeleteResponse) => {
            return response.hasError
          }
        )

        if (someItemsDeleted) {
          setState((prevState: any) => {
            let data: ISelfServiceRequest[] = [...prevState.data]

            data = data.filter((request: ISelfServiceRequest) => {
              if (
                promiseResponse.some(
                  (response: ISelfServiceAdminDeleteResponse) => {
                    return (
                      response.requestId === request.id &&
                      response.requestUpn === request.upn &&
                      !response.hasError
                    )
                  }
                )
              ) {
                return false
              }

              return true
            })

            findDuplicates(data)

            return { ...prevState, data }
          })

          if (someItemsHasError) {
            setSnackbarMessage(
              'There was an error deleting some of the request(s).'
            )
            toggleSnackbarVisibility(true)
          } else {
            setSnackbarMessage('Successfully deleted the request(s).')
            toggleSnackbarVisibility(true)
          }
        } else {
          setSnackbarMessage('There was an error deleting the request(s).')
          toggleSnackbarVisibility(true)
        }
      }

      setConfirmDialogOpen(false)
      itemsToDelete.current = null
      resolve()
    })
  }

  const handleFormOpen = (row: ISelfServiceRequest) => {
    setCurrentRow(row)
    setFormOpen(true)
  }

  const handleFormClose = () => {
    setCurrentRow(null)
    setCreateNewItem(false)
    setFormOpen(false)

    if (window.location.hash && window.location.hash.indexOf('?') !== -1) {
      window.history.pushState(
        {},
        document.title,
        window.location.hash.split('?')[0]
      )
    }
  }

  const handleSnackbarClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    toggleSnackbarVisibility(false)
  }

  const handleFormOpenFromDuplicateDialog = (rowData: ISelfServiceRequest) => {
    setShowDuplicates(null)
    handleFormOpen(rowData)
  }

  const tableColumns = useRef(
    allRequestTableColumns(
      handleRowRemoveChangeNotification,
      handleRowRemoveCommentChangeNotification,
      handleRowUpdate,
      toggleSnackbarVisibility,
      setSnackbarMessage,
      setShowDuplicates
    )
  )

  const onlyExpiredChange = (rowData: ISelfServiceRequest): boolean => {
    if (rowData.status !== RequestStatus.Submitted) {
      return false
    }

    if (
      !rowData.historyData ||
      !rowData.historyData.data ||
      rowData.historyData.isNewItem ||
      rowData.historyData.imageChanged
    ) {
      return false
    }

    if (rowData.requestType === RequestType.AdWord) {
      const adWordHistoryData = rowData.historyData.data as AdsResultHistory[]
      if (adWordHistoryData && adWordHistoryData.length === 1) {
        const adWordHistoryItem = adWordHistoryData[0] as AdsResultHistory

        if (
          adWordHistoryItem.end &&
          adWordHistoryItem.language === 'en' &&
          Object.keys(adWordHistoryItem).length === 2
        ) {
          return true
        }
      }
    } else if (rowData.requestType === RequestType.FeaturedResult) {
      const featuredHistoryItem = rowData.historyData
        .data as FeaturedResultHistory

      if (
        featuredHistoryItem.BestBetEndDate &&
        Object.keys(featuredHistoryItem).length === 1
      ) {
        return true
      }
    }

    return false
  }

  const handleSharing = (rowData: ISelfServiceRequest): void => {
    const mailInfo = generateShareMail(rowData)
    window.location.href = `mailto:?body=${mailInfo.body}&subject=${mailInfo.subject}`
  }

  const MaterialTableMemo = React.useMemo((): JSX.Element => {
    const changeCount = state.data.filter(
      (request: ISelfServiceRequest) => request.hasChanged
    ).length
    return (
      <MaterialTable
        title={
          <div style={{ position: 'relative', paddingRight: '50px' }}>
            <h2>All Requests</h2>
            {changeCount > 0 && userSettings.EnableNotifications && (
              <Tooltip title={`${changeCount} change(s) since last visit`}>
                <div
                  style={{
                    position: 'absolute',
                    top: -1,
                    right: 20,
                    backgroundColor: '#bc204b',
                    color: '#fff',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    lineHeight: '20px',
                    fontSize: '13px'
                  }}
                >
                  {changeCount}
                </div>
              </Tooltip>
            )}
          </div>
        }
        icons={tableIcons}
        columns={tableColumns.current}
        data={state.data}
        options={{
          rowStyle: (data: ISelfServiceRequest) => ({
            fontSize: 12
          }),
          addRowPosition: 'first',
          pageSize: 25,
          pageSizeOptions: [25, 50, 75],
          tableLayout: 'auto',
          filtering: true,
          exportAllData: true,
          emptyRowsWhenPaging: false,
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (columns, data) => {
                ExportCsv<IExportItem>(
                  exportColumns,
                  parseRequestsToExport(
                    state.data.filter((r: ISelfServiceRequest) =>
                      data.some(
                        (item: ISelfServiceRequest) =>
                          r.id === item.id && r.upn === item.upn
                      )
                    )
                  ),
                  'AllRequests',
                  ';'
                )
              }
            }
          ],
          selection: true,
          showTextRowsSelected: false,
          actionsCellStyle: {
            width: '50px'
          }
        }}
        actions={[
          {
            icon: Edit,
            onClick: (
              event: any,
              rowData: ISelfServiceRequest | ISelfServiceRequest[]
            ) => {
              if (!(rowData instanceof Array)) {
                handleFormOpen(rowData)
              }
            },
            tooltip: 'Edit',
            position: 'row'
          },
          {
            action: (rowData) => ({
              icon: Publish,
              onClick: (
                event: any,
                _rowData: ISelfServiceRequest | ISelfServiceRequest[]
              ) => {
                if (!(rowData instanceof Array)) {
                  quickPublishItem(rowData)
                }
              },
              tooltip: 'Quick publish request',
              hidden: !onlyExpiredChange(rowData)
            }),
            position: 'row'
          },
          {
            icon: Share,
            onClick: (
              event: any,
              rowData: ISelfServiceRequest | ISelfServiceRequest[]
            ) => {
              if (!(rowData instanceof Array)) {
                handleSharing(rowData)
              }
            },
            tooltip: 'Share',
            position: 'row'
          },
          {
            icon: Delete,
            onClick: (
              event: any,
              rowData: ISelfServiceRequest | ISelfServiceRequest[]
            ) => {
              itemsToDelete.current = Array.isArray(rowData)
                ? rowData
                : [rowData]
              setConfirmDialogOpen(true)
            },
            tooltip: 'Delete selected request(s)'
          },
          {
            icon: SwitchAccountIcon,
            onClick: (
              event: any,
              rowData: ISelfServiceRequest | ISelfServiceRequest[]
            ) => {
              itemsToReAssign.current = Array.isArray(rowData)
                ? rowData
                : [rowData]
              setReAssignDialogOpen(true)
            },
            tooltip: 'Reassign selected request(s)'
          }
        ]}
        components={{
          Container: (props: any) => (
            <div className={classes.materialUiTable} {...props} />
          ),
          Toolbar: (props: any) => (
            <div className={classes.toolbarContainer}>
              <AllRequestTableToolbar
                toolbarprops={props}
                setShowAlerts={setShowAlerts}
                setShowCreateRequestDialog={setShowCreateRequestDialog}
              />
            </div>
          ),
          Row: (props: any) => <MTableBodyRow key={props.id} {...props} />
        }}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns.current, state.data])

  return (
    <>
      {state && state.data && (
        <div className={`${classes.container} ${classes.scrollfix}`}>
          {!isLoading ? (
            MaterialTableMemo
          ) : (
            <div className={classes.loadingSpinnerContainer}>
              <CircularProgress className={classes.loadingSpinner} size={50} />
            </div>
          )}
        </div>
      )}
      {formOpen && currentRow && (
        <EditFormRequestBase
          rowData={currentRow}
          isNewItem={createNewItem}
          isOpen={formOpen}
          handleClose={handleFormClose}
          handleRowUpdate={handleRowUpdate}
          toggleSnackbarVisibility={toggleSnackbarVisibility}
          setSnackbarMessage={setSnackbarMessage}
        />
      )}
      {showAlerts && (
        <AdminAlertConfiguration
          setShowAlertSettings={setShowAlerts}
          showAlertSettings={showAlerts}
          toggleSnackbarVisibility={toggleSnackbarVisibility}
          setSnackbarMessage={setSnackbarMessage}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isSnackbarVisible}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              handleSnackbarClose()
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {showExpiredNotification && !expiredNotificationOnceShown.current && (
        <SettingsNotification
          open={showExpiredNotification}
          onClose={() => {
            setShowExpiredNotification(false)
            expiredNotificationOnceShown.current = true
          }}
          translationKey={'snackbar_expiredItems_errormessage'}
          defaultMessage={'There are request items that expire soon.'}
        />
      )}
      {showDuplicates && (
        <DuplicateDialog
          rowData={showDuplicates}
          data={state.data}
          setShowDuplicates={setShowDuplicates}
          handleFormOpen={handleFormOpenFromDuplicateDialog}
        />
      )}
      {confirmDialogOpen && (
        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
        >
          <DialogTitle id="admin_delete_confirm_dialog">
            {'Are you sure you want to delete the selected request(s)?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {itemsToDelete.current &&
              itemsToDelete.current.some(
                (request: ISelfServiceRequest) => request.oncePublished
              )
                ? `Are you sure you want to delete the ${itemsToDelete.current?.length} selected request(s)? All deleted requests will be lost and the published AdWords and Featured Results will be deleted.`
                : `Are you sure you want to delete the ${itemsToDelete.current?.length} selected request(s)? All deleted requests will be lost.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDialogOpen(false)}
              color="primary"
              autoFocus
            >
              No
            </Button>
            <Button onClick={() => handleRowDelete()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {reAssignDialogOpen && (
        <AdminReAssignConfiguration
          setShowReAssignConfiguration={setReAssignDialogOpen}
          showReAssignConfiguration={reAssignDialogOpen}
          itemsToReAssign={
            itemsToReAssign.current
              ? itemsToReAssign.current.filter(
                  (item) =>
                    item.requestType === RequestType.AdWord ||
                    item.requestType === RequestType.FeaturedResult
                )
              : []
          }
          closeAndReset={() => {
            itemsToReAssign.current = null
            setReAssignDialogOpen(false)
          }}
        />
      )}
      {showCreateRequestDialog && (
        <AdminCreateRequest
          showCreateRequestDialog={showCreateRequestDialog}
          setShowCreateRequestDialog={setShowCreateRequestDialog}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    allRequests: SelfServiceStore.selectors.getAllRequests(state),
    hasAllRequestsBeenFetched:
      SelfServiceStore.selectors.hasAllRequestsBeenFetched(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    hasUserSettingsBeenFetched:
      UserSettingsStore.selectors.hasUserSettingsBeenFetched(state),
    hasAllRequestsBeenUpdated:
      SelfServiceStore.selectors.hasAllRequestsBeenUpdated(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAllRequestsResults: () =>
      dispatch(SelfServiceStore.actions.fetchAllRequestsResults()),
    deleteAdminRequest: (deleteItem: ISelfServiceRequest) =>
      dispatch(SelfServiceStore.actions.deleteAdminRequest(deleteItem)),
    upsertAdminRequest: (request: ISelfServiceRequest) =>
      dispatch(
        SelfServiceStore.actions.upsertAdminRequest(
          request,
          AdminActionType.Publish,
          undefined,
          false
        )
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllRequests)
