import * as Config from '../../config'
const logEnabled = Config.LOCAL_DEVELOPMENT.toString() === 'true'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const logger = (store: any) => (next: any) => (action: any) => {
  if (logEnabled) {
    console.group(action.type)
    console.info('dispatching', action)
  }

  const result = next(action)

  if (logEnabled) {
    console.log('next state', store.getState())
    console.groupEnd()
  }
  return result
}
