import { IconButton, Snackbar, SnackbarCloseReason } from '@mui/material'
import { intialAdsResult } from 'models/AdsResult'
import { intialFeaturedResult } from 'models/FeaturedResult'
import EditFormRequestBase from './requetsmy/EditFormRequestBase'
import {
  defaultSelfServiceRequest,
  ISelfServiceRequest,
  RequestType
} from 'models/SelfServiceRequest'
import React, { Dispatch, SyntheticEvent, useState } from 'react'
import { connect } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'

interface RequestIndexProps {
  requestType: RequestType
}

type AllRequestIndexProps = ReturnType<typeof mapDispatchToProps> &
  RequestIndexProps

function RequestIndex(props: AllRequestIndexProps): JSX.Element {
  const { requestType } = props
  const [formOpen, setFormOpen] = useState(true)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const currentRow: ISelfServiceRequest = Object.assign(
    {},
    defaultSelfServiceRequest
  )
  const createNewItem = true

  const handleFormClose = () => {
    setFormOpen(false)

    // Redirect to the request form
    window.location.href = '/#/myrequests/'
  }

  const handleRowUpdate = () => {
    setTimeout(() => {
      setFormOpen(false)

      // Redirect to the request form
      window.location.href = '/#/myrequests/'
    }, 1000)
  }

  const handleSnackbarClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    toggleSnackbarVisibility(false)
  }

  currentRow.id = 'new'

  switch (requestType) {
    case RequestType.AdWord:
      currentRow.itemData = [Object.assign({}, intialAdsResult)]
      currentRow.requestType = RequestType.AdWord

      break
    case RequestType.FeaturedResult:
      currentRow.itemData = Object.assign({}, intialFeaturedResult)
      currentRow.requestType = RequestType.FeaturedResult
      break
  }
  return (
    <>
      <EditFormRequestBase
        rowData={currentRow}
        isNewItem={createNewItem}
        isOpen={formOpen}
        handleClose={handleFormClose}
        handleRowUpdate={handleRowUpdate}
        toggleSnackbarVisibility={toggleSnackbarVisibility}
        setSnackbarMessage={setSnackbarMessage}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isSnackbarVisible}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              handleSnackbarClose()
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {}
}

export default connect(null, mapDispatchToProps)(RequestIndex)
