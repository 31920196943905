import { trackException } from './tracking'
import * as Config from '../config'

export interface IAdminPagesAuthorization {
  value: string[]
}

/**
 * Authorizes an administration page: Checks if the current user is allowed by rights to
 * access the settings application
 * @param adminPagesAuthorization The result of the graph group membership query
 */
export function authorizeAdminPage(
  adminPagesAuthorization: IAdminPagesAuthorization
) {
  let allowRender = false

  // In case the application was opened with adword or featured result request
  // don't authorize the app, so my requests form is shown by default
  if (
    (window.location && window.location.hash === '#/request/adword/') ||
    window.location.hash === '#/request/featuredresult/'
  ) {
    return false
  }

  try {
    // Check current user allowed to access administration page
    if (
      adminPagesAuthorization &&
      adminPagesAuthorization.value.length === 1 &&
      adminPagesAuthorization.value[0] === Config.AUTHORIZATION_SECURITY_GROUP
    ) {
      // Overwrite the value, so page can be used as normal user
      if (localStorage.getItem('ESSettings_ForceRequestView') === 'true') {
        return false
      }

      allowRender = true
    }
  } catch (error) {
    trackException('Error in authorizeAdminPage method', error)
  }

  return allowRender
}
