import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface IProps {
  isModalOpen: boolean
  handleClose?: () => void
  handleConfirm: () => void
  title: string
  message: string
  hideCancelButton: boolean
  confirmBtnText: string
  cancelBtnText: string
}

export default function ConfirmationModal(props: IProps): JSX.Element {
  const {
    isModalOpen,
    handleClose,
    handleConfirm,
    title,
    message,
    hideCancelButton,
    confirmBtnText,
    cancelBtnText
  } = props

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="confirmation-modal"
      aria-describedby="confirmation-modal-description"
    >
      <DialogTitle id="confirmation-modal-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-modal-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!hideCancelButton && (
          <Button onClick={handleClose} color="primary" autoFocus>
            {cancelBtnText}
          </Button>
        )}
        <Button onClick={handleConfirm} color="primary">
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
