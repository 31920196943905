import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesNewsLetterSubscriptionForm(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    dialogHeader: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    closeIcon: {
      display: 'flex',
      fontSize: '13px',
      alignItems: 'center',
      cursor: 'pointer'
    },
    dialogHeaderSubHeadline: {
      marginTop: '5px',
      fontSize: '14px',
      color: 'gray'
    },
    formField: {
      width: '100%'
    },
    overlaySpinner: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      top: '0px',
      right: '0px',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'white'
    },
    overlaySpinnerText: {
      marginRight: '25px',
      fontSize: '18px'
    }
  }))
}
