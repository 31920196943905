import { ISelfServiceRequest } from 'models/SelfServiceRequest'
import { Store } from '..'

const selectors = {
  getMyRequests: (state: Store): ISelfServiceRequest[] =>
    state.selfService.myRequests,
  hasMyRequestsError: (state: Store): boolean =>
    state.selfService.hasMyRequestsError,
  hasMyRequestsBeenFetched: (state: Store): boolean =>
    state.selfService.hasMyRequestsBeenFetched,
  getAllRequests: (state: Store): ISelfServiceRequest[] =>
    state.selfService.allRequests,
  hasAllRequestsError: (state: Store): boolean =>
    state.selfService.hasAllRequestsError,
  hasAllRequestsBeenFetched: (state: Store): boolean =>
    state.selfService.hasAllRequestsBeenFetched,
  hasAllRequestsBeenUpdated: (state: Store): boolean =>
    state.selfService.hasAllRequestsBeenUpdated
}

export default selectors
