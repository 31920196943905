import { ContactUsTile } from 'models/ContactUsTile'
import { Reducer } from 'redux'
import {
  ContactUsActions,
  ContactUsActionTypes,
  IFetchContactUsTilesSuccess,
  IUpsertContactUsTileSuccess,
  IDeleteContactUsTileSuccess,
  IFetchContactUsAllTilesSuccess
} from './actions'

export interface IContactUsStore {
  contactUsTiles: ContactUsTile[]
  contactUsAllTiles: ContactUsTile[]
  hasBeenFetched: boolean
  hasAllBeenFetched: boolean
  hasError: boolean
  hasChangesError: boolean
}

const initialState: IContactUsStore = {
  contactUsTiles: [],
  contactUsAllTiles: [],
  hasBeenFetched: false,
  hasAllBeenFetched: false,
  hasError: false,
  hasChangesError: false
}

const reducers: Reducer<IContactUsStore, ContactUsActions> = (
  state: IContactUsStore = initialState,
  action: ContactUsActions
) => {
  switch (action.type) {
    case ContactUsActionTypes.FETCH_CONTACT_US_TILES_FAILURE:
      return {
        ...state,
        hasBeenFetched: true,
        hasError: true
      }

    case ContactUsActionTypes.FETCH_CONTACT_US_TILES_SUCCESS:
      const { contactUsTileResponse } = (action as IFetchContactUsTilesSuccess)
        .payload

      return {
        ...state,
        contactUsTiles: contactUsTileResponse,
        hasBeenFetched: true,
        hasError: false
      }

    case ContactUsActionTypes.FETCH_CONTACT_US_ALL_TILES_REQUEST:
      return {
        ...state,
        contactUsAllTiles: [],
        hasAllBeenFetched: false,
        hasError: false,
        hasChangesError: false
      }

    case ContactUsActionTypes.FETCH_CONTACT_US_ALL_TILES_SUCCESS:
      const { contactUsAllTileResponse } = (
        action as IFetchContactUsAllTilesSuccess
      ).payload

      return {
        ...state,
        contactUsAllTiles: contactUsAllTileResponse,
        hasAllBeenFetched: true,
        hasError: false
      }

    case ContactUsActionTypes.UPSERT_CONTACT_US_TILE_SUCCESS:
      const { upsertedContactUsTile } = (action as IUpsertContactUsTileSuccess)
        .payload
      const contactUsTilesChanged = state.contactUsTiles
      if (contactUsTilesChanged.length > 0) {
        const tIndex = contactUsTilesChanged.findIndex(
          (t: any) =>
            t.id === upsertedContactUsTile.id &&
            t.language === upsertedContactUsTile.language
        )
        if (tIndex >= 0) {
          contactUsTilesChanged[tIndex] = upsertedContactUsTile
        } else {
          contactUsTilesChanged.unshift(upsertedContactUsTile)
        }
      }
      return {
        ...state,
        contactUsTiles: contactUsTilesChanged,
        hasChangesError: false
      }
    case ContactUsActionTypes.CHANGE_CONTACT_US_TILE_FAILURE:
      return {
        ...state,
        hasChangesError: true
      }
    case ContactUsActionTypes.DELETE_CONTACT_US_TILE_SUCCESS:
      const { contactUsTileId, contactUsTileLanguage } = (
        action as IDeleteContactUsTileSuccess
      ).payload
      const allContactUsTiles = state.contactUsTiles
      if (allContactUsTiles.length > 0) {
        const tIndex = allContactUsTiles.findIndex(
          (t: any) =>
            t.id === contactUsTileId && t.language === contactUsTileLanguage
        )
        if (tIndex >= 0) {
          allContactUsTiles.splice(tIndex, 1)
        }
      }
      return {
        ...state,
        contactUsTiles: allContactUsTiles,
        hasChangesError: false
      }
    default:
      return state
  }
}

export default reducers
