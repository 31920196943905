import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFeaturedResultPreview(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    featuredResult: {
      padding: '10px'
    },
    breadcrump: { display: 'flex', alignItems: 'center' },
    featured: { fontSize: '14px' },
    urlBreadcrumbs: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      padding: '1px 0px',
      lineHeight: 1.3,
      marginRight: 0
    },
    link: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      color: 'rgb(0, 51, 141)'
    },
    title: { fontSize: '20px', marginBottom: '3px', width: '100%' },
    body: {
      fontSize: '14px',
      lineHeight: '20px',
      wordWrap: 'break-word',
      overflowWrap: 'break-word'
    }
  }))
}
