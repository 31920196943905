import React, { useState, useEffect, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import ConfirmationModal from 'components/contents/common/Dialog'
import {
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarCloseReason
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'
import { AdminItemChange } from 'models/AdminItemChange'
import FeaturedResultsStore from 'store/FeaturedResults'
import EditFormBase from '../common/EditFormBase'
import { FeaturedResult, intialFeaturedResult } from 'models/FeaturedResult'
import { getHighestId } from 'utils/admin/adminSettingsUtils'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import { updateFeaturedResultDefaultValues } from 'utils/admin/adminContentQuality'
import FeaturedResultsTable from './FeaturedResultsTable'
import { TableStateFeaturedResults } from 'models/TableStates'
import { deleteItemAndUpdateState } from 'utils/admin/adminTableState'
import {
  ISelfServiceRequest,
  RequestStatus,
  RequestType
} from 'models/SelfServiceRequest'
import SelfServiceStore from 'store/SelfService'

export interface FeaturedResultsProps {
  setHasChanges: (changes: boolean) => void
}

type AllFeaturedResultsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FeaturedResultsProps

function FeaturedResults(props: AllFeaturedResultsProps): JSX.Element {
  const {
    hasFeaturedResultChangesError,
    setHasChanges,
    allFeaturedResults,
    fetchFeaturedResults,
    hasFeaturedResultsBeenFetched,
    upsertFeaturedResult,
    deleteFeaturedResult,
    mapAdminRequest,
    fetchAllRequestsResults
  } = props
  const classes = getStylesAdminSettings()

  const [isLoading, setIsLoading] = useState(true)
  const [isModalVisible, toggleModalVisibility] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [state, setState] = useState<TableStateFeaturedResults>({
    data: []
  })
  const [changes, setChanges] = useState<AdminItemChange[]>([])
  const [pageHasActiveChanges, setPageHasActiveChanges] = useState(false)
  const [currentRow, setCurrentRow] = useState<FeaturedResult | null>(null)
  const [formOpen, setFormOpen] = useState(false)
  const [createNewItem, setCreateNewItem] = useState(false)
  const [featuredResultsChangesApplied, setFeaturedResultsChangesApplied] =
    useState(false)

  const loadData = () => {
    fetchFeaturedResults()
  }

  useEffect(() => {
    if (hasFeaturedResultsBeenFetched) {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFeaturedResultsBeenFetched])

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    if (pageHasActiveChanges) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHasActiveChanges])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNonInitialEffect(() => {
    if (hasFeaturedResultsBeenFetched && allFeaturedResults) {
      let data = []

      updateFeaturedResultDefaultValues(allFeaturedResults)
      data = allFeaturedResults

      setState({
        data: data
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFeaturedResultsBeenFetched])

  useNonInitialEffect(() => {
    if (featuredResultsChangesApplied) {
      if (hasFeaturedResultChangesError) {
        setSnackbarMessage('There was an error publishing the changes.')
        toggleSnackbarVisibility(true)
      } else {
        setSnackbarMessage('The changes have been published.')
        toggleSnackbarVisibility(true)
      }
    }
  }, [featuredResultsChangesApplied, hasFeaturedResultChangesError])

  const handleClickOpen = () => {
    toggleModalVisibility(true)
  }

  const handleClose = () => {
    toggleModalVisibility(false)
  }

  const handleFormClose = () => {
    setCurrentRow(null)
    setCreateNewItem(false)
    setFormOpen(false)
  }

  interface IDResult {
    upn: string
    currentId: number
  }

  const handleConfirm = () => {
    const promiseArray: Array<Promise<void>> = []

    if (
      changes.some((change: AdminItemChange) => {
        const newChange = change.changes as FeaturedResult
        return newChange.mapToRequestItem && newChange.requestUser
      })
    ) {
      fetchAllRequestsResults().then((existingItems: ISelfServiceRequest[]) => {
        const initialIds: IDResult[] = []

        setFeaturedResultsChangesApplied(false)

        // Iterate through changes, applying them
        changes.forEach((change: AdminItemChange) => {
          switch (change.changeType) {
            case 'add':
            case 'update':
              const newChange = change.changes as FeaturedResult
              if (newChange.mapToRequestItem && newChange.requestUser) {
                let idToUse = 1

                const idList = initialIds.find((ids: IDResult) => {
                  return newChange.requestUser === ids.upn
                })

                if (idList) {
                  idToUse = idList.currentId
                  idList.currentId += 1
                } else if (existingItems) {
                  const referenceItems = existingItems.filter(
                    (request: ISelfServiceRequest) => {
                      return request.upn === newChange.requestUser
                    }
                  )

                  if (referenceItems && referenceItems.length > 0) {
                    const maxId = referenceItems.reduce(
                      (max: number, requestItem) =>
                        parseInt(requestItem.id) > max
                          ? parseInt(requestItem.id)
                          : max,
                      parseInt(referenceItems[0].id)
                    )

                    idToUse = maxId + 1

                    initialIds.push({
                      upn: newChange.requestUser,
                      currentId: idToUse + 1
                    })
                  } else {
                    initialIds.push({
                      upn: newChange.requestUser,
                      currentId: idToUse + 1
                    })
                  }
                } else {
                  initialIds.push({
                    upn: newChange.requestUser,
                    currentId: idToUse + 1
                  })
                }

                const itemData = Object.assign({}, newChange)
                delete itemData.mapToRequestItem
                delete itemData.requestUser

                const newRequest: ISelfServiceRequest = {
                  id: idToUse.toString(),
                  status: RequestStatus.Published,
                  upn: newChange.requestUser,
                  requestType: RequestType.FeaturedResult,
                  title: newChange.BestBetTitle,
                  description: newChange.BestBetDescription,
                  itemData: itemData,
                  modifiedDate: new Date().toISOString(),
                  createdDate: new Date().toISOString(),
                  createdBy: newChange.requestUser,
                  modifiedBy: newChange.requestUser,
                  oncePublished: true,
                  definedLanguages: []
                }

                promiseArray.push(mapAdminRequest(newRequest))
              } else {
                promiseArray.push(upsertFeaturedResult(newChange))
              }
              break
            case 'delete':
              const currentChange = change.changes as FeaturedResult
              promiseArray.push(deleteFeaturedResult(currentChange.id))
              break
          }

          toggleModalVisibility(false)
        })

        if (promiseArray && promiseArray.length > 0) {
          Promise.all(promiseArray).then(() => {
            setState((prevState: any) => {
              const data = [...prevState.data]

              changes.forEach((change: AdminItemChange) => {
                const featureResultToChange = change.changes as FeaturedResult

                //find item index in prevState
                let index = -1
                for (let i = 0; i < data.length; i++) {
                  const target = data[i]
                  if (target && target.id === featureResultToChange.id) {
                    index = i
                    break
                  }
                }
                if (index > -1) {
                  //update item in state
                  const fResult = data[index] as FeaturedResult
                  fResult.draft = false
                  data[index] = fResult
                }
              })

              return { ...prevState, data }
            })

            setFeaturedResultsChangesApplied(true)
            setChanges([])
            setPageHasActiveChanges(false)
          })
        } else {
          toggleModalVisibility(false)
        }
      })
    } else {
      setFeaturedResultsChangesApplied(false)

      // Iterate through changes, applying them
      changes.forEach((change: AdminItemChange) => {
        switch (change.changeType) {
          case 'add':
          case 'update':
            const newChange = change.changes as FeaturedResult
            promiseArray.push(upsertFeaturedResult(newChange))
            break
          case 'delete':
            const currentChange = change.changes as FeaturedResult
            promiseArray.push(deleteFeaturedResult(currentChange.id))
            break
        }

        toggleModalVisibility(false)
      })

      if (promiseArray && promiseArray.length > 0) {
        Promise.all(promiseArray).then(() => {
          setState((prevState: any) => {
            const data = [...prevState.data]

            changes.forEach((change: AdminItemChange) => {
              const featureResultToChange = change.changes as FeaturedResult

              //find item index in prevState
              let index = -1
              for (let i = 0; i < data.length; i++) {
                const target = data[i]
                if (target && target.id === featureResultToChange.id) {
                  index = i
                  break
                }
              }
              if (index > -1) {
                //update item in state
                const fResult = data[index] as FeaturedResult
                fResult.draft = false
                data[index] = fResult
              }
            })

            return { ...prevState, data }
          })

          setFeaturedResultsChangesApplied(true)
          setChanges([])
          setPageHasActiveChanges(false)
        })
      } else {
        toggleModalVisibility(false)
      }
    }
  }

  const handleSnackbarClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    toggleSnackbarVisibility(false)
  }

  const handleFormOpen = (row: FeaturedResult) => {
    setCurrentRow(row)
    setFormOpen(true)
  }

  const addRowUpdateForFeaturedResults = (
    newFeaturedResults: FeaturedResult | FeaturedResult[]
  ) => {
    const newChanges = changes
    const featureResultsArray = Array.isArray(newFeaturedResults)
      ? newFeaturedResults
      : [newFeaturedResults]

    setState((prevState: any) => {
      const data = [...prevState.data]

      featureResultsArray.forEach((newFeaturedResult: FeaturedResult) => {
        newFeaturedResult.draft = true
        const index = data
          .map((ad: FeaturedResult) => ad.id)
          .indexOf(newFeaturedResult.id)
        if (index === -1) {
          data.unshift(newFeaturedResult)
        } else {
          data[index] = newFeaturedResult
        }

        const changeIndex = newChanges
          .map((change: AdminItemChange) => change.id)
          .indexOf(newFeaturedResult.id)

        const newChange = {
          id: index === -1 ? newFeaturedResult.id : data[index].id,
          changeType: index === -1 ? 'add' : 'update',
          changes: index === -1 ? data[0] : data[index]
        }

        if (changeIndex === -1) {
          //add change item
          newChanges.push(newChange)
        } else {
          //update item in change list
          newChanges[changeIndex] = {
            ...newChange,
            changeType: newChanges[changeIndex].changeType
          }
        }
      })

      return { ...prevState, data }
    })

    setPageHasActiveChanges(true)
    setChanges(newChanges)

    setCurrentRow(null)
    setFormOpen(false)
    setCreateNewItem(false)
  }

  const publishFeaturedResult = (newData: FeaturedResult) => {
    setFeaturedResultsChangesApplied(false)
    upsertFeaturedResult(newData).then(() => {
      // clear active change
      const newChanges = changes.filter(
        (itemChanges: AdminItemChange) => !(itemChanges.id === newData.id)
      )

      setState((prevState: any) => {
        const data = [...prevState.data]

        //find item index in prevState
        let index = -1
        for (let i = 0; i < data.length; i++) {
          const target = data[i]
          if (target && target.id === newData.id) {
            index = i
            break
          }
        }
        if (index > -1) {
          //update item in state
          const fResult = data[index] as FeaturedResult
          fResult.draft = false
          data[index] = fResult
        }

        return { ...prevState, data }
      })

      if (newChanges.length === 0) {
        setPageHasActiveChanges(false)
      }
      setFeaturedResultsChangesApplied(true)
      setChanges(newChanges)
    })
  }

  const handleRowAdd = () => {
    let currentHighestId = -1
    currentHighestId = getHighestId(state.data)

    let newId = '1'
    if (currentHighestId !== -1) {
      newId = (currentHighestId + 1).toString()
    }

    setCreateNewItem(true)

    const newFeatureResult = Object.assign({}, intialFeaturedResult)
    handleFormOpen({
      ...newFeatureResult,
      id: newId
    } as FeaturedResult)
  }

  const handleRowDelete = (deleteItem: FeaturedResult) =>
    new Promise((resolve) => {
      const newChanges = [...changes]

      setState((prevState: any) => {
        const data = [...prevState.data]

        deleteItemAndUpdateState(deleteItem, data, newChanges)

        return { ...prevState, data }
      })

      setPageHasActiveChanges(newChanges && newChanges.length > 0)
      setChanges(newChanges)

      resolve(true)
    })

  return (
    <>
      <div className={`${classes.container} ${classes.scrollfix}`}>
        {!isLoading ? (
          <FeaturedResultsTable
            state={state}
            handleRowDelete={handleRowDelete}
            handleRowUpdate={addRowUpdateForFeaturedResults}
            handleRowAdd={handleRowAdd}
            handleFormOpen={handleFormOpen}
          />
        ) : (
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress className={classes.loadingSpinner} size={50} />
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          className={classes.button}
          disabled={!pageHasActiveChanges}
          style={{ width: '100%' }}
        >
          Go Live!
        </Button>
        <ConfirmationModal
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          isModalOpen={isModalVisible}
          title={'Are you sure to publish all changes?'}
          message={
            'Changes will be immediately deployed and will be visible to all KPMG users'
          }
          hideCancelButton={false}
          confirmBtnText={'Yes'}
          cancelBtnText={'No'}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={isSnackbarVisible}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                handleSnackbarClose()
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>

      {formOpen && currentRow && (
        <EditFormBase
          rowData={currentRow as FeaturedResult}
          isNewItem={createNewItem}
          isOpen={formOpen}
          handleClose={handleFormClose}
          handleRowUpdate={addRowUpdateForFeaturedResults}
          publishItem={publishFeaturedResult}
          adminSettingType={adminSettingTypes.featuredresults}
          changes={changes}
          allFeaturedResults={allFeaturedResults}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    allFeaturedResults:
      FeaturedResultsStore.selectors.getFeaturedResults(state),
    hasFeaturedResultsBeenFetched:
      FeaturedResultsStore.selectors.hasFeaturedResultsBeenFetched(state),
    hasFeaturedResultChangesError:
      FeaturedResultsStore.selectors.hasChangesError(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchFeaturedResults: () =>
      dispatch(FeaturedResultsStore.actions.fetchFeaturedResults()),
    upsertFeaturedResult: (featuredResult: FeaturedResult) =>
      dispatch(
        FeaturedResultsStore.actions.upsertFeaturedResult(featuredResult)
      ),
    deleteFeaturedResult: (featuredResultId: string) =>
      dispatch(
        FeaturedResultsStore.actions.deleteFeaturedResult(featuredResultId)
      ),
    mapAdminRequest: (request: ISelfServiceRequest) =>
      dispatch(SelfServiceStore.actions.mapAdminRequest(request)),
    fetchAllRequestsResults: () =>
      dispatch(SelfServiceStore.actions.getAllRequests())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedResults)
