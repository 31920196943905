import { FeaturedResult } from 'models/FeaturedResult'
import { Reducer } from 'redux'
import {
  FeaturedResultsActions,
  FeaturedResultsActionTypes,
  IDeleteFeatureResultSuccess,
  IFetchFeaturedResultsSuccess,
  IUpsertFeaturedResultSuccess
} from './actions'

export interface IFeaturedResultsStore {
  featuredResults: FeaturedResult[]
  hasFeaturedResultsBeenFetched: boolean
  hasError: boolean
  hasChangesError: boolean
}

const initialState: IFeaturedResultsStore = {
  featuredResults: [],
  hasFeaturedResultsBeenFetched: false,
  hasError: false,
  hasChangesError: false
}

const reducers: Reducer<IFeaturedResultsStore, FeaturedResultsActions> = (
  state: IFeaturedResultsStore = initialState,
  action: FeaturedResultsActions
) => {
  switch (action.type) {
    case FeaturedResultsActionTypes.FETCH_FEATUREDRESULTS_REQUEST:
      return {
        ...state,
        hasFeaturedResultsBeenFetched: false,
        featuredResults: [],
        hasError: false
      }

    case FeaturedResultsActionTypes.FETCH_FEATUREDRESULTS_SUCCESS:
      const { featuredResultsResponse } = (
        action as IFetchFeaturedResultsSuccess
      ).payload
      return {
        ...state,
        hasFeaturedResultsBeenFetched: true,
        featuredResults: featuredResultsResponse,
        hasError: false
      }
    case FeaturedResultsActionTypes.FETCH_FEATUREDRESULTS_FAILURE:
      return {
        ...state,
        hasFeaturedResultsBeenFetched: true,
        hasError: true
      }
    case FeaturedResultsActionTypes.UPSERT_FEATURERESULT_SUCCESS:
      const { upsertedFeaturedResult } = (
        action as IUpsertFeaturedResultSuccess
      ).payload
      const featuredResultChanged = state.featuredResults
      if (featuredResultChanged.length > 0) {
        const tIndex = featuredResultChanged.findIndex(
          (t: any) => t.id === upsertedFeaturedResult.id
        )
        if (tIndex >= 0) {
          featuredResultChanged[tIndex] = upsertedFeaturedResult
        } else {
          featuredResultChanged.unshift(upsertedFeaturedResult)
        }
      }
      return {
        ...state,
        featuredResults: featuredResultChanged,
        hasChangesError: false
      }
    case FeaturedResultsActionTypes.CHANGE_FEATURERESULT_FAILURE:
      return {
        ...state,
        hasChangesError: true
      }
    case FeaturedResultsActionTypes.DELETE_FEATURERESULT_SUCCESS:
      const { featuredResultId } = (action as IDeleteFeatureResultSuccess)
        .payload
      const allFeaturedResults = state.featuredResults
      if (allFeaturedResults.length > 0) {
        const tIndex = allFeaturedResults.findIndex(
          (t: any) => t.id === featuredResultId
        )
        if (tIndex >= 0) {
          allFeaturedResults.splice(tIndex, 1)
        }
      }
      return {
        ...state,
        featuredResults: allFeaturedResults,
        hasChangesError: false
      }
    default:
      return state
  }
}

export default reducers
