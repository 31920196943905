import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesAdminCreateRequest(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    overlaySpinner: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      top: '0px',
      right: '0px',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'white'
    },
    overlaySpinnerText: {
      marginRight: '25px',
      fontSize: '18px'
    },
    tab_item: {
      width: '100%',
      marginBottom: '10px'
    },
    addUserButton: {
      cursor: 'pointer'
    }
  }))
}
