import { PreloadedState, StoreEnhancer } from 'redux'

import { monitorReducerEnhancer } from './enhancers'
import { logger } from './middlewares'
import { rootReducer, Store } from './'
import { staleActionCheck } from './extension/staleActionCheck'
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'

function configuredStore(initialState?: PreloadedState<Store>) {
  const storeOptions: ConfigureStoreOptions = {
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: true,
    middleware: (getDefaultMiddleware: any) =>
      getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
        .concat(logger)
        .concat(staleActionCheck),
    enhancers: (getDefaultEnhancers: any) =>
      getDefaultEnhancers().concat(monitorReducerEnhancer as StoreEnhancer)
  }

  const createdStore = configureStore(storeOptions)

  return createdStore
}

export const store = configuredStore()
