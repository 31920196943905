import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import {
  Duplicate,
  ISelfServiceRequest,
  RequestType
} from 'models/SelfServiceRequest'
import React from 'react'
import AdminTableTooltipTitle from './AdminTableTooltipTitle'
import DOMPurify from 'dompurify'
import MaterialTable, { Column } from '@material-table/core'
import { tableIcons } from 'utils/admin/adminSettingsUtils'
import { Edit } from '@mui/icons-material'
import { AdsResult } from 'models/AdsResult'
import { FeaturedResult } from 'models/FeaturedResult'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'

interface DuplicateDialogProps {
  rowData: ISelfServiceRequest
  data: ISelfServiceRequest[]
  setShowDuplicates: (data: null) => void
  handleFormOpen: (data: ISelfServiceRequest) => void
}

export default function DuplicateDialog(
  props: DuplicateDialogProps
): JSX.Element {
  const { rowData, data, setShowDuplicates, handleFormOpen } = props
  const classes = getStylesAdminSettings()

  const dataToRender = [
    ...[rowData],
    ...data.filter((d: ISelfServiceRequest) =>
      rowData.duplicates?.elements.some(
        (duplicate: Duplicate) =>
          d.id === duplicate.id && d.createdBy === duplicate.createdBy
      )
    )
  ]

  const tableColumns: Column<ISelfServiceRequest>[] = [
    {
      title: 'ID',
      field: 'id',
      type: 'string',
      cellStyle: {
        maxWidth: 50
      },
      headerStyle: {
        maxWidth: 50
      }
    },
    {
      title: 'Title',
      field: 'title',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => (
        <AdminTableTooltipTitle
          className={''}
          basedOn="words"
          unsafeHTML={rowData.title ? DOMPurify.sanitize(rowData.title) : ''}
          maxLine={4}
        />
      ),
      width: 200,
      cellStyle: {
        maxWidth: 200
      },
      headerStyle: {
        maxWidth: 200
      }
    },
    {
      title: 'Link',
      field: 'link',
      type: 'string',
      width: 160,
      cellStyle: {
        maxWidth: 160
      },
      headerStyle: {
        maxWidth: 160
      },
      render: (rowData: ISelfServiceRequest) => {
        const url =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].link
            : (rowData.itemData as FeaturedResult).BestBetUrl

        return (
          <AdminTableTooltipTitle
            basedOn="words"
            text={url}
            maxLine={1}
            className={classes.rowEllipsis}
          />
        )
      }
    },
    {
      title: 'Created By',
      field: 'createdBy',
      type: 'string',
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      }
    }
  ]

  return (
    <Dialog
      open={!!rowData}
      onClose={() => setShowDuplicates(null)}
      maxWidth={'lg'}
      fullWidth={true}
    >
      <DialogTitle id="selfservice_dialog_duplicates">
        {'Possible duplicates are found:'}
      </DialogTitle>
      <DialogContent>
        <div>
          <MaterialTable
            icons={tableIcons}
            columns={tableColumns}
            data={dataToRender}
            options={{
              tableLayout: 'auto',
              filtering: false,
              search: false,
              paging: false,
              sorting: false,
              toolbar: false
            }}
            actions={[
              {
                icon: Edit,
                onClick: (
                  event: any,
                  rowData: ISelfServiceRequest | ISelfServiceRequest[]
                ) => {
                  if (!(rowData instanceof Array)) {
                    handleFormOpen(rowData)
                  }
                },
                tooltip: 'Edit'
              }
            ]}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setShowDuplicates(null)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
