import React, { useRef } from 'react'
import { CardMedia } from '@mui/material'
import MaterialTable from '@material-table/core'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import Logo from 'images/find_logo.png'
import AdToolBar from '../common/AdToolBar'
import { tableIcons } from 'utils/admin/adminSettingsUtils'
import { TableStateSubscriptions } from 'models/TableStates'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'
import { subscriptionsTableColumns } from './SubscriptionsTableColumns'
import { ExportCsv } from '@material-table/exporters'

export interface ISubscriptionsTableProps {
  state: TableStateSubscriptions
  handleRowDelete: any
  handleRowAdd: any
  handleTranslationUpdate: any
}

export default function SubscriptionsTable(
  props: ISubscriptionsTableProps
): JSX.Element {
  const { state, handleRowDelete, handleRowAdd, handleTranslationUpdate } =
    props

  const classes = getStylesAdminSettings()

  //force browser scrollbars
  const rootElement = document.getElementById('root')
  if (rootElement) rootElement.style.flexDirection = 'unset'

  const tableColumns = useRef(subscriptionsTableColumns())

  return (
    <MaterialTable
      title={
        <>
          <div className={classes.header}>
            <CardMedia image={Logo} className={classes.logo} />
            <h2>Newsletter Subscriptions</h2>
          </div>
          <div className={classes.information}>
            Configure the newsletter subscriptions.
          </div>
        </>
      }
      icons={tableIcons}
      columns={tableColumns.current}
      data={state.data as INewsLetterSubscription[]}
      options={{
        rowStyle: () => ({
          fontSize: 12
        }),
        addRowPosition: 'first',
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
        tableLayout: 'auto',
        filtering: true,
        emptyRowsWhenPaging: false,
        exportMenu: [
          {
            label: 'Export CSV',
            exportFunc: (columns, data) => {
              ExportCsv(
                columns,
                data as INewsLetterSubscription[],
                'NewsLetterSubscriptions',
                ';'
              )
            }
          }
        ]
      }}
      localization={{
        body: {
          editRow: {
            deleteText:
              'Are you sure you want to delete this row? Please remember to publish!'
          }
        }
      }}
      editable={{
        onRowDelete: handleRowDelete
      }}
      actions={[]}
      components={{
        Toolbar: (props: any) => (
          <AdToolBar
            {...{
              ...props,
              handleRowAdd: handleRowAdd,
              handleRowUpdate: handleTranslationUpdate,
              addButtonText: 'Add Subscriptions',
              disableBulkUpload: true
            }}
          />
        )
      }}
    />
  )
}
