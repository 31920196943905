import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import {
  editFormErrorKeys,
  checkIsValidDidYouMeanItem
} from 'utils/admin/adminFormUtils'
import createDOMPurify from 'dompurify'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import { DidYouMeanItem } from 'models/DidYouMean'

export interface IEditFormProps {
  items: DidYouMeanItem[]
  setIsValid: (isValid: boolean) => void
}

export default function EditFormDidYouMeanItems(
  props: IEditFormProps
): JSX.Element {
  const { items, setIsValid } = props
  const DOMPurify = createDOMPurify(window)
  const [loaded, setLoaded] = useState(false)
  const classes = getStylesAdminForm()
  const [errorState, setErrorState] = useState<IErrorListObject[]>([])
  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())

  useEffect(() => {
    validateItem()
    setLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  ///error
  const getError = (key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error && errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }

  const validateItem = (): void => {
    let errorList: IErrorListObject[] = checkIsValidDidYouMeanItem(items[0])

    setIsValid(!errorList.find((error: IErrorListObject) => error.error.error))
    setErrorState(errorList)
  }

  return (
    <>
      {items && items.length === 1 && loaded && (
        <>
          <TextField
            variant="standard"
            required
            id="id-required"
            className={classes.tab_item}
            label="Id"
            defaultValue={items[0].id}
            disabled
          />
          <TextField
            variant="standard"
            id="term-required"
            className={classes.tab_item}
            label="Term *"
            defaultValue={items[0].term}
            onChange={(event) => {
              items[0].term = event.target.value
                ? DOMPurify.sanitize(event.target.value, {
                    USE_PROFILES: { html: false }
                  })
                : event.target.value

              validateItem()
            }}
            error={getError(editFormErrorKeys.original).error}
            helperText={getError(editFormErrorKeys.original).helperText}
            InputLabelProps={{
              shrink: true
            }}
            key={`term_${currentFormState}`}
            onBlur={() => {
              setCurrentFormState(new Date().getTime())
            }}
          />
        </>
      )}
    </>
  )
}
