import React from 'react'
import NewReleases from '@mui/icons-material/NewReleases'
import { getStylesInlineChangeNotification } from 'styles/contents/common/InlineChangeNotification'
import { Tooltip } from '@mui/material'
import { Duplicate, ISelfServiceRequest } from 'models/SelfServiceRequest'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useIntl } from 'react-intl'

export interface InlineChangeNotificationProps {
  rowData: ISelfServiceRequest
  id: string
  handleRowRemoveChangeNotification: () => void
  showDuplicates?: (rowData: ISelfServiceRequest) => void
}

export default function InlineChangeNotification(
  props: InlineChangeNotificationProps
): JSX.Element {
  const { rowData, id, handleRowRemoveChangeNotification, showDuplicates } =
    props
  const classes = getStylesInlineChangeNotification()
  const intl = useIntl()

  const onNotificationClick = () => {
    handleRowRemoveChangeNotification()
  }

  return (
    <>
      {(rowData.hasChanged || rowData.duplicates) && (
        <div className={classes.notificationContainer}>
          <span>
            {id}
            <span className={classes.notificationArea}>
              {rowData.hasChanged && (
                <Tooltip
                  title={intl.formatMessage({
                    id: 'table_column_id_tooltip_change',
                    defaultMessage:
                      'Request has been changed by another user since last visit.'
                  })}
                >
                  <NewReleases
                    className={classes.notificationIconChanged}
                    onClick={() => onNotificationClick()}
                  />
                </Tooltip>
              )}

              {rowData.duplicates && (
                <Tooltip
                  title={
                    <div
                      style={{ whiteSpace: 'pre-line', cursor: 'pointer' }}
                      onClick={() => {
                        if (showDuplicates) showDuplicates(rowData)
                      }}
                    >
                      {`Possible duplicates found:\n\n${rowData.duplicates.elements
                        .map((duplicate: Duplicate) => {
                          return `ID: ${duplicate.id}, CreatedBy: ${duplicate.createdBy}`
                        })
                        .join(' \n ')}
                    `}
                    </div>
                  }
                >
                  <ContentCopyIcon
                    className={classes.notificationDuplicate}
                    style={{
                      color: rowData.duplicates.color,
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      if (showDuplicates) showDuplicates(rowData)
                    }}
                  />
                </Tooltip>
              )}
            </span>
          </span>
        </div>
      )}
      {!rowData.hasChanged && !rowData.duplicates && <div>{id}</div>}
    </>
  )
}
