import React from 'react'
import { Grid, Typography, Box, Zoom, CircularProgress } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { getStylesFilterButton } from 'styles/contents/FilterButton'
import Tooltip from '@mui/material/Tooltip'
import { withStyles } from '@mui/styles'

export interface FilterButtonProps {
  icon: any | undefined
  linkText: string
  onClick?: (event: any) => void
  hasNoLogo?: boolean
  noResults?: boolean
  hasError?: boolean
  isLoading?: boolean
  active: boolean
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

export default function FilterButton(props: FilterButtonProps): JSX.Element {
  const {
    icon,
    linkText,
    active,
    onClick,
    hasNoLogo,
    noResults,
    hasError,
    isLoading
  } = props

  const classes = getStylesFilterButton()

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
        onClick={onClick}
        className={`${classes.rootLayout}
         ${noResults || hasError ? classes.rootNoResults : ''}
        ${active ? classes.rootActive : classes.rootInactive} `}
      >
        {!hasNoLogo && (
          <Grid item className={classes.icon}>
            {!isLoading ? (
              icon
            ) : (
              <CircularProgress className={classes.loadingSpinner} size={12} />
            )}
          </Grid>
        )}
        <LightTooltip
          title={hasError ? 'not available' : noResults ? 'no results' : ''}
          placement="top-end"
        >
          <Grid item>
            <Typography
              id={
                'btn-ds-' + linkText.toLowerCase().replace(/\s/g, '_') + '-01'
              }
              className={classes.linkText}
            >
              <FormattedMessage
                id={linkText.toLowerCase().replace(/\s/g, '_')}
                defaultMessage={linkText}
              />
            </Typography>
          </Grid>
        </LightTooltip>
      </Grid>
      <Zoom in={active}>
        <Box className={classes.underBar} />
      </Zoom>
    </>
  )
}
