import React, { useState, useEffect, CSSProperties } from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import GridLoader from 'react-spinners/GridLoader'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import createDOMPurify from 'dompurify'
import { trackEvents, trackException } from 'utils/tracking'
import { Fade } from '@mui/material'
import Contents from './Contents'
import TranslationsStore from 'store/Translations'
import UserSettingsStore from 'store/UserSettings'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import { IUserSetting } from 'models/UserSettings'
import SettingsNotification from './contents/common/SettingsNotification'
import {
  AccountInfo,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication
} from '@azure/msal-browser'
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication
} from '@azure/msal-react'
import { renewAuthorizationToken } from 'authentication/token'
import * as Config from '../config'
import * as Constants from 'constants/constants'
import { HashRouter } from 'react-router-dom'

interface UserSettingsProps {
  authProvider: PublicClientApplication
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  UserSettingsProps

function UserSettings(props: AllProps) {
  const {
    aadInfo,
    userSettings,
    hasUserSettingsBeenFetched,
    hasUserSettingsFetchError,
    translations,
    setAuthToken,
    setAADInfo,
    fetchVersionsTranslations,
    fetchSettingsTranslations,
    fetchUserSettings,
    upSertUserSettings
  } = props

  const DOMPurify = createDOMPurify(window)
  const [showUserSettingsPopup, setShowUserSettingsPopup] = useState(false)
  const [loginTracked, setLoginTracked] = useState(false)
  const [fourceLogout, setForceLogout] = useState(false)
  const [language] = useState('en')
  const [localizationMessages, setLocalizationMessages] = useState({})

  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts } = useMsal()

  const request = {
    scopes: [Config.ACTIVE_DIRECTORY_SCOPE]
  }

  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    request
  )

  const loginRedirect = async () => {
    const authRedirectResult = await login(InteractionType.Redirect, request)
    if (!authRedirectResult) setForceLogout(true)
  }

  useEffect(() => {
    if (error) {
      loginRedirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (fourceLogout) {
      instance.logoutRedirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fourceLogout])

  useEffect(() => {
    if (isAuthenticated) {
      instance.initialize().then(() => {
        renewAuthorizationToken('', instance, accounts).then(
          (authToken: string) => {
            setAuthToken(authToken)
          }
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    if (aadInfo && aadInfo.accessToken !== '' && !loginTracked) {
      trackLogin(accounts[0])
      setAADInfo(instance, accounts)
      fetchUserSettings(
        accounts[0].username ? accounts[0].username : '',
        accounts[0].name ? accounts[0].name : ''
      )
      // This is required to fetch the max id of the popups
      fetchVersionsTranslations(Constants.translationTypes.popups)
      fetchVersionsTranslations(Constants.translationTypes.settings).then(
        () => {
          if (
            !localizationMessages ||
            JSON.stringify(localizationMessages) === '{}'
          ) {
            fetchSettingsTranslations(language)
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aadInfo])

  useEffect(() => {
    if (translations && Object.keys(translations).length > 0) {
      setLocalizationMessages(translations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translations])

  const trackLogin = async (accountInfo: AccountInfo) => {
    try {
      const userName = accountInfo.username ? accountInfo.username : ''
      const name = accountInfo.name ? accountInfo.name : ''
      ESSettingsGlobalVariables.setUPN(userName)
      ESSettingsGlobalVariables.setDisplayName(name)

      // After this is done, tracking is available and can be triggered
      trackEvents('login', {
        login_webapp: true,
        login_teams: false,
        login_webpart: DOMPurify.sanitize(document.referrer),
        login_mobile: false
      })

      setLoginTracked(true)
    } catch (error) {
      trackException('Error in trackLogin method in App.tsx', error)
    }
  }

  useEffect(() => {
    if (hasUserSettingsBeenFetched) {
      userSettings.LastVisit = new Date().getTime()

      upSertUserSettings(userSettings)

      // in case there was an error loading the user settings show the error popup
      if (hasUserSettingsFetchError) {
        setShowUserSettingsPopup(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserSettingsBeenFetched])

  if (isAuthenticated) {
    const gridLoaderCSS: CSSProperties = {}
    if (!loginTracked || !hasUserSettingsBeenFetched) {
      return (
        <Fade in={true} timeout={{ appear: 1500, enter: 15000 }}>
          <div
            className="noborder"
            style={{
              textAlign: 'center',
              backgroundColor: '#fff'
            }}
          >
            <div
              style={{
                display: 'inline-block',
                color: '#004e98',
                fontSize: '5em',
                fontFamily: 'KPMG'
              }}
            >
              KPMG Find - Settings
            </div>
            <br />
            <br />
            <div id="gridLoader" style={{ display: 'inline-block' }}>
              <GridLoader
                cssOverride={gridLoaderCSS}
                size={'20px'}
                color={'#004e98'}
                margin={'5px'}
                loading={true}
              />
            </div>
          </div>
        </Fade>
      )
    } else {
      return (
        <IntlProvider locale={language} messages={localizationMessages}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              padding: 0,
              margin: 0
            }}
          >
            <div className="content" style={{ padding: 0, margin: 0 }}>
              <HashRouter>
                <Contents />
              </HashRouter>
            </div>
          </div>
          {showUserSettingsPopup && (
            <SettingsNotification
              open={showUserSettingsPopup}
              onClose={() => setShowUserSettingsPopup(false)}
              translationKey={'snackbar_usersettings_errormessage'}
              defaultMessage={
                'There was an error loading your user settings, all new changes will not be synced to the global store.'
              }
            />
          )}
        </IntlProvider>
      )
    }
  } else {
    return <></>
  }
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    hasUserSettingsBeenFetched:
      UserSettingsStore.selectors.hasUserSettingsBeenFetched(state),
    hasUserSettingsFetchError: UserSettingsStore.selectors.hasError(state),
    translations: TranslationsStore.selectors.getSettingsTranslations(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setAuthToken: (authToken: string) =>
      dispatch(AuthStore.actions.setAuthToken(authToken)),
    setAADInfo: (instance: IPublicClientApplication, accounts: AccountInfo[]) =>
      dispatch(AuthStore.actions.setAADInfo(instance, accounts)),
    fetchVersionsTranslations: (translationType: string) =>
      dispatch(
        TranslationsStore.actions.fetchVersionTranslations(translationType)
      ),
    fetchSettingsTranslations: (language: string) =>
      dispatch(TranslationsStore.actions.fetchSettingsTranslations(language)),
    fetchUserSettings: (upn: string, displayName: string) =>
      dispatch(UserSettingsStore.actions.fetchUserSettings(upn, displayName)),
    upSertUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
