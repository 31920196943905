import React, { useState, useEffect, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import TranslationsStore from 'store/Translations'
import ConfirmationModal from 'components/contents/common/Dialog'
import {
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarCloseReason
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'
import { findAdChild } from 'utils/admin/adminFormUtils'
import EditFormBase from '../common/EditFormBase'
import { AdsResult, intialAdsResult } from '../../../models/AdsResult'
import { getHighestId, groupAdsById } from 'utils/admin/adminSettingsUtils'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import { updateAdDefaultValues } from 'utils/admin/adminContentQuality'
import AdWordTable from './AdWordTable'
import { TableStateAds } from 'models/TableStates'
import { AdminItemChange } from 'models/AdminItemChange'
import {
  cleanDraftStatusAfterPublish,
  cleanDraftStatusAfterPublishAll,
  deleteItemsAndUpdateState,
  updateItemsAndUpdateSate
} from 'utils/admin/adminTableState'
import {
  ISelfServiceRequest,
  RequestStatus,
  RequestType
} from 'models/SelfServiceRequest'
import SelfServiceStore from 'store/SelfService'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'

export interface AdWordsProps {
  setHasChanges: (changes: boolean) => void
}

type AllAdWordsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  AdWordsProps

function AdWords(props: AllAdWordsProps): JSX.Element {
  const {
    fetchAllAdsTranslations,
    upsertTranslation,
    deleteTranslation,
    allAds,
    hasTranslationChangesError,
    hasUpsertImageError,
    upsertImageExists,
    upsertImageValidation,
    imageReset,
    setHasChanges,
    hasAllAdTranslationsBeenFetched,
    mapAdminRequest,
    fetchAllRequestsResults
  } = props
  const classes = getStylesAdminSettings()

  const [isLoading, setIsLoading] = useState(true)
  const [isModalVisible, toggleModalVisibility] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [state, setState] = useState<TableStateAds>({
    data: [],
    tableView: []
  })
  const [changes, setChanges] = useState<AdminItemChange[]>([])
  const [pageHasActiveChanges, setPageHasActiveChanges] = useState(false)
  const [currentRow, setCurrentRow] = useState<AdsResult | null>(null)
  const [formOpen, setFormOpen] = useState(false)
  const [createNewItem, setCreateNewItem] = useState(false)
  const [adwordChangesApplied, setadwordChangesApplied] = useState(false)

  const loadData = () => {
    fetchAllAdsTranslations()
  }

  useEffect(() => {
    if (hasAllAdTranslationsBeenFetched) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAllAdTranslationsBeenFetched])

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    if (pageHasActiveChanges) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHasActiveChanges])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNonInitialEffect(() => {
    if (hasAllAdTranslationsBeenFetched && allAds) {
      let data = []
      let tableView = []

      updateAdDefaultValues(allAds)
      data = allAds
      tableView = groupAdsById(allAds)

      setState({
        data: data,
        tableView: tableView
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAllAdTranslationsBeenFetched])

  useNonInitialEffect(() => {
    if (adwordChangesApplied) {
      if (hasTranslationChangesError) {
        setSnackbarMessage('There was an error publishing the changes.')
        toggleSnackbarVisibility(true)
      } else if (!hasTranslationChangesError) {
        setSnackbarMessage('The changes have been published.')
        toggleSnackbarVisibility(true)
      }
    }
  }, [adwordChangesApplied, hasTranslationChangesError])

  useNonInitialEffect(() => {
    if (hasUpsertImageError) {
      if (upsertImageExists) {
        setSnackbarMessage(
          'The image could not be uploaded, a image with this name already exists.'
        )
        toggleSnackbarVisibility(true)
      } else if (upsertImageValidation) {
        setSnackbarMessage(
          'The image could not be uploaded, image name validation failed. Please ensure no spaces or special characters are present on the image name.'
        )
        toggleSnackbarVisibility(true)
      } else {
        setSnackbarMessage('There was an error uploading the image.')
        toggleSnackbarVisibility(true)
      }
    }
  }, [hasUpsertImageError, upsertImageExists, upsertImageValidation])

  const handleClickOpen = () => {
    toggleModalVisibility(true)
  }

  const handleClose = () => {
    toggleModalVisibility(false)
  }

  const handleFormClose = () => {
    setCurrentRow(null)
    setCreateNewItem(false)
    setFormOpen(false)
  }

  interface IDResult {
    upn: string
    currentId: number
  }

  const createMissingLanguages = (
    itemData: AdsResult[],
    enItem: AdsResult
  ): AdsResult[] => {
    const newItemList: AdsResult[] = []

    supportedLanguages.forEach((lang: SupportedLanguage) => {
      const localeChild = itemData.find(
        (item: AdsResult) => item.language === lang.locale
      )

      if (localeChild) {
        newItemList.push(localeChild)
      } else {
        const newChild = Object.assign({}, enItem)
        newChild.language = lang.locale

        newItemList.push(newChild)
      }
    })

    return newItemList
  }

  const createRequestObjects = (
    requestChanges: AdminItemChange[],
    existingItems: ISelfServiceRequest[]
  ): ISelfServiceRequest[] => {
    const selfServiceRequests: ISelfServiceRequest[] = []
    const initialIds: IDResult[] = []

    const groupedById = requestChanges.reduce(
      (acc: any, obj: AdminItemChange) => {
        const adwordChange = obj.changes as AdsResult
        acc[adwordChange.id] = acc[adwordChange.id] || []
        acc[adwordChange.id].push(adwordChange)
        return acc
      },
      {}
    )

    Object.keys(groupedById).forEach((key) => {
      const adArray: AdsResult[] = groupedById[key]
      let itemData: AdsResult[] = []
      const enItem = adArray.find((ad) => ad.language === 'en')
      const definedLanguages = adArray.map((ad) => {
        return ad.language
      })

      if (enItem && enItem.requestUser) {
        adArray.forEach((ad) => {
          const itemDataAd = Object.assign({}, ad)
          delete itemDataAd.mapToRequestItem
          delete itemDataAd.requestUser

          itemData.push(itemDataAd)
        })

        itemData = createMissingLanguages(itemData, enItem)

        let idToUse = 1

        const idList = initialIds.find((ids: IDResult) => {
          return enItem.requestUser === ids.upn
        })

        if (idList) {
          idToUse = idList.currentId
          idList.currentId += 1
        } else if (existingItems) {
          const referenceItems = existingItems.filter(
            (request: ISelfServiceRequest) => {
              return request.upn === enItem.requestUser
            }
          )

          if (referenceItems && referenceItems.length > 0) {
            const maxId = referenceItems.reduce(
              (max: number, requestItem) =>
                parseInt(requestItem.id) > max ? parseInt(requestItem.id) : max,
              parseInt(referenceItems[0].id)
            )

            idToUse = maxId + 1

            initialIds.push({
              upn: enItem.requestUser,
              currentId: idToUse + 1
            })
          } else {
            initialIds.push({
              upn: enItem.requestUser,
              currentId: idToUse + 1
            })
          }
        } else {
          initialIds.push({
            upn: enItem.requestUser,
            currentId: idToUse + 1
          })
        }

        const newRequest: ISelfServiceRequest = {
          id: idToUse.toString(),
          status: RequestStatus.Published,
          upn: enItem.requestUser,
          requestType: RequestType.AdWord,
          title: enItem.title,
          description: enItem.text,
          itemData: itemData,
          modifiedDate: new Date().toISOString(),
          createdDate: new Date().toISOString(),
          createdBy: enItem.requestUser,
          modifiedBy: enItem.requestUser,
          oncePublished: true,
          definedLanguages: definedLanguages,
          imagePreview: enItem.image ? enItem.image : '',
          imageFileName: enItem.image ? enItem.image.split('/').pop() : ''
        }

        selfServiceRequests.push(newRequest)
      }
    })

    return selfServiceRequests
  }

  const handleConfirm = () => {
    const promiseArray: Array<Promise<void>> = []

    if (
      changes.some((change: AdminItemChange) => {
        const newChange = change.changes as AdsResult
        return newChange.mapToRequestItem && newChange.requestUser
      })
    ) {
      fetchAllRequestsResults().then((existingItems: ISelfServiceRequest[]) => {
        setadwordChangesApplied(false)

        const requestChanges = changes.filter((change: AdminItemChange) => {
          return (
            (change.changes as AdsResult).mapToRequestItem &&
            (change.changes as AdsResult).requestUser &&
            change.changeType === 'add'
          )
        })

        const requestItems = createRequestObjects(requestChanges, existingItems)

        requestItems.forEach((newRequest: ISelfServiceRequest) => {
          promiseArray.push(mapAdminRequest(newRequest))
        })

        // Iterate through changes, applying them
        changes.forEach((change: AdminItemChange) => {
          switch (change.changeType) {
            case 'add':
            case 'update':
              const newChange = change.changes as AdsResult
              if (
                !newChange.mapToRequestItem ||
                !newChange.requestUser ||
                !(change.changeType === 'add')
              ) {
                promiseArray.push(
                  upsertTranslation(newChange, adminSettingTypes.ads)
                )
              }
              break
            case 'delete':
              const currentChange = change.changes as AdsResult
              promiseArray.push(
                deleteTranslation(
                  currentChange.id,
                  adminSettingTypes.ads,
                  currentChange.language
                )
              )
              break
          }

          toggleModalVisibility(false)
        })

        if (promiseArray && promiseArray.length > 0) {
          Promise.all(promiseArray).then(() => {
            setState((prevState: any) => {
              const data = [...prevState.data]
              let tableView = [...prevState.tableView]

              cleanDraftStatusAfterPublishAll(changes, data)

              tableView = groupAdsById(data)

              return { ...prevState, data, tableView }
            })

            setadwordChangesApplied(true)

            setChanges([])
            setPageHasActiveChanges(false)
          })
        } else {
          toggleModalVisibility(false)
        }
      })
    } else {
      setadwordChangesApplied(false)

      // Iterate through changes, applying them
      changes.forEach((change: AdminItemChange) => {
        switch (change.changeType) {
          case 'add':
          case 'update':
            promiseArray.push(
              upsertTranslation(
                change.changes as AdsResult,
                adminSettingTypes.ads
              )
            )
            break
          case 'delete':
            const currentChange = change.changes as AdsResult
            promiseArray.push(
              deleteTranslation(
                currentChange.id,
                adminSettingTypes.ads,
                currentChange.language
              )
            )
            break
        }

        toggleModalVisibility(false)
      })

      if (promiseArray && promiseArray.length > 0) {
        Promise.all(promiseArray).then(() => {
          setState((prevState: any) => {
            const data = [...prevState.data]
            let tableView = [...prevState.tableView]

            cleanDraftStatusAfterPublishAll(changes, data)

            tableView = groupAdsById(data)

            return { ...prevState, data, tableView }
          })

          setadwordChangesApplied(true)

          setChanges([])
          setPageHasActiveChanges(false)
        })
      } else {
        toggleModalVisibility(false)
      }
    }
  }

  const handleSnackbarClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    toggleSnackbarVisibility(false)
  }

  const handleFormOpen = (row: AdsResult) => {
    if (row && row.id) {
      imageReset(row.id)
    }

    setCurrentRow(row)
    setFormOpen(true)
  }

  const getCurrentRowData = (isNewItem: boolean): AdsResult[] | null => {
    if (!currentRow) return null

    if (isNewItem) {
      return [currentRow as AdsResult]
    } else {
      return [
        ...[currentRow as AdsResult],
        ...findAdChild(currentRow as AdsResult, state.data as AdsResult[])
      ]
    }
  }

  const addRowUpdateForAds = (newDataList: AdsResult[]) => {
    const newChanges = changes

    setState((prevState: any) => {
      const data = [...prevState.data]
      let tableView = [...prevState.tableView]

      updateItemsAndUpdateSate(newDataList, data, newChanges)

      tableView = groupAdsById(data)

      return { ...prevState, data, tableView }
    })

    //set changes
    setPageHasActiveChanges(true)
    setChanges(newChanges)

    //cleanup modal
    setCurrentRow(null)
    setFormOpen(false)
    setCreateNewItem(false)
  }

  const publishAd = (handleAdList: AdsResult[]) => {
    const promiseArray: Array<Promise<void>> = []

    setadwordChangesApplied(false)

    handleAdList.forEach((item: AdsResult) => {
      promiseArray.push(upsertTranslation(item, adminSettingTypes.ads))
    })

    Promise.all(promiseArray).then(() => {
      // clear active change
      const newChanges = changes.filter(
        (itemChanges: AdminItemChange) =>
          !handleAdList.find(
            (itemNewAd: AdsResult) => itemChanges.id === itemNewAd.id
          )
      )

      setState((prevState: any) => {
        const data = [...prevState.data]
        let tableView = [...prevState.tableView]

        cleanDraftStatusAfterPublish(handleAdList, data)

        tableView = groupAdsById(data)

        return { ...prevState, data, tableView }
      })

      setadwordChangesApplied(true)
      setChanges(newChanges)
      if (newChanges.length === 0) {
        setPageHasActiveChanges(false)
      }
    })
  }

  const handleRowAdd = () => {
    let currentHighestId = -1

    currentHighestId = getHighestId(state.data)

    let newId = '1'

    if (currentHighestId !== -1) {
      newId = (currentHighestId + 1).toString()
    }

    setCreateNewItem(true)

    const newAd = Object.assign({}, intialAdsResult)
    handleFormOpen({
      ...newAd,
      id: newId
    } as AdsResult)
  }

  const handleRowDelete = (deleteItem: AdsResult) =>
    new Promise((resolve) => {
      const newChanges = [...changes]

      setState((prevState: any) => {
        const data = [...prevState.data]
        let tableView = [...prevState.tableView]

        let deleteItemList = [deleteItem]

        deleteItemList = [
          ...findAdChild(deleteItem as AdsResult, data),
          ...deleteItemList
        ]

        deleteItemsAndUpdateState(deleteItemList, data, newChanges)

        tableView = groupAdsById(data)

        return { ...prevState, data, tableView: tableView }
      })

      setPageHasActiveChanges(newChanges && newChanges.length > 0)
      setChanges(newChanges)

      resolve(true)
    })

  return (
    <>
      <div className={`${classes.container} ${classes.scrollfix}`}>
        {!isLoading ? (
          <AdWordTable
            state={state}
            handleRowDelete={handleRowDelete}
            handleRowUpdate={addRowUpdateForAds}
            handleRowAdd={handleRowAdd}
            handleFormOpen={handleFormOpen}
          />
        ) : (
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress className={classes.loadingSpinner} size={50} />
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          className={classes.button}
          disabled={!pageHasActiveChanges}
          style={{ width: '100%' }}
        >
          Go Live!
        </Button>
        <ConfirmationModal
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          isModalOpen={isModalVisible}
          title={'Are you sure to publish all changes?'}
          message={
            'Changes will be immediately deployed and will be visible to all KPMG users'
          }
          hideCancelButton={false}
          confirmBtnText={'Yes'}
          cancelBtnText={'No'}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={isSnackbarVisible}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                handleSnackbarClose()
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
      {formOpen && currentRow && (
        <EditFormBase
          rowData={getCurrentRowData(createNewItem)}
          isNewItem={createNewItem}
          isOpen={formOpen}
          handleClose={handleFormClose}
          handleRowUpdate={addRowUpdateForAds}
          publishItem={publishAd}
          adminSettingType={adminSettingTypes.ads}
          allAds={allAds}
          changes={changes}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    allAds: TranslationsStore.selectors.getAllAdsTranslations(state),
    hasAllAdTranslationsBeenFetched:
      TranslationsStore.selectors.hasAllAdTranslationsBeenFetched(state),
    hasTranslationChangesError:
      TranslationsStore.selectors.hasChangesError(state),
    hasUpsertImageError: TranslationsStore.selectors.hasUpsertImageError(state),
    upsertImageExists: TranslationsStore.selectors.upsertImageExists(state),
    upsertImageValidation:
      TranslationsStore.selectors.upsertImageValidation(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAllAdsTranslations: () =>
      dispatch(TranslationsStore.actions.fetchAllAdsTranslations()),
    upsertTranslation: (translation: AdsResult, translationType: string) =>
      dispatch(
        TranslationsStore.actions.upsertTranslation(
          translation,
          translationType
        )
      ),
    deleteTranslation: (
      translationId: string,
      translationType: string,
      translationLanguage: string
    ) =>
      dispatch(
        TranslationsStore.actions.deleteTranslation(
          translationId,
          translationType,
          translationLanguage
        )
      ),
    imageReset: (rowId: string) =>
      dispatch(
        TranslationsStore.actions.upsertImageReset({
          translationId: rowId,
          url: null
        })
      ),
    mapAdminRequest: (request: ISelfServiceRequest) =>
      dispatch(SelfServiceStore.actions.mapAdminRequest(request)),
    fetchAllRequestsResults: () =>
      dispatch(SelfServiceStore.actions.getAllRequests())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdWords)
