export class ESSettingsGlobalVariables {
  //user
  private static userName: string | null = null
  private static displayName: string | null = null

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  //user
  public static setUPN(state: string | null) {
    ESSettingsGlobalVariables.userName = state
  }
  public static getUPN(): string {
    return ESSettingsGlobalVariables.userName || ''
  }
  public static setDisplayName(state: string | null) {
    ESSettingsGlobalVariables.displayName = state
  }
  public static getDisplayName(): string {
    return ESSettingsGlobalVariables.displayName || ''
  }
}
