import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem
} from '@mui/material'
import { IPeopleResult } from 'models/People'
import React, { useEffect } from 'react'
import { useRef, useState } from 'react'
import { getStylesFilterMenuPeopleSearch } from 'styles/contents/common/PeopleSearch'
import { searchPeopleResults } from 'utils/peopleSearchApi'
import TooltipTitle from './TooltipTile'
import SearchIcon from '@mui/icons-material/Search'
import { IAADState } from 'store/Auth/reducers'
import { useIntl } from 'react-intl'

export interface PeopleSearchProps {
  aadInfo: IAADState
  handleClose: () => void
  addUser: (user: IPeopleResult) => void
  forcedTitle?: string
  forcedAddButtonLabel?: string
}

export default function PeopleSearch(props: PeopleSearchProps): JSX.Element {
  const { handleClose, addUser, aadInfo, forcedTitle, forcedAddButtonLabel } =
    props

  const [loading, setIsLoading] = useState<boolean>(false)
  const [peopleResults, setPeopleResults] = useState<IPeopleResult[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<IPeopleResult | null>()
  const currentCallValue = useRef(null)
  const intl = useIntl()

  const classes = getStylesFilterMenuPeopleSearch()

  const onSearch = (searchQuery: string) => {
    setIsLoading(true)
    searchPeopleResults(searchQuery, aadInfo).then(
      (result: IPeopleResult[] | null) => {
        if (searchQuery === currentCallValue.current) {
          setIsLoading(false)
          setPeopleResults(result ? result : [])
        }
      }
    )
  }

  const handleSelection = (peopleItem: IPeopleResult) => {
    setSelectedUser(peopleItem)
  }

  const rowRenderer = (peopleItem: IPeopleResult, index: number) => {
    if (!peopleItem) {
      return
    }

    return (
      <MenuItem
        disableTouchRipple
        disableRipple
        onClick={() => handleSelection(peopleItem)}
        classes={{
          root:
            selectedUser?.userPrincipalName === peopleItem.userPrincipalName
              ? classes.menuItemSelected
              : classes.rootMenuItem,
          gutters: classes.gutters
        }}
        key={index}
        id={peopleItem.mail}
      >
        <div className={classes.menuItem}>
          <TooltipTitle
            title={peopleItem.displayName}
            useCssOnly={true}
            singleLine={true}
            additionalClass={classes.peopleMenuItem}
          />
          <TooltipTitle
            title={peopleItem.mail}
            useCssOnly={true}
            singleLine={true}
            additionalClass={`${classes.peopleMenuItem} ${classes.peopleSelectionMail}`}
          />
        </div>
      </MenuItem>
    )
  }

  const handleSearchInputChange = (event: any) => {
    currentCallValue.current = event.target.value
    setSearchValue(event.target.value)
  }

  const selectUser = () => {
    if (selectedUser) {
      addUser(selectedUser)
    }
  }

  useEffect(() => {
    let getusers: any = null
    if (searchValue) {
      getusers = setTimeout(() => {
        onSearch(searchValue)
      }, 500)
    }

    return () => {
      if (getusers) {
        clearTimeout(getusers)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  return (
    <Dialog open={true}>
      <DialogTitle>
        {forcedTitle
          ? forcedTitle
          : intl.formatMessage({
              id: 'settings_add_deputy_title',
              defaultMessage: 'Add a deputy'
            })}
      </DialogTitle>
      <DialogContent style={{ maxWidth: '360px' }}>
        <div className={classes.menuTopBar}>
          <div className={classes.menuSearchBar}>
            <SearchIcon style={{ color: '#666' }}></SearchIcon>
            <Input
              className={classes.inputField}
              placeholder={intl.formatMessage({
                id: 'settings_search_user_placeholder',
                defaultMessage: 'Search user'
              })}
              onChange={handleSearchInputChange}
              disableUnderline={true}
              type={'search'}
              inputProps={{ 'aria-label': 'description', size: 200 }}
            />
          </div>
        </div>
        <div className={classes.searchResultContainer}>
          {loading && (
            <div className={classes.centerLoadingSpinner}>
              <CircularProgress className={classes.loadingSpinner} size={15} />
            </div>
          )}
          {!loading && (
            <>
              {peopleResults.map((item: IPeopleResult, index: number) => {
                return rowRenderer(item, index)
              })}
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            id={'btn-peopleSearch-Close'}
            onClick={() => {
              handleClose()
            }}
            color="primary"
            className={classes.cancelButton}
          >
            {intl.formatMessage({
              id: 'settings_button_cancel',
              defaultMessage: 'Cancel'
            })}
          </Button>
          <Button
            id={'btn-peopleSearch-Add'}
            onClick={() => {
              selectUser()
            }}
            color="primary"
            variant="contained"
            disabled={!selectedUser}
          >
            {forcedAddButtonLabel
              ? forcedAddButtonLabel
              : intl.formatMessage({
                  id: 'settings_button_add',
                  defaultMessage: 'Add'
                })}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
