import React, { useState, useEffect, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import ConfirmationModal from 'components/contents/common/Dialog'
import {
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarCloseReason
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'
import EditFormBase from '../common/EditFormBase'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import DidYouMeanStore from 'store/DidYouMean'
import DidYouMeanTable from './DidYouMeanTable'
import { TableStateDidYouMeanItems } from 'models/TableStates'
import { AdminItemChange } from 'models/AdminItemChange'
import {
  cleanDraftStatusAfterPublishDym,
  cleanDraftStatusAfterPublishAllDym,
  deleteItemsAndUpdateState,
  updateDidYouMeanItemsAndUpdateSate
} from 'utils/admin/adminTableState'
import { DidYouMeanItem, initialDidYouMean } from 'models/DidYouMean'

export interface DidYouMeanProps {
  setHasChanges: (changes: boolean) => void
}

type AllDidYouMeanProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  DidYouMeanProps

function DidYouMean(props: AllDidYouMeanProps): JSX.Element {
  const {
    setHasChanges,
    fetchAllDidYouMeanItems,
    upsertDidYouMeanItem,
    deleteDidYouMeanItem,
    allDidYouMeanItems,
    hasDidYouMeanItemsBeenFetched,
    hasDidYouMeanChangesError
  } = props
  const classes = getStylesAdminSettings()

  const [isLoading, setIsLoading] = useState(true)
  const [isModalVisible, toggleModalVisibility] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [state, setState] = useState<TableStateDidYouMeanItems>({
    data: [],
    tableView: []
  })
  const [changes, setChanges] = useState<AdminItemChange[]>([])
  const [pageHasActiveChanges, setPageHasActiveChanges] = useState(false)
  const [currentRow, setCurrentRow] = useState<DidYouMeanItem | null>(null)
  const [formOpen, setFormOpen] = useState(false)
  const [createNewItem, setCreateNewItem] = useState(false)
  const [didYouMeanChangesApplied, setDidYouMeanChangesApplied] =
    useState(false)

  const loadData = () => {
    fetchAllDidYouMeanItems()
  }

  useEffect(() => {
    if (hasDidYouMeanItemsBeenFetched) {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDidYouMeanItemsBeenFetched])

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    if (pageHasActiveChanges) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHasActiveChanges])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNonInitialEffect(() => {
    if (hasDidYouMeanItemsBeenFetched && allDidYouMeanItems) {
      setState({
        data: allDidYouMeanItems,
        tableView: allDidYouMeanItems
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDidYouMeanItemsBeenFetched])

  useNonInitialEffect(() => {
    if (didYouMeanChangesApplied) {
      if (hasDidYouMeanChangesError) {
        setSnackbarMessage('There was an error publishing the changes.')
        toggleSnackbarVisibility(true)
      } else {
        setSnackbarMessage('The changes have been published.')
        toggleSnackbarVisibility(true)
      }
    }
  }, [didYouMeanChangesApplied, hasDidYouMeanChangesError])

  const handleClickOpen = () => {
    toggleModalVisibility(true)
  }

  const handleClose = () => {
    toggleModalVisibility(false)
  }

  const handleFormClose = () => {
    setCurrentRow(null)
    setCreateNewItem(false)
    setFormOpen(false)
  }

  const handleConfirm = () => {
    const promiseArray: Array<Promise<void>> = []

    setDidYouMeanChangesApplied(false)

    // Iterate through changes, applying them
    changes.forEach((change: AdminItemChange) => {
      switch (change.changeType) {
        case 'add':
        case 'update':
          promiseArray.push(
            upsertDidYouMeanItem(change.changes as DidYouMeanItem)
          )
          break
        case 'delete':
          const currentChange = change.changes as DidYouMeanItem
          promiseArray.push(deleteDidYouMeanItem(currentChange.id))
          break
      }

      toggleModalVisibility(false)
    })

    if (promiseArray && promiseArray.length > 0) {
      Promise.all(promiseArray).then(() => {
        setState((prevState: any) => {
          const data = [...prevState.data]
          let tableView = [...prevState.tableView]

          cleanDraftStatusAfterPublishAllDym(changes, data)

          tableView = data

          return { ...prevState, data, tableView }
        })

        setDidYouMeanChangesApplied(true)

        setChanges([])
        setPageHasActiveChanges(false)
      })
    } else {
      toggleModalVisibility(false)
    }
  }

  const handleSnackbarClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    toggleSnackbarVisibility(false)
  }

  const handleFormOpen = (row: DidYouMeanItem) => {
    setCurrentRow(row)
    setFormOpen(true)
  }

  const getCurrentRowData = (isNewItem: boolean): DidYouMeanItem[] | null => {
    if (!currentRow) return null
    return [currentRow as DidYouMeanItem]
  }

  const addRowUpdateForDidYouMean = (newDataList: DidYouMeanItem[]) => {
    const newChanges = changes

    setState((prevState: any) => {
      const data = [...prevState.data]
      let tableView = [...prevState.tableView]

      updateDidYouMeanItemsAndUpdateSate(newDataList, data, newChanges)

      tableView = data

      return { ...prevState, data, tableView }
    })

    //set changes
    setPageHasActiveChanges(true)
    setChanges(newChanges)

    //cleanup modal
    setCurrentRow(null)
    setFormOpen(false)
    setCreateNewItem(false)
  }

  const publishDidYouMeanItem = (dymlist: DidYouMeanItem[]) => {
    setDidYouMeanChangesApplied(false)
    const promiseArray: Array<Promise<void>> = []

    dymlist.forEach((item: DidYouMeanItem) => {
      promiseArray.push(upsertDidYouMeanItem(item))
    })

    Promise.all(promiseArray).then(() => {
      // clear active change
      const newChanges = changes.filter(
        (itemChanges: AdminItemChange) =>
          !dymlist.find(
            (itemNewAd: DidYouMeanItem) => itemChanges.id === itemNewAd.id
          )
      )

      setState((prevState: any) => {
        const data = [...prevState.data]
        let tableView = [...prevState.tableView]

        cleanDraftStatusAfterPublishDym(dymlist, data)

        tableView = data

        return { ...prevState, data, tableView }
      })

      if (newChanges.length === 0) {
        setPageHasActiveChanges(false)
      }

      setDidYouMeanChangesApplied(true)
      setChanges(newChanges)
    })
  }

  const handleRowAdd = () => {
    setCreateNewItem(true)

    const newDidYouMeanItem = Object.assign({}, initialDidYouMean)
    handleFormOpen({
      ...newDidYouMeanItem,
      id: crypto.randomUUID()
    } as DidYouMeanItem)
  }

  const handleRowDelete = (deleteItem: DidYouMeanItem) =>
    new Promise((resolve) => {
      const newChanges = [...changes]

      setState((prevState: any) => {
        const data = [...prevState.data]
        let tableView = [...prevState.tableView]

        let deleteItemList = [deleteItem]

        deleteItemsAndUpdateState(deleteItemList, data, newChanges)

        tableView = data

        return { ...prevState, data, tableView: tableView }
      })

      setPageHasActiveChanges(newChanges && newChanges.length > 0)
      setChanges(newChanges)

      resolve(true)
    })

  return (
    <>
      <div className={`${classes.container} ${classes.scrollfix}`}>
        {!isLoading ? (
          <DidYouMeanTable
            state={state}
            handleRowDelete={handleRowDelete}
            handleRowUpdate={addRowUpdateForDidYouMean}
            handleRowAdd={handleRowAdd}
            handleFormOpen={handleFormOpen}
          />
        ) : (
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress className={classes.loadingSpinner} size={50} />
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          className={classes.button}
          disabled={!pageHasActiveChanges}
          style={{ width: '100%' }}
        >
          Go Live!
        </Button>
        <ConfirmationModal
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          isModalOpen={isModalVisible}
          title={'Are you sure to publish all changes?'}
          message={
            'Changes will be immediately deployed and will be visible to all KPMG users'
          }
          hideCancelButton={false}
          confirmBtnText={'Yes'}
          cancelBtnText={'No'}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={isSnackbarVisible}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                handleSnackbarClose()
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
      {formOpen && currentRow && (
        <EditFormBase
          rowData={getCurrentRowData(createNewItem)}
          isNewItem={createNewItem}
          isOpen={formOpen}
          handleClose={handleFormClose}
          handleRowUpdate={addRowUpdateForDidYouMean}
          publishItem={publishDidYouMeanItem}
          adminSettingType={adminSettingTypes.didyoumean}
          changes={changes}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    allDidYouMeanItems: DidYouMeanStore.selectors.getDidYouMeanItems(state),
    hasDidYouMeanItemsBeenFetched:
      DidYouMeanStore.selectors.hasBeenFetched(state),
    hasDidYouMeanChangesError: DidYouMeanStore.selectors.hasChangesError(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAllDidYouMeanItems: () =>
      dispatch(DidYouMeanStore.actions.fetchAllDidYouMean()),
    upsertDidYouMeanItem: (didYouMeanItem: DidYouMeanItem) =>
      dispatch(DidYouMeanStore.actions.upsertDidYouMean(didYouMeanItem)),
    deleteDidYouMeanItem: (didYouMeanItemId: string) =>
      dispatch(DidYouMeanStore.actions.deleteDidYouMean(didYouMeanItemId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DidYouMean)
