import { ISelfServiceRequest, RequestStatus } from './SelfServiceRequest'

export enum NotificationType {
  Submit = 'Submit',
  UpdateSubmit = 'UpdateSubmit',
  Declined = 'Declined',
  Published = 'Published',
  UnPublish = 'UnPublish',
  UpdatePublished = 'UpdatePublished',
  RequestedClarification = 'RequestedClarification',
  ResolveClarification = 'ResolveClarification',
  RequestedDeletion = 'RequestedDeletion',
  NewComment = 'NewComment'
}

export interface INotificatonRequest {
  notificationType: NotificationType | null
  sendNotification: boolean
  latestComment: string | null
}

export const getNotificationRequest = (
  requestItem: ISelfServiceRequest,
  newStatus?: RequestStatus | string,
  latestComment?: string
): INotificatonRequest => {
  const notificationRequest: INotificatonRequest = {
    notificationType: null,
    sendNotification: false,
    latestComment: null
  }

  if (newStatus === RequestStatus.Submitted) {
    switch (requestItem.status) {
      case RequestStatus.Draft:
        notificationRequest.sendNotification = true
        notificationRequest.notificationType = NotificationType.Submit
        break
      case RequestStatus.Submitted:
        notificationRequest.sendNotification = true
        notificationRequest.notificationType = NotificationType.UpdateSubmit
        break
      case RequestStatus.Published:
        notificationRequest.sendNotification = true
        notificationRequest.notificationType = NotificationType.UpdatePublished
        break
      case RequestStatus.Clarification:
        notificationRequest.sendNotification = true
        notificationRequest.notificationType =
          NotificationType.ResolveClarification
        break
    }
  } else if (newStatus === RequestStatus.ToBeDeleted) {
    notificationRequest.sendNotification = true
    notificationRequest.notificationType = NotificationType.RequestedDeletion
  } else if (
    newStatus === RequestStatus.Published &&
    requestItem.status !== RequestStatus.Published
  ) {
    notificationRequest.sendNotification = true
    notificationRequest.notificationType = NotificationType.Published
  } else if (
    newStatus === RequestStatus.Declined &&
    requestItem.status !== RequestStatus.Declined
  ) {
    notificationRequest.sendNotification = true
    notificationRequest.notificationType = NotificationType.Declined
  } else if (
    newStatus === RequestStatus.Clarification &&
    requestItem.status !== RequestStatus.Clarification
  ) {
    notificationRequest.sendNotification = true
    notificationRequest.notificationType =
      NotificationType.RequestedClarification
  } else if (newStatus === 'UnPublish') {
    notificationRequest.sendNotification = true
    notificationRequest.notificationType = NotificationType.UnPublish
  } else if (newStatus === 'NewComment') {
    notificationRequest.sendNotification = true
    notificationRequest.notificationType = NotificationType.NewComment
  }

  if (notificationRequest.sendNotification && latestComment) {
    notificationRequest.latestComment = latestComment
  }

  return notificationRequest
}
