import { Reducer } from 'redux'
import {
  IDeleteSubscriptionSuccess,
  IFetchNewsLetterSubscriptionsSuccess,
  IAddSubscriptionSuccess,
  NewsLetterSubscriptionsActionTypes,
  NewsLetterSubscriptionsActions
} from './actions'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'

export interface INewsLetterSubscriptionsStore {
  subscriptions: INewsLetterSubscription[]
  hasSubscriptionsBeenFetched: boolean
  hasSubscriptionsError: boolean
  hasChangesError: boolean
}

const initialState: INewsLetterSubscriptionsStore = {
  subscriptions: [],
  hasSubscriptionsBeenFetched: false,
  hasSubscriptionsError: false,
  hasChangesError: false
}

const reducers: Reducer<
  INewsLetterSubscriptionsStore,
  NewsLetterSubscriptionsActions
> = (
  state: INewsLetterSubscriptionsStore = initialState,
  action: NewsLetterSubscriptionsActions
) => {
  switch (action.type) {
    case NewsLetterSubscriptionsActionTypes.FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        hasSubscriptionsBeenFetched: false,
        subscriptions: [],
        hasSubscriptionsError: false
      }

    case NewsLetterSubscriptionsActionTypes.FETCH_SUBSCRIPTIONS_SUCCESS:
      const { subscriptions } = (action as IFetchNewsLetterSubscriptionsSuccess)
        .payload
      return {
        ...state,
        hasSubscriptionsBeenFetched: true,
        subscriptions: subscriptions,
        hasSubscriptionsError: false
      }
    case NewsLetterSubscriptionsActionTypes.FETCH_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        hasSubscriptionsBeenFetched: true,
        hasSubscriptionsError: true
      }
    case NewsLetterSubscriptionsActionTypes.ADD_SUBSCRIPTIONS_SUCCESS:
      const { addSubscription } = (action as IAddSubscriptionSuccess).payload
      const subscriptionItemsChanged = state.subscriptions
      if (subscriptionItemsChanged.length > 0) {
        const tIndex = subscriptionItemsChanged.findIndex(
          (t: any) => t.id === addSubscription.id
        )
        if (tIndex >= 0) {
          subscriptionItemsChanged[tIndex] = addSubscription
        } else {
          subscriptionItemsChanged.unshift(addSubscription)
        }
      }
      return {
        ...state,
        subscriptions: subscriptionItemsChanged,
        hasChangesError: false
      }
    case NewsLetterSubscriptionsActionTypes.CHANGE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        hasChangesError: true
      }
    case NewsLetterSubscriptionsActionTypes.DELETE_SUBSCRIPTIONS_SUCCESS:
      const { subscriptionId } = (action as IDeleteSubscriptionSuccess).payload
      const allSubscriptionItems = state.subscriptions
      if (allSubscriptionItems.length > 0) {
        const tIndex = allSubscriptionItems.findIndex(
          (t: any) => t.id === subscriptionId
        )
        if (tIndex >= 0) {
          allSubscriptionItems.splice(tIndex, 1)
        }
      }
      return {
        ...state,
        subscriptions: allSubscriptionItems,
        hasChangesError: false
      }
    default:
      return state
  }
}

export default reducers
