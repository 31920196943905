/**
 * Transform a given input string to first letter uppercase
 * @param input The input that should be transformed
 */
export function capitalizeFirstLetter(input: string): string {
  return input.charAt(0).toUpperCase() + input.substring(1)
}

/**
 * Strip html tags from a given string and normalizes spetial chars.
 * @param input The html string that should be stripped
 */
export function stripHtml(html: string): string {
  if (!html) {
    return ''
  }
  const strippingElement = document.createElement('DIV')
  html = html.replace(/<ddd\/>/g, '...')
  html = html.replace(/\n/g, ' ')
  strippingElement.innerHTML = html
  let input = strippingElement.textContent || strippingElement.innerText || ''
  // Remove all bad encoded received characters
  // https://en.wikipedia.org/wiki/List_of_Unicode_characters
  // From Data Link Escape onward.
  // eslint-disable-next-line no-control-regex
  input = input.replace(/[\u0010-\u001F]/g, '')
  return input
}

/**
 * Validates if current location is malformed, replacing the %25 character and encoding the URL
 * @return a string containing the well formed URL or empy string
 */
export function validateCurrentLocation(): string {
  const currentLocation = window.location.href
  if (currentLocation.indexOf('%25') >= 0) {
    return encodeURI(currentLocation.replace('%25', ''))
  } else {
    return ''
  }
}

/**
 * test url is valid
 * @param url string to check
 * @returns
 */
export function isUrlValid(url: string): boolean {
  if (url) {
    if (validateEmailHref(url)) {
      return true
    }

    const isAbsolute = new RegExp(
      '^' +
        // protocol identifier (optional)
        // short syntax // still required
        '(?:(?:(?:https?|ftp):)?\\/\\/)' +
        // user:pass BasicAuth (optional)
        '(?:\\S+(?::\\S*)?@)?' +
        '(?:' +
        // IP address exclusion
        // private & local networks
        '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
        '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
        '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
        // IP address dotted notation octets
        // excludes loopback network 0.0.0.0
        // excludes reserved space >= 224.0.0.0
        // excludes network & broadcast addresses
        // (first & last IP address of each class)
        '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
        '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
        '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
        '|' +
        // host & domain names, may end with dot
        // can be replaced by a shortest alternative
        // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
        '(?:' +
        '(?:' +
        '[a-z0-9\\u00a1-\\uffff]' +
        '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
        ')?' +
        '[a-z0-9\\u00a1-\\uffff]\\.?' +
        ')+' +
        // TLD identifier name, may end with dot
        '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
        ')' +
        // port number (optional)
        '(?::\\d{2,5})?' +
        // resource path (optional)
        '(?:[/?#]\\S*)?' +
        '$',
      'i'
    )

    return isAbsolute.test(url)
  }

  return false
}

/**
 * test upn is valid
 * @param upn string to check
 * @returns
 */
export function isUpnValid(upn: string): boolean {
  return new RegExp(/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/).test(
    upn
  )
}

const validateEmailHref = (email: string) => {
  return new RegExp(
    /(mailto:\w+)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email)
}

/**
 * Capitalizes the word given as an input
 * @param word The input word that should be capitalized
 */
export function capitalize(word: string): string {
  try {
    const parts = word.split('-')
    return parts
      .map((part) => {
        return part.charAt(0).toUpperCase() + part.substring(1)
      })
      .join('-')
  } catch (error) {
    return word
  }
}

/**
 * Truncate a string to a given length after the last full word
 * @param input The input that should be truncated
 * @param length The length to which the input should be truncated
 */
export function truncate(
  input: string,
  length: number,
  appendEllipsis = true
): string {
  const appendix = input.length > length && appendEllipsis ? '...' : ''

  const lastSpaceIndex = (input + ' ').lastIndexOf(' ', length)

  return (
    input.substring(0, lastSpaceIndex > 0 ? lastSpaceIndex : length) + appendix
  )
}

export function createRandomBoundryString(length: number): string {
  let result = ''
  const characters = 'abcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}
