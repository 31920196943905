import * as actions from './actions'
import selectors from './selectors'
import reducers from './reducers'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  actions,
  reducers,
  selectors
}
