import React, { useState, useEffect, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import TranslationsStore from 'store/Translations'
import ConfirmationModal from 'components/contents/common/Dialog'
import {
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarCloseReason
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'
import { findPopupChild } from 'utils/admin/adminFormUtils'
import { getPopupDateString } from 'utils/Popups'
import dayjs from 'dayjs'
import EditFormBase from '../common/EditFormBase'
import {
  initialPopupResult,
  PopupDisplayTypes,
  PopupResult
} from 'models/PopupResult'
import { getHighestId, groupPopupsById } from 'utils/admin/adminSettingsUtils'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import { updatePopupDefaultValues } from 'utils/admin/adminContentQuality'
import { trackException } from 'utils/tracking'
import PopupsTable from './PopupsTable'
import { TableStatePopups } from 'models/TableStates'
import { AdminItemChange } from 'models/AdminItemChange'
import {
  cleanDraftStatusAfterPublish,
  cleanDraftStatusAfterPublishAll,
  deleteItemsAndUpdateState,
  updateItemsAndUpdateSate
} from 'utils/admin/adminTableState'

export interface PopupsProps {
  setHasChanges: (changes: boolean) => void
}

type AllPopupsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  PopupsProps

function Popups(props: AllPopupsProps): JSX.Element {
  const {
    fetchAllPopupTranslations,
    deleteTranslation,
    upsertTranslation,
    hasChangesBeenApplied,
    hasTranslationChangesError,
    allPopups,
    upsertVersion,
    hasUpdateVersionError,
    hasUpsertImageError,
    upsertImageExists,
    upsertImageValidation,
    imageReset,
    setHasChanges,
    hasAllPopupTranslationsBeenFetched,
    popupTranslationsMaxId
  } = props
  const classes = getStylesAdminSettings()

  const [isLoading, setIsLoading] = useState(true)
  const [isModalVisible, toggleModalVisibility] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [state, setState] = useState<TableStatePopups>({
    data: [],
    tableView: []
  })
  const [changes, setChanges] = useState<AdminItemChange[]>([])
  const [pageHasActiveChanges, setPageHasActiveChanges] = useState(false)
  const [currentRow, setCurrentRow] = useState<PopupResult | null>(null)
  const [formOpen, setFormOpen] = useState(false)
  const [createNewItem, setCreateNewItem] = useState(false)

  const loadData = () => {
    fetchAllPopupTranslations()
  }

  useEffect(() => {
    if (hasAllPopupTranslationsBeenFetched) {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAllPopupTranslationsBeenFetched])

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    if (pageHasActiveChanges) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHasActiveChanges])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNonInitialEffect(() => {
    if (hasAllPopupTranslationsBeenFetched) {
      let data = []
      let tableView = []

      updatePopupDefaultValues(allPopups)
      data = allPopups
      tableView = groupPopupsById(allPopups)

      setState({
        data: data,
        tableView: tableView
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAllPopupTranslationsBeenFetched])

  useNonInitialEffect(() => {
    if (hasChangesBeenApplied) {
      if (hasUpdateVersionError) {
        setSnackbarMessage(
          'There was an error publishing the changes. The version could not be changed, so updates are not visible for the users.'
        )
        toggleSnackbarVisibility(true)
      } else if (hasTranslationChangesError) {
        setSnackbarMessage('There was an error publishing the changes.')
        toggleSnackbarVisibility(true)
      } else if (!hasTranslationChangesError && !hasUpdateVersionError) {
        setSnackbarMessage('The changes have been published.')
        toggleSnackbarVisibility(true)
      }
    }
  }, [hasChangesBeenApplied, hasUpdateVersionError, hasTranslationChangesError])

  useNonInitialEffect(() => {
    if (hasUpsertImageError) {
      if (upsertImageExists) {
        setSnackbarMessage(
          'The image could not be uploaded, a image with this name already exists.'
        )
        toggleSnackbarVisibility(true)
      } else if (upsertImageValidation) {
        setSnackbarMessage(
          'The image could not be uploaded, image name validation failed. Please ensure no spaces or special characters are present on the image name.'
        )
        toggleSnackbarVisibility(true)
      } else {
        setSnackbarMessage('There was an error uploading the image.')
        toggleSnackbarVisibility(true)
      }
    }
  }, [hasUpsertImageError, upsertImageExists, upsertImageValidation])

  const handleClickOpen = () => {
    toggleModalVisibility(true)
  }

  const handleClose = () => {
    toggleModalVisibility(false)
  }

  const handleFormClose = () => {
    setCurrentRow(null)
    setCreateNewItem(false)
    setFormOpen(false)
  }

  const handleConfirm = () => {
    const promiseArray: Array<Promise<void>> = []

    // Iterate through changes, applying them
    changes.forEach((change: AdminItemChange) => {
      switch (change.changeType) {
        case 'add':
        case 'update':
          promiseArray.push(
            upsertTranslation(
              change.changes as PopupResult,
              adminSettingTypes.popups
            )
          )
          break
        case 'delete':
          const currentChange = change.changes as PopupResult
          promiseArray.push(
            deleteTranslation(
              currentChange.id,
              adminSettingTypes.popups,
              currentChange.language
            )
          )
          break
      }

      toggleModalVisibility(false)
    })

    if (promiseArray && promiseArray.length > 0) {
      Promise.all(promiseArray).then(() => {
        setState((prevState: any) => {
          const data = [...prevState.data]
          let tableView = [...prevState.tableView]

          cleanDraftStatusAfterPublishAll(changes, data)

          tableView = groupPopupsById(data)

          return { ...prevState, data, tableView }
        })

        setChanges([])
        setPageHasActiveChanges(false)
      })
    } else {
      toggleModalVisibility(false)
    }
  }

  const handleSnackbarClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    toggleSnackbarVisibility(false)
  }

  const handleFormOpen = (row: PopupResult) => {
    if (row && row.id) {
      imageReset(row.id)
    }

    setCurrentRow(row)
    setFormOpen(true)
  }

  const getCurrentRowData = (isNewItem: boolean): PopupResult[] | null => {
    if (!currentRow) return null

    if (isNewItem) {
      return [currentRow as PopupResult]
    } else {
      return [
        ...[currentRow as PopupResult],
        ...findPopupChild(
          currentRow as PopupResult,
          state.data as PopupResult[]
        )
      ]
    }
  }

  const disableOldOncePerUserPopups = (popupResult: PopupResult) => {
    if (popupResult.display !== PopupDisplayTypes.oncePerUser) return true

    allPopups.forEach((item: PopupResult) => {
      if (
        item.display === PopupDisplayTypes.oncePerUser &&
        item.language === popupResult.language &&
        item.id !== popupResult.id &&
        !item.dateEnd
      ) {
        item.dateEnd = getPopupDateString(
          dayjs(popupResult.dateStart).subtract(1, 'days').toDate()
        )
        addRowUpdateForPopups([item])
      }
    })

    return false
  }

  const handleRowUpdate = (
    newData: PopupResult[],
    skipDisableOldOncePerUser: boolean | undefined
  ) => {
    if (!skipDisableOldOncePerUser) {
      const popupList = newData as PopupResult[]
      popupList.forEach((popupItem: PopupResult) => {
        disableOldOncePerUserPopups(popupItem)
      })
    }
    addRowUpdateForPopups(newData as PopupResult[])
  }

  const addRowUpdateForPopups = (newDataList: PopupResult[]) => {
    const newChanges = changes
    setState((prevState: any) => {
      const data = [...prevState.data]
      let tableView = [...prevState.tableView]

      updateItemsAndUpdateSate(newDataList, data, newChanges)

      tableView = groupPopupsById(data)

      return { ...prevState, data, tableView }
    })

    //set changes
    setPageHasActiveChanges(true)
    setChanges(newChanges)

    //cleanup modal
    setCurrentRow(null)
    setFormOpen(false)
    setCreateNewItem(false)
  }

  const publishPopup = (handlePopupList: PopupResult[]) => {
    const changedPopups: PopupResult[] = []

    handlePopupList.forEach((newPopup: PopupResult) => {
      //disable old once per user popups
      if (newPopup.display === PopupDisplayTypes.oncePerUser) {
        allPopups.forEach((item: PopupResult) => {
          if (
            item.display === PopupDisplayTypes.oncePerUser &&
            item.language === newPopup.language &&
            item.id !== newPopup.id &&
            !item.dateEnd
          ) {
            item.dateEnd = getPopupDateString(
              dayjs(newPopup.dateStart).subtract(1, 'days').toDate()
            )
            addRowUpdateForPopups([item])
            changedPopups.push(item)
          }
        })
      }

      changedPopups.push(newPopup)
    })
    let idToUpdate = -1
    const highestId = getHighestId(changedPopups)
    if (popupTranslationsMaxId.toString() !== '-1') {
      try {
        if (highestId > parseInt(popupTranslationsMaxId.toString()))
          idToUpdate = highestId
        else idToUpdate = parseInt(popupTranslationsMaxId.toString())
      } catch (ex) {
        trackException(
          'popupTranslationsMaxId cannot be parsed in handleRowAdd method',
          ex
        )
      }
    }

    const promiseArray: Array<Promise<void>> = []
    changedPopups.forEach((item: PopupResult) => {
      promiseArray.push(upsertTranslation(item, adminSettingTypes.popups))
    })

    if (promiseArray && promiseArray.length > 0) {
      Promise.all(promiseArray).then(() => {
        upsertVersion(adminSettingTypes.popups, idToUpdate)

        setState((prevState: any) => {
          const data = [...prevState.data]
          let tableView = [...prevState.tableView]

          cleanDraftStatusAfterPublish(handlePopupList, data)

          tableView = groupPopupsById(data)

          return { ...prevState, data, tableView }
        })

        // clear active change
        const newChanges = changes.filter(
          (item: AdminItemChange) =>
            !changedPopups.find(
              (itemUpdated: PopupResult) => item.id === itemUpdated.id
            )
        )

        if (newChanges.length === 0) {
          setPageHasActiveChanges(false)
        }

        setChanges(newChanges)
      })
    }
  }

  const handleRowAdd = () => {
    let currentHighestId = -1

    currentHighestId = getHighestId(state.data)
    if (popupTranslationsMaxId.toString() !== '-1') {
      try {
        currentHighestId = parseInt(popupTranslationsMaxId.toString())
      } catch (ex) {
        trackException(
          'popupTranslationsMaxId cannot be parsed in handleRowAdd method',
          ex
        )
      }
    }

    let newId = '1'

    if (currentHighestId !== -1) {
      newId = (currentHighestId + 1).toString()
    }
    setCreateNewItem(true)

    const newPopup = Object.assign({}, initialPopupResult)
    newPopup.isNewItem = true
    handleFormOpen({
      ...newPopup,
      id: newId
    } as PopupResult)
  }

  const handleRowDelete = (deleteItem: PopupResult) =>
    new Promise((resolve) => {
      const newChanges = [...changes]
      setState((prevState: any) => {
        const data = [...prevState.data]
        let tableView = [...prevState.tableView]

        let deleteItemList = [deleteItem]

        deleteItemList = [
          ...findPopupChild(deleteItem as PopupResult, data),
          ...deleteItemList
        ]

        deleteItemsAndUpdateState(deleteItemList, data, newChanges)

        tableView = groupPopupsById(data)
        return { ...prevState, data, tableView: tableView }
      })

      setPageHasActiveChanges(newChanges && newChanges.length > 0)
      setChanges(newChanges)

      resolve(true)
    })

  return (
    <>
      <div className={`${classes.container} ${classes.scrollfix}`}>
        {!isLoading ? (
          <PopupsTable
            state={state}
            handleRowDelete={handleRowDelete}
            handleRowUpdate={handleRowUpdate}
            handleRowAdd={handleRowAdd}
            handleFormOpen={handleFormOpen}
          />
        ) : (
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress className={classes.loadingSpinner} size={50} />
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          className={classes.button}
          disabled={!pageHasActiveChanges}
          style={{ width: '100%' }}
        >
          Go Live!
        </Button>
        <ConfirmationModal
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          isModalOpen={isModalVisible}
          title={'Are you sure to publish all changes?'}
          message={
            'Changes will be immediately deployed and will be visible to all KPMG users'
          }
          hideCancelButton={false}
          confirmBtnText={'Yes'}
          cancelBtnText={'No'}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={isSnackbarVisible}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                handleSnackbarClose()
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
      {formOpen && currentRow && (
        <EditFormBase
          rowData={getCurrentRowData(createNewItem)}
          isNewItem={createNewItem}
          isOpen={formOpen}
          handleClose={handleFormClose}
          handleRowUpdate={handleRowUpdate}
          publishItem={publishPopup}
          adminSettingType={adminSettingTypes.popups}
          changes={changes}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    allPopups: TranslationsStore.selectors.getAllPopupsTranslations(state),
    hasAllPopupTranslationsBeenFetched:
      TranslationsStore.selectors.hasAllPopupTranslationsBeenFetched(state),
    hasTranslationChangesError:
      TranslationsStore.selectors.hasChangesError(state),
    hasChangesBeenApplied:
      TranslationsStore.selectors.hasChangesBeenApplied(state),
    hasUpdateVersionError:
      TranslationsStore.selectors.hasUpdateVersionError(state),
    hasUpsertImageError: TranslationsStore.selectors.hasUpsertImageError(state),
    upsertImageExists: TranslationsStore.selectors.upsertImageExists(state),
    upsertImageValidation:
      TranslationsStore.selectors.upsertImageValidation(state),
    popupTranslationsMaxId:
      TranslationsStore.selectors.getPopupTranslationsMaxId(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAllPopupTranslations: () =>
      dispatch(TranslationsStore.actions.fetchAllPopupTranslations()),
    upsertTranslation: (translation: PopupResult, translationType: string) =>
      dispatch(
        TranslationsStore.actions.upsertTranslation(
          translation,
          translationType
        )
      ),
    deleteTranslation: (
      translationId: string,
      translationType: string,
      translationLanguage: string
    ) =>
      dispatch(
        TranslationsStore.actions.deleteTranslation(
          translationId,
          translationType,
          translationLanguage
        )
      ),
    upsertVersion: (translationType: string, maxId?: number) =>
      dispatch(TranslationsStore.actions.upsertVersion(translationType, maxId)),
    imageReset: (rowId: string) =>
      dispatch(
        TranslationsStore.actions.upsertImageReset({
          translationId: rowId,
          url: null
        })
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Popups)
