import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getStylesNewsLetterSubscriptionForm } from 'styles/requests/newsLetterSubscriptionForm'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import { supportedMemberFirms } from 'constants/supportedMemberFirms'
import { FormattedMessage, useIntl } from 'react-intl'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import {
  checkIsValidNewsLetterSubscription,
  editFormErrorKeys
} from 'utils/admin/adminFormUtils'
import CloseIcon from '@mui/icons-material/Close'
import { connect } from 'react-redux'
import { Store } from 'store'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'
import NewsLetterSubscritionsStore from 'store/NewsLetterSubscriptions'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'
import AuthStore from 'store/Auth'
import { fetchNewsLetterSubscription } from 'store/NewsLetterSubscriptions/actions'

interface INewsLetterSubsciptionFormProps {
  showNewsLetterForm: boolean
  setShowNewsLetterForm: (showForm: boolean) => void
  toggleSnackbarVisibility: (visible: boolean) => void
  setSnackbarMessage: (message: string) => void
}

type AllNewsLetterSubsciptionFormProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  INewsLetterSubsciptionFormProps

function NewsLetterSubsciptionForm(
  props: AllNewsLetterSubsciptionFormProps
): JSX.Element {
  const {
    showNewsLetterForm,
    setShowNewsLetterForm,
    addSubscriptionItem,
    deleteSubscriptionItem,
    hasChangesError,
    toggleSnackbarVisibility,
    setSnackbarMessage,
    AADInfo
  } = props
  const [loaded, setLoaded] = useState(false)
  const classes = getStylesNewsLetterSubscriptionForm()
  const [errorState, setErrorState] = useState<IErrorListObject[]>([])
  const [mail, setMail] = useState(ESSettingsGlobalVariables.getUPN())
  const [memberFirm, setMemberFirm] = useState('Australia')
  const [isValid, setIsValid] = useState(true)
  const [hasSubscription, setHasSubscription] = useState(false)
  const [subcribeApplied, setSubscribeApplied] = useState(false)
  const [unSubcribeApplied, setUnSubscribeApplied] = useState(false)
  const [hasSubScriptionFetchError, setHasSubscriptionFetchError] =
    useState(false)

  const intl = useIntl()
  const newsLetterItem = useRef({
    id: 'new',
    upn: ESSettingsGlobalVariables.getUPN(),
    memberFirm: 'Australia'
  })

  useEffect(() => {
    fetchNewsLetterSubscription(
      ESSettingsGlobalVariables.getUPN(),
      AADInfo
    ).then(
      (result: {
        hasError: boolean
        newsLetterItem: INewsLetterSubscription | null
      }) => {
        if (!result.hasError) {
          if (result.newsLetterItem) {
            newsLetterItem.current = result.newsLetterItem
            setHasSubscription(true)
          }
        } else {
          setHasSubscriptionFetchError(true)
        }

        setLoaded(true)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateItem = (): void => {
    let errorList: IErrorListObject[] = checkIsValidNewsLetterSubscription(
      newsLetterItem.current
    )

    setIsValid(!errorList.find((error: IErrorListObject) => error.error.error))
    setErrorState(errorList)
  }

  ///error
  const getError = (key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error && errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }

  const filterSearchTermInput = (input: string) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /[`~!#$§%^*()|\=?;:'"<>\{\}\[\]\\\/]/gi
    return input.replace(regex, '')
  }

  const subscribe = () => {
    setSubscribeApplied(false)
    addSubscriptionItem(newsLetterItem.current).then(() => {
      setSubscribeApplied(true)
    })
  }

  const unSubscribe = () => {
    setUnSubscribeApplied(false)
    deleteSubscriptionItem(newsLetterItem.current).then(() => {
      setUnSubscribeApplied(true)
    })
  }

  useNonInitialEffect(() => {
    if (subcribeApplied) {
      setShowNewsLetterForm(false)
      if (hasChangesError) {
        setSnackbarMessage('There was an error signup to the newsletter.')
        toggleSnackbarVisibility(true)
      } else {
        setSnackbarMessage('Signup to the newsletter successfully done.')
        toggleSnackbarVisibility(true)
      }
    }
  }, [subcribeApplied, hasChangesError])

  useNonInitialEffect(() => {
    if (unSubcribeApplied) {
      setShowNewsLetterForm(false)
      if (hasChangesError) {
        setSnackbarMessage(
          'There was an error canceling signup to the newsletter.'
        )
        toggleSnackbarVisibility(true)
      } else {
        setSnackbarMessage(
          'Canceling the signup to the newsletter successfully done.'
        )
        toggleSnackbarVisibility(true)
      }
    }
  }, [unSubcribeApplied, hasChangesError])

  return (
    <Dialog
      open={showNewsLetterForm}
      onClose={() => {
        setShowNewsLetterForm(false)
      }}
      aria-labelledby="newsletter-subscription"
      aria-describedby="newsletter-subscription-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>
        <div className={classes.dialogHeader}>
          <div>
            <div>
              <FormattedMessage
                id="form_newsletter_subscription_headline"
                defaultMessage="Enterprise Search Metrics Newsletter Signup"
              />
            </div>
            {loaded && !hasSubscription && (
              <div className={classes.dialogHeaderSubHeadline}>
                <FormattedMessage
                  id="form_newsletter_subscription_subheadline"
                  defaultMessage="Fill-up the form below to signup for enterprise search newsletter, tailored to your member firm search metrics. The newsletter will be send over your email on a quarterly basis and covers search metrics KPIs for KPMG Find and One Intranet."
                />
              </div>
            )}
          </div>
          <div>
            <IconButton
              onClick={() => {
                setShowNewsLetterForm(false)
              }}
              className={classes.closeIcon}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '15px' }}>
        {loaded && !hasSubscription && !hasSubScriptionFetchError && (
          <>
            <TextField
              id="input-with-icon-textfield"
              size="small"
              value={mail}
              onChange={(event) => {
                newsLetterItem.current.upn = filterSearchTermInput(
                  event.target.value
                )
                setMail(filterSearchTermInput(event.target.value))
                validateItem()
              }}
              variant="outlined"
              error={getError(editFormErrorKeys.upn).error}
              helperText={getError(editFormErrorKeys.upn).helperText}
              label={intl.formatMessage({
                id: 'form_newsletter_subscription_mail_label',
                defaultMessage: 'Email ID *'
              })}
              className={classes.formField}
              disabled={true}
            />
            <FormControl style={{ marginTop: '10px' }} fullWidth size="small">
              <InputLabel id="select-label">Member firm *</InputLabel>
              <Select
                id="memberfirm-required"
                multiple={false}
                variant={'outlined'}
                defaultValue={memberFirm}
                size="small"
                label={intl.formatMessage({
                  id: 'form_newsletter_subscription_memberfirm_label',
                  defaultMessage: 'Member firm *'
                })}
                onChange={(event: SelectChangeEvent<string>) => {
                  newsLetterItem.current.memberFirm = event.target.value
                  setMemberFirm(event.target.value)
                }}
              >
                {supportedMemberFirms.map((key: string) => {
                  return (
                    <MenuItem key={key} value={key}>
                      <ListItemText primary={key} />
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </>
        )}
        {loaded && hasSubscription && !hasSubScriptionFetchError && (
          <div>
            <FormattedMessage
              id="form_newsletter_subscription_existing_subscition"
              defaultMessage="There is already an active subscription. If you want to unsubscribe use the button below."
            />
          </div>
        )}
        {!loaded && (
          <div className={classes.overlaySpinner}>
            <div className={classes.overlaySpinnerText}>
              <FormattedMessage
                id="form_newsletter_subscription_loading"
                defaultMessage="Load Subscription"
              />
            </div>
            <CircularProgress size={80} />
          </div>
        )}
        {hasSubScriptionFetchError && (
          <FormattedMessage
            id="form_newsletter_subscription_loading_error"
            defaultMessage="There was an error loading the signup information."
          />
        )}
      </DialogContent>
      <DialogActions>
        {!hasSubscription && (
          <Button
            onClick={() => {
              subscribe()
            }}
            color="primary"
            disabled={!isValid || !loaded || hasSubScriptionFetchError}
          >
            <FormattedMessage
              id="form_newsletter_subscribe"
              defaultMessage="Subscribe"
            />
          </Button>
        )}
        {hasSubscription && (
          <Button
            onClick={() => {
              unSubscribe()
            }}
            color="primary"
            disabled={!loaded}
          >
            <FormattedMessage
              id="form_newsletter_unsubscribe"
              defaultMessage="Unsubscribe"
            />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    hasChangesError:
      NewsLetterSubscritionsStore.selectors.hasChangesError(state),
    AADInfo: AuthStore.selectors.getAADInfo(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addSubscriptionItem: (subscriptionItem: INewsLetterSubscription) =>
      dispatch(
        NewsLetterSubscritionsStore.actions.addNewsLetterSubscription(
          subscriptionItem
        )
      ),
    deleteSubscriptionItem: (subItem: INewsLetterSubscription) =>
      dispatch(
        NewsLetterSubscritionsStore.actions.deleteNewsLetterSubscription(
          subItem
        )
      )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsLetterSubsciptionForm)
