import React from 'react'
import { getStylesDefaultFormField } from 'styles/contents/common/DefaultFormField'
import createDOMPurify from 'dompurify'

export interface IDefaultFormFieldProps {
  label: string
  description: string
  inputControl: JSX.Element
  required?: boolean
  hasError?: boolean
  errorText?: string
}

const DefaultFormField = (props: IDefaultFormFieldProps): JSX.Element => {
  const { label, description, inputControl, required, errorText, hasError } =
    props

  const classes = getStylesDefaultFormField()
  const DOMPurify = createDOMPurify(window)

  return (
    <div className={classes.defaultFormFieldContainer}>
      <div className={classes.defaultFormFieldHeader}>
        <div className={classes.headerLabel}>{`${label}${
          required ? ' *' : ''
        }`}</div>
        <div
          className={classes.headerDescription}
          dangerouslySetInnerHTML={{
            __html: description
              ? DOMPurify.sanitize(description, {
                  USE_PROFILES: { html: true },
                  ADD_ATTR: ['target']
                })
              : ''
          }}
        />
      </div>
      <div className={classes.defaultFormFieldInput}>{inputControl}</div>
      <div className={classes.defaultFormFieldError}>
        {hasError && errorText ? errorText : ''}
      </div>
    </div>
  )
}

export default DefaultFormField
