import React from 'react'
import { Tooltip } from '@mui/material'
import { stripHtml } from 'utils/string'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

export interface ITooltipTitleProps {
  className: any
  basedOn: 'letters' | 'words'
  unsafeHTML?: any
  text?: any
  maxLine: number
}

const AdminTableTooltipTitle = (props: ITooltipTitleProps): JSX.Element => {
  const { className, basedOn, unsafeHTML, text, maxLine } = props

  return (
    <Tooltip title={unsafeHTML ? stripHtml(unsafeHTML) : text}>
      <div>
        {unsafeHTML ? (
          <ResponsiveHTMLEllipsis
            {...(className && { className: className })}
            style={{
              whiteSpace: 'pre-wrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              wordWrap: 'break-word'
            }}
            basedOn={basedOn}
            unsafeHTML={unsafeHTML}
            maxLine={maxLine}
          />
        ) : (
          <ResponsiveEllipsis
            {...(className && { className: className })}
            style={{
              whiteSpace: 'pre-wrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
            basedOn={basedOn}
            text={text}
            maxLine={maxLine}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default AdminTableTooltipTitle
