export interface FeaturedResult {
  BestBetTitle: string
  BestBetUrl: string
  BestBetDescription: string
  BestBetRank: number | null
  BestBetStartDate: string | null
  BestBetEndDate: string | null
  BestBetSiteUrl: string | null
  BestBetKeywords: string[]
  BestBetCountry: string[]
  BestBetCity: string[]
  BestBetFunction: string[]
  BestBetMatchType: string
  BestBetDataSources: string[]
  BestBetLastModified: string
  BestBetKeyWordMatch?: string
  id: string
  BestBetScope?: string
  type?: string
  requestId?: string
  requestUser?: string
  draft?: boolean
  mapToRequestItem?: boolean
}

export const intialFeaturedResult: FeaturedResult = {
  BestBetTitle: '',
  BestBetUrl: '',
  BestBetDescription: '',
  BestBetRank: 0,
  BestBetStartDate: '',
  BestBetEndDate: '',
  BestBetSiteUrl: '',
  BestBetKeywords: [],
  BestBetCountry: ['All'],
  BestBetCity: ['All'],
  BestBetFunction: ['All'],
  BestBetMatchType: 'Contains',
  BestBetDataSources: ['All'],
  BestBetLastModified: '',
  id: '',
  BestBetScope: 'All',
  type: 'FeaturedResult'
}
