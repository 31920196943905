import { IUserSetting } from 'models/UserSettings'
import { Store } from '..'
import { ISettingsConfiguration } from 'models/SettingsConfiguration'

const selectors = {
  getUserSettings: (state: Store): IUserSetting =>
    state.userSettings.userSettings,
  hasUserSettingsBeenFetched: (state: Store): boolean =>
    state.userSettings.hasUserSettingsBeenFetched,
  hasError: (state: Store): boolean => state.userSettings.hasError,
  getPictureUrl: (state: Store): string => state.userSettings.pictureUrl,
  getUPN: (state: Store): string => state.userSettings.upn,
  getDisplayName: (state: Store): string => state.userSettings.displayName,
  getSettingsConfiguration: (state: Store): ISettingsConfiguration =>
    state.userSettings.settingsConfiguration
}

export default selectors
