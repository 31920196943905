import MaterialTable from '@material-table/core'
import {
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarCloseReason,
  Tooltip
} from '@mui/material'
import {
  defaultSelfServiceRequest,
  IExportItem,
  ISelfServiceRequest,
  ISelfServiceUpdateResponse,
  RequestStatus,
  RequestType
} from 'models/SelfServiceRequest'
import React, { SyntheticEvent, useRef } from 'react'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SelfServiceStore from 'store/SelfService'
import { getStylesRequests } from 'styles/requests/requests'
import { tableIcons } from 'utils/admin/adminSettingsUtils'
import { myRequestTableColumns } from './myRequestsColumns'
import { Edit, Share } from '@mui/icons-material'
import EditFormRequestBase from './EditFormRequestBase'
import { intialFeaturedResult } from 'models/FeaturedResult'
import { intialAdsResult } from 'models/AdsResult'
import CloseIcon from '@mui/icons-material/Close'
import { TableStateMyRequests } from 'models/TableStates'
import UserSettingsStore from 'store/UserSettings'
import SettingsNotification from 'components/contents/common/SettingsNotification'
import {
  createUPNHash,
  exportColumns,
  generateShareMail,
  parseRequestsToExport,
  validateRequestForChangesOrExpired
} from 'utils/admin/selfServiceUtils'
import {
  INotificatonRequest,
  getNotificationRequest
} from 'models/NotificationTypes'
import MyRequestTableToolbar from './MyRequestTableToolbar'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import { findDefaultFilterComponent } from 'utils/admin/adminFormUtils'
import { ExportCsv } from '@material-table/exporters'
import { SettingMenuTypes } from 'models/SettingMenuTypes'
import { useIntl } from 'react-intl'
import { initialDidYouMean } from 'models/DidYouMean'

export interface MyRequestsLocalProps {
  setShowSettings: (settingsType: null | SettingMenuTypes) => void
}

type MyRequestsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  MyRequestsLocalProps

function MyRequests(props: MyRequestsProps): JSX.Element {
  const {
    fetchMyRequestsResults,
    hasMyRequestsBeenFetched,
    myRequests,
    deleteMyRequest,
    upsertMyRequest,
    userSettings,
    hasUserSettingsBeenFetched,
    setShowSettings
  } = props

  const classes = getStylesRequests()
  const [isLoading, setIsLoading] = useState(true)

  const [state, setState] = useState<TableStateMyRequests>({
    data: []
  })

  const [currentRow, setCurrentRow] = useState<ISelfServiceRequest | null>(null)
  const [formOpen, setFormOpen] = useState(false)
  const [createNewItem, setCreateNewItem] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [showExpiredNotification, setShowExpiredNotification] = useState(false)
  const expiredNotificationOnceShown = useRef(false)
  const intl = useIntl()

  const loadData = () => {
    fetchMyRequestsResults()
  }

  useEffect(() => {
    if (hasMyRequestsBeenFetched) {
      setIsLoading(false)

      const tableData = generateMyData()

      if (
        tableColumns.current &&
        !tableColumns.current.some((c) => c.field === 'createdBy') &&
        tableData.some(
          (item: ISelfServiceRequest) =>
            item.upn !== ESSettingsGlobalVariables.getUPN()
        )
      ) {
        tableColumns.current.push({
          title: intl.formatMessage({
            id: 'table_column_createdby',
            defaultMessage: 'Created By'
          }),
          field: 'createdBy',
          type: 'string',
          cellStyle: {
            maxWidth: 200
          },
          headerStyle: {
            maxWidth: 200
          },
          filtering: true,
          filterComponent: findDefaultFilterComponent
        })
      }

      setState({
        data: tableData
      })

      if (window.location.hash && window.location.hash.indexOf('?') !== -1) {
        const urlParams = new URLSearchParams(
          window.location.hash.split('?')[1]
        )

        const id = urlParams.get('id')
        const upn = urlParams.get('upn')

        if (id && upn) {
          const item = tableData.find(
            (item: ISelfServiceRequest) =>
              item.id === id && upn === createUPNHash(item.upn)
          )

          if (item) {
            handleFormOpen(item)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMyRequestsBeenFetched])

  useEffect(() => {
    if (hasMyRequestsBeenFetched) {
      setState({
        data: generateMyData()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserSettingsBeenFetched])

  const generateMyData = () => {
    // don't generate notifications in case they are not enabled in the usersettings
    if (!hasUserSettingsBeenFetched || !userSettings.EnableNotifications) {
      return myRequests.map((request: ISelfServiceRequest) => {
        delete request.hasChanged
        delete request.expiresSoon
        delete request.hasCommentChanges
        return request
      })
    }

    let expiredRequests = false
    const result = myRequests.map((request: ISelfServiceRequest) => {
      validateRequestForChangesOrExpired(
        request,
        hasUserSettingsBeenFetched,
        userSettings
      )

      if (request.expiresSoon) expiredRequests = true

      return request
    })

    if (expiredRequests) {
      setShowExpiredNotification(true)
    }

    return result
  }

  useEffect(() => {
    setIsLoading(true)
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRowAdd = (requestType: RequestType) => {
    setCreateNewItem(true)

    const newRequest: ISelfServiceRequest = Object.assign(
      {},
      defaultSelfServiceRequest
    )
    newRequest.requestType = requestType

    if (requestType === RequestType.SpellingSuggestion) {
      newRequest.itemData = Object.assign({}, initialDidYouMean)
    } else if (requestType === RequestType.FeaturedResult) {
      newRequest.itemData = Object.assign({}, intialFeaturedResult)
    } else {
      newRequest.itemData = [Object.assign({}, intialAdsResult)]
    }

    handleFormOpen({
      ...newRequest,
      id: 'new'
    })
  }

  const handleRowUpdate = (newData: ISelfServiceRequest) => {
    setState((prevState: any) => {
      const data: ISelfServiceRequest[] = [...prevState.data]
      newData.hasChanged = false
      let foundIndex = -1
      data.forEach((requestItem: ISelfServiceRequest, index: number) => {
        if (requestItem.id === newData.id && requestItem.upn === newData.upn) {
          foundIndex = index
          return false
        }
        return true
      })

      if (foundIndex === -1) {
        data.unshift(newData)
      } else {
        data[foundIndex] = newData
      }

      return { ...prevState, data }
    })

    setCurrentRow(null)
    setFormOpen(false)
    setCreateNewItem(false)

    if (window.location.hash && window.location.hash.indexOf('?') !== -1) {
      window.history.pushState(
        {},
        document.title,
        window.location.hash.split('?')[0]
      )
    }
  }

  const handleRowRemoveChangeNotification = (newData: ISelfServiceRequest) => {
    if (!newData.hasChanged) return

    setState((prevState: any) => {
      const data: ISelfServiceRequest[] = [...prevState.data]
      newData.hasChanged = false
      let foundIndex = -1
      data.forEach((requestItem: ISelfServiceRequest, index: number) => {
        if (requestItem.id === newData.id && requestItem.upn === newData.upn) {
          foundIndex = index
          return false
        }
        return true
      })

      if (foundIndex === -1) {
        data.unshift(newData)
      } else {
        data[foundIndex] = newData
      }

      return { ...prevState, data }
    })
  }

  const handleRowRemoveCommentChangeNotification = (
    newData: ISelfServiceRequest
  ) => {
    if (!newData.hasCommentChanges) return

    setState((prevState: any) => {
      const data: ISelfServiceRequest[] = [...prevState.data]
      newData.hasCommentChanges = false
      let foundIndex = -1
      data.forEach((requestItem: ISelfServiceRequest, index: number) => {
        if (requestItem.id === newData.id && requestItem.upn === newData.upn) {
          foundIndex = index
          return false
        }
        return true
      })

      if (foundIndex === -1) {
        data.unshift(newData)
      } else {
        data[foundIndex] = newData
      }

      return { ...prevState, data }
    })
  }

  const handleRowDelete = (deleteItem: ISelfServiceRequest) => {
    return new Promise<boolean>(async (resolve) => {
      if (!deleteItem.oncePublished) {
        const deletionResponse: ISelfServiceUpdateResponse =
          await deleteMyRequest(deleteItem)

        if (!deletionResponse.hasError) {
          setState((prevState: any) => {
            const data: ISelfServiceRequest[] = [...prevState.data]

            let foundIndex = -1
            data.forEach((requestItem: ISelfServiceRequest, index: number) => {
              if (
                requestItem.id === deleteItem.id &&
                requestItem.upn === deleteItem.upn
              ) {
                foundIndex = index
                return false
              }
              return true
            })

            if (foundIndex !== -1) {
              data.splice(foundIndex, 1)
            }
            return { ...prevState, data }
          })

          setSnackbarMessage(
            intl.formatMessage({
              id: 'table_snackbar_delete_success',
              defaultMessage: 'Successfully deleted the request.'
            })
          )
          toggleSnackbarVisibility(true)
          resolve(true)
        } else {
          setSnackbarMessage(
            intl.formatMessage({
              id: 'table_snackbar_delete_error',
              defaultMessage: 'There was an error deleting the request.'
            })
          )
          toggleSnackbarVisibility(true)
          resolve(false)
        }
      } else {
        const requestItemToUpdate = Object.assign({}, deleteItem)
        requestItemToUpdate.status = RequestStatus.ToBeDeleted
        const upsertResponse: ISelfServiceUpdateResponse =
          await upsertMyRequest(
            requestItemToUpdate,
            getNotificationRequest(
              requestItemToUpdate,
              RequestStatus.ToBeDeleted
            )
          )

        if (!upsertResponse.hasError) {
          setState((prevState: any) => {
            const data: ISelfServiceRequest[] = [...prevState.data]

            let foundIndex = -1
            data.forEach((requestItem: ISelfServiceRequest, index: number) => {
              if (
                requestItem.id === deleteItem.id &&
                requestItem.upn === deleteItem.upn
              ) {
                foundIndex = index
                return false
              }
              return true
            })

            if (foundIndex !== -1) {
              data.splice(foundIndex, 1)
            }

            return { ...prevState, data }
          })

          setSnackbarMessage(
            intl.formatMessage({
              id: 'table_snackbar_delete_success',
              defaultMessage: 'Successfully deleted the request.'
            })
          )
          toggleSnackbarVisibility(true)
          resolve(true)
        } else {
          if (upsertResponse.status === 412) {
            intl.formatMessage({
              id: 'form_message_save_conflict',
              defaultMessage:
                'Save Conflict: Your changes conflict with those made concurrently by another user. If you want your changes to be applied, refresh the page and resubmit your changes.'
            })
            toggleSnackbarVisibility(true)
            resolve(false)
          } else {
            setSnackbarMessage(
              intl.formatMessage({
                id: 'table_snackbar_delete_error',
                defaultMessage: 'There was an error deleting the request.'
              })
            )
            toggleSnackbarVisibility(true)
            resolve(false)
          }
        }
      }
    })
  }

  const handleFormOpen = (row: ISelfServiceRequest) => {
    setCurrentRow(row)
    setFormOpen(true)
  }

  const handleFormClose = () => {
    setCurrentRow(null)
    setCreateNewItem(false)
    setFormOpen(false)

    if (window.location.hash && window.location.hash.indexOf('?') !== -1) {
      window.history.pushState(
        {},
        document.title,
        window.location.hash.split('?')[0]
      )
    }
  }

  const handleSnackbarClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    toggleSnackbarVisibility(false)
  }

  const tableColumns = useRef(
    myRequestTableColumns(
      handleRowRemoveChangeNotification,
      handleRowRemoveCommentChangeNotification,
      handleRowUpdate,
      toggleSnackbarVisibility,
      setSnackbarMessage,
      intl
    )
  )

  const handleSharing = (rowData: ISelfServiceRequest): void => {
    const mailInfo = generateShareMail(rowData)
    window.location.href = `mailto:?body=${mailInfo.body}&subject=${mailInfo.subject}`
  }

  const MaterialTableMemo = React.useMemo((): JSX.Element => {
    const changeCount = state.data.filter(
      (request: ISelfServiceRequest) => request.hasChanged
    ).length

    return (
      <MaterialTable
        title={
          <div style={{ position: 'relative', paddingRight: '50px' }}>
            <h2>
              {intl.formatMessage({
                id: 'my_requests',
                defaultMessage: 'My Requests'
              })}
            </h2>
            {changeCount > 0 && userSettings.EnableNotifications && (
              <Tooltip
                title={intl
                  .formatMessage({
                    id: 'table_change_count_tooltip',
                    defaultMessage: '{changeCount} change(s) since last visit'
                  })
                  .replace('{changeCount}', changeCount.toString())}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: -1,
                    right: 20,
                    backgroundColor: '#bc204b',
                    color: '#fff',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    lineHeight: '20px',
                    fontSize: '13px'
                  }}
                >
                  {changeCount}
                </div>
              </Tooltip>
            )}
          </div>
        }
        icons={tableIcons}
        columns={tableColumns.current}
        data={state.data}
        options={{
          rowStyle: (data: ISelfServiceRequest) => ({
            fontSize: 12
          }),
          addRowPosition: 'first',
          pageSize: 25,
          pageSizeOptions: [25, 50, 75],
          tableLayout: 'auto',
          filtering: true,
          exportAllData: true,
          emptyRowsWhenPaging: false,
          exportMenu: [
            {
              label: intl.formatMessage({
                id: 'table_actions_export',
                defaultMessage: 'Export CSV'
              }),
              exportFunc: (columns, data) => {
                ExportCsv<IExportItem>(
                  exportColumns,
                  parseRequestsToExport(
                    state.data.filter((r: ISelfServiceRequest) =>
                      data.some(
                        (item: ISelfServiceRequest) =>
                          r.id === item.id && r.upn === item.upn
                      )
                    )
                  ),
                  'MyRequests',
                  ';'
                )
              }
            }
          ]
        }}
        localization={{
          body: {
            editRow: {
              deleteText: intl.formatMessage({
                id: 'table_actions_delete_row_confirm',
                defaultMessage: 'Are you sure you want to delete this row?'
              })
            },
            deleteTooltip: intl.formatMessage({
              id: 'table_delete_tooltip',
              defaultMessage: 'Delete'
            })
          },
          header: {
            actions: intl.formatMessage({
              id: 'table_action_label',
              defaultMessage: 'Actions'
            })
          },
          toolbar: {
            exportTitle: intl.formatMessage({
              id: 'table_export_tooltip',
              defaultMessage: 'Export'
            }),
            searchPlaceholder: intl.formatMessage({
              id: 'table_search_placeholder',
              defaultMessage: 'Search'
            })
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage({
              id: 'table_pagination_labelDisplayedRows',
              defaultMessage: '{from}-{to} of {count}'
            }),
            labelRows: intl.formatMessage({
              id: 'table_pagination_labelRows',
              defaultMessage: 'rows'
            }),
            labelRowsPerPage: intl.formatMessage({
              id: 'table_pagination_labelRowsPerPage',
              defaultMessage: 'Rows per page:'
            }),
            firstTooltip: intl.formatMessage({
              id: 'table_pagination_firstTooltip',
              defaultMessage: 'First Page'
            }),
            previousTooltip: intl.formatMessage({
              id: 'table_pagination_previousTooltip',
              defaultMessage: 'Previous Page'
            }),
            nextTooltip: intl.formatMessage({
              id: 'table_pagination_nextTooltip',
              defaultMessage: 'Next Page'
            }),
            lastTooltip: intl.formatMessage({
              id: 'table_pagination_lastTooltip',
              defaultMessage: 'Last Page'
            })
          }
        }}
        editable={{
          onRowDelete: handleRowDelete
        }}
        actions={[
          {
            icon: Edit,
            onClick: (
              event: any,
              rowData: ISelfServiceRequest | ISelfServiceRequest[]
            ) => {
              if (!(rowData instanceof Array)) {
                handleFormOpen(rowData)
              }
            },
            tooltip: intl.formatMessage({
              id: 'table_actions_view_request',
              defaultMessage: 'View Request'
            })
          },
          {
            icon: Share,
            onClick: (
              event: any,
              rowData: ISelfServiceRequest | ISelfServiceRequest[]
            ) => {
              if (!(rowData instanceof Array)) {
                handleSharing(rowData)
              }
            },
            tooltip: intl.formatMessage({
              id: 'table_actions_share_request',
              defaultMessage: 'Share'
            }),
            position: 'row'
          }
        ]}
        components={{
          Container: (props: any) => (
            <div className={classes.materialUiTable} {...props} />
          ),
          Toolbar: (props: any) => (
            <MyRequestTableToolbar
              toolbarprops={props}
              handleRowAdd={handleRowAdd}
              setShowSettings={setShowSettings}
            />
          )
        }}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns.current, state.data])

  return (
    <>
      {state && state.data && (
        <div className={`${classes.container} ${classes.scrollfix}`}>
          {!isLoading ? (
            MaterialTableMemo
          ) : (
            <div className={classes.loadingSpinnerContainer}>
              <CircularProgress className={classes.loadingSpinner} size={50} />
            </div>
          )}
        </div>
      )}
      {formOpen && currentRow && (
        <EditFormRequestBase
          rowData={currentRow}
          isNewItem={createNewItem}
          isOpen={formOpen}
          handleClose={handleFormClose}
          handleRowUpdate={handleRowUpdate}
          toggleSnackbarVisibility={toggleSnackbarVisibility}
          setSnackbarMessage={setSnackbarMessage}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isSnackbarVisible}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              handleSnackbarClose()
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {showExpiredNotification && !expiredNotificationOnceShown.current && (
        <SettingsNotification
          open={showExpiredNotification}
          onClose={() => {
            setShowExpiredNotification(false)
            expiredNotificationOnceShown.current = true
          }}
          translationKey={'snackbar_expiredItems_errormessage'}
          defaultMessage={'There are request items that expire soon.'}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    myRequests: SelfServiceStore.selectors.getMyRequests(state),
    hasMyRequestsBeenFetched:
      SelfServiceStore.selectors.hasMyRequestsBeenFetched(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    hasUserSettingsBeenFetched:
      UserSettingsStore.selectors.hasUserSettingsBeenFetched(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchMyRequestsResults: () =>
      dispatch(SelfServiceStore.actions.fetchMyRequestsResults()),
    deleteMyRequest: (request: ISelfServiceRequest) =>
      dispatch(SelfServiceStore.actions.deleteMyRequest(request)),
    upsertMyRequest: (
      request: ISelfServiceRequest,
      notificationRequest: INotificatonRequest
    ) =>
      dispatch(
        SelfServiceStore.actions.upsertMyRequest(request, notificationRequest)
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRequests)
