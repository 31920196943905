import { Constants } from '@azure/msal-common'

export type keyType = string | any[] | null
type keyFunction = () => keyType
export type keyInterface = keyFunction | keyType

export interface CacheInterface {
  get(key: keyInterface): any
  set(key: keyInterface, value: any): any
  keys?(): string[]
  has(key: keyInterface): boolean
  delete(key: keyInterface): void
  clear(): void
  serializeKey(key: keyInterface): [string, any, string]
}

// Use WeakMap to store the object->key mapping so the objects can be garbage collected.
// WeakMap uses a hashtable under the hood, so the lookup complexity is almost O(1).
const table = new WeakMap()

// Counter of the key
let counter = 0

// Hashes an array of objects and returns a string
export function hash(args: any[]): string {
  if (!args.length) return ''
  let key = 'arg'
  for (let i = 0; i < args.length; ++i) {
    let _hash
    if (args[i] === null || typeof args[i] !== 'object') {
      // need to consider the case that args[i] is a string:
      // args[i]        _hash
      // "undefined" -> '"undefined"'
      // undefined   -> 'undefined'
      // 123         -> '123'
      // null        -> 'null'
      // "null"      -> '"null"'
      if (typeof args[i] === 'string') {
        _hash = '"' + args[i] + '"'
      } else {
        _hash = String(args[i])
      }
    } else {
      if (!table.has(args[i])) {
        _hash = counter
        table.set(args[i], counter++)
      } else {
        _hash = table.get(args[i])
      }
    }
    key += '@' + _hash
  }
  return key
}

export class SessionCache implements CacheInterface {
  get(key: keyInterface): any {
    const [_key] = this.serializeKey(key)
    const data = sessionStorage.getItem(_key)
    if (data) {
      return JSON.parse(data)
    }
    return null
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: keyInterface, value: any): boolean {
    // each browser and environment have a different max size of the session storage,
    // depending on browser, diskspace left, user settings.
    // In most browsers it is not possible to get the max or remaining size.
    // In case the session storage will reach the sessionstorage limit an exceed quota exception is thrown
    // So an error on setItem must be catched
    try {
      const [_key] = this.serializeKey(key)
      sessionStorage.setItem(_key, JSON.stringify(value))
    } catch {
      return false
    }

    return true
  }

  has(key: keyInterface): boolean {
    const [_key] = this.serializeKey(key)
    return Boolean(sessionStorage.getItem(_key))
  }

  msalKeys(): string[] {
    const keys: string[] = []
    Object.keys(sessionStorage).forEach(function (key) {
      if (key.startsWith(Constants.CACHE_PREFIX)) keys.push(key)
    })
    return keys
  }

  size(): number {
    let totalLength = 0,
      entryLength,
      entry
    for (entry in sessionStorage) {
      if (!sessionStorage.hasOwnProperty(entry)) {
        continue
      }
      entryLength = (sessionStorage[entry].length + entry.length) * 2
      totalLength += entryLength
    }
    return totalLength / 1024
  }

  clear(): any {
    sessionStorage.clear()
  }

  delete(key: keyInterface): any {
    const [_key] = this.serializeKey(key)
    sessionStorage.removeItem(_key)
  }

  // TODO: introduce namespace for the cache
  serializeKey(key: keyInterface): [string, any, string] {
    let args = null
    if (typeof key === 'function') {
      try {
        key = key()
      } catch (err) {
        // dependencies not` ready
        key = null
      }
    }

    if (Array.isArray(key)) {
      // Args array
      args = key
      key = hash(key)
    } else {
      // Convert null to ''
      key = String(key || '')
    }

    const errorKey = key ? 'err@' + key : ''

    return [key, args, errorKey]
  }
}

const sessionCache = new SessionCache()

export default sessionCache
