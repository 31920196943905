import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Store } from '..'
import * as Config from '../../config'
import AuthStore from '../Auth'
import { trackEvents, trackException } from 'utils/tracking'
import { DidYouMeanItem } from 'models/DidYouMean'
import { IFindResponse } from 'utils/api'
import { renewAuthorizationToken } from 'authentication/token'

export enum DidYouMeanActionTypes {
  FETCH_DID_YOU_MEAN_ALL_REQUEST = 'didYouMean/FETCH_DID_YOU_MEAN_ALL_REQUEST',
  FETCH_DID_YOU_MEAN_ALL_SUCCESS = 'didYouMean/FETCH_DID_YOU_MEAN_ALL_SUCCESS',
  FETCH_DID_YOU_MEAN_ALL_FAILURE = 'didYouMean/FETCH_DID_YOU_MEAN_ALL_FAILURE',
  UPSERT_DID_YOU_MEAN_SUCCESS = 'didYouMean/UPSERT_DID_YOU_MEAN_SUCCESS',
  CHANGE_DID_YOU_MEAN_FAILURE = 'didYouMean/CHANGE_DID_YOU_MEAN_FAILURE',
  DELETE_DID_YOU_MEAN_SUCCESS = 'didYouMean/DELETE_DID_YOU_MEAN_SUCCESS'
}

export type IFetchDidYouMeanAllRequest = Action<DidYouMeanActionTypes>
export const fetchDidYouMeanAllRequest = (): IFetchDidYouMeanAllRequest => ({
  type: DidYouMeanActionTypes.FETCH_DID_YOU_MEAN_ALL_REQUEST
})

export type IFetchDidYouMeanAllFailure = Action<DidYouMeanActionTypes>
export const fetchDidYouMeanAllFailure = (): IFetchDidYouMeanAllFailure => ({
  type: DidYouMeanActionTypes.FETCH_DID_YOU_MEAN_ALL_FAILURE
})

export interface IFetchDidYouMeanAllSuccess
  extends Action<DidYouMeanActionTypes> {
  payload: {
    didYouMeanResponse: DidYouMeanItem[]
  }
}
export const fetchDidYouMeanAllSuccess = (
  didYouMeanResponse: DidYouMeanItem[]
): IFetchDidYouMeanAllSuccess => ({
  type: DidYouMeanActionTypes.FETCH_DID_YOU_MEAN_ALL_SUCCESS,
  payload: { didYouMeanResponse }
})

export interface IUpsertDidYouMeanSuccess
  extends Action<DidYouMeanActionTypes> {
  payload: {
    upsertedDidYouMean: DidYouMeanItem
  }
}
export const upsertDidYouMeanSuccess = (
  upsertedDidYouMean: DidYouMeanItem
): IUpsertDidYouMeanSuccess => ({
  type: DidYouMeanActionTypes.UPSERT_DID_YOU_MEAN_SUCCESS,
  payload: { upsertedDidYouMean }
})

export type IChangeUpsertDidYouMeanFailure = Action<DidYouMeanActionTypes>
export const changeDidYouMeanFailure = (): IChangeUpsertDidYouMeanFailure => ({
  type: DidYouMeanActionTypes.CHANGE_DID_YOU_MEAN_FAILURE
})

export interface IDeleteDidYouMeanSuccess
  extends Action<DidYouMeanActionTypes> {
  payload: {
    didYouMeanId: string
  }
}
export const deleteDidYouMeanSuccess = (
  didYouMeanId: string
): IDeleteDidYouMeanSuccess => ({
  type: DidYouMeanActionTypes.DELETE_DID_YOU_MEAN_SUCCESS,
  payload: { didYouMeanId }
})

export const fetchAllDidYouMean = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      trackEvents('getDidYouMean', {})

      dispatch(fetchDidYouMeanAllRequest())

      const apiUrl = `${Config.APIM_BASE_URL}autosuggestapi/getdidyoumeanitems`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (data.status !== 200 && data.status !== 201) {
        dispatch(fetchDidYouMeanAllFailure())
        trackException(
          'Error in fetching did you mean items',
          new Error('Error in data object after fetching did you mean items')
        )
        return Promise.resolve()
      }

      let results: IFindResponse
      results = {
        hasError: false,
        responseJSON: await data.json()
      }

      if (!results || !results.responseJSON.Documents) {
        dispatch(fetchDidYouMeanAllFailure())
        trackException(
          'Error in getting documents out of did you mean response',
          new Error('Error in data object after fetching did you mean items')
        )
        return Promise.resolve()
      }

      const result: DidYouMeanItem[] = []
      results.responseJSON.Documents.forEach((item: DidYouMeanItem) => {
        result.push(item)
      })

      result.sort((a, b) => {
        if (a.term < b.term) {
          return -1
        }
        if (a.term > b.term) {
          return 1
        }
        return 0
      })

      dispatch(fetchDidYouMeanAllSuccess(result))
    } catch (error) {
      dispatch(fetchDidYouMeanAllFailure())
      trackException('Error in fetching did you mean items', error)
    }
  }
}

export const upsertDidYouMean = (
  didYouMeanItem: DidYouMeanItem
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      const dymItem = JSON.parse(JSON.stringify(didYouMeanItem))
      if (dymItem.tableData) {
        delete dymItem.tableData
      }
      if (dymItem.draft) {
        delete dymItem.draft
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}autosuggestapi/upsertdidyoumeanitem?docId=${dymItem.id}`

      const utf8Bytes = encodeURIComponent(JSON.stringify(dymItem)).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode(Number('0x' + p1))
        }
      )

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body: '{"content":"' + btoa(utf8Bytes) + '"}'
      })

      if (data.status !== 200 && data.status !== 201) {
        trackException(
          'Error in upsert did you mean item',
          new Error('Error in data object after upserting did you mean item')
        )
        dispatch(changeDidYouMeanFailure())
        return
      }

      dispatch(upsertDidYouMeanSuccess(dymItem))
    } catch (error) {
      trackException('Error in upsert did you mean action', error)
      dispatch(changeDidYouMeanFailure())
    }
  }
}

export const deleteDidYouMean = (
  didYouMeanId: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      const apiUrl = `${Config.APIM_BASE_URL}autosuggestapi/deletedidyoumeanitem?docId=${didYouMeanId}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 204) {
        trackException(
          'Error in delete did you mean item',
          new Error('Error in data object after deleting did you mean item')
        )
        dispatch(changeDidYouMeanFailure())
        return
      }

      dispatch(deleteDidYouMeanSuccess(didYouMeanId))
    } catch (error) {
      trackException('Error in delete did you mean item action', error)
      dispatch(changeDidYouMeanFailure())
    }
  }
}

export type DidYouMeanActions =
  | IFetchDidYouMeanAllSuccess
  | IFetchDidYouMeanAllSuccess
  | IFetchDidYouMeanAllFailure
  | IUpsertDidYouMeanSuccess
  | IDeleteDidYouMeanSuccess
