import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesAdBulkUpload(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    spaceBottom: {
      paddingBottom: '1em'
    },
    tableStyle: {
      borderSpacing: 0,
      '& td, th': {
        borderLeft: '1px solid #aaa',
        borderTop: '1px solid #aaa',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '200px'
      }
    },
    tableActions: {
      paddingTop: '1em'
    },
    tableActionsSelected: {
      paddingInlineStart: '1em'
    }
  }))
}
