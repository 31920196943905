import React, { useState, useEffect } from 'react'
import { Snackbar, IconButton, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/lab/Alert'
import { useIntl } from 'react-intl'
import { getStylesSettingsNotification } from 'styles/contents/common/SettingsNotification'

export interface SettingsNotificationProps {
  open: boolean | undefined
  onClose?: () => void
  translationKey?: string
  defaultMessage: string
}

export default function SettingsNotification(
  props: SettingsNotificationProps
): JSX.Element {
  const { open, onClose, translationKey, defaultMessage } = props

  const [showFindNotification, setFindNotification] = useState(false)
  const [message, setMessage] = useState('')

  const classes = getStylesSettingsNotification()
  const intl = useIntl()

  useEffect(() => {
    if (open) {
      setFindNotification(true)

      if (translationKey) {
        setMessage(
          intl.formatMessage({
            id: translationKey,
            defaultMessage: defaultMessage
          })
        )
      } else {
        setMessage(defaultMessage)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onCloseFindNotification = () => {
    setFindNotification(false)
    if (onClose) onClose()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={showFindNotification}
      autoHideDuration={10000}
      onClose={onCloseFindNotification}
    >
      <Alert severity="warning" className={classes.alertbox}>
        <Box className={classes.alertboxContainer}>
          <Box className={classes.alertboxMessage}>{message}</Box>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onCloseFindNotification}
            className={classes.alertboxButton}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Alert>
    </Snackbar>
  )
}
