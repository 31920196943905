import React, { useEffect, useState } from 'react'
import {
  AdminActionType,
  IItemMetrics,
  ISelfServiceRequest,
  ISelfServiceUpdateResponse,
  RequestComment,
  RequestStatus,
  RequestType
} from 'models/SelfServiceRequest'
import { Button, CircularProgress, TextField } from '@mui/material'
import { getStylesCommentArea } from 'styles/requests/commentArea'
import { dateFormatOptions } from 'constants/constants'
import createDOMPurify from 'dompurify'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import { connect } from 'react-redux'
import SelfServiceStore from 'store/SelfService'
import { INotificatonRequest, NotificationType } from 'models/NotificationTypes'
import { updateComments } from 'utils/admin/selfServiceUtils'
import { useIntl } from 'react-intl'
import { getItemMetrics } from 'apiclient/getItemMetrics'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import { FeaturedResult } from 'models/FeaturedResult'
import { AdsResult } from 'models/AdsResult'

export interface ICommentProps {
  item: ISelfServiceRequest
  isAdminRequest: boolean
  handleRowUpdate: (request: ISelfServiceRequest) => void
  toggleSnackbarVisibility: (visible: boolean) => void
  setSnackbarMessage: (message: string) => void
  itemMetrics: IItemMetrics | null
  setItemMetrics: (value: IItemMetrics | null) => void
}

type IAllCommentProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  ICommentProps

function CommentCallOut(props: IAllCommentProps): JSX.Element {
  const {
    item,
    isAdminRequest,
    upsertAdminRequest,
    upsertMyRequest,
    handleRowUpdate,
    setSnackbarMessage,
    toggleSnackbarVisibility,
    AADInfo,
    itemMetrics,
    setItemMetrics
  } = props

  const classes = getStylesCommentArea()
  const DOMPurify = createDOMPurify(window)
  const intl = useIntl()

  const [commentsToShow, setCommentToShow] = useState<RequestComment[]>(
    item.comments ? item.comments : []
  )
  const [textInputValue, setInputValue] = useState<string>('')

  const [internalComment, setInternalComment] = useState<RequestComment>({
    id: 1,
    text: '',
    modifiedDate: new Date().toISOString(),
    modifiedBy: ESSettingsGlobalVariables.getUPN(),
    modifiedByDisplayName: ESSettingsGlobalVariables.getDisplayName()
  })

  useEffect(() => {
    const maxId =
      item.comments && item.comments.length > 0
        ? item.comments.reduce(
            (max, comment) => (comment.id > max ? comment.id : max),
            item.comments[0].id
          )
        : 0

    setInternalComment({
      id: maxId + 1,
      text: '',
      modifiedDate: new Date().toISOString(),
      modifiedBy: ESSettingsGlobalVariables.getUPN(),
      modifiedByDisplayName: ESSettingsGlobalVariables.getDisplayName()
    })

    //fetch metrics on open callout
    fetchItemMetrics()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCommentSubmit = () => {
    if (isAdminRequest && internalComment.text && handleRowUpdate) {
      const copyItem = Object.assign({}, item)
      updateComments(copyItem, internalComment)
      upsertAdminRequest(
        copyItem,
        AdminActionType.Comment,
        internalComment
      ).then((upsertResponse: ISelfServiceUpdateResponse) => {
        if (!upsertResponse.hasError) {
          if (upsertResponse.etag) {
            copyItem._etag = upsertResponse.etag
          }

          updateInternalComment()
          handleRowUpdate(copyItem)
        } else {
          if (upsertResponse.status === 412) {
            setSnackbarMessage(
              'Save Conflict: Your changes conflict with those made concurrently by another user. If you want your changes to be applied, refresh the page and resubmit your changes.'
            )
            toggleSnackbarVisibility(true)
          } else {
            setSnackbarMessage('There was an error submitting the new comment.')
            toggleSnackbarVisibility(true)
          }
        }
      })
    } else if (internalComment.text && handleRowUpdate) {
      const copyItem = Object.assign({}, item)
      updateComments(copyItem, internalComment)
      upsertMyRequest(
        copyItem,
        item.status === RequestStatus.Draft
          ? {
              notificationType: null,
              sendNotification: false,
              latestComment: null
            }
          : {
              notificationType: NotificationType.NewComment,
              sendNotification: true,
              latestComment: internalComment.text
            }
      ).then((upsertResponse: ISelfServiceUpdateResponse) => {
        if (!upsertResponse.hasError) {
          if (upsertResponse.etag) {
            copyItem._etag = upsertResponse.etag
          }

          updateInternalComment()
          handleRowUpdate(copyItem)
        } else {
          if (upsertResponse.status === 412) {
            setSnackbarMessage(
              intl.formatMessage({
                id: 'form_message_save_conflict',
                defaultMessage:
                  'Save Conflict: Your changes conflict with those made concurrently by another user. If you want your changes to be applied, refresh the page and resubmit your changes.'
              })
            )
            toggleSnackbarVisibility(true)
          } else {
            setSnackbarMessage(
              intl.formatMessage({
                id: 'table_comment_area_save_error',
                defaultMessage: 'There was an error submitting the new comment.'
              })
            )
            toggleSnackbarVisibility(true)
          }
        }
      })
    }
  }

  const updateInternalComment = () => {
    const newComments = commentsToShow
    newComments.unshift(internalComment)
    setCommentToShow(newComments)
    setInternalComment({
      id: internalComment.id + 1,
      text: '',
      modifiedDate: new Date().toISOString(),
      modifiedBy: ESSettingsGlobalVariables.getUPN(),
      modifiedByDisplayName: ESSettingsGlobalVariables.getDisplayName()
    })
    setInputValue('')
  }

  const displayMetrics =
    item.status === RequestStatus.Published &&
    (item.requestType === RequestType.AdWord ||
      item.requestType === RequestType.FeaturedResult)

  const fetchItemMetrics = () => {
    //only ads & featured, Published
    if (!displayMetrics) return

    //skip refetch (already loaded)
    if (itemMetrics !== null) return

    //get title & type
    let titles: string[] = []
    let type: 'adwords' | 'featuredresults' = 'adwords'
    if (item.requestType === RequestType.AdWord) {
      type = 'adwords'

      const adList = item.itemData as AdsResult[]

      for (let index = 0; index < adList.length; index++) {
        const element = adList[index]
        if (!titles.includes(element.title)) titles.push(element.title)
      }
    } else if (item.requestType === RequestType.FeaturedResult) {
      type = 'featuredresults'
      titles.push((item.itemData as FeaturedResult).BestBetTitle)
    }

    if (titles && titles.length > 0) {
      setItemMetrics({
        TimesShown: 0,
        TimesClicked: 0,
        CTR: 0,
        Loaded: true,
        Fetching: true
      })
      getItemMetrics(AADInfo, type, titles).then((result: IItemMetrics) => {
        setItemMetrics(result)
      })
    }
  }

  return (
    <div className={classes.commentArea}>
      {displayMetrics ? (
        <div className={classes.commentContainer}>
          <div className={classes.commentTitle}>Metrics</div>

          {itemMetrics && itemMetrics.Fetching ? (
            <div className={classes.metricsWrapper}>
              <CircularProgress className={classes.loadingSpinner} size={21} />
            </div>
          ) : null}

          {itemMetrics && itemMetrics.Loaded && !itemMetrics.Fetching ? (
            <div className={classes.metricsWrapper}>
              <div>
                Shown <span>{itemMetrics?.TimesShown}</span> times
              </div>
              <div>
                Clicked <span>{itemMetrics?.TimesClicked}</span> times
              </div>
              <div>
                CTR <span>{itemMetrics?.CTR} %</span>
              </div>
            </div>
          ) : null}

          {itemMetrics && !itemMetrics.Loaded ? (
            <div className={classes.metricsWrapper}>
              Sorry, something went wrong.
            </div>
          ) : null}
        </div>
      ) : null}

      <div className={classes.commentContainer}>
        <div className={classes.commentTitle}>
          {intl.formatMessage({
            id: 'table_comment_area_title',
            defaultMessage: 'Latest Comments'
          })}
        </div>
        <div className={classes.newCommentArea}>
          <TextField
            variant="outlined"
            size="small"
            id="comments"
            className={classes.tab_item}
            label={intl.formatMessage({
              id: 'table_comment_area_label',
              defaultMessage: 'New Comment'
            })}
            value={textInputValue}
            onChange={(event) => {
              if (internalComment) {
                internalComment.text = DOMPurify.sanitize(event.target.value, {
                  USE_PROFILES: { html: false }
                })
                setInputValue(event.target.value)
              }
            }}
            onBlur={() => {
              setInputValue(
                DOMPurify.sanitize(textInputValue, {
                  USE_PROFILES: { html: false }
                })
              )
            }}
            InputLabelProps={{
              shrink: true
            }}
            maxRows={5}
            multiline={true}
          />
        </div>
        <div className={classes.newCommentSubmit}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCommentSubmit()}
            style={{ marginLeft: '10px' }}
          >
            {intl.formatMessage({
              id: 'table_comment_area_submit',
              defaultMessage: 'Submit'
            })}
          </Button>
        </div>
        <div className={classes.commentScrollableArea}>
          {commentsToShow.map((comment: RequestComment, index: number) => {
            return (
              <div className={classes.commentItemContainer} key={index}>
                <div className={classes.commentMetaData}>{`${
                  comment.modifiedByDisplayName
                } - ${new Intl.DateTimeFormat(
                  'en-US',
                  dateFormatOptions
                ).format(new Date(comment.modifiedDate))}`}</div>
                <div
                  className={classes.commentBody}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(comment.text).replace(
                      /\n/g,
                      '</br>'
                    )
                  }}
                ></div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    AADInfo: AuthStore.selectors.getAADInfo(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    upsertAdminRequest: (
      request: ISelfServiceRequest,
      adminActionType: AdminActionType,
      latestComment?: RequestComment
    ) =>
      dispatch(
        SelfServiceStore.actions.upsertAdminRequest(
          request,
          adminActionType,
          latestComment
        )
      ),
    upsertMyRequest: (
      request: ISelfServiceRequest,
      notificationRequest: INotificatonRequest
    ) =>
      dispatch(
        SelfServiceStore.actions.upsertMyRequest(request, notificationRequest)
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentCallOut)
