import { Button, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import {
  checkIsValidDidYouMeanItem,
  editFormErrorKeys
} from 'utils/admin/adminFormUtils'
import { getStylesRequestForm } from 'styles/requests/requestForm'
import createDOMPurify from 'dompurify'
import {
  ISelfServiceRequest,
  RequestComment,
  RequestStatus
} from 'models/SelfServiceRequest'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import DefaultFormField from 'components/contents/common/DefaultFormField'
import { FormattedMessage, useIntl } from 'react-intl'
import { updateComments } from 'utils/admin/selfServiceUtils'
import EditFormCommentDialog from './EditFormCommentDialog'
import { DidYouMeanItem } from 'models/DidYouMean'

export interface IEditFormSpellingSuggestionStepsProps {
  item: ISelfServiceRequest
  isNewItem: boolean
  saveItem: (
    newStatus?: RequestStatus,
    newComment?: RequestComment | null,
    orgItem?: DidYouMeanItem,
    isNewItem?: boolean
  ) => void
  hasActiveChanges: boolean
  setHasActiveChanges: (hasChanges: boolean) => void
  loading: boolean
}

export default function EditFormSpellingSuggestionSteps(
  props: IEditFormSpellingSuggestionStepsProps
): JSX.Element {
  const {
    item,
    isNewItem,
    saveItem,
    hasActiveChanges,
    setHasActiveChanges,
    loading
  } = props

  const rowData: DidYouMeanItem = item.itemData as DidYouMeanItem
  const orgRowData = useRef(Object.assign({}, rowData))

  const DOMPurify = createDOMPurify(window)
  const classes = getStylesRequestForm()
  const intl = useIntl()
  const topElement = useRef() as React.MutableRefObject<HTMLInputElement>

  const [errorState, setErrorState] = useState<IErrorListObject[]>([])

  // used to trigger rerendering of input fields that are using default values
  // so the shown values are sanitized onBlur
  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())

  const [showCommentDialog, setShowCommentDialog] = useState(false)
  const [blockSubmitOption, setBlockSubmitOption] = useState(true)

  useEffect(() => {
    if (isNewItem) {
      if (!rowData.id) {
        rowData.id = crypto.randomUUID()
      }
    } else {
      const isCompleteItemValid = validateCompleteItem()
      if (isCompleteItemValid) {
        setBlockSubmitOption(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Error Handling and Validation */
  const validateItem = (): boolean => {
    const hasChanges =
      JSON.stringify(rowData) !== JSON.stringify(orgRowData.current)
    setHasActiveChanges(hasChanges)

    let errorList: IErrorListObject[] = checkIsValidDidYouMeanItem(rowData)

    const isValid = !errorList.find(
      (error: IErrorListObject) => error.error.error
    )

    setErrorState(errorList)

    setBlockSubmitOption(!isValid)
    return isValid
  }

  const getError = (key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error && errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }

  const validateCompleteItem = (): boolean => {
    let errorList: IErrorListObject[] = checkIsValidDidYouMeanItem(rowData)

    const isValid = !errorList.find(
      (error: IErrorListObject) => error.error.error
    )
    return isValid
  }

  /** Handle submit */
  const handleSubmit = () => {
    const stepValid = validateItem()
    if (stepValid) {
      if (item.status !== RequestStatus.Clarification) {
        saveItem(RequestStatus.Submitted, null, orgRowData.current, isNewItem)
      } else {
        setShowCommentDialog(true)
      }
    }
  }

  const handleCommentClose = () => {
    setShowCommentDialog(false)
  }

  const handleCommentSubmit = (newComment: RequestComment | null) => {
    if (newComment) {
      updateComments(item, newComment)
    }

    saveItem(RequestStatus.Submitted, newComment, orgRowData.current, false)
  }

  return (
    <>
      <div ref={topElement} />

      <div className={classes.formContainer}>
        <div className={classes.leftFormArea}>
          <DefaultFormField
            label={intl.formatMessage({
              id: 'form_spellingsuggestion_headline',
              defaultMessage: 'Spelling suggestion term'
            })}
            required={true}
            description={intl.formatMessage({
              id: 'form_spellingsuggestion_headline_desc',
              defaultMessage: 'Spelling suggestion term'
            })}
            key={`term_${currentFormState}`}
            inputControl={
              <TextField
                variant="outlined"
                id="term-required"
                className={classes.form_field}
                size="small"
                defaultValue={rowData.term}
                onChange={(event) => {
                  rowData.term = event.target.value
                    ? DOMPurify.sanitize(event.target.value, {
                        USE_PROFILES: { html: false }
                      })
                    : event.target.value

                  validateItem()
                }}
                error={getError(editFormErrorKeys.original).error}
                //helperText={getError(editFormErrorKeys.original).helperText}
                InputLabelProps={{
                  shrink: true
                }}
                key={`term_${currentFormState}`}
                onBlur={() => {
                  setCurrentFormState(new Date().getTime())
                }}
              />
            }
            hasError={getError(editFormErrorKeys.original).error}
            errorText={getError(editFormErrorKeys.original).helperText}
          />
          <DefaultFormField
            label={intl.formatMessage({
              id: 'form_spellingsuggestion_description',
              defaultMessage: 'Spelling suggestion description'
            })}
            description={intl.formatMessage({
              id: 'form_spellingsuggestion_description_desc',
              defaultMessage: 'Spelling suggestion description'
            })}
            key={`description_${currentFormState}`}
            inputControl={
              <TextField
                variant="outlined"
                id="description"
                className={classes.form_field}
                size="small"
                defaultValue={rowData.description}
                onChange={(event) => {
                  rowData.description = event.target.value
                    ? DOMPurify.sanitize(event.target.value, {
                        USE_PROFILES: { html: false }
                      })
                    : event.target.value

                  validateItem()
                }}
                InputLabelProps={{
                  shrink: true
                }}
                key={`description_${currentFormState}`}
                onBlur={() => {
                  setCurrentFormState(new Date().getTime())
                }}
              />
            }
          />
        </div>

        <div className={classes.rightFormArea}>
          <div className={classes.rightFormTitle}>
            <FormattedMessage
              id="form_spellingsuggestion_step1_headline"
              defaultMessage="Request a Spelling Suggestion"
            />
          </div>
          <div className={classes.questionArea}>
            <div className={classes.questionTitle}>
              <FormattedMessage
                id="form_spellingsuggestion_step1_q1"
                defaultMessage="What is a Spelling Suggestion?"
              />
            </div>
            <div
              className={classes.questionAnswer}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  intl.formatMessage({
                    id: 'form_spellingsuggestion_step1_a1',
                    defaultMessage: 'A Spelling Suggestion is a...'
                  }),
                  {
                    USE_PROFILES: { html: true },
                    ADD_ATTR: ['target']
                  }
                )
              }}
            />
          </div>
          <div className={classes.questionArea}>
            <div className={classes.questionTitle}>
              <FormattedMessage
                id="form_spellingsuggestion_step1_q2"
                defaultMessage="When to request a Spelling Suggestion?"
              />
            </div>
            <div
              className={classes.questionAnswer}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  intl.formatMessage({
                    id: 'form_spellingsuggestion_step1_a2',
                    defaultMessage: 'Request a Spelling Suggestion when...'
                  }),
                  {
                    USE_PROFILES: { html: true },
                    ADD_ATTR: ['target']
                  }
                )
              }}
            />
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Tooltip
          title={
            item.status === RequestStatus.Declined
              ? intl.formatMessage({
                  id: 'form_spellingsuggestion_declined_tooltip',
                  defaultMessage:
                    'The requested spelling suggestion got declined, no changes can be submitted.'
                })
              : !hasActiveChanges && item.status !== RequestStatus.Clarification
              ? intl.formatMessage({
                  id: 'form_spellingsuggestion_nochange_tooltip',
                  defaultMessage:
                    "The spelling suggestion request didn't have any changes."
                })
              : ''
          }
          placement={'top'}
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={
                loading ||
                item.status === RequestStatus.Declined ||
                (!hasActiveChanges &&
                  item.status !== RequestStatus.Clarification &&
                  item.status !== RequestStatus.Draft) ||
                blockSubmitOption
              }
              style={{ marginLeft: '10px' }}
            >
              {item.status !== RequestStatus.Clarification ? (
                <FormattedMessage
                  id="form_button_submit"
                  defaultMessage="Submit Request"
                />
              ) : (
                <FormattedMessage
                  id="form_button_resolve_clarification"
                  defaultMessage="Resolve Clarification"
                />
              )}
            </Button>
          </span>
        </Tooltip>
      </div>
      {showCommentDialog && (
        <EditFormCommentDialog
          item={item}
          showCommentDialog={showCommentDialog}
          closeCommentDialog={handleCommentClose}
          submitNewComment={handleCommentSubmit}
          hasActiveChanges={hasActiveChanges}
        />
      )}
    </>
  )
}
