import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MobileStepper,
  Tab,
  Tabs,
  TextField,
  Tooltip
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { countriesISO } from 'constants/countriesISO'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import {
  SupportedFunction,
  supportedFunctions
} from 'constants/supportedFunctions'
import {
  renderSelect,
  editFormErrorKeys,
  validateUrl,
  validateSiteUrlValid,
  checkIsAdFormStepValid
} from 'utils/admin/adminFormUtils'
import createDOMPurify from 'dompurify'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { createDefaultDate } from 'utils/date'
import { filterSearchTermInput } from 'utils/admin/adminContentQuality'
import { capitalizeFirstLetter } from 'utils/string'
import {
  ISelfServiceRequest,
  RequestComment,
  RequestStatus
} from 'models/SelfServiceRequest'
import { AdsResult, intialAdsResult } from 'models/AdsResult'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import {
  ISupportedVertical,
  supportedVerticals
} from 'constants/supportedVerticals'
import DefaultFormField from 'components/contents/common/DefaultFormField'
import { getStylesRequestForm } from 'styles/requests/requestForm'
import Ads from 'components/contents/resultpages/Ads'
import AddCircle from '@mui/icons-material/AddCircle'
import ClearIcon from '@mui/icons-material/Clear'
import { FormattedMessage, useIntl } from 'react-intl'
import { TabContext, TabPanel } from '@mui/lab'
import { IFormStep, IKeyWordChipData } from 'models/FormModels'
import EditFormCommentDialog from './EditFormCommentDialog'
import { updateComments } from 'utils/admin/selfServiceUtils'
import { fileTypeFromBuffer } from 'file-type'
import { maximumAllowedFileSize } from 'constants/constants'

export interface IEditFormAdWordStepsProps {
  item: ISelfServiceRequest
  isNewItem: boolean
  saveItem: (
    newStatus?: RequestStatus,
    newComment?: RequestComment | null,
    orgItem?: AdsResult[],
    isNewItem?: boolean
  ) => void
  setItemList: (newItems: AdsResult[]) => void
  activeStep: number
  setActiveStep: (newStep: number) => void
  hasActiveChanges: boolean
  setHasActiveChanges: (hasChanges: boolean) => void
  setHasImageChange: (hasImageChange: boolean) => void
  loading: boolean
}

export default function EditFormAdWordSteps(
  props: IEditFormAdWordStepsProps
): JSX.Element {
  const {
    isNewItem,
    item,
    saveItem,
    setItemList,
    activeStep,
    setActiveStep,
    hasActiveChanges,
    setHasActiveChanges,
    setHasImageChange,
    loading
  } = props

  const itemList = item.itemData as AdsResult[]
  const orgItem = useRef(
    Object.assign(
      {},
      {
        ...item,
        itemData: (item.itemData as AdsResult[]).map((ad) => {
          return Object.assign({}, ad)
        })
      }
    )
  )
  const orgImage = useRef(item.imagePreview)

  const DOMPurify = createDOMPurify(window)
  const classes = getStylesRequestForm()
  const intl = useIntl()
  const topElement = useRef() as React.MutableRefObject<HTMLInputElement>

  // timezone into start date
  const parsedStartDate =
    itemList[0] && itemList[0].start ? new Date(itemList[0].start) : null
  let startDateString
  if (parsedStartDate) {
    const timeOffsetInMS = parsedStartDate.getTimezoneOffset() * 60000 * -1
    parsedStartDate.setTime(parsedStartDate.getTime() - timeOffsetInMS)
    startDateString = parsedStartDate.toISOString()
  }
  // timezone into end date
  const parsedEndDate =
    itemList[0] && itemList[0].end ? new Date(itemList[0].end) : null
  let endDateString
  if (parsedEndDate) {
    const timeOffsetInMS = parsedEndDate.getTimezoneOffset() * 60000 * -1
    parsedEndDate.setTime(parsedEndDate.getTime() - timeOffsetInMS)
    endDateString = parsedEndDate.toISOString()
  }

  const [startDate, setStartDate] = useState<Date | null | undefined>(
    startDateString ? new Date(startDateString) : null
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    endDateString ? new Date(endDateString) : null
  )

  const [sources, setSources] = useState(
    itemList[0]?.sources.map((dsKey: string) => {
      return {
        key: capitalizeFirstLetter(dsKey),
        name: capitalizeFirstLetter(dsKey)
      }
    })
  )

  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())
  const [countries, setCountries] = useState(itemList[0]?.countries)
  const [functions, setFunctions] = useState(itemList[0]?.functions)
  const [flavour, setFlavour] = useState(
    itemList[0] && itemList[0].flavour ? itemList[0].flavour : 'small'
  )
  const [scope, setScope] = useState(
    itemList[0] && itemList[0].scope ? itemList[0].scope : 'All'
  )
  const [availableSources, setAvailableSources] = useState<
    ISupportedVertical[]
  >([])
  const [loaded, setLoaded] = useState(false)
  const [countryList, setCountryList] = useState<string[]>([])
  const [functionList, setFunctionList] = useState<string[]>([])
  const [errorState, setErrorState] = useState<IErrorListObject[]>([])
  const [matchType, setMatchType] = useState(
    itemList[0] && itemList[0].match_type ? itemList[0].match_type : 'Contains'
  )

  const formState = useRef([
    { shouldValidate: false },
    { shouldValidate: false },
    { shouldValidate: false },
    { shouldValidate: false }
  ] as Array<IFormStep>)

  const [showCommentDialog, setShowCommentDialog] = useState(false)
  const [blockSubmitOption, setBlockSubmitOption] = useState(true)

  const [currentKeyWordToAdd, setCurrentKeyWordToAdd] = useState<string>('')
  const [selectedSearchTerms, setSelectedSearchTerms] = useState<
    Array<IKeyWordChipData>
  >(
    itemList[0] && itemList[0].search_terms
      ? itemList[0].search_terms.map((label: string, index: number) => {
          return {
            key: index,
            label: label
          }
        })
      : []
  )
  const selectedTermsRef = useRef(selectedSearchTerms)

  const [previewItem, setPreviewItem] = useState<AdsResult>(intialAdsResult)

  const [uploadCheckError, setUploadCheckError] = useState('')
  const [showLanguageSelection, setShowLanguageSelection] = useState(false)
  const [selectedLanguages, setSelectedLanguages] = useState(
    item.definedLanguages ? item.definedLanguages : ['en']
  )
  const [tabIndex, setTabIndex] = React.useState(0)
  const [hasImageSizeWarning, setHasImageSizeWarning] = useState(false)
  const [imageSize, setImageSize] = useState({
    loaded: false,
    width: 0,
    height: 0
  })

  useEffect(() => {
    setItemList(createMissingLanguages(itemList))

    updateAvailableSources(itemList)
    setLoaded(true)

    if (isNewItem) {
      if (!endDate) {
        const dateString = createDefaultDate()
        setEndDate(new Date(dateString))
        itemList[0].end = dateString
      }
      if (!startDate) {
        const dateString = new Date().toISOString()
        setStartDate(new Date(dateString))
        itemList[0].start = dateString
      }
    } else {
      const isCompleteItemValid = validateCompleteItem()
      if (isCompleteItemValid) {
        setBlockSubmitOption(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loaded) {
      updateAvailableSources(itemList)

      if (scope === 'KPMGFind' && itemList[0].site_url) {
        itemList[0].site_url = ''
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope])

  useEffect(() => {
    updatePreviewItem()

    if (item.imagePreview && item.imageFileName) {
      const img = document.createElement('img')
      img.onload = function () {
        if (!(img.width === 0) && !(img.height === 0)) {
          setImageSize({
            loaded: true,
            width: img.width,
            height: img.height
          })
        }
      }

      img.src = `data:${item.imagePreview}`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateImageSize = (): void => {
    if (imageSize.loaded) {
      const ratio = imageSize.width / imageSize.height
      const rationToReach = flavour === 'large' ? 3 : 1

      if (Math.abs(rationToReach - ratio) > (flavour === 'large' ? 1 : 0.2)) {
        setHasImageSizeWarning(true)
      } else {
        setHasImageSizeWarning(false)
      }
    } else if (hasImageSizeWarning) {
      setHasImageSizeWarning(false)
    }
  }

  useEffect(() => {
    validateImageSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSize, flavour])

  /** Error Handling and Validation */
  const getError = (locale: string, key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error &&
        errorItem.locale === locale &&
        errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }

  const validateItem = (
    checkLanguages = false,
    currentTabIndex?: number
  ): boolean => {
    setHasActiveChanges(
      JSON.stringify(item) !== JSON.stringify(orgItem.current)
    )
    updatePreviewItem(currentTabIndex)

    if (formState.current[activeStep].shouldValidate || checkLanguages) {
      let errorList: IErrorListObject[] = []
      const enItem = Object.assign({}, getCurrentObjectByLanguage('en'))
      enItem.image = item.imagePreview ? item.imagePreview : ''
      if (!enItem) {
        return false
      }

      if (checkLanguages) {
        supportedLanguages.forEach((lanuage: SupportedLanguage) => {
          const item = getCurrentObjectByLanguage(lanuage.locale)
          if (!item) {
            return false
          }

          errorList = [
            ...errorList,
            ...checkIsAdFormStepValid(
              item,
              enItem as AdsResult,
              intl,
              activeStep
            )
          ]
        })
      } else {
        if (!enItem) {
          return false
        }

        errorList = [
          ...errorList,
          ...checkIsAdFormStepValid(
            enItem,
            enItem as AdsResult,
            intl,
            activeStep
          )
        ]
      }

      const isValid = !errorList.find(
        (error: IErrorListObject) => error.error.error
      )

      setErrorState(errorList)

      return isValid
    }

    return false
  }

  const validateUploadedFile = async (file: Blob) => {
    const buffer = await file.arrayBuffer()
    const realFileType = await fileTypeFromBuffer(new Uint8Array(buffer))

    if (
      !realFileType ||
      !realFileType.mime.startsWith('image') ||
      (realFileType.ext !== 'jpg' && realFileType.ext !== 'png')
    ) {
      setUploadCheckError(
        intl.formatMessage({
          id: 'form_image_error_filetype',
          defaultMessage:
            'Wrong file format. Only jpg or png images are allowed.'
        })
      )
      return false
    }

    if (
      file &&
      file.size &&
      file.size > 0 &&
      file.size > maximumAllowedFileSize
    ) {
      setUploadCheckError(
        intl.formatMessage({
          id: 'form_image_error_filesize',
          defaultMessage:
            'The file size is too big. Only images with a size less than 0.25MB are allowed'
        })
      )
      return false
    }

    try {
      const img = document.createElement('img')
      img.onload = function () {
        if (img.width === 0 || img.height === 0) {
          setUploadCheckError(
            intl.formatMessage({
              id: 'form_image_error_zero_width',
              defaultMessage: 'The image have a height or width of zero.'
            })
          )
          return false
        }

        setImageSize({
          loaded: true,
          width: img.width,
          height: img.height
        })
      }

      img.onerror = function () {
        setUploadCheckError(
          intl.formatMessage({
            id: 'form_image_error_onload',
            defaultMessage: "The image can't be loaded."
          })
        )
        return false
      }

      img.src = URL.createObjectURL(file)
    } catch {
      setUploadCheckError(
        intl.formatMessage({
          id: 'form_image_error_validate_size',
          defaultMessage: 'Image validation failed.'
        })
      )
      return false
    }

    setUploadCheckError('')
    return true
  }

  const validateFieldAndUpdateError = (
    validateFunction: any,
    customErrorText?: string
  ) => {
    const newError = validateFunction(itemList[0], customErrorText)

    if (newError && newError.length > 0) {
      const newErrorState = errorState.filter(
        (error: IErrorListObject) => error.key !== newError[0].key
      )
      newErrorState.push(newError[0])
      setErrorState(newErrorState)
    }
  }

  const validateCompleteItem = (): boolean => {
    let errorList: IErrorListObject[] = []
    const enItem = Object.assign({}, getCurrentObjectByLanguage('en'))
    enItem.image = item.imagePreview ? item.imagePreview : ''

    errorList = [
      ...errorList,
      ...checkIsAdFormStepValid(enItem, enItem as AdsResult, intl)
    ]

    const isValid = !errorList.find(
      (error: IErrorListObject) => error.error.error
    )
    return isValid
  }

  /** Handle languages and intial states */
  const createMissingLanguages = (itemListToCheck: AdsResult[]) => {
    const changedItems: string[] = []
    const newItemList: AdsResult[] = []

    supportedLanguages.forEach((lang: SupportedLanguage) => {
      const localeChild = itemListToCheck.find(
        (item: AdsResult) => item.language === lang.locale
      )

      if (localeChild) {
        newItemList.push(localeChild)
      } else {
        const id = itemList[0].id

        const newChild = JSON.parse(JSON.stringify(itemListToCheck[0]))
        newChild.language = lang.locale
        newChild.id = id.toString()

        newItemList.push(newChild)
        changedItems.push(lang.locale)
      }
    })

    return newItemList
  }

  const handleLanguageItemChange = (oldItem: AdsResult, newItem: AdsResult) => {
    const newItemList: AdsResult[] = []
    itemList.forEach((item: AdsResult) => {
      if (oldItem.language === 'en' && oldItem.language !== item.language) {
        const targetNewItem = newItem as AdsResult
        const targetItem = item as AdsResult
        if (
          oldItem.title === item.title &&
          targetNewItem.title !== targetItem.title
        ) {
          item.title = newItem.title
        }
        if (
          oldItem.text === targetItem.text &&
          targetNewItem.text !== targetItem.text
        ) {
          targetItem.text = targetNewItem.text
        }

        if (
          oldItem.link_text === targetItem.link_text &&
          targetNewItem.link_text !== targetItem.link_text
        ) {
          targetItem.link_text = targetNewItem.link_text
        }
      }
      newItemList.push(item)
    })
    setItemList(newItemList)
  }

  const getCurrentObjectByLanguage = (locale: string) => {
    const localeChild = itemList.find(
      (item: AdsResult) => item.language === locale
    )

    if (localeChild) {
      return localeChild
    }
  }

  const updateAvailableSources = (items: AdsResult[]) => {
    let functionList = supportedFunctions.map(
      (item: SupportedFunction) => item.name
    )
    let countryList = countriesISO.map((item) => item.Name)
    let sourcelist = supportedVerticals

    sourcelist = [{ key: 'All', name: 'All' }, ...sourcelist]
    countryList = ['All', ...countryList]
    functionList = ['All', ...functionList]

    setAvailableSources(sourcelist)

    setFunctionList(functionList)
    setCountryList(countryList)
  }

  /** Update the preview */
  const updatePreviewItem = (currentTabIndex?: number) => {
    const previewItem = Object.assign({}, itemList[0])
    if (currentTabIndex && supportedLanguages[currentTabIndex]) {
      const languageItem = getCurrentObjectByLanguage(
        supportedLanguages[currentTabIndex].locale
      )

      if (languageItem) {
        previewItem.title = languageItem.title
        previewItem.text = languageItem.text
        previewItem.link_text = languageItem.link_text
      }
    }
    previewItem.image = item.imagePreview ? `data:${item.imagePreview}` : ''
    setPreviewItem(previewItem)
  }

  /** Handle steps and submit */
  const handleNext = () => {
    formState.current[activeStep].shouldValidate = true
    const stepValid = validateItem(activeStep === 2)
    if (stepValid) {
      if (tabIndex !== 0) setTabIndex(0)
      updatePreviewItem()
      setActiveStep(activeStep + 1)
      topElement.current?.scrollIntoView({ block: 'nearest' })
    }
  }

  const handleSubmit = () => {
    formState.current[activeStep].shouldValidate = true
    const stepValid = validateItem()
    if (stepValid) {
      if (item.status !== RequestStatus.Clarification) {
        saveItem(
          RequestStatus.Submitted,
          null,
          orgItem.current.itemData as AdsResult[],
          isNewItem
        )
      } else {
        setShowCommentDialog(true)
      }
    }
  }

  const handleCommentClose = () => {
    setShowCommentDialog(false)
  }

  const handleCommentSubmit = (newComment: RequestComment | null) => {
    if (newComment) {
      updateComments(item, newComment)
    }

    saveItem(
      RequestStatus.Submitted,
      newComment,
      orgItem.current.itemData as AdsResult[],
      false
    )
  }

  const handleBack = () => {
    if (tabIndex !== 0) setTabIndex(0)
    updatePreviewItem()
    setActiveStep(activeStep - 1)
    topElement.current?.scrollIntoView({ block: 'nearest' })
  }

  /** handle keyWord Add and delete */
  const handleKeyWordDelete = (termToDelete: IKeyWordChipData) => {
    const newValue = selectedSearchTerms.filter(
      (chip) => chip.key !== termToDelete.key
    )
    setSelectedSearchTerms(newValue)

    itemList[0].search_terms = newValue.map(
      (chip: IKeyWordChipData) => chip.label
    )

    validateItem()
  }

  const handleKeyWordAdd = (keyWordToAdd: string) => {
    if (keyWordToAdd) {
      const newKeyWords = [
        ...selectedSearchTerms.map((chip: IKeyWordChipData) => {
          chip.showWarning = false
          return chip
        })
      ]

      const keywords = keyWordToAdd.split(',')
      let index = 1
      keywords.forEach((keyword: string) => {
        if (keyword && keyword.trim()) {
          const existingKeyWord = newKeyWords.find(
            (chipData: IKeyWordChipData) =>
              chipData.label.trim().toLowerCase() ===
              keyword.trim().toLowerCase()
          )

          if (!existingKeyWord) {
            newKeyWords.push({
              key: selectedSearchTerms.length + index,
              label: keyword.trim()
            })

            index = index + 1
          } else if (existingKeyWord.key <= selectedSearchTerms.length) {
            existingKeyWord.showWarning = true
          }
        }
      })

      if (
        selectedTermsRef.current.some(
          (chip: IKeyWordChipData) => chip.showWarning
        )
      ) {
        setTimeout(() => {
          setSelectedSearchTerms(
            selectedTermsRef.current.map((chip: IKeyWordChipData) => {
              chip.showWarning = false
              return chip
            })
          )
        }, 2000)
      }

      selectedTermsRef.current = newKeyWords
      setSelectedSearchTerms(newKeyWords)

      itemList[0].search_terms = newKeyWords.map(
        (chip: IKeyWordChipData) => chip.label
      )
    }
    validateItem()
  }

  /** Handle add translation */
  const addLanguage = (language: string) => {
    const lastSelectedLanguages = [...selectedLanguages]
    const indexSelected = lastSelectedLanguages.indexOf(language)
    if (indexSelected > -1) {
      //remove
      lastSelectedLanguages.splice(indexSelected, 1)
    } else {
      //add
      lastSelectedLanguages.push(language)
    }
    setSelectedLanguages(lastSelectedLanguages)
  }

  const handleRemoveLanguage = (event: any, language: string) => {
    const lastSelectedLanguages = [...selectedLanguages]
    const indexSelected = lastSelectedLanguages.indexOf(language)
    if (indexSelected > -1) {
      event.stopPropagation()
      lastSelectedLanguages.splice(indexSelected, 1)

      if (tabIndex >= lastSelectedLanguages.length) {
        setTabIndex(tabIndex - 1)
      }

      setTimeout(() => {
        item.definedLanguages = lastSelectedLanguages
        setSelectedLanguages(lastSelectedLanguages)
      }, 300)

      const newItemList = itemList.filter(
        (ad: AdsResult) => ad.language !== language
      )

      const refItem = Object.assign({}, getCurrentObjectByLanguage('en'))
      refItem.language = language

      newItemList.push(refItem)

      setItemList(newItemList)
    }
  }

  return (
    <>
      <div ref={topElement} />
      {activeStep === 0 && (
        <div className={classes.formContainer}>
          <div className={classes.leftFormArea}>
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_headline',
                defaultMessage: 'AdWord Headline'
              })}
              required={true}
              description={intl.formatMessage({
                id: 'form_adword_headline_desc',
                defaultMessage: 'Add an engaging title for the AdWord'
              })}
              key={`title_${currentFormState}`}
              inputControl={
                <TextField
                  variant="outlined"
                  size="small"
                  id="title-required"
                  className={classes.form_field}
                  defaultValue={itemList[0].title}
                  onChange={(event) => {
                    const oldItem = Object.assign({}, itemList[0])

                    itemList[0].title = event.target.value
                      ? DOMPurify.sanitize(event.target.value, {
                          USE_PROFILES: { html: false }
                        })
                      : event.target.value
                    handleLanguageItemChange(oldItem, itemList[0])
                    validateItem()
                  }}
                  onBlur={() => {
                    setCurrentFormState(new Date().getTime())
                  }}
                  error={getError('en', editFormErrorKeys.title).error}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              }
              hasError={getError('en', editFormErrorKeys.title).error}
              errorText={getError('en', editFormErrorKeys.title).helperText}
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_body_text',
                defaultMessage: 'AdWord Body Text'
              })}
              required={true}
              description={intl.formatMessage({
                id: 'form_adword_body_text_desc',
                defaultMessage:
                  'Add a descriptive text capturing details of the AdWord'
              })}
              key={`desc_${currentFormState}`}
              inputControl={
                <TextField
                  variant="outlined"
                  size="small"
                  className={classes.form_field}
                  multiline
                  id="text"
                  defaultValue={itemList[0].text}
                  rows={5}
                  onChange={(event) => {
                    const oldItem = Object.assign({}, itemList[0])
                    itemList[0].text = event.target.value
                      ? DOMPurify.sanitize(event.target.value, {
                          USE_PROFILES: { html: true },
                          ADD_ATTR: ['target']
                        })
                      : event.target.value
                    handleLanguageItemChange(oldItem, itemList[0])
                    validateItem()
                  }}
                  onBlur={() => {
                    setCurrentFormState(new Date().getTime())
                  }}
                  error={getError('en', editFormErrorKeys.description).error}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              }
              hasError={getError('en', editFormErrorKeys.description).error}
              errorText={
                getError('en', editFormErrorKeys.description).helperText
              }
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_link',
                defaultMessage: 'AdWord Link'
              })}
              description={intl.formatMessage({
                id: 'form_adword_link_desc',
                defaultMessage:
                  'Optionally provide a clickable link to redirect user to relevant content.'
              })}
              key={`link_text_${currentFormState}`}
              inputControl={
                <>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.form_field}
                    id="link-text"
                    defaultValue={itemList[0].link_text}
                    onChange={(event) => {
                      const oldItem = Object.assign({}, itemList[0])
                      itemList[0].link_text = event.target.value
                        ? DOMPurify.sanitize(event.target.value, {
                            USE_PROFILES: { html: false }
                          })
                        : event.target.value
                      handleLanguageItemChange(oldItem, itemList[0])
                      validateItem()
                    }}
                    onBlur={(event) => {
                      validateItem()
                      setCurrentFormState(new Date().getTime())
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={getError('en', editFormErrorKeys.linkText).error}
                    label={intl.formatMessage({
                      id: 'form_adword_link_text',
                      defaultMessage: 'Text'
                    })}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.form_field}
                    id="link"
                    defaultValue={itemList[0].link}
                    onChange={(event) => {
                      itemList[0].link = event.target.value
                        ? DOMPurify.sanitize(
                            event.target.value.replace(/ /g, '%20')
                          )
                        : event.target.value
                      validateItem()
                    }}
                    onBlur={() => {
                      validateFieldAndUpdateError(
                        validateUrl,
                        intl.formatMessage({
                          id: 'form_validation_link_url',
                          defaultMessage: 'Please enter a valid link URL.'
                        })
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={
                      getError(itemList[0].language, editFormErrorKeys.link)
                        .error ||
                      getError(itemList[0].language, editFormErrorKeys.url)
                        .error
                    }
                    style={{ marginTop: '8px' }}
                    label={intl.formatMessage({
                      id: 'form_adword_link_url',
                      defaultMessage: 'Url'
                    })}
                  />
                </>
              }
              hasError={
                getError(itemList[0].language, editFormErrorKeys.linkText)
                  .error ||
                getError(itemList[0].language, editFormErrorKeys.link).error ||
                getError(itemList[0].language, editFormErrorKeys.url).error
              }
              errorText={
                getError(itemList[0].language, editFormErrorKeys.linkText)
                  .helperText ||
                getError(itemList[0].language, editFormErrorKeys.link)
                  .helperText ||
                getError(itemList[0].language, editFormErrorKeys.url).helperText
              }
            />
          </div>

          <div className={classes.rightFormArea}>
            <div className={classes.rightFormTitle}>
              <FormattedMessage
                id="form_adword_step1_headline"
                defaultMessage="Request an AdWord"
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_adword_step1_q1"
                  defaultMessage="What is an AdWord?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_adword_step1_a1',
                      defaultMessage:
                        'An AdWord is an engaging piece of content used to promote and highlight an important piece of content, an initiative or offering.  It is displayed right of the search results, and can be optionally linked to an action or resource via a URL.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_adword_step1_q2"
                  defaultMessage="When to request an AdWord?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_adword_step1_a2',
                      defaultMessage:
                        'An AdWord is often requested to attract the attention of the user to an important piece of content. It holds more real-estate than a featured result, and offers more flexibility with rich-text styling and image options.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
          </div>
        </div>
      )}
      {activeStep === 1 && (
        <div className={classes.formContainer}>
          <div className={classes.leftFormArea}>
            <div className={classes.singleLine}>
              <DefaultFormField
                label={intl.formatMessage({
                  id: 'form_adword_start',
                  defaultMessage: 'Start Date'
                })}
                required={true}
                description={intl.formatMessage({
                  id: 'form_adword_start_desc',
                  defaultMessage: 'Date when AdWord starts appearing'
                })}
                inputControl={
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="MM/dd/yyyy"
                      value={startDate || null}
                      onChange={(date: Date | null) => {
                        let dateString = null
                        if (date && date.toString() !== 'Invalid Date') {
                          const day = date.getDate()
                          const month = date.getMonth() + 1
                          const year = date.getFullYear()
                          dateString =
                            year.toString() +
                            '-' +
                            month.toString().padStart(2, '0') +
                            '-' +
                            day.toString().padStart(2, '0') +
                            'T00:00:00.000Z'
                        }

                        itemList[0].start = dateString
                        setStartDate(date ? date : null)
                        validateItem()
                      }}
                      onYearChange={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          const transformValue = (popperRoot[0] as HTMLElement)
                            .style.transform
                          setTimeout(() => {
                            ;(popperRoot[0] as HTMLElement).style.transform =
                              transformValue
                          }, 10)
                        }
                      }}
                      onClose={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          ;(popperRoot[0] as HTMLElement).style.display = 'none'
                        }
                      }}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: classes.datePicker
                        }
                      }}
                      slots={{
                        textField: (textFieldProps) => (
                          <TextField
                            {...textFieldProps}
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                              shrink: true
                            }}
                            className={classes.form_field}
                            error={
                              getError(
                                itemList[0].language,
                                editFormErrorKeys.startDate
                              ).error
                            }
                          />
                        )
                      }}
                    />
                  </LocalizationProvider>
                }
                hasError={
                  getError(itemList[0].language, editFormErrorKeys.startDate)
                    .error
                }
                errorText={
                  getError(itemList[0].language, editFormErrorKeys.startDate)
                    .helperText
                }
              />
              <DefaultFormField
                label={intl.formatMessage({
                  id: 'form_adword_end',
                  defaultMessage: 'Expiration Date'
                })}
                required={true}
                description={intl.formatMessage({
                  id: 'form_adword_end_desc',
                  defaultMessage: 'Date when AdWord stops appearing'
                })}
                inputControl={
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="MM/dd/yyyy"
                      value={endDate}
                      onChange={(date: Date | null) => {
                        let dateString = null
                        if (date && date.toString() !== 'Invalid Date') {
                          const day = date.getDate()
                          const month = date.getMonth() + 1
                          const year = date.getFullYear()
                          dateString =
                            year.toString() +
                            '-' +
                            month.toString().padStart(2, '0') +
                            '-' +
                            day.toString().padStart(2, '0') +
                            'T23:59:59.000Z'
                        }

                        itemList[0].end = dateString
                        setEndDate(date ? date : null)
                        validateItem()
                      }}
                      onYearChange={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          const transformValue = (popperRoot[0] as HTMLElement)
                            .style.transform
                          setTimeout(() => {
                            ;(popperRoot[0] as HTMLElement).style.transform =
                              transformValue
                          }, 10)
                        }
                      }}
                      onClose={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          ;(popperRoot[0] as HTMLElement).style.display = 'none'
                        }
                      }}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: classes.datePicker
                        }
                      }}
                      slots={{
                        textField: (textFieldProps) => (
                          <TextField
                            {...textFieldProps}
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                              shrink: true
                            }}
                            className={classes.form_field}
                            error={
                              getError(
                                itemList[0].language,
                                editFormErrorKeys.startDate
                              ).error
                            }
                          />
                        )
                      }}
                    />
                  </LocalizationProvider>
                }
                hasError={
                  getError(itemList[0].language, editFormErrorKeys.endDate)
                    .error
                }
                errorText={
                  getError(itemList[0].language, editFormErrorKeys.endDate)
                    .helperText
                }
              />
            </div>
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_keywords',
                defaultMessage: 'List of Keywords'
              })}
              required={true}
              description={intl.formatMessage({
                id: 'form_adword_keywords_desc',
                defaultMessage:
                  'A list of comma separated keywords that trigger the AdWord Example: "covid, covid-19, work from home"'
              })}
              inputControl={
                <div
                  className={`${classes.selectChipArea} ${
                    getError(
                      itemList[0].language,
                      editFormErrorKeys.searchTerms
                    ).error
                      ? classes.selectChipAreaError
                      : ''
                  }`}
                >
                  <div className={classes.selectChipInnerArea}>
                    <div>
                      <TextField
                        id="input-with-icon-textfield"
                        size="small"
                        value={currentKeyWordToAdd}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.addKeyWordFieldAddButton}
                              onClick={() => {
                                if (currentKeyWordToAdd) {
                                  handleKeyWordAdd(currentKeyWordToAdd)
                                  setCurrentKeyWordToAdd('')
                                }
                              }}
                            >
                              <AddCircle />
                            </InputAdornment>
                          )
                        }}
                        onChange={(event) => {
                          setCurrentKeyWordToAdd(
                            filterSearchTermInput(event.target.value)
                          )
                        }}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter' && currentKeyWordToAdd) {
                            handleKeyWordAdd(currentKeyWordToAdd)
                            setCurrentKeyWordToAdd('')
                          }
                        }}
                        variant="outlined"
                        className={classes.addKeyWordField}
                      />
                    </div>
                    {selectedSearchTerms.map((data: IKeyWordChipData) => {
                      return (
                        <Chip
                          label={data.label}
                          onDelete={() => handleKeyWordDelete(data)}
                          className={
                            data.showWarning
                              ? [
                                  classes.keyWordChip,
                                  classes.keyWordChipWarning
                                ].join(' ')
                              : classes.keyWordChip
                          }
                        />
                      )
                    })}
                  </div>
                </div>
              }
              hasError={
                getError(itemList[0].language, editFormErrorKeys.searchTerms)
                  .error
              }
              errorText={
                getError(itemList[0].language, editFormErrorKeys.searchTerms)
                  .helperText
              }
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_matchtype',
                defaultMessage: 'Match Type'
              })}
              description={intl.formatMessage({
                id: 'form_adword_matchtype_desc',
                defaultMessage:
                  'Match type when comparing searched term against trigger keywords.<ul><li>Contains: AdWord is triggered if searched term contains one of the trigger keywords.</li><li>Exact: AdWord is triggered if searched term exactly matches one of the trigger keywords.</li><ul>'
              })}
              inputControl={renderSelect(
                matchType,
                setMatchType,
                ['Contains', 'Exact'],
                false,
                '',
                'match_type',
                itemList[0],
                isNewItem,
                validateItem
              )}
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_flavour',
                defaultMessage: 'AdWord Flavour'
              })}
              required={true}
              description={intl.formatMessage({
                id: 'form_adword_flavour_desc',
                defaultMessage:
                  'Select the size of the AdWord (Difference is visible in the preview, Large AdWord contain an image).'
              })}
              inputControl={renderSelect(
                flavour,
                setFlavour,
                ['large', 'small'],
                false,
                '',
                'flavour',
                itemList[0],
                isNewItem,
                validateItem
              )}
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_image',
                defaultMessage: 'AdWord Image'
              })}
              description={intl.formatMessage({
                id: 'form_adword_image_desc',
                defaultMessage: 'Upload an image to be displayed in the AdWord'
              })}
              required={itemList[0].flavour === 'large' ? true : false}
              inputControl={
                <>
                  <TextField
                    className={classes.form_field}
                    variant="outlined"
                    size="small"
                    id="image-url"
                    value={item.imageFileName}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={
                      getError(itemList[0].language, editFormErrorKeys.image)
                        .error
                    }
                    disabled={true}
                    style={{ marginBottom: '10px' }}
                  />
                  <Box
                    className={classes.imageUploadButtonContainer}
                    style={{ marginBottom: '16px' }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      className={classes.imageUploadButton}
                    >
                      <Box>
                        <FormattedMessage
                          id="form_image_upload"
                          defaultMessage="Upload"
                        />
                      </Box>
                      <input
                        type="file"
                        hidden
                        onChange={(event: any) => {
                          const file = event.target.files[0]
                          if (file) {
                            const fr = new FileReader()
                            fr.onload = async () => {
                              let value = fr.result as string
                              if (value && file.name) {
                                if (await validateUploadedFile(file)) {
                                  value = value.substr(5)
                                  if (value !== orgImage.current) {
                                    setHasImageChange(true)
                                  } else {
                                    setHasImageChange(false)
                                  }
                                  item.imagePreview = value
                                  item.imageFileName = file.name
                                    .replace(/[/\\?%*:|"<>]/g, '')
                                    .replace(/\s+/g, '')
                                  validateItem()
                                }
                              }
                            }
                            fr.readAsDataURL(file)
                          }
                        }}
                        onClick={(event: any) => {
                          event.target.value = null
                        }}
                      />
                    </Button>
                    <Button
                      variant="contained"
                      component="label"
                      className={classes.imageUploadButton}
                      onClick={() => {
                        item.imagePreview = ''
                        item.imageFileName = ''
                        if (orgImage.current) {
                          setHasImageChange(true)
                        } else {
                          setHasImageChange(false)
                        }
                        validateItem()
                        setImageSize({ loaded: false, width: 0, height: 0 })
                      }}
                      style={{ marginLeft: '5px' }}
                    >
                      <Box>
                        <FormattedMessage
                          id="form_image_clear"
                          defaultMessage="Clear"
                        />
                      </Box>
                    </Button>
                  </Box>
                </>
              }
              hasError={
                uploadCheckError
                  ? true
                  : getError(itemList[0].language, editFormErrorKeys.image)
                      .error
              }
              errorText={
                uploadCheckError
                  ? uploadCheckError
                  : getError(itemList[0].language, editFormErrorKeys.image)
                      .helperText
              }
            />
            {!uploadCheckError && hasImageSizeWarning && (
              <div className={classes.iamgeSizeWarning}>
                <div>
                  {`${intl.formatMessage({
                    id: 'form_image_warning_size',
                    defaultMessage:
                      "The width and height of the image doesn't match the flavor of the AdWord."
                  })}  ${
                    flavour === 'large'
                      ? intl.formatMessage({
                          id: 'form_image_warning_size_large',
                          defaultMessage:
                            'For large AdWords the ratio of the image should be 3:1. This means at a width of 450px, the image should have a height around 150px.'
                        })
                      : intl.formatMessage({
                          id: 'form_image_warning_size_small',
                          defaultMessage:
                            'For small AdWords the ratio of the image should be 1:1. This means at a width of 450px, the image should have a height around 450px.'
                        })
                  }`}
                </div>
              </div>
            )}
          </div>
          <div className={classes.rightFormArea}>
            <div className={classes.rightFormTitle}>
              <FormattedMessage
                id="form_adword_step2_headline"
                defaultMessage="Request an AdWord"
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_adword_step2_q1"
                  defaultMessage="What is the lifecycle of an AdWord?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_adword_step2_a1',
                      defaultMessage:
                        'Once the request is submitted, we review the request and it goes LIVE if no clarifications are needed. Once LIVE, it is triggered for the specified search terms, taking into account other trigger criterias like Country, Function etc.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_adword_step2_q2"
                  defaultMessage="When are AdWords visible to the end users?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_adword_step2_a2',
                      defaultMessage:
                        'When the specified search criteria including search term, country, vertical etc. is met, AdWord is triggered and displayed to the end user.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
          </div>
        </div>
      )}
      {activeStep === 2 && (
        <div className={classes.formContainer}>
          <div className={classes.leftFormArea}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={(event: any, newValue: number): void => {
                  const isValid = validateItem(true)
                  if (isValid) {
                    setTabIndex(newValue)
                    updatePreviewItem(newValue)
                  }
                }}
                variant="scrollable"
                aria-label="languages"
                scrollButtons={'auto'}
              >
                {supportedLanguages.map((language: SupportedLanguage) => {
                  if (
                    item.definedLanguages &&
                    item.definedLanguages.indexOf(language.locale) === -1
                  ) {
                    return null
                  }

                  return (
                    <Tab
                      label={
                        <div className={classes.languageLabelContainer}>
                          <div>{language.name}</div>
                          {language.locale !== 'en' && (
                            <div>
                              <Button
                                className={classes.languageDeleteButton}
                                onClick={(e) =>
                                  handleRemoveLanguage(e, language.locale)
                                }
                              >
                                <ClearIcon
                                  className={classes.languageDeleteIcon}
                                />
                              </Button>
                            </div>
                          )}
                        </div>
                      }
                    />
                  )
                })}
              </Tabs>

              <Tooltip
                title={intl.formatMessage({
                  id: 'form_adword_translation_add_tooltip',
                  defaultMessage: 'Add Translation'
                })}
              >
                <Button
                  color="primary"
                  onClick={() => {
                    setShowLanguageSelection(true)
                  }}
                >
                  <AddCircle></AddCircle>
                </Button>
              </Tooltip>
            </Box>
            <TabContext value={tabIndex + ''}>
              {supportedLanguages.map(
                (lanuage: SupportedLanguage, languageIndex: number) => {
                  const currentSelectedObject = getCurrentObjectByLanguage(
                    lanuage.locale
                  ) as AdsResult

                  return (
                    <TabPanel
                      value={languageIndex + ''}
                      className={classes.tab_panel}
                    >
                      <DefaultFormField
                        label={intl.formatMessage({
                          id: 'form_adword_headline_translation',
                          defaultMessage: 'AdWord Headline'
                        })}
                        required={true}
                        description={intl.formatMessage({
                          id: 'form_adword_headline_translation_desc',
                          defaultMessage:
                            'Optionally add a translated title text for supported languages.'
                        })}
                        key={`title_lang_${currentFormState}`}
                        inputControl={
                          <TextField
                            variant="outlined"
                            size="small"
                            id="title-required"
                            className={classes.form_field}
                            defaultValue={currentSelectedObject.title}
                            onChange={(event) => {
                              const oldItem = Object.assign(
                                {},
                                currentSelectedObject
                              )

                              currentSelectedObject.title = event.target.value
                                ? DOMPurify.sanitize(event.target.value, {
                                    USE_PROFILES: { html: false }
                                  })
                                : event.target.value
                              handleLanguageItemChange(
                                oldItem,
                                currentSelectedObject
                              )
                              validateItem(true, tabIndex)
                            }}
                            onBlur={() => {
                              setCurrentFormState(new Date().getTime())
                            }}
                            error={
                              getError(
                                currentSelectedObject.language,
                                editFormErrorKeys.title
                              ).error
                            }
                            InputLabelProps={{
                              shrink: true
                            }}
                            disabled={lanuage.locale === 'en'}
                          />
                        }
                        hasError={
                          getError(
                            currentSelectedObject.language,
                            editFormErrorKeys.title
                          ).error
                        }
                        errorText={
                          getError(
                            currentSelectedObject.language,
                            editFormErrorKeys.title
                          ).helperText
                        }
                      />
                      <DefaultFormField
                        label={intl.formatMessage({
                          id: 'form_adword_body_text_translation',
                          defaultMessage: 'AdWord Body Text'
                        })}
                        required={true}
                        description={intl.formatMessage({
                          id: 'form_adword_body_text_translation_desc',
                          defaultMessage:
                            'Optionally add a translated descriptive text for the supported languages.'
                        })}
                        key={`desc_lang_${currentFormState}`}
                        inputControl={
                          <TextField
                            variant="outlined"
                            size="small"
                            className={classes.form_field}
                            multiline
                            id="text"
                            defaultValue={currentSelectedObject.text}
                            rows={5}
                            onChange={(event) => {
                              const oldItem = Object.assign(
                                {},
                                currentSelectedObject
                              )
                              currentSelectedObject.text = event.target.value
                                ? DOMPurify.sanitize(event.target.value, {
                                    USE_PROFILES: { html: true }
                                  })
                                : event.target.value
                              handleLanguageItemChange(
                                oldItem,
                                currentSelectedObject
                              )
                              validateItem(true, tabIndex)
                            }}
                            onBlur={() => {
                              setCurrentFormState(new Date().getTime())
                            }}
                            error={
                              getError(
                                currentSelectedObject.language,
                                editFormErrorKeys.description
                              ).error
                            }
                            InputLabelProps={{
                              shrink: true
                            }}
                            disabled={lanuage.locale === 'en'}
                          />
                        }
                        hasError={
                          getError(
                            currentSelectedObject.language,
                            editFormErrorKeys.description
                          ).error
                        }
                        errorText={
                          getError(
                            currentSelectedObject.language,
                            editFormErrorKeys.description
                          ).helperText
                        }
                      />
                      {itemList[0].link && itemList[0].link_text && (
                        <DefaultFormField
                          label={intl.formatMessage({
                            id: 'form_adword_link_text_translation',
                            defaultMessage: 'AdWord Link'
                          })}
                          description={intl.formatMessage({
                            id: 'form_adword_link_text_translation_desc',
                            defaultMessage:
                              'Optionally add a translated link text for the supported languages.'
                          })}
                          key={`link_text_lang_${currentFormState}`}
                          inputControl={
                            <>
                              <TextField
                                variant="outlined"
                                size="small"
                                className={classes.form_field}
                                id="link-text"
                                defaultValue={currentSelectedObject.link_text}
                                onChange={(event) => {
                                  const oldItem = Object.assign(
                                    {},
                                    currentSelectedObject
                                  )
                                  currentSelectedObject.link_text = event.target
                                    .value
                                    ? DOMPurify.sanitize(event.target.value, {
                                        USE_PROFILES: { html: false }
                                      })
                                    : event.target.value
                                  handleLanguageItemChange(
                                    oldItem,
                                    currentSelectedObject
                                  )
                                  validateItem(true, tabIndex)
                                }}
                                onBlur={() => {
                                  setCurrentFormState(new Date().getTime())
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                error={
                                  getError(
                                    currentSelectedObject.language,
                                    editFormErrorKeys.linkText
                                  ).error
                                }
                                label={'Text'}
                                disabled={lanuage.locale === 'en'}
                              />
                            </>
                          }
                          hasError={
                            getError(
                              currentSelectedObject.language,
                              editFormErrorKeys.linkText
                            ).error
                          }
                          errorText={
                            getError(
                              currentSelectedObject.language,
                              editFormErrorKeys.linkText
                            ).helperText
                          }
                        />
                      )}
                    </TabPanel>
                  )
                }
              )}
            </TabContext>
            {showLanguageSelection && (
              <Dialog
                open={showLanguageSelection}
                onClose={() => {
                  setShowLanguageSelection(false)
                }}
                aria-labelledby="confirmation-modal"
                aria-describedby="confirmation-modal-description"
              >
                <DialogTitle>
                  <FormattedMessage
                    id="form_adword_translation_add_headline"
                    defaultMessage="Select translations to add"
                  />
                </DialogTitle>
                <DialogContent style={{ marginLeft: '-16px' }}>
                  <FormGroup>
                    {supportedLanguages.map((language: SupportedLanguage) => {
                      if (language.locale === 'en') {
                        return null
                      } else {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableRipple
                                color="default"
                                checked={selectedLanguages.includes(
                                  language.locale
                                )}
                                onClick={() => {
                                  addLanguage(language.locale)
                                }}
                              />
                            }
                            label={language.name}
                          />
                        )
                      }
                    })}
                  </FormGroup>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      if (tabIndex >= selectedLanguages.length) {
                        setTabIndex(0)
                      }

                      item.definedLanguages.forEach((language: string) => {
                        if (!selectedLanguages.includes(language)) {
                          const newItemList = itemList.filter(
                            (ad: AdsResult) => ad.language !== language
                          )

                          const refItem = Object.assign(
                            {},
                            getCurrentObjectByLanguage('en')
                          )
                          refItem.language = language

                          newItemList.push(refItem)

                          setItemList(newItemList)
                        }
                      })

                      setTimeout(() => {
                        item.definedLanguages = selectedLanguages
                        setShowLanguageSelection(false)
                      }, 300)
                    }}
                    color="primary"
                  >
                    <FormattedMessage
                      id="form_button_apply"
                      defaultMessage="Apply"
                    />
                  </Button>
                  <Button
                    onClick={() => setShowLanguageSelection(false)}
                    color="primary"
                  >
                    <FormattedMessage
                      id="form_button_close"
                      defaultMessage="Close"
                    />
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </div>
          <div className={classes.rightFormArea}>
            <div className={classes.rightFormTitle}>
              <FormattedMessage
                id="form_adword_step3_headline"
                defaultMessage="Request an AdWord"
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_adword_step3_q1"
                  defaultMessage="Which languages are supported for AdWords?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_adword_step3_a1',
                      defaultMessage:
                        'Supported languages include English, German, Spanish, Italian, French, Russian, Korean, Portuguese, Chinese and Japanese.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_adword_step3_q2"
                  defaultMessage="How can I add multi-lingual AdWords?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_adword_step3_a2',
                      defaultMessage:
                        'Click the + sign on the left side and choose from the list of specified languages to add translations for your AdWord.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
          </div>
        </div>
      )}
      {activeStep === 3 && (
        <div className={classes.formContainer}>
          <div className={classes.leftFormArea}>
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_scope',
                defaultMessage: 'Application Scope'
              })}
              description={intl.formatMessage({
                id: 'form_adword_scope_desc',
                defaultMessage:
                  'Choose one or both of Enterprise Search applications (KPMG Find, One Intranet Search) where AdWord will be displayed.'
              })}
              inputControl={renderSelect(
                scope,
                setScope,
                ['OI', 'KPMGFind', 'All'],
                false,
                '',
                'scope',
                itemList[0],
                isNewItem,
                validateItem
              )}
            />
            {(scope === 'OI' || scope === 'All') && (
              <DefaultFormField
                label={intl.formatMessage({
                  id: 'form_adword_memberurl',
                  defaultMessage: 'Site URL'
                })}
                description={intl.formatMessage({
                  id: 'form_adword_memberurl_desc',
                  defaultMessage:
                    'Optionally restrict the AdWord to a particular site URL'
                })}
                inputControl={
                  <TextField
                    variant="outlined"
                    id="site_url"
                    size="small"
                    defaultValue={itemList[0].site_url}
                    onChange={(event) => {
                      itemList[0].site_url = event.target.value
                        ? event.target.value.replace(/ /g, '%20')
                        : event.target.value
                      validateItem()
                    }}
                    onBlur={(event) => {
                      validateFieldAndUpdateError(
                        validateSiteUrlValid,
                        intl.formatMessage({
                          id: 'form_validation_siteurl',
                          defaultMessage:
                            'Please enter a valid OneIntranet site url.'
                        })
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.form_field}
                    error={
                      getError(itemList[0].language, editFormErrorKeys.site_url)
                        .error
                    }
                  />
                }
                hasError={
                  getError(itemList[0].language, editFormErrorKeys.site_url)
                    .error
                }
                errorText={
                  getError(itemList[0].language, editFormErrorKeys.site_url)
                    .helperText
                }
              />
            )}

            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_datasources',
                defaultMessage: 'Search Verticals'
              })}
              required={true}
              description={intl.formatMessage({
                id: 'form_adword_datasources_desc',
                defaultMessage:
                  'A list of comma separated search verticals where AdWords will be displayed. <b>Default-All</b>'
              })}
              inputControl={renderSelect(
                sources,
                setSources,
                availableSources,
                true,
                '',
                'sources',
                itemList[0],
                isNewItem,
                validateItem,
                false,
                {
                  error: getError(
                    itemList[0].language,
                    editFormErrorKeys.source
                  ).error,
                  helperText: ''
                }
              )}
              hasError={
                getError(itemList[0].language, editFormErrorKeys.source).error
              }
              errorText={
                getError(itemList[0].language, editFormErrorKeys.source)
                  .helperText
              }
            />

            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_countries',
                defaultMessage: 'List of Countries'
              })}
              description={intl.formatMessage({
                id: 'form_adword_countries_desc',
                defaultMessage:
                  'A list of comma separated countries where AdWords will be displayed. <b>Default-All</b>'
              })}
              inputControl={renderSelect(
                countries,
                setCountries,
                countryList,
                true,
                '',
                'countries',
                itemList[0],
                isNewItem,
                validateItem
              )}
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_adword_functions',
                defaultMessage: 'List of Functions'
              })}
              description={intl.formatMessage({
                id: 'form_adword_functions_desc',
                defaultMessage:
                  'A list of comma separated functions where AdWords will be displayed. <b>Default-All</b>'
              })}
              inputControl={renderSelect(
                functions,
                setFunctions,
                functionList,
                true,
                '',
                'functions',
                itemList[0],
                isNewItem,
                validateItem
              )}
            />
          </div>
          <div className={classes.rightFormArea}>
            <div className={classes.rightFormTitle}>
              <FormattedMessage
                id="form_adword_step4_headline"
                defaultMessage="Request an AdWord"
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_adword_step4_q1"
                  defaultMessage="How can I control the reach of AdWords?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_adword_step4_a1',
                      defaultMessage:
                        'There are multiple options including Search Term, Country, Function, Scope etc. that can be used to control the reach, and details for each of them are provided with the field name.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_adword_step4_q2"
                  defaultMessage="Are there any metrics available for AdWord usage?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_adword_step4_a2',
                      defaultMessage:
                        'We have AdWord usage metrics available as part of Enterprise Search metrics dashboard, that can be accessed here: <a href="https://app.powerbi.com/groups/e222ecc1-1a00-44b8-ba77-3e1c524f8e18/reports/40f33a4b-d30b-4566-baca-3f18ba012e1d/ReportSection8dae7197a0d1245c125e?experience=power-bi&chromeless=1" target="_blank">Link to usage metrics</a>'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className={classes.previewArea}>
        <div className={classes.previewHeader}>
          <FormattedMessage id="form_label_preview" defaultMessage="Preview:" />
        </div>
        <div className={classes.preview}>
          <div className={classes.adPreview}>
            <Ads
              adItem={previewItem}
              isRequestPreview={true}
              hasImageSizeWarning={
                !uploadCheckError && hasImageSizeWarning && activeStep === 1
              }
            />
          </div>
        </div>
      </div>
      <MobileStepper
        variant="dots"
        steps={4}
        position="static"
        activeStep={activeStep}
        nextButton={
          <>
            <div>
              <Button onClick={handleBack} disabled={activeStep === 0}>
                <FormattedMessage id="form_button_back" defaultMessage="Back" />
              </Button>
              <Button
                onClick={handleNext}
                disabled={activeStep === 3}
                style={{ marginLeft: '10px' }}
              >
                <FormattedMessage id="form_button_next" defaultMessage="Next" />
              </Button>
              {(activeStep === 3 || !blockSubmitOption) && (
                <Tooltip
                  title={
                    item.status === RequestStatus.Declined
                      ? intl.formatMessage({
                          id: 'form_adword_declined_tooltip',
                          defaultMessage:
                            'The requested AdWord got declined, no changes can be submitted.'
                        })
                      : !hasActiveChanges &&
                        item.status !== RequestStatus.Clarification
                      ? intl.formatMessage({
                          id: 'form_adword_nochange_tooltip',
                          defaultMessage:
                            "The AdWords request didn't have any changes."
                        })
                      : ''
                  }
                  placement={'top'}
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}
                      disabled={
                        loading ||
                        item.status === RequestStatus.Declined ||
                        (!hasActiveChanges &&
                          item.status !== RequestStatus.Clarification &&
                          item.status !== RequestStatus.Draft)
                      }
                      style={{ marginLeft: '10px' }}
                    >
                      {item.status !== RequestStatus.Clarification ? (
                        <FormattedMessage
                          id="form_button_submit"
                          defaultMessage="Submit Request"
                        />
                      ) : (
                        <FormattedMessage
                          id="form_button_resolve_clarification"
                          defaultMessage="Resolve Clarification"
                        />
                      )}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </div>
          </>
        }
        backButton={
          <div style={{ width: '50%' }}>
            {!item.oncePublished && (
              <Button
                onClick={() => saveItem()}
                disabled={loading || item.status === RequestStatus.Declined}
              >
                <FormattedMessage
                  id="form_button_save"
                  defaultMessage="Save as Draft"
                />
              </Button>
            )}
          </div>
        }
      />
      {showCommentDialog && (
        <EditFormCommentDialog
          item={item}
          showCommentDialog={showCommentDialog}
          closeCommentDialog={handleCommentClose}
          submitNewComment={handleCommentSubmit}
          hasActiveChanges={hasActiveChanges}
        />
      )}
    </>
  )
}
