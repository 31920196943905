import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterMenuPeopleSearch(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    menuTopBar: {
      flex: 1,
      display: 'flex',
      height: '43px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e0e0e0',
      position: 'sticky',
      top: 0,
      zIndex: 205,
      backgroundColor: '#fff'
    },
    loadingSpinner: {
      color: 'rgba(0, 0, 0, 0.2)'
    },
    centerLoadingSpinner: {
      textAlign: 'center',
      padding: 5,
      height: 23
    },
    searchResultContainer: {
      overflowY: 'auto',
      height: '270px'
    },
    menuSearchBar: {
      flex: 2,
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginLeft: '10px'
    },
    inputField: {
      marginRight: 8,
      '& .MuiInputBase-input': {
        padding: 0
      },
      '& .MuiInput-input': {
        fontSize: '14px'
      }
    },
    rootMenuItem: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    menuItemSelected: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      backgroundColor: 'rgb(187, 187, 187) !important'
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'column',
      whiteSpace: 'nowrap',
      justifyContent: 'flex-start',
      padding: '0.5em',
      height: '40px',
      zIndex: 200,
      cursor: 'pointer',
      fontSize: '14px'
    },
    gutters: {
      padding: 0
    },
    peopleMenuItem: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    peopleSelectionMail: {
      fontSize: '12px',
      color: 'rgba(0, 0, 0, 0.6)'
    },
    cancelButton: {
      marginRight: 8,
      border: '1px solid #005eb8'
    }
  }))
}
