import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesInlineChangeNotification(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    notificationContainer: {
      position: 'relative',
      paddingBottom: '12px'
    },
    notificationArea: {
      verticalAlign: 'super'
    },
    notificationIconChanged: {
      width: '20px',
      color: '#bc204b',
      cursor: 'pointer'
    },
    notificationDuplicate: {
      width: '20px'
    }
  }))
}
