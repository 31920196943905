import { capitalizeFirstLetter } from 'utils/string'

export interface ISupportedVertical {
  key: string
  name: string
}

export const supportedVerticals: ISupportedVertical[] = [
  {
    key: 'Alex',
    name: 'Alex'
  },
  {
    key: 'Aris',
    name: 'ARIS'
  },
  {
    key: 'Aris_documents',
    name: 'ARIS - Documents'
  },
  {
    key: 'Aris_tom',
    name: 'ARIS - TOM'
  },
  {
    key: 'Customdatasource',
    name: 'Custom Datasource'
  },
  {
    key: 'Engagements',
    name: 'Engagements'
  },
  {
    key: 'Ibfd',
    name: 'IBFD'
  },
  {
    key: 'Images',
    name: 'Images'
  },
  {
    key: 'Intranet',
    name: 'Intranet'
  },
  {
    key: 'Klardenker',
    name: 'Klardenker'
  },
  {
    key: 'Kpmg.com',
    name: 'kpmg.com'
  },
  {
    key: 'Kpmg.us',
    name: 'Kpmg.us'
  },
  {
    key: 'Knowledgeexchange',
    name: 'Knowledge eXchange'
  },
  {
    key: 'Knowledgeexchange_open',
    name: 'Knowledge eXchange - Open'
  },
  {
    key: 'Knowledgeexchange_catalog',
    name: 'Knowledge eXchange - Catalog'
  },
  {
    key: 'Mpp',
    name: 'Manuals'
  },
  {
    key: 'News',
    name: 'News'
  },
  {
    key: 'Office365',
    name: 'My.Data'
  },
  {
    key: 'Office365_files',
    name: 'My.Data - Files'
  },
  {
    key: 'Office365_sites',
    name: 'My.Data - Sites'
  },
  {
    key: 'Office365_mails',
    name: 'My.Data - Mails'
  },
  {
    key: 'Office365_calendar',
    name: 'My.Data - Calendar'
  },
  {
    key: 'Office365_teams',
    name: 'My.Data - Teams'
  },
  {
    key: 'Statistics',
    name: 'Statistics'
  },
  {
    key: 'ServiceNowAU',
    name: 'Service Now AU'
  },
  {
    key: 'Source',
    name: 'Source'
  },
  {
    key: 'People',
    name: 'People'
  },
  {
    key: 'Marketresearch',
    name: 'Market Research'
  },
  {
    key: 'Videos',
    name: 'Videos'
  }
]

export const getVerticalByKey = (source: string): string => {
  if (!source) {
    return ''
  }

  const vertical = supportedVerticals.find(
    (supportedVertical: ISupportedVertical) => {
      return supportedVertical.key.toLowerCase() === source.toLowerCase()
    }
  )

  if (vertical) {
    return vertical.name
  }

  return capitalizeFirstLetter(source)
}
