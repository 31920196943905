import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesAdToolbar(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      '& > div:first-child': { flexGrow: 1, paddingRight: 0 }
    },
    buttonWrapper: { lineHeight: '64px', display: 'inline-block' },
    button: {
      borderRadius: '50%',
      width: '48px',
      height: '48px',
      minWidth: '48px',
      minHeight: '48px',
      display: 'inline-Block',
      padding: 0,
      '&>span': {
        position: 'relative',
        top: '-2px'
      }
    },
    icon: {
      fill: 'rgba(0, 0, 0, 0.54)',
      verticalAlign: 'middle'
    }
  }))
}
