export interface IDeputy {
  userPrincipalName: string
  mail: string
  displayName: string
}

export interface IUserSetting {
  id: string
  EnableNotifications: boolean | undefined
  LastVisit: number | undefined
  Deputies: Array<IDeputy>
  OrgLastVisit: number | undefined
}

export const initialUserSettingState = (): IUserSetting => ({
  id: '',
  LastVisit: undefined,
  EnableNotifications: true,
  Deputies: [],
  OrgLastVisit: undefined
})
