import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRequests(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    scrollfix: {
      '& div > div:nth-child(2) > div  > div': {
        overflowY: 'unset!important'
      },
      '& div > div:nth-child(2) ': {
        overflowX: 'unset!important'
      },
      display: 'table !important',
      width: '100%'
    },
    container: {
      display: 'flex',
      flex: 1,
      flexGrow: 0,
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '85px',
      marginBottom: '80px'
    },
    loadingSpinner: {
      margin: '100px auto'
    },
    loadingSpinnerContainer: {
      width: '100%',
      display: 'flex'
    },
    toolbarContainer: {
      display: 'flex',
      '& > div:first-child': { flexGrow: 1, paddingRight: 0 }
    },
    buttonWrapper: { lineHeight: '70px', display: 'inline-block' },
    filterClearButton: {
      color: '#004e98',
      padding: '9px 16px',
      textAlign: 'right'
    },
    filterSelect: {
      '&& #filterSelect:focus': {
        backgroundColor: '#fff'
      }
    },
    button: {
      borderRadius: '50%',
      width: '48px',
      height: '48px',
      minWidth: '48px',
      minHeight: '48px',
      display: 'inline-Block',
      padding: 0,
      '&>span': {
        position: 'relative',
        top: '-2px'
      }
    },
    buttonAdd: {
      marginRight: '5px'
    },
    buttonText: {
      marginLeft: '5px'
    },
    icon: {
      fill: 'rgba(0, 0, 0, 0.54)',
      verticalAlign: 'middle'
    },
    buttonContainer: {
      position: 'absolute',
      right: '380px',
      top: '0px'
    },
    dropdownItemHeader: {
      display: 'flex',
      flexDirection: 'row-reverse',
      flexWrap: 'nowrap',
      justifyContent: 'space-between'
    }
  }))
}
