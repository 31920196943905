import React from 'react'
import { render } from 'react-dom'
import './index.css'
import App from './App'

const rootElement = document.getElementById('root')

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const RootComponent = () => <App />

render(<RootComponent />, rootElement)
