import { PublicClientApplication } from '@azure/msal-browser'
import * as Config from '../config'
import { validateCurrentLocation } from '../utils/string'

function loggerCallback(logLevel: any, message: any, containsPii: any) {
  if (Config.LOCAL_DEVELOPMENT.toString() === 'true') console.log(message)
}

const msalConfig = {
  auth: {
    clientId: Config.ACTIVE_DIRECTORY_CLIENT_ID,
    authority: Config.ACTIVE_DIRECTORY_AUTHORITY
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
    secureCookies: false
  },
  system: {
    loggerOptions: {
      loggerCallback,
      piiLoggingEnabled: false
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false
  },
  telemetry: {
    application: {
      appName: 'Enterprise Search Settings',
      appVersion: Config.BUILD_NUMBER
    }
  }
}

// Fix for malformed URL, msal auth provider breaks, we need to remove the %25 char and reload.
const newLocation = validateCurrentLocation()
if (newLocation !== '') window.location.href = newLocation

export const authProvider = new PublicClientApplication(msalConfig)
