export interface SupportedFunction {
  name: string
}
export const supportedFunctions: SupportedFunction[] = [
  {
    name: 'Audit'
  },
  {
    name: 'Tax'
  },
  {
    name: 'Advisory'
  },
  {
    name: 'BPG'
  }
]
