import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Checkbox, Typography } from '@mui/material'
import { read, utils, WorkBook } from 'xlsx'
import { getStylesTranslationBulkUpload } from 'styles/admin/TranslationBulkUpload'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import createDOMPurify from 'dompurify'
import {
  SingleTranslationResult,
  TranslationResult
} from 'models/TranslationResult'

interface IProps {
  open: boolean
  tableData: TranslationResult[]
  setOpen: (value: boolean) => void
  handleRowUpdate: (newData: TranslationResult) => void
}

export default function TranslationBulkUpload(props: IProps): JSX.Element {
  const { open, setOpen, handleRowUpdate, tableData } = props
  const [errorText, setErrorText] = useState('')
  const [infoText, setInfoText] = useState('')
  const [isImportDataValid, setIsImportDataValid] = useState(true)
  const [xlsxJson, setXlsxJson] = useState<any>(null)
  const [xlsxWorkbook, setXlsxWorkbook] = useState<WorkBook>()
  const [xlsxSheetList, setXlsxSheetList] = useState<string[]>([])
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const classes = getStylesTranslationBulkUpload()
  const [importedTranslations, setImportedTranslations] = useState<
    TranslationResult[]
  >([])
  const DOMPurify = createDOMPurify(window)

  const getSingleHighestId = (singleResults: SingleTranslationResult[]) => {
    if (!singleResults || singleResults.length === 0) {
      return ''
    }
    const maxId = singleResults.reduce(
      (max, result) => (parseInt(result.id) > max ? parseInt(result.id) : max),
      parseInt(singleResults[0].id)
    )

    return maxId
  }

  useEffect(() => {
    //prevent browser back button
    if (open)
      window.history.pushState(null, document.title, window.location.href)
    window.onpopstate = function () {
      if (open) {
        window.history.go(1)
        setOpen(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const hasTranslationUpdate = (updateData: TranslationResult): boolean => {
    const originalData = tableData.find(
      (orginalTranslation: TranslationResult) =>
        orginalTranslation.key === updateData.key
    )

    if (!originalData) {
      // Row Add
      return false
    } else {
      let hasUpdate = false

      for (let i = 0; i < updateData.translations.length; i++) {
        const orginalSingleTranslation = originalData.translations.find(
          (orgSingleTrans: SingleTranslationResult) =>
            orgSingleTrans.language === updateData.translations[i].language
        )

        if (!orginalSingleTranslation) {
          hasUpdate = true
          break
        } else if (
          updateData.translations[i].value !== orginalSingleTranslation.value
        ) {
          hasUpdate = true
          break
        }
      }

      return hasUpdate
    }
  }

  const setTranslationScope = (updateData: TranslationResult): void => {
    const originalData = tableData.find(
      (orginalTranslation: TranslationResult) =>
        orginalTranslation.key === updateData.key
    )

    if (originalData && originalData.scope) {
      updateData.scope = originalData.scope

      for (let i = 0; i < updateData.translations.length; i++) {
        updateData.translations[i].scope = originalData.scope
      }
    }
  }

  const computeValues = () => {
    // Iterate the imported data and create updates
    if (!importedTranslations || !selectedRows) {
      return false
    }

    selectedRows.forEach((indexImport: number) => {
      const update: TranslationResult = importedTranslations[indexImport]
      if (hasTranslationUpdate(update) && update.isValid) {
        setTranslationScope(update)
        handleRowUpdate(update)
      }
    })

    return true
  }

  /**
   * Find existing key for translation
   * @param key translation key
   * @param locale translation locale
   * @returns id
   */
  const findSingleTanslationResultId = (key: string, locale: string) => {
    const originalData = tableData.find(
      (orginalTranslation: TranslationResult) => orginalTranslation.key === key
    )
    if (originalData) {
      const single = originalData.translations.find(
        (originalSingleTR: SingleTranslationResult) =>
          originalSingleTR.language === locale
      )
      if (single) return single.id
    }
    return '-1'
  }

  useEffect(() => {
    if (xlsxJson && xlsxJson.length > 0) {
      const newImportedValues: TranslationResult[] = []

      //iterate rows
      xlsxJson.forEach((row: string[], indexRow: number) => {
        if (indexRow !== 0) {
          //skip header
          const singleResult: SingleTranslationResult[] = []

          //iterate cols
          row.forEach((col: any, index: number) => {
            //skip key
            if (index !== 0) {
              //get supportedLanguage by name
              const supportedLanguage = supportedLanguages.find(
                (supportedLanguage: SupportedLanguage) =>
                  supportedLanguage.name ===
                  DOMPurify.sanitize(xlsxJson[0][index])
              )

              if (supportedLanguage) {
                singleResult.push({
                  id: findSingleTanslationResultId(
                    DOMPurify.sanitize(row[0]),
                    supportedLanguage.locale
                  ),
                  language: supportedLanguage.locale,
                  value: DOMPurify.sanitize(col),
                  key: DOMPurify.sanitize(row[0])
                })
              }
            }
          })

          let isValid = true
          const errorValues: string[] = []

          // Validate ids are present
          singleResult.forEach((result: SingleTranslationResult) => {
            if (!result.id || result.id === '-1') {
              isValid = false

              errorValues.push(`Missing id for language: '${result.language}'`)
            }
          })

          // Validate all languages
          supportedLanguages.forEach((lang) => {
            const foundTranslation = singleResult.find(
              (translation: SingleTranslationResult) =>
                translation.language === lang.locale
            )

            if (!foundTranslation) {
              isValid = false
              errorValues.push(
                `Missing translation for language: '${lang.locale}'`
              )
            }
          })

          newImportedValues.push({
            id: getSingleHighestId(singleResult).toString(),
            key: DOMPurify.sanitize(row[0]),
            translations: singleResult,
            isValid: isValid,
            errorValues: errorValues
          })
        }
      })

      setImportedTranslations(newImportedValues)
    } else {
      setImportedTranslations([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xlsxJson])

  useEffect(() => {
    setErrorText('')
    setInfoText('')
    setIsImportDataValid(true)
    setXlsxJson(null)
    setXlsxWorkbook(undefined)
    setXlsxSheetList([])
    setSelectedRows([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    let countUpdateItems = 0

    selectedRows.forEach((indexImport: number) => {
      const updateData: TranslationResult = importedTranslations[indexImport]

      if (hasTranslationUpdate(updateData)) {
        countUpdateItems++
      }
    })

    let infoText = ''
    if (countUpdateItems) infoText += 'update ' + countUpdateItems + ' item(s)'

    setInfoText(infoText)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows])

  const readXlsxFile = (fileData: string | ArrayBuffer) => {
    if (!fileData) return

    const workbook = read(fileData, { type: 'buffer', cellDates: true })
    setXlsxWorkbook(workbook)
    setXlsxSheetList(workbook.SheetNames)
  }

  const selectXlsxSheet = (sheetName: string, index: number) => {
    if (xlsxWorkbook) {
      //set json
      setXlsxJson(
        utils.sheet_to_json(xlsxWorkbook.Sheets[sheetName], { header: 1 })
      )
    }
  }

  const addRowIndex = (index: number) => {
    const lastSelectedRows = [...selectedRows]
    const indexSelected = lastSelectedRows.indexOf(index)
    if (indexSelected > -1) {
      //remove
      lastSelectedRows.splice(indexSelected, 1)
    } else {
      //add
      lastSelectedRows.push(index)
    }
    setSelectedRows(lastSelectedRows)
  }

  return (
    <Dialog open={open} fullWidth={true} fullScreen={true}>
      <DialogTitle>Bulk upload</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.spaceBottom}
        >
          <Box>1. Select File (xlsx):</Box>
          <Button
            variant="contained"
            component="label"
            disabled={!!xlsxWorkbook}
          >
            <Box>Upload</Box>
            <input
              type="file"
              hidden
              onChange={(event: any) => {
                const file = event.target.files[0]
                if (file) {
                  const reader = new FileReader()
                  reader.onload = function (evt) {
                    const data = evt.target?.result
                    if (data) readXlsxFile(data)
                  }
                  reader.readAsArrayBuffer(file)
                }
              }}
            />
          </Button>
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.spaceBottom}
        >
          {xlsxSheetList && xlsxSheetList.length > 0 && (
            <>
              <Box>2. Select Sheet:</Box>
              {xlsxSheetList.map((sheetName: string, index: number) => (
                <Button
                  variant="contained"
                  component="label"
                  onClick={() => {
                    setSelectedRows([])
                    selectXlsxSheet(sheetName, index)
                  }}
                >
                  {sheetName}
                </Button>
              ))}
            </>
          )}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.spaceBottom}
        >
          {xlsxJson && importedTranslations && (
            <>
              <Box>3. Select items to import:</Box>
              <Box
                style={{
                  maxWidth: '100%',
                  maxHeight: '300px'
                }}
              >
                <table className={classes.tableStyle}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Key</th>
                      <th>English</th>
                      <th>Error</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importedTranslations.map(
                      (data: TranslationResult, index: number) => {
                        const translation = data.translations.find(
                          (trans: any) => trans.language === 'en'
                        )
                        let translationValue = ''
                        if (translation) translationValue = translation.value

                        return (
                          <tr key={index}>
                            <td>
                              <Checkbox
                                disableRipple
                                color="default"
                                disabled={
                                  data.errorValues &&
                                  data.errorValues.length > 0
                                }
                                checked={selectedRows.includes(index)}
                                onClick={() => {
                                  addRowIndex(index)
                                }}
                              />
                            </td>
                            <td>{DOMPurify.sanitize(data.key)}</td>
                            <td>{DOMPurify.sanitize(translationValue)}</td>
                            <td>
                              <ul>
                                {data.errorValues?.map((errorText: string) => {
                                  return <li>{errorText}</li>
                                })}
                              </ul>
                            </td>
                          </tr>
                        )
                      }
                    )}
                  </tbody>
                </table>
                <div className={classes.tableActions}>
                  <Button
                    variant="outlined"
                    component="label"
                    onClick={() => {
                      const lastSelectedRows = [...selectedRows]

                      for (
                        let index = 0;
                        index < importedTranslations.length;
                        index++
                      ) {
                        const importTranslationItem =
                          importedTranslations[index]
                        const indexSelected = lastSelectedRows.indexOf(index)
                        if (
                          indexSelected === -1 &&
                          (!importTranslationItem.errorValues ||
                            importTranslationItem.errorValues.length === 0)
                        ) {
                          //add
                          lastSelectedRows.push(index)
                        }
                      }

                      setSelectedRows(lastSelectedRows)
                    }}
                  >
                    Select All
                  </Button>
                  <span className={classes.tableActionsSelected}>
                    {selectedRows.length}/{importedTranslations.length} selected
                  </span>
                </div>
              </Box>
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        {errorText && <Box style={{ color: 'red' }}>{errorText}</Box>}
        {infoText && <Box style={{ color: 'red' }}>{infoText}</Box>}
        <Button
          onClick={() => {
            if (computeValues()) {
              setOpen(false)
            } else {
              setErrorText('Import data invalid')
            }
          }}
          color="primary"
          disabled={!isImportDataValid || selectedRows.length === 0}
        >
          Import
        </Button>
        <Button
          onClick={() => {
            setOpen(false)
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
