import { trackException } from './tracking'
import { FeaturedResult } from 'models/FeaturedResult'

/**
 * Ensures that a given result matches the date filtering criteria
 * @param result The result item to filter by
 * @param isStaticJSONUsed Retrocompatibility with static JSON file
 */
export function filterByDate(result: FeaturedResult): boolean {
  let includeResult = false
  try {
    const startDate = result.BestBetStartDate
    const endDate = result.BestBetEndDate
    const bestBetStartDate = new Date(startDate ? startDate : '')
    // End date is optional, so set enddate to max value in case not set
    const bestBetEndDate = endDate
      ? new Date(endDate)
      : new Date(8640000000000000)
    if (bestBetStartDate <= new Date() && bestBetEndDate >= new Date()) {
      includeResult = true
    }
  } catch (error) {
    trackException('Error in filterByDate utils method', error)
  }
  return includeResult
}
