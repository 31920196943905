import { adFlavour, adminScopes } from 'constants/adminSettingTypes'

export type RequestHistory = {
  isNewItem: boolean
  imageChanged?: boolean
  data?:
    | AdsResultHistory[]
    | FeaturedResultHistory
    | DidYouMeanItemHistory
    | undefined
}

export type AdsResultHistory = {
  language: string
  title?: string
  text?: string
  link?: string
  link_text?: string
  start?: string | null
  end?: string | null
  site_url?: string
  search_terms?: string[]
  rank?: number
  sources?: string[]
  functions?: string[]
  countries?: string[]
  match_type?: string
  flavour?: adFlavour
  scope?: adminScopes
}

export const adWordHistoryKeys = [
  'title',
  'text',
  'link',
  'link_text',
  'start',
  'end',
  'site_url',
  'search_terms',
  'rank',
  'sources',
  'functions',
  'countries',
  'match_type',
  'flavour',
  'scope'
]

export interface FeaturedResultHistory {
  BestBetTitle?: string
  BestBetUrl?: string
  BestBetDescription?: string
  BestBetRank?: number | null
  BestBetStartDate?: string | null
  BestBetEndDate?: string | null
  BestBetSiteUrl?: string | null
  BestBetKeywords?: string[]
  BestBetCountry?: string[]
  BestBetCity?: string[]
  BestBetFunction?: string[]
  BestBetMatchType?: string
  BestBetDataSources?: string[]
  BestBetKeyWordMatch?: string
  BestBetScope?: string
}

export const featuredHistoryKeys = [
  'BestBetTitle',
  'BestBetUrl',
  'BestBetDescription',
  'BestBetRank',
  'BestBetStartDate',
  'BestBetEndDate',
  'BestBetSiteUrl',
  'BestBetKeywords',
  'BestBetCountry',
  'BestBetCity',
  'BestBetFunction',
  'BestBetMatchType',
  'BestBetDataSources',
  'BestBetKeyWordMatch',
  'BestBetScope'
]

export interface DidYouMeanItemHistory {
  term?: string
  description?: string
}

export const didYouMeanItemHistoryKeys = ['term', 'description']
