import React, { useRef, useState } from 'react'
import { CardMedia } from '@mui/material'
import MaterialTable from '@material-table/core'
import { Edit } from '@mui/icons-material'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import Logo from 'images/find_logo.png'
import AdToolBar from '../common/AdToolBar'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import {
  SingleTranslationResult,
  TranslationResult
} from 'models/TranslationResult'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import { tableIcons } from 'utils/admin/adminSettingsUtils'
import { translationsTableColumns } from './TranslationsTableColumns'
import { TableStateTranslations } from 'models/TableStates'
const filefy = require('filefy')

export interface ITranslationsTableProps {
  state: TableStateTranslations
  handleRowDelete: any
  handleRowAdd: any
  handleFormOpen: any
  handleTranslationUpdate: any
  adminSettingType: adminSettingTypes
}

const convertForExport = (d: TranslationResult) => {
  const tmp = []
  tmp.push(d.key)

  d.translations.forEach((lang: SingleTranslationResult) => {
    const languageIndex = supportedLanguages.findIndex(
      (language: SupportedLanguage) => language.locale === lang.language
    )
    tmp[languageIndex + 1] = lang.value
  })

  return tmp
}

export default function TranslationsTable(
  props: ITranslationsTableProps
): JSX.Element {
  const {
    state,
    handleRowDelete,
    handleRowAdd,
    handleFormOpen,
    handleTranslationUpdate,
    adminSettingType
  } = props

  const classes = getStylesAdminSettings()
  const [isFilterSet, setIsFilterSet] = useState(false)

  //force browser scrollbars
  const rootElement = document.getElementById('root')
  if (rootElement) rootElement.style.flexDirection = 'unset'

  const tableColumns = useRef(
    translationsTableColumns(
      adminSettingType === adminSettingTypes.oitranslations
    )
  )

  if (adminSettingType === adminSettingTypes.translations) {
    return (
      <MaterialTable
        title={
          <>
            <div className={classes.header}>
              <CardMedia image={Logo} className={classes.logo} />
              <h2>Translations Configuration</h2>
            </div>
            <div className={classes.information}>
              Any changes being made to the Translations using this
              configuration screen are only valid for Find application.
            </div>
          </>
        }
        icons={tableIcons}
        columns={tableColumns.current}
        data={state.data as TranslationResult[]}
        options={{
          rowStyle: () => ({
            fontSize: 12
          }),
          addRowPosition: 'first',
          pageSize: 25,
          pageSizeOptions: [25, 50, 75],
          tableLayout: 'auto',
          filtering: true,
          emptyRowsWhenPaging: false,
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (data, columns) => {
                const languageArray: any[] = []

                if (state.data && state.data.length > 0) {
                  const translations = state.data as TranslationResult[]
                  const exportData = columns

                  translations.forEach((d: TranslationResult) => {
                    if (isFilterSet) {
                      if (
                        d.translations &&
                        d.translations.length > 0 &&
                        exportData.find((eD: any) => eD.key === d.key)
                      ) {
                        languageArray.push(convertForExport(d))
                      }
                    } else {
                      if (d.translations && d.translations.length > 0) {
                        languageArray.push(convertForExport(d))
                      }
                    }
                  })
                }

                const builder = new filefy.CsvBuilder('Translations-Global.csv')
                builder
                  .setDelimeter(';')
                  .setColumns([
                    'key',
                    ...supportedLanguages.map(
                      (lang: SupportedLanguage) => lang.name
                    )
                  ])
                  .addRows(languageArray)
                  .exportFile()
              }
            }
          ]
        }}
        localization={{
          body: {
            editRow: {
              deleteText:
                'Are you sure you want to delete this row? Please remember to publish!'
            }
          }
        }}
        editable={{
          onRowDelete: handleRowDelete
        }}
        actions={[
          {
            icon: Edit,
            onClick: (
              event: any,
              rowData: TranslationResult | TranslationResult[]
            ) => {
              if (!(rowData instanceof Array)) {
                handleFormOpen(rowData)
              }
            },
            tooltip: 'Edit'
          }
        ]}
        components={{
          Toolbar: (props: any) => (
            <AdToolBar
              {...{
                ...props,
                handleRowAdd: handleRowAdd,
                handleRowUpdate: handleTranslationUpdate,
                addButtonText: 'Add Translation',
                adminSettingType: adminSettingType
              }}
            />
          )
        }}
        onFilterChange={(filters: any) => {
          setIsFilterSet(filters.length > 0)
        }}
      />
    )
  } else if (adminSettingType === adminSettingTypes.oitranslations) {
    return (
      <MaterialTable
        title={
          <>
            <div className={classes.header}>
              <CardMedia image={Logo} className={classes.logo} />
              <h2>OI / Teams Translations Configuration</h2>
            </div>
            <div className={classes.information}>
              Any changes being made to the Translations using this
              configuration screen are only valid for Enterprise Search - OI /
              Teams application.
            </div>
          </>
        }
        icons={tableIcons}
        columns={tableColumns.current}
        data={state.data as TranslationResult[]}
        options={{
          rowStyle: () => ({
            fontSize: 12
          }),
          addRowPosition: 'first',
          pageSize: 25,
          pageSizeOptions: [25, 50, 75],
          tableLayout: 'auto',
          filtering: true,
          emptyRowsWhenPaging: false,
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (data, columns) => {
                const languageArray: any[] = []

                if (state.data && state.data.length > 0) {
                  const translations = state.data as TranslationResult[]
                  const exportData = columns

                  translations.forEach((d: TranslationResult) => {
                    if (isFilterSet) {
                      if (
                        d.translations &&
                        d.translations.length > 0 &&
                        exportData.find((eD: any) => eD.key === d.key)
                      ) {
                        languageArray.push(convertForExport(d))
                      }
                    } else {
                      if (d.translations && d.translations.length > 0) {
                        languageArray.push(convertForExport(d))
                      }
                    }
                  })
                }

                const builder = new filefy.CsvBuilder('Translations-OI.csv')
                builder
                  .setDelimeter(';')
                  .setColumns([
                    'key',
                    ...supportedLanguages.map(
                      (lang: SupportedLanguage) => lang.name
                    )
                  ])
                  .addRows(languageArray)
                  .exportFile()
              }
            }
          ]
        }}
        localization={{
          body: {
            editRow: {
              deleteText:
                'Are you sure you want to delete this row? Please remember to publish!'
            }
          }
        }}
        editable={{
          onRowDelete: handleRowDelete
        }}
        actions={[
          {
            icon: Edit,
            onClick: (
              event: any,
              rowData: TranslationResult | TranslationResult[]
            ) => {
              if (!(rowData instanceof Array)) {
                handleFormOpen(rowData)
              }
            },
            tooltip: 'Edit'
          }
        ]}
        components={{
          Toolbar: (props: any) => (
            <AdToolBar
              {...{
                ...props,
                handleRowAdd: handleRowAdd,
                handleRowUpdate: handleTranslationUpdate,
                addButtonText: 'Add Translation',
                adminSettingType: adminSettingType
              }}
            />
          )
        }}
        onFilterChange={(filters: any) => {
          setIsFilterSet(filters.length > 0)
        }}
      />
    )
  } else if (adminSettingType === adminSettingTypes.settingstranslations) {
    return (
      <MaterialTable
        title={
          <>
            <div className={classes.header}>
              <CardMedia image={Logo} className={classes.logo} />
              <h2>Settings Translations Configuration</h2>
            </div>
            <div className={classes.information}>
              Any changes being made to the Translations using this
              configuration screen are only valid for ES-Settings application.
            </div>
          </>
        }
        icons={tableIcons}
        columns={tableColumns.current}
        data={state.data as TranslationResult[]}
        options={{
          rowStyle: () => ({
            fontSize: 12
          }),
          addRowPosition: 'first',
          pageSize: 25,
          pageSizeOptions: [25, 50, 75],
          tableLayout: 'auto',
          filtering: true,
          emptyRowsWhenPaging: false,
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (data, columns) => {
                const languageArray: any[] = []

                if (state.data && state.data.length > 0) {
                  const translations = state.data as TranslationResult[]
                  const exportData = columns

                  translations.forEach((d: TranslationResult) => {
                    if (isFilterSet) {
                      if (
                        d.translations &&
                        d.translations.length > 0 &&
                        exportData.find((eD: any) => eD.key === d.key)
                      ) {
                        languageArray.push(convertForExport(d))
                      }
                    } else {
                      if (d.translations && d.translations.length > 0) {
                        languageArray.push(convertForExport(d))
                      }
                    }
                  })
                }

                const builder = new filefy.CsvBuilder(
                  'Translations-ES-Settings.csv'
                )
                builder
                  .setDelimeter(';')
                  .setColumns([
                    'key',
                    ...supportedLanguages.map(
                      (lang: SupportedLanguage) => lang.name
                    )
                  ])
                  .addRows(languageArray)
                  .exportFile()
              }
            }
          ]
        }}
        localization={{
          body: {
            editRow: {
              deleteText:
                'Are you sure you want to delete this row? Please remember to publish!'
            }
          }
        }}
        editable={{
          onRowDelete: handleRowDelete
        }}
        actions={[
          {
            icon: Edit,
            onClick: (
              event: any,
              rowData: TranslationResult | TranslationResult[]
            ) => {
              if (!(rowData instanceof Array)) {
                handleFormOpen(rowData)
              }
            },
            tooltip: 'Edit'
          }
        ]}
        components={{
          Toolbar: (props: any) => (
            <AdToolBar
              {...{
                ...props,
                handleRowAdd: handleRowAdd,
                handleRowUpdate: handleTranslationUpdate,
                addButtonText: 'Add Translation',
                adminSettingType: adminSettingType
              }}
            />
          )
        }}
        onFilterChange={(filters: any) => {
          setIsFilterSet(filters.length > 0)
        }}
      />
    )
  }
  return <></>
}
