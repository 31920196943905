import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Store } from '..'
import * as Config from '../../config'
import AuthStore from '../Auth'
import { trackEvents, trackException } from 'utils/tracking'
import * as Constants from 'constants/constants'
import { AdsResult } from 'models/AdsResult'
import { translationTypes } from 'constants/constants'
import { TranslationResult } from 'models/TranslationResult'
import { PopupResult } from 'models/PopupResult'
import { renewAuthorizationToken } from 'authentication/token'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import { createRandomBoundryString } from 'utils/string'

export enum TranslationsActionTypes {
  FETCH_VERSION_TRANSLATIONS_REQUEST = 'translations/FETCH_VERSION_TRANSLATIONS_REQUEST',
  FETCH_VERSION_TRANSLATIONS_SUCCESS = 'translations/FETCH_VERSION_TRANSLATIONS_SUCCESS',
  FETCH_ALL_GLOBAL_TRANSLATIONS_REQUEST = 'translations/FETCH_ALL_GLOBAL_TRANSLATIONS_REQUEST',
  FETCH_ALL_GLOBAL_TRANSLATIONS_SUCCESS = 'translations/FETCH_ALL_GLOBAL_TRANSLATIONS_SUCCESS',
  FETCH_ALL_GLOBAL_OI_TRANSLATIONS_REQUEST = 'translations/FETCH_ALL_GLOBAL_OI_TRANSLATIONS_REQUEST',
  FETCH_ALL_GLOBAL_OI_TRANSLATIONS_SUCCESS = 'translations/FETCH_ALL_GLOBAL_OI_TRANSLATIONS_SUCCESS',
  FETCH_ALL_SETTINGS_TRANSLATIONS_REQUEST = 'translations/FETCH_ALL_SETTINGS_TRANSLATIONS_REQUEST',
  FETCH_ALL_SETTINGS_TRANSLATIONS_SUCCESS = 'translations/FETCH_ALL_SETTINGS_TRANSLATIONS_SUCCESS',
  FETCH_ALL_ADS_TRANSLATIONS_REQUEST = 'translations/FETCH_ALL_ADS_TRANSLATIONS_REQUEST',
  FETCH_ALL_ADS_TRANSLATIONS_SUCCESS = 'translations/FETCH_ALL_ADS_TRANSLATIONS_SUCCESS',
  FETCH_ALL_POPUPS_TRANSLATIONS_REQUEST = 'translations/FETCH_ALL_POPUPS_TRANSLATIONS_REQUEST',
  FETCH_ALL_POPUPS_TRANSLATIONS_SUCCESS = 'translations/FETCH_ALL_POPUPS_TRANSLATIONS_SUCCESS',
  FETCH_TRANSLATIONS_NOTFOUND = 'translations/FETCH_TRANSLATIONS_NOTFOUND',
  FETCH_TRANSLATIONS_FAILURE = 'translations/FETCH_TRANSLATIONS_FAILURE',
  DELETE_TRANSLATION_SUCCESS = 'translations/DELETE_TRANSLATION_SUCCESS',
  UPSERT_TRANSLATION_SUCCESS = 'translations/UPSERT_TRANSLATION_SUCCESS',
  UPSERT_IMAGE_FAILURE = 'translations/UPSERT_IMAGE_FAILURE',
  UPSERT_IMAGE_SUCCESS = 'translations/UPSERT_IMAGE_SUCCESS',
  UPSERT_IMAGE_REQUEST = 'translations/UPSERT_IMAGE_REQUEST',
  UPSERT_IMAGE_EXISTS = 'translations/UPSERT_IMAGE_EXISTS',
  UPSERT_IMAGE_VALIDATION = 'translations/UPSERT_IMAGE_VALIDATION',
  CHANGE_TRANSLATIONS_FAILURE = 'translations/CHANGE_TRANSLATIONS_FAILURE',
  UPSERT_VERSION_REQUEST = 'translations/UPSERT_VERSION_REQUEST',
  UPSERT_VERSION_FAILURE = 'translations/UPSERT_VERSION_FAILURE',
  UPSERT_VERSION_SUCCESS = 'translations/UPSERT_VERSION_SUCCESS',
  FETCH_SETTINGS_TRANSLATIONS_REQUEST = 'translations/FETCH_SETTINGS_TRANSLATIONS_REQUEST',
  FETCH_SETTINGS_TRANSLATIONS_SUCCESS = 'translations/FETCH_SETTINGS_TRANSLATIONS_SUCCESS',
  FETCH_SETTINGS_TRANSLATIONS_FAILURE = 'translations/FETCH_SETTINGS_TRANSLATIONS_FAILURE'
}

export type IFetchAllGlobalTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchAllGlobalTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    allGlobalResponse: any
  }
}
export type IFetchAllGlobalOITranslationsRequest =
  Action<TranslationsActionTypes>
export interface IFetchAllGlobalOITranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: { allGlobalOIResponse: any }
}
export type IFetchAllSettingsTranslationsRequest =
  Action<TranslationsActionTypes>
export interface IFetchAllSettingsTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    allSettingsResponse: any
  }
}

export type IFetchAllAdsTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchAllAdsTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    allAdsResponse: any
  }
}

export type IFetchAllPopupsTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchAllPopupsTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    allPopupsResponse: any
  }
}

export type IFetchTranslationsFailure = Action<TranslationsActionTypes>
export type IFetchTranslationsNotFound = Action<TranslationsActionTypes>
export interface IDeleteTranslationSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    translationId: string
  }
}
export interface IUpsertTranslationSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    upsertedTranslation: AdsResult | any
  }
}
export type IChangeTranslationsFailure = Action<TranslationsActionTypes>

export const fetchAllGlobalTranslationsRequest =
  (): IFetchAllGlobalTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_ALL_GLOBAL_TRANSLATIONS_REQUEST
  })
export const fetchAllGlobalTranslationsSuccess = (
  allGlobalResponse: any = {}
): IFetchAllGlobalTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_ALL_GLOBAL_TRANSLATIONS_SUCCESS,
  payload: { allGlobalResponse }
})
export const fetchAllGlobalOITranslationsRequest =
  (): IFetchAllGlobalOITranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_ALL_GLOBAL_OI_TRANSLATIONS_REQUEST
  })
export const fetchAllGlobalOITranslationsSuccess = (
  allGlobalOIResponse: any = {}
): IFetchAllGlobalOITranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_ALL_GLOBAL_OI_TRANSLATIONS_SUCCESS,
  payload: { allGlobalOIResponse }
})
export const fetchAllSettingsTranslationsRequest =
  (): IFetchAllSettingsTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_ALL_SETTINGS_TRANSLATIONS_REQUEST
  })
export const fetchAllSettingsTranslationsSuccess = (
  allSettingsResponse: any = {}
): IFetchAllSettingsTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_ALL_SETTINGS_TRANSLATIONS_SUCCESS,
  payload: { allSettingsResponse }
})
export const fetchAllAdsTranslationsRequest =
  (): IFetchAllAdsTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_ALL_ADS_TRANSLATIONS_REQUEST
  })
export const fetchAllAdsTranslationsSuccess = (
  allAdsResponse: any = {}
): IFetchAllAdsTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_ALL_ADS_TRANSLATIONS_SUCCESS,
  payload: { allAdsResponse }
})
export const fetchAllPopupsTranslationsRequest =
  (): IFetchAllPopupsTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_ALL_POPUPS_TRANSLATIONS_REQUEST
  })

export const fetchAllPopupsTranslationsSuccess = (
  allPopupsResponse: any = {}
): IFetchAllPopupsTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_ALL_POPUPS_TRANSLATIONS_SUCCESS,
  payload: { allPopupsResponse }
})

export const fetchTranslationsNotFound = (
  response: any = {}
): IFetchTranslationsNotFound => ({
  type: TranslationsActionTypes.FETCH_TRANSLATIONS_NOTFOUND
})

export const fetchTranslationsFailure = (): IFetchTranslationsFailure => ({
  type: TranslationsActionTypes.FETCH_TRANSLATIONS_FAILURE
})

export const deleteTranslationSuccess = (
  translationId: string
): IDeleteTranslationSuccess => ({
  type: TranslationsActionTypes.DELETE_TRANSLATION_SUCCESS,
  payload: { translationId }
})

export const upsertTranslationSuccess = (
  upsertedTranslation: AdsResult | any
): IUpsertTranslationSuccess => ({
  type: TranslationsActionTypes.UPSERT_TRANSLATION_SUCCESS,
  payload: { upsertedTranslation }
})

export const changeTranslationsFailure = (): IChangeTranslationsFailure => ({
  type: TranslationsActionTypes.CHANGE_TRANSLATIONS_FAILURE
})

export interface IUpsertImageResult {
  translationId: string
  url: string | null
}

export interface IUpsertImageSuccess extends Action<TranslationsActionTypes> {
  payload: {
    imageSuccess: any
  }
}
export interface IUpsertImageRequest extends Action<TranslationsActionTypes> {
  payload: {
    imageRequest: any
  }
}

export const upsertImageSuccess = (
  imageSuccess: IUpsertImageResult
): IUpsertImageSuccess => ({
  type: TranslationsActionTypes.UPSERT_IMAGE_SUCCESS,
  payload: { imageSuccess }
})
export const upsertImageFailure = (): IChangeTranslationsFailure => ({
  type: TranslationsActionTypes.UPSERT_IMAGE_FAILURE
})
export const upsertImageExists = (): IChangeTranslationsFailure => ({
  type: TranslationsActionTypes.UPSERT_IMAGE_EXISTS
})
export const upsertImageValidation = (): IChangeTranslationsFailure => ({
  type: TranslationsActionTypes.UPSERT_IMAGE_VALIDATION
})
export const upsertImageRequest = (
  imageRequest: IUpsertImageResult
): IUpsertImageRequest => ({
  type: TranslationsActionTypes.UPSERT_IMAGE_REQUEST,
  payload: { imageRequest }
})

export const upsertImageReset = (
  imageRequest: IUpsertImageResult
): IUpsertImageRequest => ({
  type: TranslationsActionTypes.UPSERT_IMAGE_REQUEST,
  payload: { imageRequest }
})

export type IUpsertVersionSuccess = Action<TranslationsActionTypes>
export type IUpsertVersionRequest = Action<TranslationsActionTypes>

export const upsertVersionSuccess = (): IUpsertVersionSuccess => ({
  type: TranslationsActionTypes.UPSERT_VERSION_SUCCESS
})

export const upsertVersionFailure = (): IChangeTranslationsFailure => ({
  type: TranslationsActionTypes.UPSERT_VERSION_FAILURE
})

export const upsertVersionRequest = (): IUpsertVersionRequest => ({
  type: TranslationsActionTypes.UPSERT_VERSION_REQUEST
})

/** Fetch Versions */
export type IFetchVersionTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchVersionTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    translationType: string
    version: string
    maxid: string
  }
}

export const fetchVersionTranslationsRequest =
  (): IFetchVersionTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_VERSION_TRANSLATIONS_REQUEST
  })
export const fetchVersionTranslationsSuccess = (
  translationType: string,
  version: any = {},
  maxid: any = {}
): IFetchVersionTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_VERSION_TRANSLATIONS_SUCCESS,
  payload: { translationType, version, maxid }
})

export const fetchVersionTranslations = (
  translationType: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchVersionTranslationsRequest())
    try {
      trackEvents('getVersionsTranslations', {})

      const apiUrl = `${Config.APIM_BASE_URL}translationsapi/gettranslations?language=version&type=${translationType}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!data || !data.ok) {
        dispatch(fetchTranslationsFailure())
        trackException(
          `Error in fetching version translations: ${translationType}`,
          new Error('Error in data object after fetching version translations')
        )
        return
      } else if (data.status === 404) {
        dispatch(fetchTranslationsNotFound())
        return
      }

      const result = await data.json()

      if (!result || !result.Documents) {
        dispatch(fetchTranslationsFailure())
        trackException(
          `Error in getting documents out of translations: ${translationType}`,
          new Error('Error in data object after fetching translations')
        )
        return
      }
      dispatch(
        fetchVersionTranslationsSuccess(
          translationType,
          result.Documents[0].value,
          result.Documents[0].maxid ? result.Documents[0].maxid : '-1'
        )
      )
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException(
        `Error in fetching version translations action: ${translationType}`,
        error
      )
    }
  }
}

/** Fetch settings translations */
export type IFetchSettingsTranslationsRequest = Action<TranslationsActionTypes>
export interface IFetchSettingsTranslationsSuccess
  extends Action<TranslationsActionTypes> {
  payload: {
    settingsResponse: any
  }
}
export type IFetchSettingsTranslationsFailure = Action<TranslationsActionTypes>

export const fetchSettingsTranslationsRequest =
  (): IFetchSettingsTranslationsRequest => ({
    type: TranslationsActionTypes.FETCH_SETTINGS_TRANSLATIONS_REQUEST
  })
export const fetchSettingsTranslationsSuccess = (
  settingsResponse: any = {}
): IFetchSettingsTranslationsSuccess => ({
  type: TranslationsActionTypes.FETCH_SETTINGS_TRANSLATIONS_SUCCESS,
  payload: { settingsResponse }
})

export const fetchSettingsTranslationsFailure =
  (): IFetchTranslationsFailure => ({
    type: TranslationsActionTypes.FETCH_SETTINGS_TRANSLATIONS_FAILURE
  })

export const fetchSettingsTranslations = (
  language: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchSettingsTranslationsRequest())
    try {
      const translations = getState() ? getState().translations : null
      const translationsVersion = translations
        ? translations.settingsTranslationsVersion
        : '0'
      let result: any = readAndCleanUpLocalStorage(
        Constants.translationTypes.settings,
        language,
        translationsVersion
      )

      if (result === null) {
        const apiUrl = `${Config.APIM_BASE_URL}translationsapi/gettranslations?language=${language}&type=${Constants.translationTypes.settings}`

        // Get and check authentication token
        const aadInfo = AuthStore.selectors.getAADInfo(getState())
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )
        if (esToken !== aadInfo.accessToken) {
          AuthStore.actions.setAuthToken(esToken)
        }
        if (esToken === '') {
          return
        }

        const data = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (!data || !data.ok || data.status !== 200) {
          dispatch(fetchSettingsTranslationsFailure())
          trackException(
            'Error in fetching settings translations',
            new Error(
              'Error in data object after fetching settings translations'
            )
          )
          return
        }

        const results = await data.json()

        if (!results || !results.Documents) {
          dispatch(fetchSettingsTranslationsFailure())
          trackException(
            'Error in getting documents out of translations',
            new Error('Error in data object after fetching translations')
          )
          return
        }

        result = []
        results.Documents.map((document: any) => {
          result.push(document)
          return ''
        })
      }

      updateLocalStorage(
        result,
        Constants.translationTypes.settings,
        language,
        translationsVersion
      )

      const parsedResults: any = []
      result.map((document: any) => {
        parsedResults[document.key.replace("'", "'")] = document.value.replace(
          "'",
          "'"
        )
        return ''
      })

      dispatch(fetchSettingsTranslationsSuccess(parsedResults))
    } catch (error) {
      dispatch(fetchSettingsTranslationsFailure())
      trackException('Error in fetching settings translations action', error)
    }
  }
}

export const fetchAllGlobalTranslations = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchAllGlobalTranslationsRequest())
    try {
      trackEvents('getGlobalTranslations', {})

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}translationsapi/getalltranslations?type=${Constants.translationTypes.global}`

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!data || !data.ok) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in fetching global translations',
          new Error(
            'Error in data object after fetching all global translations'
          )
        )
        return
      } else if (data.status === 404) {
        dispatch(fetchTranslationsNotFound())
        return
      }

      const results: TranslationResult[] = await data.json()

      if (!results) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in getting documents out of translations',
          new Error('Error in data object after fetching translations')
        )
        return
      }

      dispatch(fetchAllGlobalTranslationsSuccess(results))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException('Error in fetching all global translations action', error)
    }
  }
}

export const fetchAllGlobalOITranslations = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchAllGlobalOITranslationsRequest())
    try {
      trackEvents('getGlobalOITranslations', {})

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}translationsapi/getalltranslations?type=${Constants.translationTypes.globaloi}`

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!data || !data.ok) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in fetching global oi translations',
          new Error(
            'Error in data object after fetching all global oi translations'
          )
        )
        return
      } else if (data.status === 404) {
        dispatch(fetchTranslationsNotFound())
        return
      }

      const results: TranslationResult[] = await data.json()

      if (!results) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in getting documents out of translations',
          new Error('Error in data object after fetching translations')
        )
        return
      }

      dispatch(fetchAllGlobalOITranslationsSuccess(results))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException(
        'Error in fetching all global oi translations action',
        error
      )
    }
  }
}

export const fetchAllSettingsTranslations = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchAllSettingsTranslationsRequest())
    try {
      trackEvents('getAllSettingsTranslations', {})

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}translationsapi/getalltranslations?type=${Constants.translationTypes.settings}`

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!data || !data.ok) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in fetching all settings translations',
          new Error(
            'Error in data object after fetching all settings translations'
          )
        )
        return
      } else if (data.status === 404) {
        dispatch(fetchTranslationsNotFound())
        return
      }

      const results: TranslationResult[] = await data.json()

      if (!results) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in getting documents out of settings translations',
          new Error('Error in data object after fetching settings translations')
        )
        return
      }

      dispatch(fetchAllSettingsTranslationsSuccess(results))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException(
        'Error in fetching all settings translations action',
        error
      )
    }
  }
}

export const fetchAllAdsTranslations = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchAllAdsTranslationsRequest())
    try {
      trackEvents('getAllAdsTranslations', {})

      const apiUrl = `${Config.APIM_BASE_URL}translationsapi/gettranslations?language=all&type=${Constants.translationTypes.ads}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!data || !data.ok) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in fetching all ads translations',
          new Error('Error in data object after fetching all ads translations')
        )
        return
      } else if (data.status === 404) {
        dispatch(fetchTranslationsNotFound())
        return
      }

      const results = await data.json()

      if (!results || !results.Documents) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in getting documents out of translations',
          new Error('Error in data object after fetching translations')
        )
        return
      }

      const result: any = []
      results.Documents.map((document: AdsResult) => {
        result.push(document)
        return ''
      })

      dispatch(fetchAllAdsTranslationsSuccess(result))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException('Error in fetching all ads translations action', error)
    }
  }
}

export const fetchAllPopupTranslations = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchAllPopupsTranslationsRequest())
    try {
      trackEvents('getAllPopupTranslations', {})

      const apiUrl = `${Config.APIM_BASE_URL}translationsapi/gettranslations?language=all&type=${Constants.translationTypes.popups}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!data || !data.ok) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in fetching all popups translations',
          new Error(
            'Error in data object after fetching all popups translations'
          )
        )
        return
      } else if (data.status === 404) {
        dispatch(fetchTranslationsNotFound())
        return
      }

      const results = await data.json()

      if (!results || !results.Documents) {
        dispatch(fetchTranslationsFailure())
        trackException(
          'Error in getting documents out of translations',
          new Error('Error in data object after fetching translations')
        )
        return
      }

      const result: any = []
      results.Documents.map((document: PopupResult) => {
        result.push(document)
        return ''
      })

      dispatch(fetchAllPopupsTranslationsSuccess(result))
    } catch (error) {
      dispatch(fetchTranslationsFailure())
      trackException('Error in fetching all popups translations action', error)
    }
  }
}

export const deleteTranslation = (
  translationId: string,
  translationType: string,
  translationLanguage: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      trackEvents('deleteTranslationRequest', {})

      let apiUrl = `${Config.APIM_BASE_URL}translationsapi/deletetranslation?docId=${translationId}&language=${translationLanguage}&type=${translationType}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 204) {
        trackException(
          'Error in delete translation',
          new Error('Error in data object after deleting translation')
        )
        dispatch(changeTranslationsFailure())
        return
      }

      // Delete translations from Search Index
      if (
        translationType === translationTypes.ads &&
        translationLanguage === 'en'
      ) {
        // Get all translations from search index by id
        apiUrl = `${Config.APIM_BASE_URL}searchapi/getAdwords?docId=${translationId}`
        const ads = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })
        if (ads && ads.status === 200) {
          const items = (await ads.json()).value
          for (let i = 0; i < items.length; i++) {
            apiUrl = `${Config.APIM_BASE_URL}searchapi/deletesearchindexentry?docId=${items[i].rid}&datasource=kpmg-find-adwords-index`
            await fetch(apiUrl, {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
                Authorization: `Bearer ${esToken}`
              }
            })
          }
        }
      }

      dispatch(deleteTranslationSuccess(translationId))
    } catch (error) {
      trackException('Error in delete translation action', error)
      dispatch(changeTranslationsFailure())
    }
  }
}

export const upsertTranslation = (
  translationData: AdsResult | any,
  translationType: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      trackEvents('upsertTranslationRequest', {})

      const translation = JSON.parse(JSON.stringify(translationData))
      if (translation.tableData) {
        delete translation.tableData
      }
      if (translation.draft) {
        delete translation.draft
      }
      if (translation.childs) {
        delete translation.childs
      }

      const apiUrl = `${Config.APIM_BASE_URL}translationsapi/upserttranslation?docId=${translation.id}&language=${translation.language}&type=${translationType}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const utf8Bytes = encodeURIComponent(JSON.stringify(translation)).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode(Number('0x' + p1))
        }
      )

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body: '{"content":"' + btoa(utf8Bytes) + '"}'
      })
      if (data.status !== 200 && data.status !== 201) {
        trackException(
          'Error in upsert translation',
          new Error('Error in data object after upserting translation')
        )
        dispatch(changeTranslationsFailure())
        return
      }

      dispatch(upsertTranslationSuccess(translation))
    } catch (error) {
      trackException('Error in upsert translation action', error)
      dispatch(changeTranslationsFailure())
    }
  }
}

export const upsertImage = (
  fileName: string,
  fileContent: string,
  translationId: string,
  overWriteImage: boolean
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      trackEvents('upsertImageUploadRequest', {})

      dispatch(
        upsertImageRequest({
          translationId: translationId,
          url: null
        })
      )
      const apiUrl = `${
        Config.APIM_BASE_URL
      }translationsapi/upsertimage?filename=${fileName}${
        overWriteImage ? '&overwriteimage=true' : ''
      }`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const boundry = createRandomBoundryString(10)
      const formData = `--${boundry}\r\nContent-Disposition: form-data; name=""; filename="${fileName}"\r\nContent-Type: image/png\r\n\r\n${fileContent}\r\n--${boundry}--\r\n`

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': `multipart/form-data; boundary=${boundry}`,
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body: formData
      })
      if (data.status !== 200 && data.status !== 201) {
        if (data.status === 405) {
          trackException(
            'Error in upsert image, image already exists',
            new Error(
              'Error in upsert image, image already exists error in data object after image upload'
            )
          )
          dispatch(upsertImageExists())
          return
        } else if (data.status === 406) {
          trackException(
            'Error in upsert image, image validation failed',
            new Error(
              'Error in upsert image, image validation failed error in data object after image upload'
            )
          )
          dispatch(upsertImageValidation())
          return
        } else {
          trackException(
            'Error in upsert image',
            new Error('Error in data object after image upload')
          )
          dispatch(upsertImageFailure())
          return
        }
      }

      const result = await data.json()

      if (!result || !result.url) {
        dispatch(upsertImageFailure())
        trackException(
          'Error in getting documents out of image upsert',
          new Error('Error in data object after image upsert')
        )
        return
      }

      dispatch(
        upsertImageSuccess({
          translationId: translationId,
          url: result.url
        })
      )
    } catch (error) {
      trackException('Error in upsert image action', error)
      dispatch(upsertImageFailure())
    }
  }
}

export const upsertVersion = (
  translationType: string,
  maxId?: number
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      trackEvents('upsertAdsVersionRequest', {})
      dispatch(upsertVersionRequest())

      let apiUrl = `${Config.APIM_BASE_URL}translationsapi/upsertversion?type=${translationType}`
      if (maxId) {
        apiUrl += `&maxid=${maxId}`
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (response.status !== 200) {
        trackException(
          'Error in upsert version',
          new Error('Error in upsert version')
        )
        dispatch(upsertVersionFailure())
        return
      } else {
        dispatch(upsertVersionSuccess())
      }
    } catch (error) {
      trackException('Error in upsert version action', error)
      dispatch(upsertVersionFailure())
    }
  }
}

const readAndCleanUpLocalStorage = (
  translationType: string,
  language: string,
  version: string
): void => {
  let result: any = null
  try {
    const translationsVersionFromLS = localStorage
      ? localStorage.getItem(translationType + '-Version')
      : ''
    const translationsFromLS = localStorage
      ? localStorage.getItem(translationType + '-' + language)
      : ''

    if (
      translationsVersionFromLS &&
      translationsVersionFromLS !== '' &&
      translationsFromLS &&
      translationsFromLS !== ''
    ) {
      if (version === translationsVersionFromLS) {
        result = JSON.parse(translationsFromLS)
      } else if (
        translationsVersionFromLS &&
        translationsVersionFromLS !== version
      ) {
        // In this case the version has changed and all existing translations for this type are not valid,
        // so remove translations for this type in other languages.
        // If this is done, the translations will be fetched on the next language change
        supportedLanguages.forEach((supportedLanguage: SupportedLanguage) => {
          if (supportedLanguage.locale !== language) {
            localStorage.removeItem(
              translationType + '-' + supportedLanguage.locale
            )
          }
        })
      }
    }
  } catch (e) {
    result = null
  }
  return result
}

const updateLocalStorage = (
  items: any[],
  translationType: string,
  language: string,
  version: string
): void => {
  localStorage.setItem(translationType + '-' + language, JSON.stringify(items))
  localStorage.setItem(translationType + '-Version', version)
}

export type TranslationsActions =
  | IFetchAllGlobalOITranslationsSuccess
  | IFetchAllAdsTranslationsSuccess
  | IFetchAllPopupsTranslationsSuccess
  | IFetchTranslationsFailure
  | IFetchTranslationsNotFound
  | IDeleteTranslationSuccess
  | IUpsertTranslationSuccess
  | IUpsertImageSuccess
