import React from 'react'
import { Box } from '@mui/material'
import { getStylesAds } from 'styles/contents/resultpages/Ads'
import createDOMPurify from 'dompurify'
import { AdsResult } from 'models/AdsResult'
import { adFlavour } from 'constants/adminSettingTypes'
import * as Config from '../../../config'
import { ErrorOutline } from '@mui/icons-material'

type Props = {
  adItem: AdsResult
  isRequestPreview?: boolean
  hasImageSizeWarning?: boolean
}

const Ads = ({ adItem, isRequestPreview, hasImageSizeWarning }: Props): any => {
  const DOMPurify = createDOMPurify(window)
  if (!adItem) {
    return null
  }
  const sanitizedAdItem: AdsResult = {
    id: DOMPurify.sanitize(adItem.id, {
      USE_PROFILES: { html: false }
    }),
    language: DOMPurify.sanitize(adItem.language, {
      USE_PROFILES: { html: false }
    }),
    title: DOMPurify.sanitize(adItem.title, {
      USE_PROFILES: { html: false }
    }),
    text: DOMPurify.sanitize(adItem.text, {
      USE_PROFILES: { html: true },
      ADD_ATTR: ['target']
    }),
    link: DOMPurify.sanitize(adItem.link, {
      USE_PROFILES: { html: false }
    }),
    link_text: DOMPurify.sanitize(adItem.link_text, {
      USE_PROFILES: { html: false }
    }),
    image: DOMPurify.sanitize(adItem.image, {
      USE_PROFILES: { html: false }
    }),
    start: adItem.start
      ? DOMPurify.sanitize(adItem.start, {
          USE_PROFILES: { html: false }
        })
      : null,
    end: adItem.end
      ? DOMPurify.sanitize(adItem.end, {
          USE_PROFILES: { html: false }
        })
      : null,
    search_terms: adItem.search_terms.map((searchTerm: string) => {
      return DOMPurify.sanitize(searchTerm, {
        USE_PROFILES: { html: false }
      })
    }),
    rank: adItem.rank,
    sources: adItem.sources.map((source: string) => {
      return DOMPurify.sanitize(source, {
        USE_PROFILES: { html: false }
      })
    }),
    functions: adItem.functions.map((func: string) => {
      return DOMPurify.sanitize(func, {
        USE_PROFILES: { html: false }
      })
    }),
    countries: adItem.countries.map((country: string) => {
      return DOMPurify.sanitize(country, {
        USE_PROFILES: { html: false }
      })
    }),
    match_type: DOMPurify.sanitize(adItem.match_type, {
      USE_PROFILES: { html: false }
    }),
    flavour: adItem.flavour
      ? (DOMPurify.sanitize(adItem.flavour, {
          USE_PROFILES: { html: false }
        }) as adFlavour)
      : undefined,
    foundBySearchTerm: adItem.foundBySearchTerm
      ? adItem.foundBySearchTerm
      : false,
    scope: adItem.scope,
    site_url: adItem.site_url
      ? DOMPurify.sanitize(adItem.site_url, {
          USE_PROFILES: { html: false }
        })
      : ''
  }

  const { flavour } = sanitizedAdItem

  switch (flavour) {
    case 'small':
      return (
        <SmallAds
          adItem={sanitizedAdItem}
          isRequestPreview={isRequestPreview}
          hasImageSizeWarning={hasImageSizeWarning}
        />
      )
    case 'large':
      return (
        <LargeAds
          adItem={sanitizedAdItem}
          isRequestPreview={isRequestPreview}
          hasImageSizeWarning={hasImageSizeWarning}
        />
      )
    default:
      // no flavour specified, pick one...

      /*
      const chosenFlavour = ['small', 'medium', 'large'][
        Math.round((3 - 1) * Math.random())
      ]
      */

      const chosenFlavour = 'small'

      return (
        <Ads
          adItem={{
            ...sanitizedAdItem,
            flavour: chosenFlavour as 'small' | 'large'
          }}
          hasImageSizeWarning={hasImageSizeWarning}
        />
      )
  }
}

const SmallAds = ({ adItem, isRequestPreview, hasImageSizeWarning }: Props) => {
  const classes = getStylesAds()

  return (
    <Box className={classes.advertisementArea}>
      <div className={classes.advertisementTextContainer}>
        <div className={classes.smallAdsTopRow}>
          {/* Image place holders here */}
          {adItem.image && (
            <img
              className={
                hasImageSizeWarning
                  ? [classes.smallAdsIcon, classes.smallAdsIconWarning].join(
                      ' '
                    )
                  : classes.smallAdsIcon
              }
              src={`${
                adItem.image.startsWith('/externalContent/adsmedia/')
                  ? `${Config.APIM_BASE_URL.replace('/apim/', '')}${
                      adItem.image
                    }`
                  : adItem.image
              }${
                isRequestPreview
                  ? ''
                  : `?version=${new Date().getTime().toString()}`
              }`}
              alt="Ads"
            />
          )}
          <div className={classes.smallAdsTitle}>
            {adItem.title ? adItem.title : 'AdWord Headline'}
          </div>
        </div>
        <div
          className={classes.advertisementText}
          dangerouslySetInnerHTML={{
            __html: adItem.text ? adItem.text : 'AdWord Body Text'
          }}
        />
        <a
          className={classes.smallAdsLink}
          href={adItem.link}
          target="_blank"
          rel="noreferrer"
        >
          <div>{adItem.link_text}</div>
        </a>
      </div>
    </Box>
  )
}

const LargeAds = ({ adItem, isRequestPreview, hasImageSizeWarning }: Props) => {
  const classes = getStylesAds()

  const hideTextContainer =
    (adItem.title == null || adItem.title.length <= 0) &&
    (adItem.text == null || adItem.text.length <= 0) &&
    (adItem.link == null || adItem.link.length <= 0)

  return (
    <Box className={classes.advertisementArea}>
      {adItem.image && (
        <img
          className={
            hasImageSizeWarning
              ? [classes.largeAdsRow, classes.smallAdsIconWarning].join(' ')
              : classes.largeAdsRow
          }
          src={`${
            adItem.image.startsWith('/externalContent/adsmedia/')
              ? `${Config.APIM_BASE_URL.replace('/apim/', '')}${adItem.image}`
              : adItem.image
          }${
            isRequestPreview
              ? ''
              : `?version=${new Date().getTime().toString()}`
          }`}
          alt="Missing Adword icon"
          style={{ borderRadius: hideTextContainer ? 8 : undefined }}
        />
      )}
      {!adItem.image && (
        <div className={classes.largeAdsImageMissing}>
          <ErrorOutline className={classes.largeAdsMissingIcon} />
          <div className={classes.largeAdsMissingText}>
            {'Missing Adword Image'}
          </div>
        </div>
      )}
      {!hideTextContainer && (
        <div className={classes.advertisementTextContainer}>
          {adItem.title != null && adItem.title.length > 0 && (
            <div className={classes.largeAdsTitle}>
              {adItem.title ? adItem.title : 'AdWord Headline'}
            </div>
          )}
          {adItem.text != null && adItem.text.length > 0 && (
            <div
              className={classes.advertisementText}
              dangerouslySetInnerHTML={{
                __html: adItem.text ? adItem.text : 'AdWord Body Text'
              }}
            />
          )}
          {adItem.link != null && adItem.link.length > 0 && (
            <a
              className={classes.smallAdsLink}
              href={adItem.link}
              target="_blank"
              rel="noreferrer"
            >
              <div> {adItem.link_text}</div>
            </a>
          )}
        </div>
      )}
    </Box>
  )
}
export default Ads
