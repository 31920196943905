import { DidYouMeanItem } from 'models/DidYouMean'
import { Reducer } from 'redux'
import {
  DidYouMeanActions,
  DidYouMeanActionTypes,
  IFetchDidYouMeanAllSuccess,
  IUpsertDidYouMeanSuccess,
  IDeleteDidYouMeanSuccess
} from './actions'

export interface IDidYouMeanStore {
  didYouMeanItems: DidYouMeanItem[]
  hasBeenFetched: boolean
  hasError: boolean
  hasChangesError: boolean
}

const initialState: IDidYouMeanStore = {
  didYouMeanItems: [],
  hasBeenFetched: false,
  hasError: false,
  hasChangesError: false
}

const reducers: Reducer<IDidYouMeanStore, DidYouMeanActions> = (
  state: IDidYouMeanStore = initialState,
  action: DidYouMeanActions
) => {
  switch (action.type) {
    case DidYouMeanActionTypes.FETCH_DID_YOU_MEAN_ALL_FAILURE:
      return {
        ...state,
        hasBeenFetched: true,
        hasError: true
      }

    case DidYouMeanActionTypes.FETCH_DID_YOU_MEAN_ALL_SUCCESS:
      const { didYouMeanResponse } = (action as IFetchDidYouMeanAllSuccess)
        .payload

      return {
        ...state,
        didYouMeanItems: didYouMeanResponse,
        hasBeenFetched: true,
        hasError: false
      }

    case DidYouMeanActionTypes.FETCH_DID_YOU_MEAN_ALL_REQUEST:
      return {
        ...state,
        didYouMeanItems: [],
        hasBeenFetched: false,
        hasError: false,
        hasChangesError: false
      }

    case DidYouMeanActionTypes.UPSERT_DID_YOU_MEAN_SUCCESS:
      const { upsertedDidYouMean } = (action as IUpsertDidYouMeanSuccess)
        .payload
      const didYouMeanItemsChanged = state.didYouMeanItems
      if (didYouMeanItemsChanged.length > 0) {
        const tIndex = didYouMeanItemsChanged.findIndex(
          (t: any) =>
            t.id === upsertedDidYouMean.id && t.term === upsertedDidYouMean.term
        )
        if (tIndex >= 0) {
          didYouMeanItemsChanged[tIndex] = upsertedDidYouMean
        } else {
          didYouMeanItemsChanged.unshift(upsertedDidYouMean)
        }
      }
      return {
        ...state,
        didYouMeanItems: didYouMeanItemsChanged,
        hasChangesError: false
      }
    case DidYouMeanActionTypes.CHANGE_DID_YOU_MEAN_FAILURE:
      return {
        ...state,
        hasChangesError: true
      }
    case DidYouMeanActionTypes.DELETE_DID_YOU_MEAN_SUCCESS:
      const { didYouMeanId } = (action as IDeleteDidYouMeanSuccess).payload
      const allDidYouMeanItems = state.didYouMeanItems
      if (allDidYouMeanItems.length > 0) {
        const tIndex = allDidYouMeanItems.findIndex(
          (t: any) => t.id === didYouMeanId
        )
        if (tIndex >= 0) {
          allDidYouMeanItems.splice(tIndex, 1)
        }
      }
      return {
        ...state,
        didYouMeanItems: allDidYouMeanItems,
        hasChangesError: false
      }
    default:
      return state
  }
}

export default reducers
