import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Store } from '..'
import AuthStore from '../Auth'
import { IFindResponse } from 'utils/api'
import { trackException } from 'utils/tracking'
import * as Config from '../../config'
import { FeaturedResult } from 'models/FeaturedResult'
import { renewAuthorizationToken } from 'authentication/token'

export enum FeaturedResultsActionTypes {
  FETCH_FEATUREDRESULTS_REQUEST = 'featuredresults/FETCH_FEATUREDRESULTS_REQUEST',
  FETCH_FEATUREDRESULTS_SUCCESS = 'featuredresults/FETCH_FEATUREDRESULTS_SUCCESS',
  FETCH_FEATUREDRESULTS_FAILURE = 'featuredresults/FETCH_FEATUREDRESULTS_FAILURE',
  UPSERT_FEATURERESULT_SUCCESS = 'featuredresults/UPSERT_FEATURERESULT_SUCCESS',
  CHANGE_FEATURERESULT_FAILURE = 'featuredresults/CHANGE_FEATURERESULT_FAILURE',
  DELETE_FEATURERESULT_SUCCESS = 'featuredresults/DELETE_FEATURERESULT_SUCCESS'
}

export type IFetchFeaturedResultsRequest = Action<FeaturedResultsActionTypes>
export interface IFetchFeaturedResultsSuccess
  extends Action<FeaturedResultsActionTypes> {
  payload: {
    featuredResultsResponse: any
  }
}
export type IFetchFeaturedResultsFailure = Action<FeaturedResultsActionTypes>

export interface IUpsertFeaturedResultSuccess
  extends Action<FeaturedResultsActionTypes> {
  payload: {
    upsertedFeaturedResult: FeaturedResult
  }
}
export type IChangeUpsertFeaturecResultFailure =
  Action<FeaturedResultsActionTypes>

export interface IDeleteFeatureResultSuccess
  extends Action<FeaturedResultsActionTypes> {
  payload: {
    featuredResultId: string
  }
}

export const fetchFeaturedResultsRequest =
  (): IFetchFeaturedResultsRequest => ({
    type: FeaturedResultsActionTypes.FETCH_FEATUREDRESULTS_REQUEST
  })
export const fetchFeaturedResultsSuccess = (
  featuredResultsResponse: any = {}
): IFetchFeaturedResultsSuccess => ({
  type: FeaturedResultsActionTypes.FETCH_FEATUREDRESULTS_SUCCESS,
  payload: { featuredResultsResponse }
})

export const fetchFeaturedResultsFailure =
  (): IFetchFeaturedResultsFailure => ({
    type: FeaturedResultsActionTypes.FETCH_FEATUREDRESULTS_FAILURE
  })

export const upsertFeaturedResultSuccess = (
  upsertedFeaturedResult: FeaturedResult
): IUpsertFeaturedResultSuccess => ({
  type: FeaturedResultsActionTypes.UPSERT_FEATURERESULT_SUCCESS,
  payload: { upsertedFeaturedResult }
})

export const changeFeaturedResultFailure =
  (): IChangeUpsertFeaturecResultFailure => ({
    type: FeaturedResultsActionTypes.CHANGE_FEATURERESULT_FAILURE
  })

export const deleteFeaturedResultSuccess = (
  featuredResultId: string
): IDeleteFeatureResultSuccess => ({
  type: FeaturedResultsActionTypes.DELETE_FEATURERESULT_SUCCESS,
  payload: { featuredResultId }
})

export const fetchFeaturedResults = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchFeaturedResultsRequest())
    try {
      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}featuredresultsapi/getfeaturedresults?scope=All`

      let response: IFindResponse

      const initialResponse = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (initialResponse && initialResponse.ok) {
        response = {
          hasError: false,
          responseJSON: await initialResponse.json()
        }
      } else {
        response = {
          hasError: true
        }
      }

      let featuredResultsRaw: FeaturedResult[] = []
      if (response && !response.hasError && response.responseJSON) {
        featuredResultsRaw = response.responseJSON
      }

      if (!featuredResultsRaw || featuredResultsRaw.length === 0) {
        dispatch(fetchFeaturedResultsFailure())
        return
      }
      dispatch(fetchFeaturedResultsSuccess(featuredResultsRaw))
    } catch (error) {
      dispatch(fetchFeaturedResultsFailure())
    }
  }
}

export const upsertFeaturedResult = (
  featuredResultData: FeaturedResult
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      const featuredResult = JSON.parse(JSON.stringify(featuredResultData))
      if (!featuredResult.BestBetLastModified) {
        featuredResult.BestBetLastModified = new Date().toISOString()
      }

      if (featuredResult.tableData) {
        delete featuredResult.tableData
      }
      if (featuredResult.draft) {
        delete featuredResult.draft
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}featuredresultsapi/upsertfeaturedresults?docId=${featuredResult.id}`

      const utf8Bytes = encodeURIComponent(
        JSON.stringify(featuredResult)
      ).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(Number('0x' + p1))
      })

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body: '{"content":"' + btoa(utf8Bytes) + '"}'
      })

      if (data.status !== 200 && data.status !== 201) {
        trackException(
          'Error in upsert featured result',
          new Error('Error in data object after upserting featured result')
        )
        dispatch(changeFeaturedResultFailure())
        return
      }

      dispatch(upsertFeaturedResultSuccess(featuredResult))
    } catch (error) {
      trackException('Error in upsert featured result action', error)
      dispatch(changeFeaturedResultFailure())
    }
  }
}

export const deleteFeaturedResult = (
  featuredResultId: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      let apiUrl = `${Config.APIM_BASE_URL}featuredresultsapi/deletefeaturedresults?docId=${featuredResultId}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 204) {
        trackException(
          'Error in delete featured result',
          new Error('Error in data object after deleting featured result')
        )
        dispatch(changeFeaturedResultFailure())
        return
      }

      // Delete featured result from search index
      apiUrl = `${Config.APIM_BASE_URL}searchapi/getfeaturedresults?docId=${featuredResultId}`
      const featuredResults = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (featuredResults && featuredResults.status === 200) {
        const items = (await featuredResults.json()).value
        for (let i = 0; i < items.length; i++) {
          apiUrl = `${Config.APIM_BASE_URL}searchapi/deletesearchindexentry?docId=${items[i].rid}&datasource=kpmg-find-featured-results-index`
          await fetch(apiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })
        }
      }

      dispatch(deleteFeaturedResultSuccess(featuredResultId))
    } catch (error) {
      trackException('Error in delete featured result action', error)
      dispatch(changeFeaturedResultFailure())
    }
  }
}

export type FeaturedResultsActions =
  | IFetchFeaturedResultsRequest
  | IFetchFeaturedResultsSuccess
  | IFetchFeaturedResultsFailure
  | IUpsertFeaturedResultSuccess
  | IChangeUpsertFeaturecResultFailure
  | IDeleteFeatureResultSuccess
