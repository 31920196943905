import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import { getReportAuthToken } from 'authentication/token'
import 'styles/dashboard/metricsDashboard.css'
import { useIntl } from 'react-intl'

export interface MetricsDashboardLocalProps {}

type MetricsDashboardProps = ReturnType<typeof mapStateToProps> &
  MetricsDashboardLocalProps

function MetricsDashboard(props: MetricsDashboardProps): JSX.Element {
  let aadInfo = props.aadInfo
  const [isLoading, setIsLoading] = useState(true)
  const [reportToken, setReportToken] = useState('')
  const intl = useIntl()

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getReportAuthToken(aadInfo.instance, aadInfo.accounts)
      setReportToken(token)
    }

    fetchToken().then(() => {
      setIsLoading(false)
    })
  }, [aadInfo.accessToken, aadInfo.accounts, aadInfo.instance])

  return (
    <>
      {isLoading && (
        <div className="metrics-dashboard-container">
          {intl.formatMessage({
            id: 'metrics_dashboard_loading',
            defaultMessage: 'Loading Metrics Dashboard...'
          })}
        </div>
      )}
      {!isLoading && (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: '40f33a4b-d30b-4566-baca-3f18ba012e1d',
            embedUrl:
              'https://app.powerbi.com/reportEmbed?reportId=40f33a4b-d30b-4566-baca-3f18ba012e1d&ctid=deff24bb-2089-4400-8c8e-f71e680378b2',
            accessToken: reportToken,
            tokenType: models.TokenType.Aad,
            pageView: 'actualSize',
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: false
            }
          }}
          cssClassName={'metrics-dashboard-container'}
        />
      )}
    </>
  )
}
const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

export default connect(mapStateToProps)(MetricsDashboard)
