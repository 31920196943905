import { ContactUsTile } from 'models/ContactUsTile'
import { Store } from '..'

const selectors = {
  getContactUsTiles: (state: Store): ContactUsTile[] =>
    state.contactUs.contactUsTiles,
  getContactUsAllTiles: (state: Store): ContactUsTile[] =>
    state.contactUs.contactUsAllTiles,
  hasBeenFetched: (state: Store): boolean => state.contactUs.hasBeenFetched,
  hasAllBeenFetched: (state: Store): boolean =>
    state.contactUs.hasAllBeenFetched,
  hasError: (state: Store): boolean => state.contactUs.hasError,
  hasChangesError: (state: Store): boolean => state.contactUs.hasChangesError
}

export default selectors
