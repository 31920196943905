/**
 * Convert Date to popup datestring
 * @param date date
 * @returns datestring
 */
export function getPopupDateString(date: Date): string {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return year + '/' + month + '/' + day
}
