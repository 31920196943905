import { AdsResult } from 'models/AdsResult'
import { FeaturedResult } from 'models/FeaturedResult'
import { RequestHistory } from './SelfServiceHistory'
import { DidYouMeanItem } from './DidYouMean'

export enum AdminActionType {
  UnPublish = 'UnPublish',
  Publish = 'Publish',
  Decline = 'Decline',
  Clarification = 'Clarification',
  Comment = 'Comment'
}

export enum RequestType {
  AdWord = 'AdWord',
  FeaturedResult = 'FeaturedResult',
  SpellingSuggestion = 'SpellingSuggestion'
}

export enum RequestStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Published = 'Published',
  Declined = 'Declined',
  ToBeDeleted = 'ToBeDeleted',
  Clarification = 'Clarification'
}

export interface RequestComment {
  id: number
  text: string
  modifiedDate: string
  modifiedBy: string
  modifiedByDisplayName: string
}

export interface Duplicate {
  id: string
  createdBy: string
}

export interface DuplicateInfo {
  color: string
  elements: Duplicate[]
}

export interface ISelfServiceRequest {
  id: string
  status: RequestStatus
  upn: string
  requestType: RequestType
  title: string
  description: string
  itemData: AdsResult[] | FeaturedResult | DidYouMeanItem | null
  historyData?: RequestHistory | null
  modifiedDate: string
  createdDate: string
  createdBy: string
  modifiedBy: string
  oncePublished: boolean
  definedLanguages: Array<string>
  imagePreview?: string
  imageFileName?: string
  comments?: Array<RequestComment>
  endDate?: string
  _etag?: string
  _rid?: string
  hasChanged?: boolean
  hasCommentChanges?: boolean
  expiresSoon?: boolean
  duplicates?: DuplicateInfo
}

export interface IExportItem {
  id: string
  type: RequestType
  status: RequestStatus
  modifiedDate: string
  createdDate: string
  createdBy: string
  modifiedBy: string
  title: string
  desc: string
  link: string
  link_text?: string
  start: string
  end: string
  keywords: string
  scope: string
  verticals: string
  countries: string
  functions: string
  flavour?: string
  matchType: string
}

export const defaultSelfServiceRequest: ISelfServiceRequest = {
  id: '',
  status: RequestStatus.Draft,
  upn: '',
  requestType: RequestType.AdWord,
  title: '',
  description: '',
  itemData: null,
  createdBy: '',
  modifiedBy: '',
  modifiedDate: '',
  createdDate: '',
  oncePublished: false,
  definedLanguages: ['en']
}

export interface ISelfServiceImageUploadResponse
  extends ISelfServiceUpdateResponse {
  url: string
}

export interface ISelfServiceGetHighestIdResponse
  extends ISelfServiceUpdateResponse {
  newId: string
}

export interface ISelfServiceUpdateResponse {
  hasError: boolean
  errorMessage: string
  status?: number
  error?: unknown
  etag?: string | null
  imageError?: boolean
}

export interface ISelfServiceAdminDeleteResponse {
  hasError: boolean
  errorMessage: string
  status?: number
  error?: unknown
  requestId: string
  requestUpn: string
}

export interface ISelfServiceAdminExpirationCheckResponse {
  hasError: boolean
  hasPartialError?: boolean
  noNotifications?: boolean
  errorMessage: string
  status?: number
}

export interface IItemMetrics {
  TimesShown: number
  TimesClicked: number
  CTR: number
  Loaded: boolean
  Fetching: boolean
}

export interface ISelfServiceAdminReAssignItem {
  id: string
  upn: string
  _rid: string | undefined
}

export interface ISelfServiceAdminReAssignResponse {
  hasError: boolean
  hasPartialError?: boolean
  addedItems: ISelfServiceRequest[]
  deletedItems: ISelfServiceRequest[]
  failedItems: ISelfServiceAdminReAssignItem[]
}
