import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Checkbox, Typography } from '@mui/material'
import { read, utils, WorkBook } from 'xlsx'
import { getStylesAdBulkUpload } from 'styles/admin/AdBulkUpload'
import createDOMPurify from 'dompurify'
import { FeaturedResult } from 'models/FeaturedResult'
import { updateFeaturedResultDefaultValues } from 'utils/admin/adminContentQuality'
import {
  displayNegatedValues,
  findNotValidCountry,
  findNotValidFunction,
  findNotValidSources,
  isMatchTypeValid,
  isScopeValid,
  parseDate,
  parseDateHuman,
  splitCsV
} from 'utils/admin/adminSettingsUtils'
import { isUrlValid } from 'utils/string'
import { ISelfServiceBulkUploadEvent } from 'models/SelfServiceTrackingEvent'
import { trackSelfServiceEvent } from 'utils/tracking'

interface IProps {
  open: boolean
  tableData: FeaturedResult[]
  setOpen: (value: boolean) => void
  handleRowUpdate: (newData: FeaturedResult[]) => void
}

export default function FeaturedBulkUpload(props: IProps): JSX.Element {
  const { open, setOpen, handleRowUpdate, tableData } = props

  const [errorText, setErrorText] = useState('')
  const [infoText, setInfoText] = useState('')

  const [xlsxJson, setXlsxJson] = useState<any>(null)
  const [xlsxWorkbook, setXlsxWorkbook] = useState<WorkBook>()
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [xlsxSheetList, setXlsxSheetList] = useState<string[]>([])
  const classes = getStylesAdBulkUpload()
  const DOMPurify = createDOMPurify(window)

  const findExistingItemId = (importId: number) => {
    if (
      importId &&
      tableData.find(
        (featuredResult: FeaturedResult) =>
          featuredResult.id === importId.toString()
      )
    ) {
      return importId
    }
    return -1
  }

  const getItemId = (importId: number) => {
    let id = findExistingItemId(importId)
    if (id > 0) {
      return id
    } else {
      tableData.forEach((featuredResult: FeaturedResult) => {
        const idInt = parseInt(featuredResult.id)
        if (idInt > id) {
          id = idInt
        }
      })
      return id
    }
  }

  useEffect(() => {
    //prevent browser back button
    if (open)
      window.history.pushState(null, document.title, window.location.href)
    window.onpopstate = function () {
      if (open) {
        window.history.go(1)
        setOpen(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const generateAdObjectListFromXls = () => {
    const newFeaturedResults: FeaturedResult[] = []
    let newFeaturedResultIndex = 0
    selectedRows.forEach((indexImport: number) => {
      const itemImport = xlsxJson[indexImport]
      const currentItemId = itemImport.Id ? itemImport.Id : -1

      let itemId = getItemId(currentItemId)
      if (itemId.toString() !== currentItemId.toString()) {
        itemId = itemId + newFeaturedResultIndex + 1
        newFeaturedResultIndex = newFeaturedResultIndex + 1
      }

      const newFeatureResult: FeaturedResult = {
        id: itemId.toString(),
        BestBetTitle: DOMPurify.sanitize(itemImport.Title),
        BestBetUrl: DOMPurify.sanitize(itemImport.Url),
        BestBetDescription: DOMPurify.sanitize(itemImport.Description),
        BestBetRank: itemImport.Rank ? parseInt(itemImport.Rank) : 0,
        BestBetStartDate: parseDate(DOMPurify.sanitize(itemImport.Start)),
        BestBetEndDate: parseDate(DOMPurify.sanitize(itemImport.End)),
        BestBetSiteUrl: DOMPurify.sanitize(itemImport['Site Url']),
        BestBetKeywords: splitCsV(
          DOMPurify.sanitize(itemImport['Search Terms'])
        ),
        BestBetCountry: splitCsV(DOMPurify.sanitize(itemImport.Countries)),
        BestBetCity: [],
        BestBetFunction: splitCsV(DOMPurify.sanitize(itemImport.Functions)),
        BestBetMatchType: DOMPurify.sanitize(itemImport['Match Type']),
        BestBetDataSources: splitCsV(DOMPurify.sanitize(itemImport.Sources)),
        BestBetScope: DOMPurify.sanitize(itemImport.Scope),
        BestBetLastModified: new Date().toISOString(),
        type: 'FeaturedResult'
      }

      if (itemImport['Mapped User']) {
        newFeatureResult.requestUser = itemImport['Mapped User']
        newFeatureResult.mapToRequestItem = true
      }

      newFeaturedResults.push(newFeatureResult)
    })

    return newFeaturedResults
  }

  const computeValues = () => {
    const newFeaturedResults: FeaturedResult[] = generateAdObjectListFromXls()

    const event: ISelfServiceBulkUploadEvent = {
      type: 'BulkUploadFeaturedResults',
      added: [],
      overwrites: []
    }

    newFeaturedResults.forEach((newitem: FeaturedResult) => {
      const index = findExistingItemId(parseInt(newitem.id))
      if (index === -1) {
        event.added.push(newitem.id)
      } else {
        event.overwrites.push(newitem.id)
      }
    })

    trackSelfServiceEvent(event)

    updateFeaturedResultDefaultValues(newFeaturedResults)
    handleRowUpdate(newFeaturedResults)
    return true
  }

  useEffect(() => {
    setErrorText('')

    setXlsxJson(null)
    setXlsxWorkbook(undefined)
    setSelectedRows([])
    setXlsxSheetList([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    let countNewItems = 0
    let countUpdateItems = 0

    const newFeaturedResults: FeaturedResult[] = generateAdObjectListFromXls()
    newFeaturedResults.forEach((newitem: FeaturedResult) => {
      const index = findExistingItemId(parseInt(newitem.id))
      if (index === -1) {
        //add new item on top
        countNewItems++
      } else {
        //update item in state
        countUpdateItems++
      }
    })

    let infoText = ''
    if (countNewItems) infoText += 'add ' + countNewItems + ' new item(s)'

    if (countUpdateItems && infoText !== '') infoText += ', '

    if (countUpdateItems) infoText += 'update ' + countUpdateItems + ' item(s)'

    setInfoText(infoText)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows])

  const readXlsxFile = (fileData: string | ArrayBuffer) => {
    if (!fileData) return

    const workbook = read(fileData, { type: 'buffer', cellDates: true })
    setXlsxWorkbook(workbook)
    setXlsxSheetList(workbook.SheetNames)
  }

  const selectXlsxSheet = (sheetName: string, index: number) => {
    if (xlsxWorkbook) {
      //set json
      setXlsxJson(utils.sheet_to_json(xlsxWorkbook.Sheets[sheetName]))
    }
  }

  const addRowIndex = (index: number) => {
    const lastSelectedRows = [...selectedRows]
    const indexSelected = lastSelectedRows.indexOf(index)
    if (indexSelected > -1) {
      //remove
      lastSelectedRows.splice(indexSelected, 1)
    } else {
      //add
      lastSelectedRows.push(index)
    }
    setSelectedRows(lastSelectedRows)
  }

  const isImportItemInValid = (data: any) => {
    let isInValid =
      !data.Title ||
      !data.Description ||
      !data.Sources ||
      !data.Url ||
      !isUrlValid(data.Url) ||
      !data.Start ||
      !data.End ||
      !data['Search Terms'] ||
      !!findNotValidCountry(data.Countries) ||
      !!findNotValidFunction(data.Functions) ||
      !!findNotValidSources(data.Sources) ||
      !isMatchTypeValid(data['Match Type']) ||
      !isScopeValid(data.Scope)

    if (isMappedUserInvalid(data)) {
      isInValid = true
    }

    return isInValid
  }

  const isMappedUserInvalid = (data: any) => {
    if (data.Id && data['Mapped User']) {
      const tableItem = tableData.find((f: FeaturedResult) => {
        return f.id === data.Id.toString()
      })

      if (tableItem) {
        return true
      }
    }
    return false
  }

  return (
    <Dialog open={open} fullWidth={true} fullScreen={true}>
      <DialogTitle>Bulk upload - Featured Results</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.spaceBottom}
        >
          <Box>1. Select File (xlsx):</Box>
          <Button
            variant="contained"
            component="label"
            disabled={!!xlsxWorkbook}
          >
            <Box>Upload</Box>
            <input
              type="file"
              hidden
              onChange={(event: any) => {
                const file = event.target.files[0]
                if (file) {
                  const reader = new FileReader()
                  reader.onload = function (evt) {
                    const data = evt.target?.result
                    if (data) readXlsxFile(data)
                  }
                  reader.readAsArrayBuffer(file)
                }
              }}
            />
          </Button>
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.spaceBottom}
        >
          {xlsxSheetList && xlsxSheetList.length > 0 && (
            <>
              <Box>2. Select Sheet:</Box>
              {xlsxSheetList.map((sheetName: string, index: number) => (
                <Button
                  variant="contained"
                  component="label"
                  onClick={() => {
                    setSelectedRows([])
                    selectXlsxSheet(sheetName, index)
                  }}
                >
                  {sheetName}
                </Button>
              ))}
            </>
          )}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.spaceBottom}
        >
          {xlsxJson && (
            <>
              <Box>3. Select items to import:</Box>
              <Box
                style={{
                  maxWidth: '100%',
                  maxHeight: '300px',
                  overflow: 'scroll'
                }}
              >
                <table className={classes.tableStyle}>
                  <thead>
                    <tr>
                      <th>-</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>URL</th>
                      <th>Start Date</th>
                      <th>End date</th>
                      <th>Site Url</th>
                      <th>Rank</th>
                      <th>Scope</th>
                      <th>Sources</th>
                      <th>Countries</th>
                      <th>Functions</th>
                      <th>Match Type</th>
                      <th>Search terms</th>
                      <th>Mapped User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {xlsxJson.map((data: any, index: number) => (
                      <tr>
                        <td>
                          <Checkbox
                            disableRipple
                            color="default"
                            disabled={isImportItemInValid(data)}
                            checked={selectedRows.includes(index)}
                            onClick={() => {
                              addRowIndex(index)
                            }}
                          />
                        </td>
                        <td>
                          {data.Title ? (
                            DOMPurify.sanitize(data.Title)
                          ) : (
                            <Box style={{ color: 'red' }}>Title missing</Box>
                          )}
                        </td>
                        <td>
                          {data.Description ? (
                            DOMPurify.sanitize(data.Description)
                          ) : (
                            <Box style={{ color: 'red' }}>
                              Description missing
                            </Box>
                          )}
                        </td>
                        <td>
                          {data.Url ? (
                            !isUrlValid(data.Url) ? (
                              <Box style={{ color: 'red' }}>URL is invalid</Box>
                            ) : (
                              DOMPurify.sanitize(data.Url)
                            )
                          ) : (
                            <Box style={{ color: 'red' }}>URL missing</Box>
                          )}
                        </td>
                        <td>
                          {data.Start ? (
                            parseDateHuman(DOMPurify.sanitize(data.Start))
                          ) : (
                            <Box style={{ color: 'red' }}>
                              Start Date missing
                            </Box>
                          )}
                        </td>
                        <td>
                          {data.End ? (
                            parseDateHuman(DOMPurify.sanitize(data.End))
                          ) : (
                            <Box style={{ color: 'red' }}>End Date missing</Box>
                          )}
                        </td>
                        <td>{DOMPurify.sanitize(data['Site Url'])}</td>
                        <td>{DOMPurify.sanitize(data.Rank)}</td>
                        <td>
                          {!isScopeValid(data.Scope) ? (
                            <Box style={{ color: 'red' }}>
                              "{DOMPurify.sanitize(data.Scope)}" is not
                              supported
                            </Box>
                          ) : (
                            <>{DOMPurify.sanitize(data.Scope)}</>
                          )}
                        </td>
                        <td>
                          {data.Sources ? (
                            <>
                              {!!findNotValidSources(data.Sources) ? (
                                <Box style={{ color: 'red' }}>
                                  "{findNotValidSources(data.Sources)}" is not
                                  supported
                                </Box>
                              ) : (
                                <>
                                  {displayNegatedValues(
                                    DOMPurify.sanitize(data.Sources)
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <Box style={{ color: 'red' }}>Sources missing</Box>
                          )}
                        </td>
                        <td>
                          {!!findNotValidCountry(
                            DOMPurify.sanitize(data.Countries)
                          ) ? (
                            <Box style={{ color: 'red' }}>
                              "
                              {findNotValidCountry(
                                DOMPurify.sanitize(data.Countries)
                              )}
                              " is not supported
                            </Box>
                          ) : (
                            <>
                              {displayNegatedValues(
                                DOMPurify.sanitize(data.Countries)
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          {!!findNotValidFunction(
                            DOMPurify.sanitize(data.Functions)
                          ) ? (
                            <Box style={{ color: 'red' }}>
                              "
                              {findNotValidFunction(
                                DOMPurify.sanitize(data.Functions)
                              )}
                              " is not supported
                            </Box>
                          ) : (
                            <>
                              {displayNegatedValues(
                                DOMPurify.sanitize(data.Functions)
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          {!isMatchTypeValid(data['Match Type']) ? (
                            <Box style={{ color: 'red' }}>
                              "{DOMPurify.sanitize(data['Match Type'])}" is not
                              supported
                            </Box>
                          ) : (
                            <>{DOMPurify.sanitize(data['Match Type'])}</>
                          )}
                        </td>
                        <td>
                          {data['Search Terms'] ? (
                            DOMPurify.sanitize(data['Search Terms'])
                          ) : (
                            <Box style={{ color: 'red' }}>
                              Search Terms missing
                            </Box>
                          )}
                        </td>
                        <td>
                          {!isMappedUserInvalid(data) ? (
                            DOMPurify.sanitize(data['Mapped User'])
                          ) : (
                            <Box style={{ color: 'red' }}>
                              Item already mapped
                            </Box>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
              <div className={classes.tableActions}>
                <Button
                  variant="outlined"
                  component="label"
                  onClick={() => {
                    const lastSelectedRows = [...selectedRows]
                    for (let index = 0; index < xlsxJson.length; index++) {
                      const indexSelected = lastSelectedRows.indexOf(index)
                      if (
                        indexSelected === -1 &&
                        !isImportItemInValid(xlsxJson[index])
                      ) {
                        //add
                        lastSelectedRows.push(index)
                      }
                    }

                    setSelectedRows(lastSelectedRows)
                  }}
                >
                  Select All
                </Button>
                <span className={classes.tableActionsSelected}>
                  {selectedRows.length}/{xlsxJson.length} selected
                </span>
              </div>
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        {errorText && <Box style={{ color: 'red' }}>{errorText}</Box>}
        {infoText && <Box style={{ color: 'red' }}>{infoText}</Box>}
        <Button
          onClick={() => {
            setErrorText('')
            if (selectedRows.length === 0) {
              setErrorText('Please select the rows to be imported.')
              return
            }

            if (computeValues()) {
              setOpen(false)
            }
          }}
          color="primary"
        >
          Import
        </Button>
        <Button
          onClick={() => {
            setOpen(false)
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
