import { Column } from '@material-table/core'
import { DidYouMeanItem } from 'models/DidYouMean'
import AdminTableTooltipTitle from '../common/AdminTableTooltipTitle'
import React from 'react'
import createDOMPurify from 'dompurify'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'

const DOMPurify = createDOMPurify(window)

/**
 * Generate did you mean columns list
 * @param changes AdminItemChange list
 * @returns columns list
 */
export const didYouMeanItemColumns = (): Column<DidYouMeanItem>[] => {
  const classes = getStylesAdminSettings()
  return [
    {
      title: 'Id',
      field: 'id',
      type: 'string',
      filtering: false,
      cellStyle: {
        maxWidth: 180,
        width: 180
      },
      headerStyle: {
        maxWidth: 180,
        width: 180
      },
      width: 180
    },
    {
      title: 'Term',
      field: 'term',
      filtering: true,
      type: 'string',
      render: (rowData: DidYouMeanItem) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          unsafeHTML={`${rowData.draft ? '<i><b>(Draft)</b></i> ' : ''}${
            rowData.term ? DOMPurify.sanitize(rowData.term) : ''
          }`}
          maxLine={4}
        />
      ),
      cellStyle: {
        maxWidth: 680,
        width: 680
      },
      headerStyle: {
        maxWidth: 680,
        width: 680
      },
      width: 680
    }
  ]
}
