import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesTranslationBulkUpload(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    spaceBottom: {
      paddingBottom: '1em'
    },
    tableStyle: {
      borderSpacing: 0,
      marginTop: 10,
      width: '100%',
      '& td, th': {
        border: '1px solid #aaa',
        padding: '10px'
      }
    },
    tableActions: {
      paddingTop: '1em'
    },
    tableActionsSelected: {
      paddingInlineStart: '1em'
    }
  }))
}
