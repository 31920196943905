import React, { useState, useEffect, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import NewsLetterSubscritionsStore from 'store/NewsLetterSubscriptions'
import {
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarCloseReason
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { AdminItemChange } from 'models/AdminItemChange'
import { TableStateSubscriptions } from 'models/TableStates'
import {
  cleanDraftStatusAfterPublishAllSubsciptions,
  cleanDraftStatusAfterPublishSubscription,
  deleteItemAndUpdateState
} from 'utils/admin/adminTableState'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'
import SubscriptionsTable from './SubscriptionsTable'
import ConfirmationModal from 'components/contents/common/Dialog'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'
import EditFormBase from '../common/EditFormBase'
import { adminSettingTypes } from 'constants/adminSettingTypes'

export interface SubscriptionsProps {
  setHasChanges: (changes: boolean) => void
}

type AllSubscriptionsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  SubscriptionsProps

function Subscriptions(props: AllSubscriptionsProps): JSX.Element {
  const {
    fetchAllSubscriptions,
    allSubscriptions,
    hasSubscriptionsBeenFetched,
    hasChangesError,
    addSubscriptionItem,
    deleteSubscriptionItem,
    setHasChanges
  } = props

  const classes = getStylesAdminSettings()

  const [changes, setChanges] = useState<AdminItemChange[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isModalVisible, toggleModalVisibility] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [state, setState] = useState<TableStateSubscriptions>({
    data: []
  })
  const [pageHasActiveChanges, setPageHasActiveChanges] = useState(false)
  const [currentRow, setCurrentRow] = useState<INewsLetterSubscription | null>(
    null
  )
  const [formOpen, setFormOpen] = useState(false)
  const [createNewItem, setCreateNewItem] = useState(false)
  const [subsciptionChangesApplied, setSubscriptionChangesApplied] =
    useState(false)
  const [updateRunning, setUpdateRunning] = useState(false)

  const loadData = () => {
    fetchAllSubscriptions()
  }

  useEffect(() => {
    if (hasSubscriptionsBeenFetched) {
      setIsLoading(false)

      setState({
        data: allSubscriptions
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubscriptionsBeenFetched])

  useEffect(() => {
    if (pageHasActiveChanges) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHasActiveChanges])

  useNonInitialEffect(() => {
    if (subsciptionChangesApplied) {
      if (hasChangesError) {
        setSnackbarMessage('There was an error publishing the changes.')
        toggleSnackbarVisibility(true)
      } else {
        setSnackbarMessage('The changes have been published.')
        toggleSnackbarVisibility(true)
      }
    }
  }, [subsciptionChangesApplied, hasChangesError])

  useEffect(() => {
    setIsLoading(true)
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickOpen = () => {
    toggleModalVisibility(true)
  }

  const handleClose = () => {
    toggleModalVisibility(false)
  }

  const handleFormClose = () => {
    setCurrentRow(null)
    setCreateNewItem(false)
    setFormOpen(false)
  }

  const handleSnackbarClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    toggleSnackbarVisibility(false)
  }

  /**
   * Cleans changes and update table data after update for did you mean
   * @param newDataList Items to update
   * @param data Current Table data
   * @param newChanges Current active changes
   */
  const updateSubscriptionsItemsAndUpdateSate = (
    newDataList: INewsLetterSubscription[],
    data: any,
    newChanges: AdminItemChange[]
  ) => {
    newDataList.forEach((newData: INewsLetterSubscription) => {
      newData.draft = true
      //find item index in prevState
      let index = -1
      for (let i = 0; i < data.length; i++) {
        const target = data[i]
        if (target && target.id === newData.id) {
          index = i
          break
        }
      }
      if (index === -1) {
        //add new item on top
        data.unshift(newData)
      } else {
        //update item in state
        data[index] = newData
      }

      let changeIndex = -1
      for (let i = 0; i < newChanges.length; i++) {
        const target = newChanges[i]
        if (target && target.id === newData.id) {
          changeIndex = i
          break
        }
      }

      //set type add, if not exist in prevState, else update
      const newChange = {
        id: index === -1 ? data[0].id : data[index].id,
        changeType: index === -1 ? 'add' : 'update',
        changes: index === -1 ? data[0] : data[index]
      }

      if (changeIndex === -1) {
        //add change item
        newChanges.push(newChange)
      } else {
        //update item in change list
        newChanges[changeIndex] = {
          ...newChange,
          changeType: newChanges[changeIndex].changeType
        }
      }
    })
  }

  const handleFormOpen = (row: INewsLetterSubscription) => {
    setCurrentRow(row)
    setFormOpen(true)
  }

  const addRowUpdateForSubscription = (newData: INewsLetterSubscription) => {
    let newDataArray = [newData]

    if (newData.upn.indexOf(',') !== -1) {
      newDataArray = newData.upn
        .split(',')
        .map((upn: string, index: number) => {
          return {
            ...newData,
            upn: upn,
            id: (parseInt(newData.id) + index).toString()
          }
        })
    }

    const newChanges = changes

    setState((prevState: any) => {
      const data = [...prevState.data]

      updateSubscriptionsItemsAndUpdateSate(newDataArray, data, newChanges)

      return { ...prevState, data }
    })

    //set changes
    setPageHasActiveChanges(true)
    setChanges(newChanges)

    //cleanup modal
    setCurrentRow(null)
    setFormOpen(false)
    setCreateNewItem(false)
  }

  const getNewId = (): number => {
    let itemWithMaxId = undefined

    if (
      !(state as TableStateSubscriptions).data ||
      (state as TableStateSubscriptions).data.length === 0
    ) {
      return 1
    }

    itemWithMaxId = (state as TableStateSubscriptions).data.reduce(function (
      prev: INewsLetterSubscription,
      current: INewsLetterSubscription
    ) {
      return parseInt(prev.id) > parseInt(current.id) ? prev : current
    })

    let newId = 1
    if (itemWithMaxId) {
      newId = parseInt(itemWithMaxId.id) + 1
    }

    return newId
  }

  const handleRowAdd = () => {
    setCreateNewItem(true)

    let idNumber = getNewId()

    handleFormOpen({
      id: idNumber.toString(),
      upn: '',
      memberFirm: 'Australia'
    })
  }

  const handleRowDelete = (deleteItem: INewsLetterSubscription) =>
    new Promise((resolve) => {
      const newChanges = [...changes]

      setState((prevState: any) => {
        const data = [...prevState.data]

        deleteItemAndUpdateState(deleteItem, data, newChanges)

        return { ...prevState, data }
      })

      setPageHasActiveChanges(newChanges && newChanges.length > 0)
      setChanges(newChanges)

      resolve(true)
    })

  const handleConfirm = () => {
    setUpdateRunning(true)
    const promiseArray: Array<Promise<void>> = []

    setSubscriptionChangesApplied(false)

    // Iterate through changes, applying them
    changes.forEach((change: AdminItemChange) => {
      switch (change.changeType) {
        case 'add':
        case 'update':
          promiseArray.push(
            addSubscriptionItem(change.changes as INewsLetterSubscription)
          )
          break
        case 'delete':
          const currentChange = change.changes as INewsLetterSubscription
          promiseArray.push(deleteSubscriptionItem(currentChange))
          break
      }

      toggleModalVisibility(false)
    })

    if (promiseArray && promiseArray.length > 0) {
      Promise.all(promiseArray).then(() => {
        setState((prevState: any) => {
          const data = [...prevState.data]

          cleanDraftStatusAfterPublishAllSubsciptions(changes, data)

          return { ...prevState, data }
        })

        setSubscriptionChangesApplied(true)

        setChanges([])
        setPageHasActiveChanges(false)
        setUpdateRunning(false)
      })
    } else {
      setUpdateRunning(false)
      toggleModalVisibility(false)
    }
  }

  const publishSubscription = (subscriptionItem: INewsLetterSubscription) => {
    setSubscriptionChangesApplied(false)
    const promiseArray: Array<Promise<void>> = []

    let newDataArray = [subscriptionItem]

    if (subscriptionItem.upn.indexOf(',') !== -1) {
      newDataArray = subscriptionItem.upn
        .split(',')
        .map((upn: string, index: number) => {
          return {
            ...subscriptionItem,
            upn: upn,
            id: (parseInt(subscriptionItem.id) + index).toString()
          }
        })
    }

    newDataArray.forEach((subscription: INewsLetterSubscription) => {
      promiseArray.push(addSubscriptionItem(subscription))
    })

    Promise.all(promiseArray).then(() => {
      // clear active change
      const newChanges = changes.filter(
        (itemChanges: AdminItemChange) =>
          !newDataArray.find(
            (itemNewAd: INewsLetterSubscription) =>
              itemChanges.id === itemNewAd.id
          )
      )

      setState((prevState: any) => {
        const data = [...prevState.data]

        cleanDraftStatusAfterPublishSubscription(newDataArray, data)

        return { ...prevState, data }
      })

      if (newChanges.length === 0) {
        setPageHasActiveChanges(false)
      }

      setSubscriptionChangesApplied(true)
      setChanges(newChanges)
    })
  }

  return (
    <>
      <div className={`${classes.container} ${classes.scrollfix}`}>
        {!isLoading ? (
          <SubscriptionsTable
            state={state}
            handleRowDelete={handleRowDelete}
            handleTranslationUpdate={addRowUpdateForSubscription}
            handleRowAdd={handleRowAdd}
          />
        ) : (
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress className={classes.loadingSpinner} size={50} />
          </div>
        )}
        <ConfirmationModal
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          isModalOpen={isModalVisible}
          title={'Are you sure to publish all changes?'}
          message={'Subscriptions will directly been added or removed'}
          hideCancelButton={false}
          confirmBtnText={'Yes'}
          cancelBtnText={'No'}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          className={classes.button}
          disabled={!pageHasActiveChanges}
          style={{ width: '100%' }}
        >
          <>
            {'Go Live!'}
            {updateRunning && (
              <CircularProgress
                size={15}
                style={{ marginInlineStart: '10px', color: 'white' }}
              />
            )}
          </>
        </Button>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={isSnackbarVisible}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                handleSnackbarClose()
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
      {formOpen && currentRow && (
        <div>
          {formOpen && currentRow && (
            <EditFormBase
              rowData={currentRow as INewsLetterSubscription}
              isNewItem={createNewItem}
              isOpen={formOpen}
              handleClose={handleFormClose}
              handleRowUpdate={addRowUpdateForSubscription}
              publishItem={publishSubscription}
              adminSettingType={adminSettingTypes.newslettersubscriptions}
              changes={changes}
            />
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    allSubscriptions:
      NewsLetterSubscritionsStore.selectors.getSubscriptions(state),
    hasSubscriptionsBeenFetched:
      NewsLetterSubscritionsStore.selectors.hasSubscriptionsBeenFetched(state),
    hasChangesError:
      NewsLetterSubscritionsStore.selectors.hasChangesError(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAllSubscriptions: () =>
      dispatch(
        NewsLetterSubscritionsStore.actions.fetchNewsLetterSubscriptionsResults()
      ),
    addSubscriptionItem: (subscriptionItem: INewsLetterSubscription) =>
      dispatch(
        NewsLetterSubscritionsStore.actions.addNewsLetterSubscription(
          subscriptionItem
        )
      ),
    deleteSubscriptionItem: (subItem: INewsLetterSubscription) =>
      dispatch(
        NewsLetterSubscritionsStore.actions.deleteNewsLetterSubscription(
          subItem
        )
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions)
