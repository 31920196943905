import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Store } from '..'
import AuthStore from '../Auth'
import { IFindResponse } from 'utils/api'
import * as Config from '../../config'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import { trackException, trackSelfServiceEvent } from 'utils/tracking'
import { renewAuthorizationToken } from 'authentication/token'
import { maxSelfServicePageLoadingDepth } from 'constants/constants'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'
import { IAADState } from 'store/Auth/reducers'

export enum NewsLetterSubscriptionsActionTypes {
  FETCH_SUBSCRIPTIONS_REQUEST = 'newslettersubscriptions/FETCH_SUBSCRIPTIONS_REQUEST',
  FETCH_SUBSCRIPTIONS_SUCCESS = 'newslettersubscriptions/FETCH_SUBSCRIPTIONS_SUCCESS',
  FETCH_SUBSCRIPTIONS_FAILED = 'newslettersubscriptions/FETCH_SUBSCRIPTIONS_FAILED',
  ADD_SUBSCRIPTIONS_SUCCESS = 'newslettersubscriptions/ADD_SUBSCRIPTIONS_SUCCESS',
  CHANGE_SUBSCRIPTIONS_FAILURE = 'newslettersubscriptions/CHANGE_SUBSCRIPTIONS_FAILURE',
  DELETE_SUBSCRIPTIONS_SUCCESS = 'newslettersubscriptions/DELETE_SUBSCRIPTIONS_SUCCESS'
}

export type IFetchNewsLetterSubscriptionsRequest =
  Action<NewsLetterSubscriptionsActionTypes>
export interface IFetchNewsLetterSubscriptionsSuccess
  extends Action<NewsLetterSubscriptionsActionTypes> {
  payload: {
    subscriptions: INewsLetterSubscription[]
  }
}
export type IFetchNewsLetterSubscriptionsFailure =
  Action<NewsLetterSubscriptionsActionTypes>

export const fetchNewsLetterSubscriptionsRequest =
  (): IFetchNewsLetterSubscriptionsRequest => ({
    type: NewsLetterSubscriptionsActionTypes.FETCH_SUBSCRIPTIONS_REQUEST
  })
export const fetchNewsLetterSubscriptionsSuccess = (
  subscriptions: INewsLetterSubscription[]
): IFetchNewsLetterSubscriptionsSuccess => ({
  type: NewsLetterSubscriptionsActionTypes.FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: { subscriptions }
})

export const fetchNewsLetterSubscriptionsFailure =
  (): IFetchNewsLetterSubscriptionsFailure => ({
    type: NewsLetterSubscriptionsActionTypes.FETCH_SUBSCRIPTIONS_FAILED
  })

export const fetchNewsLetterSubscriptionsResults = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchNewsLetterSubscriptionsRequest())
    try {
      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/getsubscriptions?upn=${ESSettingsGlobalVariables.getUPN()}`

      const initialResponse = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let newsletterSubscriptions: INewsLetterSubscription[] = []

      newsletterSubscriptions = await fetchResultsWithContinueToken(
        newsletterSubscriptions,
        initialResponse,
        apiUrl,
        esToken,
        'GetNewsLetterSubscriptions'
      )

      dispatch(fetchNewsLetterSubscriptionsSuccess(newsletterSubscriptions))
    } catch (error) {
      dispatch(fetchNewsLetterSubscriptionsFailure())
    }
  }
}

const fetchResultsWithContinueToken = async (
  results: INewsLetterSubscription[],
  initialResponse: Response,
  apiUrl: string,
  accessToken: string,
  trackingType: string,
  depth = 0
): Promise<INewsLetterSubscription[]> => {
  let response: IFindResponse

  if (initialResponse && initialResponse.ok) {
    response = {
      hasError: false,
      responseJSON: await initialResponse.json()
    }
  } else {
    response = {
      hasError: true
    }
  }

  if (
    !response ||
    response.hasError ||
    !response.responseJSON ||
    !response.responseJSON.Documents ||
    response.responseJSON.Documents.length < 1
  ) {
    return results
  }

  results = [...results, ...response.responseJSON.Documents]

  const continueToken = initialResponse.headers
    ? initialResponse.headers.get('x-ms-continuation')
    : null

  const newDepth = depth + 1

  if (!continueToken || newDepth > maxSelfServicePageLoadingDepth) {
    return results
  }

  trackSelfServiceEvent({
    type: trackingType,
    continueToken: continueToken,
    currentLength: results.length
  })

  try {
    const continueResponse = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${accessToken}`,
        'x-ms-continuation': continueToken
      }
    })

    return await fetchResultsWithContinueToken(
      results,
      continueResponse,
      apiUrl,
      accessToken,
      trackingType,
      newDepth
    )
  } catch (error) {
    trackSelfServiceEvent({
      type: trackingType,
      continueToken: continueToken,
      currentLength: results.length,
      hasError: true,
      exception: (error as any).message
    })
  }

  return results
}

export interface IAddSubscriptionSuccess
  extends Action<NewsLetterSubscriptionsActionTypes> {
  payload: {
    addSubscription: INewsLetterSubscription
  }
}
export const addSubscriptionSuccess = (
  addSubscription: INewsLetterSubscription
): IAddSubscriptionSuccess => ({
  type: NewsLetterSubscriptionsActionTypes.ADD_SUBSCRIPTIONS_SUCCESS,
  payload: { addSubscription }
})

export type IChangeSubscriptionFailure =
  Action<NewsLetterSubscriptionsActionTypes>
export const changeSubscriptionFailure = (): IChangeSubscriptionFailure => ({
  type: NewsLetterSubscriptionsActionTypes.CHANGE_SUBSCRIPTIONS_FAILURE
})

export interface IDeleteSubscriptionSuccess
  extends Action<NewsLetterSubscriptionsActionTypes> {
  payload: {
    subscriptionId: string
  }
}
export const deleteSubscriptionSuccess = (
  subscriptionId: string
): IDeleteSubscriptionSuccess => ({
  type: NewsLetterSubscriptionsActionTypes.DELETE_SUBSCRIPTIONS_SUCCESS,
  payload: { subscriptionId }
})

export const addNewsLetterSubscription = (
  subscriptionItem: INewsLetterSubscription
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      const subItem = JSON.parse(JSON.stringify(subscriptionItem))
      if (subItem.tableData) {
        delete subItem.tableData
      }
      if (subItem.draft) {
        delete subItem.draft
      }
      if (subItem._rid) {
        delete subItem._rid
      }
      if (subItem.etag) {
        delete subItem.etag
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/addsubscription?upn=${ESSettingsGlobalVariables.getUPN()}&subscriptionupn=${
        subItem.upn
      }`

      const utf8Bytes = encodeURIComponent(JSON.stringify(subItem)).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode(Number('0x' + p1))
        }
      )

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body:
          Config.LOCAL_DEVELOPMENT.toString() === 'true'
            ? JSON.stringify({
                content: subItem
              })
            : JSON.stringify({
                content: `${btoa(utf8Bytes).replace(/=/g, '{eq}')}`
              })
      })

      if (data.status !== 200 && data.status !== 201) {
        trackException(
          'Error in upsert subscription item',
          new Error('Error in data object after upserting subscription item')
        )
        dispatch(changeSubscriptionFailure())
        return
      }

      dispatch(addSubscriptionSuccess(subItem))
    } catch (error) {
      trackException('Error in upsert subscription action', error)
      dispatch(changeSubscriptionFailure())
    }
  }
}

export const deleteNewsLetterSubscription = (
  subItem: INewsLetterSubscription
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      const apiUrl = `${
        Config.APIM_BASE_URL
      }selfserviceapi/deletesubscription?docId=${
        subItem.id
      }&upn=${ESSettingsGlobalVariables.getUPN()}&subscriptionupn=${
        subItem.upn
      }`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (data.status !== 204 && data.status !== 200) {
        trackException(
          'Error in delete subscription item',
          new Error('Error in data object after deleting subscription item')
        )
        dispatch(changeSubscriptionFailure())
        return
      }

      dispatch(deleteSubscriptionSuccess(subItem.id))
    } catch (error) {
      trackException('Error in delete subscription item action', error)
      dispatch(changeSubscriptionFailure())
    }
  }
}

export const fetchNewsLetterSubscription = async (
  upn: string,
  aadInfo: IAADState
): Promise<{
  hasError: boolean
  newsLetterItem: INewsLetterSubscription | null
}> => {
  try {
    // Get and check authentication token
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    if (esToken === '') {
      return {
        hasError: true,
        newsLetterItem: null
      }
    }

    const apiUrl = `${Config.APIM_BASE_URL}selfserviceapi/getsubscription?upn=${upn}`

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${esToken}`
      }
    })

    if (response.status !== 200) {
      return {
        hasError: true,
        newsLetterItem: null
      }
    }

    const jsonResponse = await response.json()

    if (!jsonResponse || !jsonResponse.Documents) {
      return {
        hasError: true,
        newsLetterItem: null
      }
    }

    if (jsonResponse.Documents.length > 0 && jsonResponse.Documents[0]) {
      const result = jsonResponse.Documents[0] as INewsLetterSubscription
      if (result.upn === upn) {
        return {
          hasError: false,
          newsLetterItem: result
        }
      }
    }

    return {
      hasError: false,
      newsLetterItem: null
    }
  } catch (error) {
    return {
      hasError: true,
      newsLetterItem: null
    }
  }
}

export type NewsLetterSubscriptionsActions =
  | IFetchNewsLetterSubscriptionsRequest
  | IFetchNewsLetterSubscriptionsSuccess
  | IFetchNewsLetterSubscriptionsFailure
