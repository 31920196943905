import React, { useRef, useState } from 'react'
import { CardMedia } from '@mui/material'
import MaterialTable from '@material-table/core'
import { Edit } from '@mui/icons-material'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import Logo from 'images/find_logo.png'

import AdToolBar from '../common/AdToolBar'
import { hasDateExpired } from 'utils/date'
import { AdsResult } from 'models/AdsResult'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import { ExportCsv } from '@material-table/exporters'
import { parseAddsDataToExport } from 'utils/admin/adminExportUtils'
import { tableIcons } from 'utils/admin/adminSettingsUtils'
import { adWordColumns } from './AdwordTableColumns'
import { TableStateAds } from 'models/TableStates'

export interface IAdWordTableProps {
  state: TableStateAds
  handleRowDelete: any
  handleRowUpdate: any
  handleRowAdd: any
  handleFormOpen: any
}

export default function AdWordTable(props: IAdWordTableProps): JSX.Element {
  const {
    state,
    handleRowDelete,
    handleRowUpdate,
    handleRowAdd,
    handleFormOpen
  } = props
  const classes = getStylesAdminSettings()

  const [isFilterSet, setIsFilterSet] = useState(false)

  //force browser scrollbars
  const rootElement = document.getElementById('root')
  if (rootElement) rootElement.style.flexDirection = 'unset'

  const dataColumnsList = useRef(adWordColumns())

  return (
    <>
      <MaterialTable
        title={
          <>
            <div className={classes.header}>
              <CardMedia image={Logo} className={classes.logo} />
              <h2>Adwords Configuration</h2>
            </div>
            <div className={classes.information}>
              Any changes being made to the AdWords using this configuration
              screen are common for OI and Find applications.
            </div>
          </>
        }
        icons={tableIcons}
        columns={dataColumnsList.current}
        data={state.tableView as AdsResult[]}
        options={{
          rowStyle: (data: AdsResult) => ({
            fontSize: 12,
            backgroundColor:
              data.end && hasDateExpired(data.end) ? '#BBB' : 'inherit'
          }),
          addRowPosition: 'first',
          pageSize: 25,
          pageSizeOptions: [25, 50, 75],
          tableLayout: 'auto',
          filtering: true,
          exportAllData: true,
          emptyRowsWhenPaging: false,
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (data, columns) => {
                if (isFilterSet) {
                  const exportList: AdsResult[] = []

                  const allAds = state.data as AdsResult[]
                  const exportData = columns

                  exportData.forEach((exportAd: AdsResult) => {
                    allAds.forEach((ad: AdsResult) => {
                      if (exportAd.id === ad.id) exportList.push(ad)
                    })
                  })

                  ExportCsv(
                    data,
                    parseAddsDataToExport(exportList),
                    'AdWords',
                    ';'
                  )
                } else {
                  ExportCsv(
                    data,
                    parseAddsDataToExport(state.data as AdsResult[]),
                    'AdWords',
                    ';'
                  )
                }
              }
            }
          ]
        }}
        localization={{
          body: {
            editRow: {
              deleteText:
                'Are you sure you want to delete this row? Please remember to publish!'
            }
          }
        }}
        editable={{
          isDeleteHidden: (rowData) => {
            return !!rowData.requestId
          },
          onRowDelete: handleRowDelete
        }}
        actions={[
          (rowData) => ({
            icon: Edit,
            onClick: (event: any, rowData: AdsResult | AdsResult[]) => {
              if (!(rowData instanceof Array)) {
                handleFormOpen(rowData)
              }
            },
            tooltip: !rowData.requestId
              ? 'Edit'
              : `Edit Adword through "All Requests" view (Id: "${rowData.requestId}", Created by: "${rowData.requestUser}").`,
            disabled: !!rowData.requestId
          })
        ]}
        components={{
          Toolbar: (props: any) => (
            <AdToolBar
              {...{
                ...props,
                handleRowAdd: handleRowAdd,
                handleRowUpdate: handleRowUpdate,
                adminSettingType: adminSettingTypes.ads
              }}
            />
          )
        }}
        onFilterChange={(filters: any) => {
          setIsFilterSet(filters.length > 0)
        }}
      />
    </>
  )
}
