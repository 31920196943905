import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Checkbox, Input, Typography } from '@mui/material'
import { read, utils, WorkBook } from 'xlsx'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import { getStylesAdBulkUpload } from 'styles/admin/AdBulkUpload'
import { AdsResult } from 'models/AdsResult'
import createDOMPurify from 'dompurify'
import { adminScopes } from 'constants/adminSettingTypes'
import { updateAdDefaultValues } from 'utils/admin/adminContentQuality'
import {
  displayNegatedValues,
  findNotValidCountry,
  findNotValidFunction,
  findNotValidSources,
  isMatchTypeValid,
  isScopeValid,
  parseDate,
  parseDateHuman,
  splitCsV
} from 'utils/admin/adminSettingsUtils'
import { ISelfServiceBulkUploadEvent } from 'models/SelfServiceTrackingEvent'
import { trackSelfServiceEvent } from 'utils/tracking'

interface AdsResultImport extends AdsResult {
  importId: number
}

interface IProps {
  open: boolean
  tableData: AdsResult[]
  setOpen: (value: boolean) => void
  handleRowUpdate: (newData: AdsResult[]) => void
}

export default function AdBulkUpload(props: IProps): JSX.Element {
  const { open, setOpen, handleRowUpdate, tableData } = props

  const [language, setLanguage] = useState('')
  const [languageInXlsx, setLanguageInXlsx] = useState(false)
  const [hasErrorLanguage, setHasErrorLanguage] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [infoText, setInfoText] = useState('')

  const [xlsxJson, setXlsxJson] = useState<any>(null)
  const [xlsxWorkbook, setXlsxWorkbook] = useState<WorkBook>()
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [xlsxSheetList, setXlsxSheetList] = useState<string[]>([])
  const classes = getStylesAdBulkUpload()
  const DOMPurify = createDOMPurify(window)

  const findExistingItemId = (importId: number) => {
    if (
      importId &&
      tableData.find(
        (adsResult: AdsResult) => adsResult.id === importId.toString()
      )
    ) {
      return importId
    }
    return -1
  }

  const getItemId = (importId: number) => {
    let id = findExistingItemId(importId)
    if (id > 0) {
      return id
    } else {
      tableData.forEach((adsResult: AdsResult) => {
        const idInt = parseInt(adsResult.id)
        if (idInt > id) {
          id = idInt
        }
      })
      return id
    }
  }

  useEffect(() => {
    //prevent browser back button
    if (open)
      window.history.pushState(null, document.title, window.location.href)
    window.onpopstate = function () {
      if (open) {
        window.history.go(1)
        setOpen(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const filterflavour = (input: string): 'small' | 'medium' | 'large' => {
    if (input === 'small' || input === 'medium' || input === 'large') {
      return input
    } else {
      return 'small'
    }
  }

  const generateAdObjectListFromXls = () => {
    const newAds: AdsResultImport[] = []
    let newAdsIndex = 0

    selectedRows.forEach((indexImport: number) => {
      const itemImport = xlsxJson[indexImport]

      //search already imported parent translation
      const imFound = newAds.find(
        (item: AdsResultImport) =>
          item.importId && itemImport.Id && item.importId === itemImport.Id
      )

      let itemId = -1
      if (imFound) {
        //use same id
        itemId = parseInt(imFound.id)
      } else {
        //create new id
        itemId = getItemId(itemImport.Id)
        if (!itemImport.Id || itemId.toString() !== itemImport.Id.toString()) {
          itemId = itemId + newAdsIndex + 1
          newAdsIndex = newAdsIndex + 1
        }
      }

      newAds.push({
        importId: itemImport.Id,
        id: itemId.toString(),
        language: languageInXlsx
          ? DOMPurify.sanitize(itemImport.Language)
          : language,
        title: DOMPurify.sanitize(itemImport.Title),
        text: DOMPurify.sanitize(itemImport.Description),
        link: itemImport.URL
          ? DOMPurify.sanitize(itemImport.URL)
          : DOMPurify.sanitize(itemImport.Link),
        link_text: DOMPurify.sanitize(itemImport['Link Text']),
        image: DOMPurify.sanitize(itemImport.Image)
          ? '/externalContent/adsmedia/' + DOMPurify.sanitize(itemImport.Image)
          : '',
        start: parseDate(
          itemImport['Start Date']
            ? DOMPurify.sanitize(itemImport['Start Date'])
            : DOMPurify.sanitize(itemImport['Start'])
        ),
        end: parseDate(
          itemImport['End date']
            ? DOMPurify.sanitize(itemImport['End date'])
            : DOMPurify.sanitize(itemImport['End'])
        ),
        site_url: DOMPurify.sanitize(itemImport['Site Url']),
        search_terms: splitCsV(
          itemImport['Search terms']
            ? DOMPurify.sanitize(itemImport['Search terms'])
            : DOMPurify.sanitize(itemImport['Search Terms'])
        ),
        rank: itemImport.Rank
          ? parseInt(DOMPurify.sanitize(itemImport.Rank))
          : 0,
        sources: splitCsV(
          itemImport.Vertical
            ? DOMPurify.sanitize(itemImport.Vertical)
            : itemImport.Sources
        ),
        functions: splitCsV(
          itemImport.Function
            ? DOMPurify.sanitize(itemImport.Function)
            : itemImport.Functions
        ),
        countries: splitCsV(
          itemImport.Country
            ? DOMPurify.sanitize(itemImport.Country)
            : itemImport.Countries
        ),
        match_type: itemImport['Match Type']
          ? DOMPurify.sanitize(itemImport['Match Type'])
          : DOMPurify.sanitize(itemImport['Match type']),
        flavour: filterflavour(DOMPurify.sanitize(itemImport.Flavour)),
        foundBySearchTerm: true,
        scope: itemImport.Scope
          ? (DOMPurify.sanitize(itemImport.Scope) as adminScopes)
          : 'All',
        requestUser: itemImport['Mapped User']
      })
    })

    return newAds
  }

  const computeValues = () => {
    const newAds: AdsResultImport[] = generateAdObjectListFromXls()

    const event: ISelfServiceBulkUploadEvent = {
      type: 'BulkUploadAdWords',
      added: [],
      overwrites: []
    }

    const newAdsCleaned: AdsResult[] = []
    newAds.forEach((item: AdsResultImport) => {
      const newCleanAd: AdsResult = {
        id: item.id,
        language: item.language,
        title: item.title,
        text: item.text,
        link: item.link,
        link_text: item.link_text,
        image: item.image,
        start: item.start,
        end: item.end,
        site_url: item.site_url,
        search_terms: item.search_terms,
        rank: item.rank,
        sources: item.sources,
        functions: item.functions,
        countries: item.countries,
        match_type: item.match_type,
        flavour: item.flavour,
        foundBySearchTerm: item.foundBySearchTerm,
        scope: item.scope
      }

      if (item.requestUser) {
        newCleanAd.requestUser = item.requestUser
        newCleanAd.mapToRequestItem = true
      }
      newAdsCleaned.push(newCleanAd)

      const index = findExistingItemId(parseInt(newCleanAd.id))
      if (
        index === -1 &&
        event.added.indexOf(newCleanAd.id.toString()) === -1
      ) {
        event.added.push(newCleanAd.id.toString())
      } else if (event.overwrites.indexOf(newCleanAd.id.toString()) === -1) {
        event.overwrites.push(newCleanAd.id.toString())
      }
    })

    trackSelfServiceEvent(event)

    updateAdDefaultValues(newAdsCleaned)
    handleRowUpdate(newAdsCleaned)
    return true
  }

  useEffect(() => {
    setHasErrorLanguage(false)
    setErrorText('')
    setInfoText('')

    setXlsxJson(null)
    setXlsxWorkbook(undefined)
    setSelectedRows([])
    setXlsxSheetList([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    let countNewItems = 0
    let countUpdateItems = 0

    const newAds: AdsResultImport[] = generateAdObjectListFromXls()
    newAds.forEach((newitem: AdsResultImport) => {
      const index = findExistingItemId(parseInt(newitem.id))
      if (index === -1) {
        //add new item on top
        countNewItems++
      } else {
        //update item in state
        countUpdateItems++
      }
    })

    let infoText = ''
    if (countNewItems) infoText += 'add ' + countNewItems + ' new item(s)'

    if (countUpdateItems && infoText !== '') infoText += ', '

    if (countUpdateItems) infoText += 'update ' + countUpdateItems + ' item(s)'

    setInfoText(infoText)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows])

  const readXlsxFile = (fileData: string | ArrayBuffer) => {
    if (!fileData) return

    const workbook = read(fileData, { type: 'buffer', cellDates: true })
    setXlsxWorkbook(workbook)
    setXlsxSheetList(workbook.SheetNames)
  }

  const selectXlsxSheet = (sheetName: string, index: number) => {
    if (xlsxWorkbook) {
      //try to find language
      const language = supportedLanguages.find(
        (languageItem: SupportedLanguage) =>
          languageItem.name
            .toLocaleLowerCase()
            .startsWith(sheetName.toLocaleLowerCase())
      )
      if (language) setLanguage(language.locale)

      //set json
      setXlsxJson(utils.sheet_to_json(xlsxWorkbook.Sheets[sheetName]))
    }
  }

  useEffect(() => {
    if (xlsxJson && xlsxJson.length > 0 && xlsxJson[0].Language) {
      setLanguageInXlsx(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xlsxJson])

  const addRowIndex = (index: number) => {
    const lastSelectedRows = [...selectedRows]
    const indexSelected = lastSelectedRows.indexOf(index)
    if (indexSelected > -1) {
      //remove
      lastSelectedRows.splice(indexSelected, 1)
    } else {
      //add
      lastSelectedRows.push(index)
    }
    setSelectedRows(lastSelectedRows)
  }

  const isLanguageValid = (lang: string) => {
    return !!supportedLanguages.find((item: any) => item.locale === lang)
  }

  const isFlavourValid = (flavour: string) => {
    return ['small', 'medium', 'large'].includes(flavour)
  }

  const isImportItemInValid = (data: any) => {
    let isInvalid =
      (!data.Vertical && !data.Sources) ||
      (languageInXlsx && !data.Language) ||
      !!findNotValidCountry(data.Country ? data.Country : data.Countries) ||
      !!findNotValidFunction(data.Function ? data.Function : data.Functions) ||
      !isFlavourValid(data['Flavour']) ||
      !isScopeValid(data['Scope']) ||
      !isMatchTypeValid(
        data['Match Type'] ? data['Match Type'] : data['Match type']
      ) ||
      !!findNotValidSources(data.Vertical ? data.Vertical : data.Sources) ||
      !isLanguageValid(data.Language) ||
      !data.Description ||
      !data.Title ||
      !(data['Search terms'] || data['Search Terms']) ||
      ((data['Start Date'] || data['Start']) &&
        !parseDate(data['Start Date'] || data['Start'])) ||
      ((data['End Date'] || data['End']) &&
        !parseDate(data['End Date'] || data['End']))

    if (getUserMappingErrorMessage(data)) {
      isInvalid = true
    }

    return isInvalid
  }

  const getUserMappingErrorMessage = (data: any): string | null => {
    if (data.Id && data['Mapped User']) {
      // Check if item already exist, no mapping is possible in this case
      const tableItem = tableData.find((f: AdsResult) => {
        return f.id === data.Id.toString()
      })

      if (tableItem) {
        return `Item with id '${data.Id}' already exist, mapping not possible`
      }

      // Check if the user is equal, if not mapping is not possible
      if (
        xlsxJson.some((xslData: any) => {
          return (
            xslData.Id === data.Id &&
            data['Mapped User'] !== xslData['Mapped User']
          )
        })
      ) {
        return `User values not the same for items with id '${data.Id}', mapping not possible`
      }

      // Check if default language is provided, if not mapping is not possible
      if (
        !xlsxJson.some((xslData: any) => {
          return xslData.Id === data.Id && xslData.Language === 'en'
        })
      ) {
        return `Default language 'en' missing for items with id '${data.Id}', mapping not possible`
      }
    }

    // Check if id is provided if not mapping is not possible
    if (data['Mapped User'] && !data.Id) {
      return `Id is required for user mapping`
    }

    return null
  }

  return (
    <Dialog open={open} fullWidth={true} fullScreen={true}>
      <DialogTitle>Bulk upload</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.spaceBottom}
          component="div"
        >
          <Box>1. Select File (xlsx):</Box>
          <Button
            variant="contained"
            component="label"
            disabled={!!xlsxWorkbook}
          >
            <Box>Upload</Box>
            <input
              type="file"
              hidden
              onChange={(event: any) => {
                const file = event.target.files[0]
                if (file) {
                  const reader = new FileReader()
                  reader.onload = function (evt) {
                    const data = evt.target?.result
                    if (data) readXlsxFile(data)
                  }
                  reader.readAsArrayBuffer(file)
                }
              }}
            />
          </Button>
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.spaceBottom}
          component="div"
        >
          {xlsxSheetList && xlsxSheetList.length > 0 && (
            <>
              <Box>2. Select Sheet:</Box>
              {xlsxSheetList.map((sheetName: string, index: number) => (
                <Button
                  key={'sheet_' + index}
                  variant="contained"
                  component="label"
                  onClick={() => {
                    setSelectedRows([])
                    selectXlsxSheet(sheetName, index)
                  }}
                >
                  {sheetName}
                </Button>
              ))}
            </>
          )}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.spaceBottom}
          component="div"
        >
          {xlsxJson && (
            <>
              <Box>3. Select items to import:</Box>
              <Box
                style={{
                  maxWidth: '100%',
                  maxHeight: '300px',
                  overflow: 'scroll'
                }}
              >
                <table className={classes.tableStyle}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Vertical</th>
                      {languageInXlsx && <th>Language</th>}
                      <th>Title</th>
                      <th>Description</th>
                      <th>Link Text</th>
                      <th>URL</th>
                      <th>Image</th>
                      <th>Rank</th>
                      <th>Country</th>
                      <th>Match Type</th>
                      <th>Function</th>
                      <th>Start Date</th>
                      <th>End date</th>
                      <th>Site Url</th>
                      <th>Search terms</th>
                      <th>Flavour</th>
                      <th>Scope</th>
                      <th>Mapped User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {xlsxJson.map((data: any, index: number) => (
                      <tr key={'import_item_' + index}>
                        <td>
                          <Checkbox
                            disableRipple
                            color="default"
                            disabled={isImportItemInValid(data)}
                            checked={selectedRows.includes(index)}
                            onClick={() => {
                              addRowIndex(index)
                            }}
                          />
                        </td>
                        <td>
                          {data.Vertical || data.Sources ? (
                            <>
                              {!!findNotValidSources(
                                data.Vertical ? data.Vertical : data.Sources
                              ) ? (
                                <Box style={{ color: 'red' }}>
                                  "
                                  {findNotValidSources(
                                    data.Vertical ? data.Vertical : data.Sources
                                  )}
                                  " is not supported
                                </Box>
                              ) : (
                                <>
                                  {displayNegatedValues(
                                    data.Vertical
                                      ? DOMPurify.sanitize(data.Vertical)
                                      : DOMPurify.sanitize(data.Sources)
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <Box style={{ color: 'red' }}>source missing</Box>
                          )}
                        </td>
                        {languageInXlsx && (
                          <td>
                            {data.Language ? (
                              <>
                                {!isLanguageValid(data.Language) ? (
                                  <Box style={{ color: 'red' }}>
                                    "{DOMPurify.sanitize(data.Language)}" is not
                                    supported
                                  </Box>
                                ) : (
                                  <>{DOMPurify.sanitize(data.Language)}</>
                                )}
                              </>
                            ) : (
                              <Box style={{ color: 'red' }}>
                                language missing
                              </Box>
                            )}
                          </td>
                        )}

                        <td>
                          {data.Title ? (
                            DOMPurify.sanitize(data.Title)
                          ) : (
                            <Box style={{ color: 'red' }}>title missing</Box>
                          )}
                        </td>
                        <td>
                          {data.Description ? (
                            DOMPurify.sanitize(data.Description)
                          ) : (
                            <Box style={{ color: 'red' }}>
                              description missing
                            </Box>
                          )}
                        </td>
                        <td>{DOMPurify.sanitize(data['Link Text'])}</td>
                        <td>
                          {data['URL']
                            ? DOMPurify.sanitize(data['URL'])
                            : DOMPurify.sanitize(data['Link'])}
                        </td>
                        <td>{DOMPurify.sanitize(data['Image'])}</td>
                        <td>{DOMPurify.sanitize(data['Rank'])}</td>
                        <td>
                          {!!findNotValidCountry(
                            data.Country ? data.Country : data.Countries
                          ) ? (
                            <Box style={{ color: 'red' }}>
                              "
                              {findNotValidCountry(
                                data.Country ? data.Country : data.Countries
                              )}
                              " is not supported
                            </Box>
                          ) : (
                            <>
                              {displayNegatedValues(
                                data.Country
                                  ? DOMPurify.sanitize(data.Country)
                                  : DOMPurify.sanitize(data.Countries)
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          {!isMatchTypeValid(
                            data['Match Type']
                              ? data['Match Type']
                              : data['Match type']
                          ) ? (
                            <Box style={{ color: 'red' }}>
                              "
                              {data['Match Type']
                                ? DOMPurify.sanitize(data['Match Type'])
                                : DOMPurify.sanitize(data['Match type'])}
                              " is not supported
                            </Box>
                          ) : (
                            <>
                              {data['Match Type']
                                ? DOMPurify.sanitize(data['Match Type'])
                                : DOMPurify.sanitize(data['Match type'])}
                            </>
                          )}
                        </td>
                        <td>
                          {!!findNotValidFunction(
                            data.Function ? data.Function : data.Functions
                          ) ? (
                            <Box style={{ color: 'red' }}>
                              "
                              {findNotValidFunction(
                                data.Function ? data.Function : data.Functions
                              )}
                              " is not supported
                            </Box>
                          ) : (
                            <>
                              {displayNegatedValues(
                                data.Function
                                  ? DOMPurify.sanitize(data.Function)
                                  : DOMPurify.sanitize(data.Functions)
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          {(data['Start Date'] || data['Start']) &&
                          !parseDate(data['Start Date'] || data['Start']) ? (
                            <Box style={{ color: 'red' }}>
                              Cannot parse start date.
                            </Box>
                          ) : (
                            <>
                              {parseDateHuman(
                                data['Start Date'] || data['Start']
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          {(data['End Date'] || data['End']) &&
                          !parseDate(data['End Date'] || data['End']) ? (
                            <Box style={{ color: 'red' }}>
                              Cannot parse end date.
                            </Box>
                          ) : (
                            <>
                              {parseDateHuman(data['End Date'] || data['End'])}
                            </>
                          )}
                        </td>
                        <td>{DOMPurify.sanitize(data['Site Url'])}</td>
                        <td>
                          {data['Search terms'] || data['Search Terms'] ? (
                            data['Search terms'] ? (
                              DOMPurify.sanitize(data['Search terms'])
                            ) : (
                              DOMPurify.sanitize(data['Search Terms'])
                            )
                          ) : (
                            <Box style={{ color: 'red' }}>
                              Search terms missing
                            </Box>
                          )}
                        </td>
                        <td>
                          {!isFlavourValid(data['Flavour']) ? (
                            <Box style={{ color: 'red' }}>
                              "{DOMPurify.sanitize(data['Flavour'])}" is not
                              supported
                            </Box>
                          ) : (
                            <>{DOMPurify.sanitize(data['Flavour'])}</>
                          )}
                        </td>
                        <td>
                          {!isScopeValid(data['Scope']) ? (
                            <Box style={{ color: 'red' }}>
                              "{DOMPurify.sanitize(data['Scope'])}" is not
                              supported
                            </Box>
                          ) : (
                            <>{DOMPurify.sanitize(data['Scope'])}</>
                          )}
                        </td>
                        <td>
                          {!getUserMappingErrorMessage(data) ? (
                            DOMPurify.sanitize(data['Mapped User'])
                          ) : (
                            <Box style={{ color: 'red' }}>
                              {getUserMappingErrorMessage(data)}
                            </Box>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
              <div className={classes.tableActions}>
                <Button
                  variant="outlined"
                  component="label"
                  onClick={() => {
                    const lastSelectedRows = [...selectedRows]
                    for (let index = 0; index < xlsxJson.length; index++) {
                      const indexSelected = lastSelectedRows.indexOf(index)
                      if (
                        indexSelected === -1 &&
                        !isImportItemInValid(xlsxJson[index])
                      ) {
                        //add
                        lastSelectedRows.push(index)
                      }
                    }

                    setSelectedRows(lastSelectedRows)
                  }}
                >
                  Select All
                </Button>
                <span className={classes.tableActionsSelected}>
                  {selectedRows.length}/{xlsxJson.length} selected
                </span>
              </div>
            </>
          )}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="div">
          {xlsxJson && !languageInXlsx && (
            <>
              <Box>4. Select language:</Box>
              <Input
                style={
                  hasErrorLanguage
                    ? { border: '1px solid red', marginTop: '1em' }
                    : { marginTop: '1em' }
                }
                value={language}
                placeholder={'Enter language'}
                onChange={(event: any) => {
                  setLanguage(event.target.value)
                }}
              />
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        {errorText && <Box style={{ color: 'red' }}>{errorText}</Box>}
        {infoText && <Box style={{ color: 'red' }}>{infoText}</Box>}
        <Button
          onClick={() => {
            setHasErrorLanguage(false)

            setErrorText('')
            if (selectedRows.length === 0) {
              setErrorText('Please select the rows to be imported.')
              return
            }
            if (!language && !languageInXlsx) {
              setErrorText('Please select a language.')
              setHasErrorLanguage(true)
              return
            }
            if (computeValues()) {
              setOpen(false)
            }
          }}
          color="primary"
        >
          Import
        </Button>
        <Button
          onClick={() => {
            setOpen(false)
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
