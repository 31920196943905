import dayjs from 'dayjs'

/**
 * Is given date outdated
 * @param date date as string
 * @returns outdated true/false
 */
export function hasDateExpired(date: string | null): boolean {
  return !!(date && dayjs().diff(new Date(date), 'days', true) > 1)
}

export function createDefaultDate() {
  const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  return date.toISOString()
}
