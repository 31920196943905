import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesAds(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    advertisementArea: {
      marginLeft: 16,
      marginBottom: 16,
      outline: 'solid 1px #dfe1e5',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      minWidth: 400,
      maxWidth: 448
    },
    advertisementText: {
      color: '#424242',
      textAlign: 'left',
      fontWeight: 'lighter',
      font: 'Arial,Helvetica Neue,Helvetica,sans-serif-light',
      flex: 1,
      margin: '16px 0px',
      lineHeight: 1.5
    },
    advertisementTextContainer: {
      padding: 20
    },
    // classes for individual ads flavour
    smallAdsTopRow: {
      display: 'flex',
      alignItems: 'center'
    },
    smallAdsIcon: {
      width: '80px',
      height: '80px',
      marginRight: 8,
      borderRadius: 8,
      objectFit: 'cover'
    },
    smallAdsTitle: {
      flex: 1,
      fontSize: 22
    },
    smallAdsLink: {
      color: '#004e98',
      textDecoration: 'none'
    },
    largeAdsRow: {
      objectFit: 'contain',
      borderRadius: '8px 8px 0px 0px',
      width: '100%',
      maxWidth: 448,
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        minHeight: '1px'
      }
    },
    largeAdsImageMissing: {
      width: '100%',
      maxWidth: 448,
      minHeight: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    largeAdsMissingIcon: {
      color: '#d32f2f',
      marginRight: '5px'
    },
    largeAdsMissingText: {
      lineHeight: '24px'
    },
    largeAdsTitle: {
      fontSize: 22
    },
    smallAdsIconWarning: {
      border: '1px solid rgba(234,170,0,1)'
    }
  }))
}
