import React, { useRef, useState } from 'react'
import { CardMedia } from '@mui/material'
import MaterialTable, { Column } from '@material-table/core'
import { Edit } from '@mui/icons-material'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import Logo from 'images/find_logo.png'
import AdToolBar from '../common/AdToolBar'
import { hasDateExpired } from 'utils/date'
import { PopupResult } from 'models/PopupResult'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import { ExportCsv } from '@material-table/exporters'
import { parsePopupsDataToExport } from 'utils/admin/adminExportUtils'
import { tableIcons } from 'utils/admin/adminSettingsUtils'
import { popupColumns } from './PopupsTableColumns'
import { TableStatePopups } from 'models/TableStates'

export interface IPopupsTableProps {
  state: TableStatePopups
  handleRowDelete: any
  handleRowUpdate: any
  handleRowAdd: any
  handleFormOpen: any
}

export default function PopupsTable(props: IPopupsTableProps): JSX.Element {
  const {
    state,
    handleRowDelete,
    handleRowUpdate,
    handleRowAdd,
    handleFormOpen
  } = props

  const classes = getStylesAdminSettings()

  const [isFilterSet, setIsFilterSet] = useState(false)

  //force browser scrollbars
  const rootElement = document.getElementById('root')
  if (rootElement) rootElement.style.flexDirection = 'unset'

  const dataColumnsList = useRef(popupColumns())

  return (
    <MaterialTable
      title={
        <>
          <div className={classes.header}>
            <CardMedia image={Logo} className={classes.logo} />
            <h2>Popups Configuration</h2>
          </div>
          <div className={classes.information}>
            Any changes being made to the Popups using this configuration screen
            are common for OI and Find applications.
          </div>
        </>
      }
      icons={tableIcons}
      columns={dataColumnsList.current as Column<PopupResult>[]}
      data={state.tableView as PopupResult[]}
      options={{
        rowStyle: (data: PopupResult) => ({
          fontSize: 12,
          backgroundColor:
            data.dateEnd && hasDateExpired(data.dateEnd) ? '#BBB' : 'inherit'
        }),
        addRowPosition: 'first',
        pageSize: 25,
        pageSizeOptions: [25, 50, 75],
        tableLayout: 'auto',
        filtering: true,
        emptyRowsWhenPaging: false,
        exportMenu: [
          {
            label: 'Export CSV',
            exportFunc: (data, columns) => {
              if (isFilterSet) {
                const exportList: PopupResult[] = []

                const allPopups = state.data as PopupResult[]
                const exportData = columns

                exportData.forEach((exportPopup: PopupResult) => {
                  allPopups.forEach((popup: PopupResult) => {
                    if (exportPopup.id === popup.id) exportList.push(popup)
                  })
                })

                ExportCsv(
                  data,
                  parsePopupsDataToExport(exportList),
                  'Popups',
                  ';'
                )
              } else {
                ExportCsv(
                  data,
                  parsePopupsDataToExport(state.data as PopupResult[]),
                  'Popups',
                  ';'
                )
              }
            }
          }
        ]
      }}
      localization={{
        body: {
          editRow: {
            deleteText:
              'Are you sure you want to delete this row? Please remember to publish!'
          }
        }
      }}
      editable={{
        onRowDelete: handleRowDelete
      }}
      actions={[
        {
          icon: Edit,
          onClick: (event: any, rowData: PopupResult | PopupResult[]) => {
            if (!(rowData instanceof Array)) {
              handleFormOpen(rowData)
            }
          },
          tooltip: 'Edit'
        }
      ]}
      components={{
        Toolbar: (props: any) => (
          <AdToolBar
            {...{
              ...props,
              handleRowAdd: handleRowAdd,
              handleRowUpdate: handleRowUpdate,
              disableBulkUpload: true,
              addButtonText: 'Add Popup',
              adminSettingType: adminSettingTypes.popups
            }}
          />
        )
      }}
      onFilterChange={(filters: any) => {
        setIsFilterSet(filters.length > 0)
      }}
    />
  )
}
