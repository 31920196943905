import { CustomAction } from './customAction'

// Hold the current running Actiontypes, with the latest transaction id
interface StoredActionType {
  [key: string]: {
    transActionId: string
  }
}
const actions: StoredActionType = {}

/**
 * Sets the action type in the actions object
 * @param action current running action
 */
const setAction = (action: CustomAction) => {
  actions[action.metaData.transactionType] = {
    transActionId: action.metaData.transactionId
  }
}

/**
 * Return the stored transaction id for the given action
 * @param action current action
 * @returns the found entry from the stored actions
 */
const getAction = (action: CustomAction) => {
  return actions[action.metaData.transactionType]
}

/**
 * Check if the given action is of the type of the custom action and contains the required properties
 * @param action current action to check
 * @returns true in case it is a custom action
 */
const isCustomAction = (action: any) => {
  if (
    action &&
    Object.keys(action).includes('metaData') &&
    action['metaData'].transactionType
  ) {
    return true
  }

  return false
}

/**
 * Checks if the given actions is from the current running process
 * @param newAction the new action to process
 * @returns true in case an oldaction exists and the transaction ids are not the same
 */
const isStaleAction = (newAction: CustomAction) => {
  const oldAction = getAction(newAction)
  return (
    oldAction && oldAction.transActionId !== newAction.metaData.transactionId
  )
}

/**
 * custom middleware to prevent proccessing of old transactions, this should run as first middleware extension
 * @param store the store
 * @returns the next action to perform
 */

export const staleActionCheck =
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  (store: any) => (next: any) => (action: any) => {
    if (!isCustomAction(action)) {
      // no custom actions, run the default processing
      return next(action)
    } else {
      const customAction = action as CustomAction

      if (customAction.metaData.isStartAction) {
        // in case there exists already a running action
        // delete the transaction from the actions object
        if (isStaleAction(customAction))
          delete actions[customAction.metaData.transactionType]

        // add the startaction and perform normally
        setAction(customAction)
        return next(action)
      } else {
        // in case it is a stale action
        // interupt the redux flow and return
        // otherwise delete the action, everything is done and continue normally
        if (isStaleAction(customAction)) {
          return
        } else {
          return next(action)
        }
      }
    }
  }
