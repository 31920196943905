import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Store } from '..'
import * as Config from '../../config'
import AuthStore from '../Auth'
import { trackEvents, trackException } from 'utils/tracking'
import { ContactUsTile } from 'models/ContactUsTile'
import { IFindResponse } from 'utils/api'
import { renewAuthorizationToken } from 'authentication/token'

export enum ContactUsActionTypes {
  FETCH_CONTACT_US_TILES_SUCCESS = 'contactUs/FETCH_CONTACT_US_TILES_SUCCESS',
  FETCH_CONTACT_US_ALL_TILES_REQUEST = 'contactUs/FETCH_CONTACT_US_ALL_TILES_REQUEST',
  FETCH_CONTACT_US_ALL_TILES_SUCCESS = 'contactUs/FETCH_CONTACT_US_ALL_TILES_SUCCESS',
  FETCH_CONTACT_US_TILES_FAILURE = 'contactUs/FETCH_CONTACT_US_TILES_FAILURE',
  UPSERT_CONTACT_US_TILE_SUCCESS = 'contactUs/UPSERT_CONTACT_US_TILE_SUCCESS',
  CHANGE_CONTACT_US_TILE_FAILURE = 'contactUs/CHANGE_CONTACT_US_TILE_FAILURE',
  DELETE_CONTACT_US_TILE_SUCCESS = 'contactUs/DELETE_CONTACT_US_TILE_SUCCESS'
}

export type IFetchContactUsAllTilesRequest = Action<ContactUsActionTypes>
export const fetchContactUsAllTilesRequest =
  (): IFetchContactUsAllTilesRequest => ({
    type: ContactUsActionTypes.FETCH_CONTACT_US_ALL_TILES_REQUEST
  })

export type IFetchContactUsTilesFailure = Action<ContactUsActionTypes>
export const fetchContactUsTilesFailure = (): IFetchContactUsTilesFailure => ({
  type: ContactUsActionTypes.FETCH_CONTACT_US_TILES_FAILURE
})

export interface IFetchContactUsTilesSuccess
  extends Action<ContactUsActionTypes> {
  payload: {
    contactUsTileResponse: any
  }
}
export const fetchContactUsTilesSuccess = (
  contactUsTileResponse: ContactUsTile[]
): IFetchContactUsTilesSuccess => ({
  type: ContactUsActionTypes.FETCH_CONTACT_US_TILES_SUCCESS,
  payload: { contactUsTileResponse }
})

export interface IFetchContactUsAllTilesSuccess
  extends Action<ContactUsActionTypes> {
  payload: {
    contactUsAllTileResponse: any
  }
}
export const fetchContactUsAllTilesSuccess = (
  contactUsAllTileResponse: ContactUsTile[]
): IFetchContactUsAllTilesSuccess => ({
  type: ContactUsActionTypes.FETCH_CONTACT_US_ALL_TILES_SUCCESS,
  payload: { contactUsAllTileResponse }
})

export interface IUpsertContactUsTileSuccess
  extends Action<ContactUsActionTypes> {
  payload: {
    upsertedContactUsTile: ContactUsTile
  }
}
export const upsertContactUsTileSuccess = (
  upsertedContactUsTile: ContactUsTile
): IUpsertContactUsTileSuccess => ({
  type: ContactUsActionTypes.UPSERT_CONTACT_US_TILE_SUCCESS,
  payload: { upsertedContactUsTile }
})

export type IChangeUpsertContactUsTileFailure = Action<ContactUsActionTypes>
export const changeContactUsTileFailure =
  (): IChangeUpsertContactUsTileFailure => ({
    type: ContactUsActionTypes.CHANGE_CONTACT_US_TILE_FAILURE
  })

export interface IDeleteContactUsTileSuccess
  extends Action<ContactUsActionTypes> {
  payload: {
    contactUsTileId: string
    contactUsTileLanguage: string
  }
}
export const deleteContactUsTileSuccess = (
  contactUsTileId: string,
  contactUsTileLanguage: string
): IDeleteContactUsTileSuccess => ({
  type: ContactUsActionTypes.DELETE_CONTACT_US_TILE_SUCCESS,
  payload: { contactUsTileId, contactUsTileLanguage }
})

export const fetchContactUsTiles = (
  language: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      trackEvents('getContactUsTiles', {})

      if (language === 'all') {
        dispatch(fetchContactUsAllTilesRequest())
      }

      const apiUrl = `${Config.APIM_BASE_URL}contactusapi/getcontactustiles?language=${language}&scope=KPMGFind`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (data.status !== 200 && data.status !== 201) {
        dispatch(fetchContactUsTilesFailure())
        trackException(
          'Error in fetching contact us options',
          new Error('Error in data object after fetching contact us options')
        )
        return Promise.resolve()
      }

      let results: IFindResponse
      results = {
        hasError: false,
        responseJSON: await data.json()
      }

      if (!results || !results.responseJSON.Documents) {
        dispatch(fetchContactUsTilesFailure())
        trackException(
          'Error in getting documents out of contact us options',
          new Error('Error in data object after fetching contact us options')
        )
        return Promise.resolve()
      }

      const result: ContactUsTile[] = []
      results.responseJSON.Documents.forEach((co: ContactUsTile) => {
        result.push(co)
      })

      result.sort((a: ContactUsTile, b: ContactUsTile) => {
        return a.order > b.order ? 1 : -1
      })

      if (language === 'all') {
        dispatch(fetchContactUsAllTilesSuccess(result))
      } else {
        dispatch(fetchContactUsTilesSuccess(result))
      }
    } catch (error) {
      dispatch(fetchContactUsTilesFailure())
      trackException('Error in fetching contact us options', error)
    }
  }
}

export const upsertContactUsTile = (
  contactUsTileData: ContactUsTile
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      const contactUsTile = JSON.parse(JSON.stringify(contactUsTileData))
      if (contactUsTile.tableData) {
        delete contactUsTile.tableData
      }
      if (contactUsTile.draft) {
        delete contactUsTile.draft
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}contactusapi/upsertcontactustile?language=${contactUsTile.language}`

      const utf8Bytes = encodeURIComponent(
        JSON.stringify(contactUsTile)
      ).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(Number('0x' + p1))
      })

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body: '{"content":"' + btoa(utf8Bytes) + '"}'
      })

      if (data.status !== 200 && data.status !== 201) {
        trackException(
          'Error in upsert contact us tile',
          new Error('Error in data object after upserting contact us tile')
        )
        dispatch(changeContactUsTileFailure())
        return
      }

      dispatch(upsertContactUsTileSuccess(contactUsTile))
    } catch (error) {
      trackException('Error in upsert contact us tile action', error)
      dispatch(changeContactUsTileFailure())
    }
  }
}

export const deleteContactUsTile = (
  contactUsTileId: string,
  contactUsTileLanguage: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      const apiUrl = `${Config.APIM_BASE_URL}contactusapi/deletecontactustile?docId=${contactUsTileId}&language=${contactUsTileLanguage}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 204) {
        trackException(
          'Error in delete contact us tile',
          new Error('Error in data object after deleting contact us tile')
        )
        dispatch(changeContactUsTileFailure())
        return
      }

      dispatch(
        deleteContactUsTileSuccess(contactUsTileId, contactUsTileLanguage)
      )
    } catch (error) {
      trackException('Error in delete contact us tile action', error)
      dispatch(changeContactUsTileFailure())
    }
  }
}

export type ContactUsActions =
  | IFetchContactUsTilesSuccess
  | IFetchContactUsAllTilesSuccess
  | IFetchContactUsTilesFailure
  | IUpsertContactUsTileSuccess
  | IDeleteContactUsTileSuccess
