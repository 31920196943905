import React from 'react'
import {
  Chip,
  ChipPropsColorOverrides,
  ChipPropsSizeOverrides,
  ChipPropsVariantOverrides,
  Tooltip
} from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { ISelfServiceRequest } from 'models/SelfServiceRequest'

interface AdminTableTooltipClipProps {
  label: string
  color?: OverridableStringUnion<
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    ChipPropsColorOverrides
  >
  maxWidth?: number
  size?: OverridableStringUnion<'small' | 'medium', ChipPropsSizeOverrides>
  variant?: OverridableStringUnion<
    'filled' | 'outlined',
    ChipPropsVariantOverrides
  >
  additionalProps?: any
  rowData?: ISelfServiceRequest
  showComments?: boolean
  additionalTooltip?: string
  handleRowUpdate?: (request: ISelfServiceRequest) => void
  isAdminRequest?: boolean
  addMargin?: boolean
}

export default function AdminTableTooltipClip(
  props: AdminTableTooltipClipProps
): JSX.Element {
  const { label, color, maxWidth, size, variant, additionalProps, addMargin } =
    props

  return (
    <Tooltip title={label} aria-label={label}>
      <Chip
        label={label}
        style={
          maxWidth
            ? addMargin
              ? { maxWidth: maxWidth, margin: '4px' }
              : { maxWidth: maxWidth }
            : addMargin
            ? { margin: '4px' }
            : {}
        }
        {...(color && { color: color })}
        {...(size && { size: size })}
        {...(variant && { variant: variant })}
        {...(additionalProps && { ...additionalProps })}
      />
    </Tooltip>
  )
}
