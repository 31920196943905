export const supportedMemberFirms: string[] = [
  'Australia',
  'Canada',
  'China',
  'France',
  'Germany',
  'Japan',
  'United Kingdom',
  'United States'
]
