export const translationTypes = {
  ads: 'Translations-Ads',
  global: 'Translations-Global',
  globaloi: 'Translations-Global-OI',
  popups: 'Translations-Popups',
  settings: 'Translations-Settings'
}

export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
}

export const requestItemMaxValues = {
  title: 256,
  description: 2048,
  search_terms: 25
}

/** Used to prevent endless loading for the paged results */
export const maxSelfServicePageLoadingDepth = 20

export const maximumAllowedFileSize = 512 * 512
