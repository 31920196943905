import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import {
  ApplicationInsights,
  IExceptionTelemetry,
  ITelemetryItem,
  SeverityLevel
} from '@microsoft/applicationinsights-web'
import * as Config from '../config'
import { IErrorResponse } from './api'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import {
  ISelfServiceBulkUploadEvent,
  ISelfServiceDeputyUpdateEvent,
  ISelfServicePagingEvent,
  ISelfServiceTrackingEvent
} from 'models/SelfServiceTrackingEvent'

// App Insights Global class
export class ApplicationInsightsGlobal {
  private static instance: ApplicationInsights

  private constructor() {
    // This class wraps an ApplicationInsights object to make it a singleton
    // Objects of this class should not be instantiated,
    // Use `getInstance()` to get an `ApplicationInsights` objects instead
  }

  // Gets the App Insights instance
  static getInstance(): ApplicationInsights {
    if (
      !ApplicationInsightsGlobal.instance &&
      Config.LOCAL_DEVELOPMENT.toString() === 'false'
    ) {
      const reactPlugin = new ReactPlugin()
      ApplicationInsightsGlobal.instance = new ApplicationInsights({
        config: {
          instrumentationKey: Config.APP_INSIGHTS_INTRUMENTATION_KEY,
          extensions: [reactPlugin],
          enableAutoRouteTracking: true
        }
      })
      ApplicationInsightsGlobal.instance.loadAppInsights()

      const telemetryInitializer = (envelope: ITelemetryItem) => {
        if (envelope.data) envelope.data['KPMG Source'] = 'ES-Settings'
      }

      ApplicationInsightsGlobal.instance.addTelemetryInitializer(
        telemetryInitializer
      )
    }

    return ApplicationInsightsGlobal.instance
  }
}

// Get App Insights Instance
const appInsights: ApplicationInsights = ApplicationInsightsGlobal.getInstance()

/**
 * Tracks a given Event and its time of occurrence
 * @param propertyName The name of the Event that is supposed to be tracked
 * @param parameters object of parameters to be passed to Application Insight
 */
export function trackEvents(
  propertyName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  parameters: any,
  flush = false
): void {
  if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
    // Make sure username is defined when tracking.
    // Undefined username can potentially occur when the MSAL login has not
    // been fully completed yet.
    // Ensure that country exists to prevent logging of empty default parameters.
    if (!ESSettingsGlobalVariables.getUPN()) {
      return
    }

    const timestamp = Date.now()

    appInsights.trackEvent({
      name: propertyName,
      properties: { ...parameters, timestamp }
    })
    if (flush) appInsights.flush(false) //send all telemetry synced (not async) for links or leave page events
  } else {
    console.log(propertyName, parameters)
  }
}
/**
 * Tracks an exception
 */
export function trackException(
  message: string,
  exception: Error | unknown,
  errorResponse: IErrorResponse | null = null
): void {
  //filter TypeError: Cancelled (happens on redirect while loading, or user cancelled the open session)
  if (
    exception instanceof TypeError &&
    exception.message &&
    exception.message.toLocaleLowerCase() === 'cancelled'
  ) {
    return
  }

  if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
    const exceptionTelemetry: IExceptionTelemetry = {
      exception: exception instanceof Error ? exception : new Error(message),
      properties: {
        'ES Settings Message': message
      },
      severityLevel: SeverityLevel.Error
    }

    if (errorResponse && exceptionTelemetry.properties) {
      exceptionTelemetry.properties['ES Settings Response'] = errorResponse
    }

    appInsights.trackException(exceptionTelemetry)
  }
}

export function trackSelfServiceEvent(
  data:
    | ISelfServiceTrackingEvent
    | ISelfServiceDeputyUpdateEvent
    | ISelfServiceBulkUploadEvent
    | ISelfServicePagingEvent
): void {
  trackEvents('SelfServiceEvent', data, false)
}
