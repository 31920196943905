import sessionCache from './cache'

const TIMESTAMP_KEY = '@request_cache_ts'
export const MAX_TIME_CACHE = 10
const MAX_ITERATIONS_REDUCE_STORAGE = 100
const MAX_CACHED_CONTENT_SIZE = 4000

// Note that this function is executed asynchronously several times in parallel
// work in memory and only update localstorage on change to prevent overwriting
let TIMESTAMP_CACHE: any = null

export interface IFindResponse {
  hasError: boolean
  responseJSON?: any
  errorResponse?: IErrorResponse
}

export interface IErrorResponse {
  responseCode?: number
  message: string
  messageKey?: string
  isApimError?: boolean
  orginalResponseBody?: string | null
  internalError?: boolean
  exception?: Error
}

export const ReduceSessionStorageSize = async (): Promise<void> => {
  let size = sessionCache.size()
  let iteration = 0
  while (
    size > MAX_CACHED_CONTENT_SIZE &&
    iteration < MAX_ITERATIONS_REDUCE_STORAGE
  ) {
    if (!TIMESTAMP_CACHE) {
      TIMESTAMP_CACHE = sessionCache.get(TIMESTAMP_KEY) || {}
    }
    for (const key in TIMESTAMP_CACHE) {
      sessionCache.delete(key)
      delete TIMESTAMP_CACHE[key]
      sessionCache.set(TIMESTAMP_KEY, TIMESTAMP_CACHE)
      break
    }
    size = sessionCache.size()
    iteration++
  }
}
