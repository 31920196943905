import React, { forwardRef } from 'react'
import { AdsResult } from 'models/AdsResult'
import { ContactUsTile } from 'models/ContactUsTile'
import { FeaturedResult } from 'models/FeaturedResult'
import { PopupResult } from 'models/PopupResult'
import { TranslationResult } from 'models/TranslationResult'
import { dateFormatOptions } from 'constants/constants'
import { countriesISO } from 'constants/countriesISO'
import {
  SupportedFunction,
  supportedFunctions
} from 'constants/supportedFunctions'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import { filterByDate } from 'utils/featuredResults'
import { findAdChild, findItemChild, findPopupChild } from './adminFormUtils'
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from '@mui/icons-material'
import {
  ISupportedVertical,
  supportedVerticals
} from 'constants/supportedVerticals'
import { ISearchTip } from 'models/SearchTip'

export const groupAdsById = (ads: AdsResult[]): AdsResult[] => {
  const groupedAds: AdsResult[] = []
  let recognizedAds: AdsResult[] = []

  supportedLanguages.forEach((item: SupportedLanguage) => {
    ads.forEach((ad: AdsResult) => {
      if (ad.language === item.locale) {
        if (!recognizedAds.find((regItem: AdsResult) => regItem.id === ad.id)) {
          const languageChilds = findAdChild(ad, ads)
          const languageChildsFiltered: AdsResult[] = []
          languageChilds.forEach((langChild: AdsResult) => {
            if (
              !recognizedAds.find(
                (regItem: AdsResult) => regItem.id === langChild.id
              )
            ) {
              languageChildsFiltered.push(langChild)
            }
          })

          recognizedAds = [...recognizedAds, ...languageChilds, ...[ad]]

          ad.childs = languageChilds
          groupedAds.push(ad)
        }
      }
    })
  })

  return groupedAds
}

export const groupPopupsById = (popups: PopupResult[]): PopupResult[] => {
  const groupedPopups: PopupResult[] = []
  let recognizedPopups: PopupResult[] = []

  supportedLanguages.forEach((item: SupportedLanguage) => {
    popups.forEach((popup: PopupResult) => {
      if (popup.language === item.locale) {
        if (
          !recognizedPopups.find(
            (regItem: PopupResult) => regItem.id === popup.id
          )
        ) {
          const languageChilds = findPopupChild(popup, popups)
          const languageChildsFiltered: PopupResult[] = []
          languageChilds.forEach((langChild: PopupResult) => {
            if (
              !recognizedPopups.find(
                (regItem: PopupResult) => regItem.id === langChild.id
              )
            ) {
              languageChildsFiltered.push(langChild)
            }
          })

          recognizedPopups = [
            ...recognizedPopups,
            ...languageChilds,
            ...[popup]
          ]

          popup.childs = languageChilds
          groupedPopups.push(popup)
        }
      }
    })
  })

  return groupedPopups
}

export const groupItemsById = (
  itemList: ContactUsTile[] | ISearchTip[]
): ContactUsTile[] | ISearchTip[] => {
  const groupedItemList: ContactUsTile[] | ISearchTip[] = []
  let recongnizedItems: ContactUsTile[] | ISearchTip[] = []

  supportedLanguages.forEach((item: SupportedLanguage) => {
    itemList.forEach((itemToCheck: ContactUsTile | ISearchTip) => {
      if (itemToCheck.language === item.locale) {
        if (
          !recongnizedItems.find(
            (regItem: ContactUsTile | ISearchTip) =>
              regItem.id === itemToCheck.id
          )
        ) {
          const languageChilds = findItemChild(itemToCheck, itemList)
          const languageChildsFiltered: ContactUsTile[] | ISearchTip[] = []
          languageChilds.forEach((langChild: ContactUsTile | ISearchTip) => {
            if (
              !recongnizedItems.find(
                (regItem: ContactUsTile | ISearchTip) =>
                  regItem.id === langChild.id
              )
            ) {
              languageChildsFiltered.push(langChild)
            }
          })

          recongnizedItems = [
            ...recongnizedItems,
            ...languageChilds,
            ...[itemToCheck]
          ]

          groupedItemList.push(itemToCheck)
        }
      }
    })
  })

  return groupedItemList
}

export const getHighestId = (
  data: (
    | AdsResult
    | PopupResult
    | TranslationResult
    | FeaturedResult
    | ContactUsTile
    | ISearchTip
  )[]
): number => {
  let id = -1

  data.forEach(
    (
      item:
        | AdsResult
        | PopupResult
        | TranslationResult
        | FeaturedResult
        | ContactUsTile
        | ISearchTip
    ) => {
      if (parseInt(item.id ? item.id : '0') > id) {
        id = parseInt(item.id ? item.id : '0')
      }
    }
  )

  return id
}

export const getHighestOrder = (
  data: ContactUsTile[] | ISearchTip[]
): number => {
  let order = -1

  data.forEach((item: ContactUsTile | ISearchTip) => {
    if ((item.order ? item.order : 0) > order) {
      order = item.order ? item.order : 0
    }
  })

  return order
}

export const searchFeaturedResultsDuplications = (
  featuredResult: FeaturedResult,
  allAvailableFeaturedResults: FeaturedResult[]
): number => {
  let highestRank = -1
  for (let index = 0; index < allAvailableFeaturedResults.length; index++) {
    const _featuredResult = allAvailableFeaturedResults[index]

    //skip result with the same id
    //skip outdated featured results
    if (
      featuredResult.id === _featuredResult.id ||
      !filterByDate(_featuredResult)
    ) {
      continue
    }

    //skip different sources
    if (
      featuredResult.BestBetScope === 'OI' &&
      _featuredResult.BestBetScope === 'KPMGFind'
    ) {
      continue
    }
    if (
      featuredResult.BestBetScope === 'KPMGFind' &&
      _featuredResult.BestBetScope === 'OI'
    ) {
      continue
    }

    //compare keywords
    const keywordFound = featuredResult.BestBetKeywords.some(
      (bestBetKeyWord) => {
        if (
          bestBetKeyWord &&
          _featuredResult.BestBetKeywords.find(
            (_bestBetKeyWord) =>
              _bestBetKeyWord &&
              _bestBetKeyWord.toLocaleLowerCase() ===
                bestBetKeyWord.toLocaleLowerCase()
          )
        ) {
          return true
        }

        return false
      }
    )

    //compare criteria
    const countryList = countriesISO.map((item) => item.Name)
    const functionList = supportedFunctions.map(
      (item: SupportedFunction) => item.name
    )

    if (
      keywordFound &&
      //datasource
      compareFeaturedMultiSelect(
        featuredResult.BestBetDataSources,
        _featuredResult.BestBetDataSources,
        supportedVerticals.map((supportedVertical: ISupportedVertical) => {
          return supportedVertical.key
        })
      ) &&
      //Country
      compareFeaturedMultiSelect(
        featuredResult.BestBetCountry,
        _featuredResult.BestBetCountry,
        countryList
      ) &&
      //City - not implemented
      /*compareFeaturedMultiSelect(
          featuredResult.BestBetCity,
          _featuredResult.BestBetCity
        ) &&*/
      //Function
      compareFeaturedMultiSelect(
        featuredResult.BestBetFunction,
        _featuredResult.BestBetFunction,
        functionList
      )
    ) {
      const _rank = _featuredResult.BestBetRank
        ? _featuredResult.BestBetRank
        : 0
      if (_rank > highestRank) {
        highestRank = _rank
      }
    }
  }

  return highestRank
}

const compareFeaturedMultiSelect = (
  sourceRules: string[],
  targetRules: string[],
  possibleValues: string[]
): boolean => {
  let sourceAllSet = false
  let targetAllSet = false

  if (
    (sourceRules.length === 0 ||
      (sourceRules.length === 1 &&
        sourceRules[0].toLocaleLowerCase() === 'all')) &&
    (targetRules.length === 0 ||
      (targetRules.length === 1 &&
        targetRules[0].toLocaleLowerCase() === 'all'))
  ) {
    return true //both set to "all"
  }

  //is all flag set?
  sourceRules.forEach((rule: string) => {
    if (rule.toLocaleLowerCase() === 'all') {
      sourceAllSet = true
    }
  })
  targetRules.forEach((rule: string) => {
    if (rule.toLocaleLowerCase() === 'all') {
      targetAllSet = true
    }
  })

  //"all" false for both, search for match
  if (!sourceAllSet && !targetAllSet) {
    for (let sI = 0; sI < sourceRules.length; sI++) {
      let foundInTarget = false
      for (let tI = 0; tI < targetRules.length; tI++) {
        if (
          targetRules[tI].toLocaleLowerCase() ===
          sourceRules[sI].toLocaleLowerCase()
        ) {
          foundInTarget = true
          break
        }
      }

      if (foundInTarget) {
        return true
      }
    }
  }

  //"all" true for both, search for match
  else if (sourceAllSet && targetAllSet) {
    const usedSourceValues: boolean[] = new Array<boolean>(
      possibleValues.length
    )
    const usedTargetValues: boolean[] = new Array<boolean>(
      possibleValues.length
    )

    //mark possible values as used
    for (let sI = 0; sI < sourceRules.length; sI++) {
      for (let uI = 0; uI < usedSourceValues.length; uI++) {
        if (
          possibleValues[uI].toLocaleLowerCase() ===
          sourceRules[sI].toLocaleLowerCase()
        ) {
          usedSourceValues[uI] = true
        }
      }
    }

    for (let tI = 0; tI < targetRules.length; tI++) {
      for (let uI = 0; uI < usedTargetValues.length; uI++) {
        if (
          possibleValues[uI].toLocaleLowerCase() ===
          targetRules[tI].toLocaleLowerCase()
        ) {
          usedTargetValues[uI] = true
        }
      }
    }

    //compare, iterate used values, if both on same index are used => match
    for (let uI = 0; uI < possibleValues.length; uI++) {
      if (!usedSourceValues[uI] && !usedTargetValues[uI]) {
        //same criteria used
        return true
      }
    }
  }

  // only source has "all"
  else if (sourceAllSet && !targetAllSet) {
    const possibleSourceValues: string[] = []
    for (let pI = 0; pI < possibleValues.length; pI++) {
      let found = false
      for (let sI = 0; sI < sourceRules.length; sI++) {
        if (
          possibleValues[pI].toLocaleLowerCase() ===
          sourceRules[sI].toLocaleLowerCase()
        ) {
          found = true
          break
        }
      }

      if (!found) possibleSourceValues.push(possibleValues[pI])
    }

    //compare
    for (let tI = 0; tI < targetRules.length; tI++) {
      for (let pI = 0; pI < possibleSourceValues.length; pI++) {
        if (
          possibleSourceValues[pI].toLocaleLowerCase() ===
          targetRules[tI].toLocaleLowerCase()
        ) {
          return true
        }
      }
    }
  }

  // only target has "all"
  else if (!sourceAllSet && targetAllSet) {
    const possibleSourceValues: string[] = []
    for (let pI = 0; pI < possibleValues.length; pI++) {
      let found = false
      for (let tI = 0; tI < targetRules.length; tI++) {
        if (
          possibleValues[pI].toLocaleLowerCase() ===
          targetRules[tI].toLocaleLowerCase()
        ) {
          found = true
          break
        }
      }

      if (!found) possibleSourceValues.push(possibleValues[pI])
    }

    //compare
    for (let sI = 0; sI < sourceRules.length; sI++) {
      for (let pI = 0; pI < possibleSourceValues.length; pI++) {
        if (
          possibleSourceValues[pI].toLocaleLowerCase() ===
          sourceRules[sI].toLocaleLowerCase()
        ) {
          return true
        }
      }
    }
  }

  return false
}

export const parseDate = (date: string): string | null => {
  if (!date) return null

  const dateParsed = new Date(date)
  if (dateParsed && dateParsed.toString() !== 'Invalid Date') {
    const timeOffsetInMS = dateParsed.getTimezoneOffset() * 60000
    dateParsed.setTime(dateParsed.getTime() - timeOffsetInMS)
    return dateParsed.toISOString()
  }

  return ''
}

/**
 * Parse date in a human readable format
 * @param date
 * @returns
 */
export const parseDateHuman = (date: string): string | null => {
  if (!date) return null

  const dateParsed = new Date(date)
  if (dateParsed && dateParsed.toString() !== 'Invalid Date') {
    const timeOffsetInMS = dateParsed.getTimezoneOffset() * 60000
    dateParsed.setTime(dateParsed.getTime() - timeOffsetInMS)

    return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
      dateParsed
    )
  }
  return ''
}

/**
 * Split csv sting into an array
 * @param input
 * @returns
 */
export const splitCsV = (input: string): string[] => {
  if (!input) return []

  input = input.replaceAll('"', '').replaceAll(',,', ',')
  const clean = input.split(',').map((cell: string) => cell.trim())
  return clean
}

/**
 * Display by "All" negated values with "!="
 * @param values
 * @returns
 */
export const displayNegatedValues = (values: string) => {
  if (!values) return
  const splitedValues = splitCsV(values)

  const isAllSet = !!splitedValues.find(
    (item: string) => item.toLocaleLowerCase() === 'all'
  )

  //remove all from list
  let showList = splitedValues.filter(
    (item: string) => item.toLocaleLowerCase() !== 'all'
  )

  //set prefix & add all on top
  if (isAllSet) {
    for (let index = 0; index < showList.length; index++) {
      showList[index] = ' !=' + showList[index]
    }

    showList = ['All', ...showList]
  }

  return showList.join(',')
}

/**
 * Search not supported countrys in csv string
 * @param country
 * @returns
 */
export const findNotValidCountry = (country: string) => {
  if (!country) return
  const cList = country.split(',')

  for (let index = 0; index < cList.length; index++) {
    const _c = cList[index]
    if (!isCountryValid(_c)) return _c
  }

  return null
}

const isCountryValid = (country: string) => {
  if (country === 'All') return true
  return !!countriesISO.find((item: any) => item.Name === country)
}

/**
 * Search not supported functions in csv string
 * @param country
 * @returns
 */
export const findNotValidFunction = (func: string) => {
  if (!func) return
  const fList = func.split(',')

  for (let index = 0; index < fList.length; index++) {
    const _f = fList[index]
    if (!isFunctionValid(_f)) return _f
  }

  return null
}

const isFunctionValid = (func: string) => {
  if (func === 'All') return true
  return !!supportedFunctions.find((item: any) => item.name === func)
}

/**
 * Search not supported sources in csv string
 * @param country
 * @returns
 */
export const findNotValidSources = (source: string) => {
  if (!source) return
  const sList = source.split(',')

  for (let index = 0; index < sList.length; index++) {
    const _s = sList[index]
    if (!isSourceValid(_s)) return _s
  }

  return null
}

const isSourceValid = (source: string) => {
  if (source === 'All') return true
  return !!supportedVerticals.find(
    (supportedVertical: ISupportedVertical) =>
      supportedVertical.key.toLocaleLowerCase() === source.toLocaleLowerCase()
  )
}

/**
 * Is given scope valide
 * @param country
 * @returns
 */
export const isScopeValid = (scope: string) => {
  return ['OI', 'KPMGFind', 'All'].includes(scope)
}

/**
 * Is given matchtype valide
 * @param country
 * @returns
 */
export const isMatchTypeValid = (matchType: string) => {
  return ['Contains', 'Exact'].includes(matchType)
}

//admin table icons
export const tableIcons = {
  Add: forwardRef<any, any>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<any, any>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<any, any>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<any, any>((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef<any, any>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef<any, any>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<any, any>((props, ref) => (
    <SaveAlt {...props} ref={ref} />
  )),
  Filter: forwardRef<any, any>((props, ref) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef<any, any>((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef<any, any>((props, ref) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef<any, any>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef<any, any>((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef<any, any>((props, ref) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef<any, any>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<any, any>((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef<any, any>((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef<any, any>((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  ))
}
