import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import {
  SingleTranslationResult,
  TranslationResult
} from 'models/TranslationResult'
import createDOMPurify from 'dompurify'
import { IErrorObject } from 'models/FormError'

export interface IEditFormTranslationsProps {
  rowData: TranslationResult
  isNewItem: boolean
  setIsValid: (isValid: boolean) => void
  isOiTranslation?: boolean
}

export default function EditFormTranslations(
  props: IEditFormTranslationsProps
): JSX.Element {
  const { rowData, isNewItem, setIsValid, isOiTranslation } = props
  const DOMPurify = createDOMPurify(window)
  const [keyError, setKeyError] = useState<IErrorObject>({
    error: false,
    helperText: ''
  })
  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())
  const [languageError, setLanguageError] = useState<IErrorObject>({
    error: false,
    helperText: ''
  })

  const validateKey = (): boolean => {
    if (!rowData.key) {
      setKeyError({
        error: true,
        helperText: 'A key must be defined!'
      })

      return false
    }

    setKeyError({
      error: false,
      helperText: ''
    })

    return true
  }

  const validateLanguage = (): boolean => {
    const enTranslation = rowData.translations.find((t) => t.language === 'en')
    if (!enTranslation || !enTranslation.value) {
      setLanguageError({
        error: true,
        helperText:
          'At least the english translation must be defined! If not set all other languages will use this value.'
      })

      return false
    }

    setLanguageError({
      error: false,
      helperText: ''
    })

    return true
  }

  const checkIsValidAd = (): boolean => {
    const keyValid = validateKey()
    const languageValid = validateLanguage()

    if (!keyValid) return false
    if (!languageValid) return false

    return true
  }

  const validateItem = (): void => {
    setIsValid(checkIsValidAd())
  }

  useEffect(() => {
    validateItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!isOiTranslation && (
        <TextField
          variant="standard"
          required
          id="key-required"
          label="Key"
          value={rowData.key}
          disabled={!isNewItem}
          error={keyError.error}
          helperText={keyError.helperText}
          onChange={(event) => {
            rowData.key = event.target.value
              ? event.target.value.replace(/[^a-zA-Z0-9_]/g, '')
              : ''
            validateItem()
          }}
        />
      )}
      {isOiTranslation && (
        <div style={{ display: 'flex', width: '100%' }}>
          <TextField
            variant="standard"
            required
            id="key-required"
            label="Key"
            value={rowData.key}
            disabled={!isNewItem}
            error={keyError.error}
            helperText={keyError.helperText}
            onChange={(event) => {
              rowData.key = event.target.value
                ? event.target.value.replace(/[^a-zA-Z0-9_]/g, '')
                : ''
              validateItem()
            }}
            style={{ width: '100%' }}
          />
          <Autocomplete
            style={{ marginLeft: '10px', marginTop: '10px', minWidth: '140px' }}
            multiple={false}
            id="size-small-outlined-multi"
            size="small"
            options={['All', 'OI', 'Teams']}
            onChange={(event, value, reason) => {
              if (value === 'All') {
                delete rowData.scope
              } else {
                rowData.scope = value
              }
            }}
            disableClearable={true}
            defaultValue={rowData.scope ? rowData.scope : 'All'}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Scope'}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
          {/* <FormControlLabel
            control={
              <Select
                id="scope"
                multiple={false}
                variant={'standard'}
                defaultValue="All"
                onChange={(event: SelectChangeEvent<string>) => {
                  if (event.target.value === 'All') {
                    delete rowData.scope
                  } else {
                    rowData.scope = event.target.value
                  }
                }}
              >
                {['All', 'OI', 'Teams'].map((value: string) => {
                  return (
                    <MenuItem key={value} value={value}>
                      <ListItemText primary={value} />
                    </MenuItem>
                  )
                })}
              </Select>
            }
            label="Scope"
            labelPlacement="start"
            style={{ whiteSpace: 'nowrap' }}
          /> */}
        </div>
      )}
      {supportedLanguages.map((lang: SupportedLanguage, index: number) => {
        let translationItem = rowData.translations.find(
          (translation) => translation.language === lang.locale
        )

        if (!translationItem) {
          const newSingleTranslationResult: SingleTranslationResult = {
            id: '-1',
            language: lang.locale,
            value: ''
          }
          rowData.translations.push(newSingleTranslationResult)
          translationItem = newSingleTranslationResult
        }

        return (
          <TextField
            variant="standard"
            id={`${lang.locale}-required`}
            label={lang.name}
            defaultValue={translationItem?.value}
            InputLabelProps={{
              shrink: true
            }}
            {...(lang.locale === 'en'
              ? {
                  onChange: (event) => {
                    if (translationItem)
                      translationItem.value = event.target.value
                        ? DOMPurify.sanitize(event.target.value, {
                            USE_PROFILES: { html: true },
                            ADD_ATTR: ['target']
                          })
                        : event.target.value
                    validateItem()
                  },
                  required: true,
                  error: languageError.error,
                  helperText: languageError.helperText
                }
              : {
                  onChange: (event) => {
                    if (translationItem)
                      translationItem.value = event.target.value
                        ? DOMPurify.sanitize(event.target.value, {
                            USE_PROFILES: { html: true },
                            ADD_ATTR: ['target']
                          })
                        : event.target.value
                  }
                })}
            key={`text_${index}_${currentFormState}`}
            onBlur={() => {
              setCurrentFormState(new Date().getTime())
            }}
          />
        )
      })}
    </>
  )
}
