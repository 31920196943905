import React from 'react'
import { MTableToolbar } from '@material-table/core'
import { Button, Tooltip } from '@mui/material'
import { getStylesRequests } from 'styles/requests/requests'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import AddIcon from '@mui/icons-material/Add'

export interface IAllRequestTableToolbarProps {
  toolbarprops: any
  setShowAlerts: (showAlerts: boolean) => void
  setShowCreateRequestDialog: (createRequest: boolean) => void
}

export default function AllRequestTableToolbar(
  props: IAllRequestTableToolbarProps
): JSX.Element {
  const { toolbarprops, setShowAlerts, setShowCreateRequestDialog } = props

  const classes = getStylesRequests()

  return (
    <div className={classes.toolbarContainer}>
      <MTableToolbar {...toolbarprops} />
      <div className={classes.buttonContainer}>
        <span className={classes.buttonWrapper}>
          <Tooltip title={'Create a request on behalf of a user'}>
            <Button
              id={'btn-create-on-behalf-02'}
              onClick={(event) => {
                setShowCreateRequestDialog(true)
              }}
              className={classes.buttonAdd}
            >
              <div
                className={classes.buttonText}
                style={{ marginRight: '10px' }}
              >
                {'Create a Request'}
              </div>
              <AddIcon />
            </Button>
          </Tooltip>
        </span>
        <span className={classes.buttonWrapper}>
          <Tooltip title={'Send expiration alerts'}>
            <Button
              id={'btn-alerts-01'}
              onClick={(event) => {
                setShowAlerts(true)
              }}
              className={classes.buttonAdd}
            >
              <div
                className={classes.buttonText}
                style={{ marginRight: '10px' }}
              >
                {'Expiration Alerts'}
              </div>
              <CircleNotificationsIcon />
            </Button>
          </Tooltip>
        </span>
      </div>
    </div>
  )
}
