import { Column } from '@material-table/core'
import { dateFormatOptions } from 'constants/constants'
import dayjs from 'dayjs'
import { AdsResult } from 'models/AdsResult'
import { DidYouMeanItem } from 'models/DidYouMean'
import { FeaturedResult } from 'models/FeaturedResult'
import {
  AdsResultHistory,
  DidYouMeanItemHistory,
  FeaturedResultHistory,
  RequestHistory,
  adWordHistoryKeys,
  didYouMeanItemHistoryKeys,
  featuredHistoryKeys
} from 'models/SelfServiceHistory'
import {
  IExportItem,
  Duplicate,
  ISelfServiceRequest,
  RequestComment,
  RequestStatus,
  RequestType
} from 'models/SelfServiceRequest'
import { IUserSetting } from 'models/UserSettings'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'

export const updateBaseProperties = (
  referenceRowData: ISelfServiceRequest,
  setModifiedDate = false
) => {
  switch (referenceRowData.requestType) {
    case RequestType.AdWord:
      const baseAdWord = (referenceRowData.itemData as AdsResult[])[0]
      referenceRowData.title = baseAdWord.title
      referenceRowData.description = baseAdWord.text
      referenceRowData.endDate = baseAdWord.end || undefined
      break
    case RequestType.FeaturedResult:
      const featuredResult = referenceRowData.itemData as FeaturedResult
      referenceRowData.title = featuredResult.BestBetTitle
      referenceRowData.description = featuredResult.BestBetDescription
      referenceRowData.endDate = featuredResult.BestBetEndDate || undefined

      if (featuredResult && featuredResult.BestBetCountry.length === 0)
        featuredResult.BestBetCountry = ['All']
      if (featuredResult && featuredResult.BestBetCity.length === 0)
        featuredResult.BestBetCity = ['All']
      if (featuredResult && featuredResult.BestBetFunction.length === 0)
        featuredResult.BestBetFunction = ['All']
      if (featuredResult && featuredResult.BestBetDataSources.length === 0)
        featuredResult.BestBetDataSources = ['All']
      if (setModifiedDate || !featuredResult.BestBetLastModified) {
        featuredResult.BestBetLastModified = new Date().toISOString()
      }
      break
    case RequestType.SpellingSuggestion:
      const _spellingSuggestion = referenceRowData.itemData as DidYouMeanItem
      referenceRowData.title = _spellingSuggestion.term
      referenceRowData.description = _spellingSuggestion.description
      break
  }
}

export const updateComments = (
  referenceRowData: ISelfServiceRequest,
  newComment: RequestComment
) => {
  if (!newComment.text) {
    return
  }

  if (!referenceRowData.comments) {
    referenceRowData.comments = [newComment]
  } else {
    referenceRowData.comments = referenceRowData.comments.filter(
      (c: RequestComment) => c.id !== newComment.id
    )
    referenceRowData.comments.unshift(newComment)
  }
}

/**
 * Validates requests for changes and if the request will expire soon
 * @param request The request item
 * @param hasUserSettingsBeenFetched Flag indicating if the usersettings are already fetched
 * @param userSettings The user settings
 */
export const validateRequestForChangesOrExpired = (
  request: ISelfServiceRequest,
  hasUserSettingsBeenFetched: boolean,
  userSettings: IUserSetting
): void => {
  // Check for updates
  if (hasUserSettingsBeenFetched && userSettings.OrgLastVisit) {
    try {
      if (request.modifiedBy !== ESSettingsGlobalVariables.getDisplayName()) {
        const lastVisisted = new Date(
          userSettings.OrgLastVisit ? userSettings.OrgLastVisit : ''
        ).getTime()

        const modifiedAt = new Date(request.modifiedDate).getTime()

        if (lastVisisted < modifiedAt) {
          request.hasChanged = true
        }
      }

      if (request && request.comments && request.comments.length > 0) {
        const latestComment = request.comments[0]

        if (latestComment.modifiedBy !== ESSettingsGlobalVariables.getUPN()) {
          const lastVisisted = new Date(
            userSettings.OrgLastVisit ? userSettings.OrgLastVisit : ''
          ).getTime()

          const modifiedAt = new Date(latestComment.modifiedDate).getTime()

          if (lastVisisted < modifiedAt) {
            request.hasCommentChanges = true
          }
        }
      }
    } catch {}
  }

  // Check for expired requests
  if (request.endDate && request.oncePublished) {
    try {
      const endDate = new Date(request.endDate ? request.endDate : '')
      const differenceInDays = dayjs().diff(new Date(endDate), 'days', true)

      if (differenceInDays > -30 && differenceInDays < 0) {
        request.expiresSoon = true
      }
    } catch {}
  }
}

export const getDayDifference = (endDate: string | undefined): string => {
  const differenceInDays = Math.abs(
    dayjs().diff(new Date(new Date(endDate ? endDate : '')), 'days', false)
  )

  return differenceInDays === 0 ? '1' : differenceInDays.toString()
}

const calculateStringDistance = (s1: string, s2: string): number => {
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  const costs: number[] = []

  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) costs[j] = j
      else {
        if (j > 0) {
          let newValue = costs[j - 1]
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
    }

    if (i > 0) costs[s2.length] = lastValue
  }

  return costs[s2.length]
}

const stringEqual = (s1: string, s2: string): number => {
  let longer = s1
  let shorter = s2

  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }

  const longerLength = longer.length

  if (longerLength === 0) {
    return 1.0
  }

  return (
    (longerLength - calculateStringDistance(longer, shorter)) /
    parseFloat(longerLength.toString())
  )
}

export const findDuplicates = (requests: ISelfServiceRequest[]): void => {
  const featuredResultRequests = requests.filter(
    (elem: ISelfServiceRequest) =>
      elem.requestType === RequestType.FeaturedResult &&
      elem.status !== RequestStatus.Declined
  )
  const adWordResultRequests = requests.filter(
    (elem: ISelfServiceRequest) =>
      elem.requestType === RequestType.AdWord &&
      elem.status !== RequestStatus.Declined
  )

  const addedDuplicates: any[] = []

  const sortedRequests = [...requests].sort(
    (a: ISelfServiceRequest, b: ISelfServiceRequest) => {
      if (
        a.status === RequestStatus.Published &&
        b.status === RequestStatus.Published
      ) {
        return 0
      } else if (a.status === RequestStatus.Published) {
        return 1
      }

      return -1
    }
  )

  sortedRequests.forEach((sortedRequestToCheck: ISelfServiceRequest) => {
    const requestToCheck = requests.find(
      (r: ISelfServiceRequest) =>
        sortedRequestToCheck.id === r.id && r.upn === sortedRequestToCheck.upn
    )

    if (requestToCheck && requestToCheck.status !== RequestStatus.Declined) {
      let duplicates: ISelfServiceRequest[] = []

      switch (requestToCheck.requestType) {
        case RequestType.FeaturedResult:
          duplicates = featuredResultRequests.filter(
            (elem: ISelfServiceRequest) => {
              // Filter out current element
              if (
                elem.id === requestToCheck.id &&
                elem.upn === requestToCheck.upn
              )
                return false

              // filter out published items
              if (
                (requestToCheck.status === RequestStatus.Published ||
                  elem.status === RequestStatus.Published) &&
                requestToCheck.status === elem.status
              ) {
                return false
              }

              // filter out all items with different titles or links
              const result = stringEqual(elem.title, requestToCheck.title)
              if (result > 0.75) return true

              const currentUrl = (
                requestToCheck.itemData as FeaturedResult
              ).BestBetUrl.toLowerCase()
              const toCheckUrl = (
                elem.itemData as FeaturedResult
              ).BestBetUrl.toLowerCase()

              if (
                currentUrl.includes(toCheckUrl) ||
                toCheckUrl.includes(currentUrl)
              )
                return true

              return false
            }
          )
          break
        case RequestType.AdWord:
          duplicates = adWordResultRequests.filter(
            (elem: ISelfServiceRequest) => {
              // Filter out current element
              if (
                elem.id === requestToCheck.id &&
                elem.upn === requestToCheck.upn
              )
                return false

              // filter out published items
              if (
                (requestToCheck.status === RequestStatus.Published ||
                  elem.status === RequestStatus.Published) &&
                requestToCheck.status === elem.status
              ) {
                return false
              }

              // filter out all items with different titles or links
              const result = stringEqual(elem.title, requestToCheck.title)
              if (result > 0.75) return true

              const currentUrl = (
                requestToCheck.itemData as AdsResult[]
              )[0].link.toLowerCase()
              const toCheckUrl = (
                elem.itemData as AdsResult[]
              )[0].link.toLowerCase()

              if (
                (currentUrl.includes(toCheckUrl) && toCheckUrl) ||
                (toCheckUrl.includes(currentUrl) && currentUrl)
              )
                return true

              return false
            }
          )
          break
      }

      if (duplicates && duplicates.length > 0) {
        let color = `#${Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, '0')
          .toUpperCase()}`

        const foundDuplicates = duplicates.map((elem: ISelfServiceRequest) => {
          const duplicate: Duplicate = {
            id: elem.id,
            createdBy: elem.createdBy
          }

          return duplicate
        })

        const alreadyAddedElem = addedDuplicates.find((aD: any) => {
          return duplicates.some(
            (d) => d.id === aD.id && d.createdBy === aD.createdBy
          )
        })

        if (alreadyAddedElem) {
          color = alreadyAddedElem.color
        }
        if (requestToCheck.duplicates) {
          color = requestToCheck.duplicates.color
        }

        addedDuplicates.push({
          id: requestToCheck.id,
          color: color,
          createdBy: requestToCheck.createdBy
        })

        // add also the duplicates
        foundDuplicates.forEach((el: Duplicate) => {
          if (
            !addedDuplicates.some(
              (d) => d.id === el.id && d.createdBy === el.createdBy
            )
          ) {
            addedDuplicates.push({
              id: el.id,
              color: color,
              createdBy: el.createdBy
            })
          }
        })

        requestToCheck.duplicates = {
          color: color,
          elements: foundDuplicates
        }
      } else {
        delete requestToCheck.duplicates
      }
    }
  })
}

export const createUPNHash = (upn: string): string => {
  if (!upn) return ''

  let btoaString = btoa(upn.toLowerCase())
  btoaString = btoaString.replace(/=/g, 'EQ')

  return btoaString
}

export const exportColumns: Column<IExportItem>[] = [
  {
    title: 'ID',
    field: 'id'
  },
  {
    title: 'Request Type',
    field: 'type'
  },
  {
    title: 'Request Status',
    field: 'status'
  },
  {
    title: 'Modfied Date',
    field: 'modifiedDate'
  },
  {
    title: 'Created Date',
    field: 'createdDate'
  },
  {
    title: 'Created By',
    field: 'createdBy'
  },
  {
    title: 'Modified By',
    field: 'modifiedBy'
  },
  {
    title: 'Headline',
    field: 'title'
  },
  {
    title: 'Body Text',
    field: 'desc'
  },
  {
    title: 'Link',
    field: 'link'
  },
  {
    title: 'Link Text',
    field: 'link_text'
  },
  {
    title: 'Start Date',
    field: 'start'
  },
  {
    title: 'Expiration Date',
    field: 'end'
  },
  {
    title: 'Keywords',
    field: 'keywords'
  },
  {
    title: 'Match Type',
    field: 'matchType'
  },
  {
    title: 'Flavour',
    field: 'flavour'
  },
  {
    title: 'Application Scope',
    field: 'scope'
  },
  {
    title: 'Search Verticals',
    field: 'verticals'
  },
  {
    title: 'Countries',
    field: 'countries'
  },
  {
    title: 'Functions',
    field: 'functions'
  }
]

export const parseRequestsToExport = (
  requests: ISelfServiceRequest[]
): IExportItem[] => {
  const itemsToExport = requests.map((request: ISelfServiceRequest) => {
    const exportItem: IExportItem = {
      id: request.id,
      type: request.requestType,
      status: request.status,
      modifiedDate: request.modifiedDate,
      createdDate: request.createdDate,
      createdBy: request.createdBy,
      modifiedBy: request.modifiedBy,
      title: request.title.replaceAll('\n', ''),
      desc: request.description,
      link: '',
      start: '',
      end: '',
      keywords: '',
      scope: '',
      verticals: '',
      countries: '',
      functions: '',
      matchType: ''
    }

    switch (request.requestType) {
      case RequestType.FeaturedResult:
        const featuredResult = request.itemData as FeaturedResult

        exportItem.link = featuredResult.BestBetUrl
        exportItem.start = featuredResult.BestBetStartDate
          ? featuredResult.BestBetStartDate
          : ''
        exportItem.end = featuredResult.BestBetEndDate
          ? featuredResult.BestBetEndDate
          : ''
        exportItem.keywords = featuredResult.BestBetKeywords.join(',')
        exportItem.scope = featuredResult.BestBetScope
          ? featuredResult.BestBetScope
          : 'All'
        exportItem.verticals = featuredResult.BestBetDataSources.join(',')
        exportItem.countries = featuredResult.BestBetCountry.join(',')
        exportItem.functions = featuredResult.BestBetFunction.join(',')
        exportItem.matchType = featuredResult.BestBetMatchType

        break
      case RequestType.AdWord:
        const adWord = (request.itemData as AdsResult[])[0]

        exportItem.link = adWord.link
        exportItem.link_text = adWord.link_text
        exportItem.start = adWord.start ? adWord.start : ''
        exportItem.end = adWord.end ? adWord.end : ''
        exportItem.keywords = adWord.search_terms.join(',')
        exportItem.scope = adWord.scope ? adWord.scope : 'All'
        exportItem.verticals = adWord.sources.join(',')
        exportItem.countries = adWord.countries.join(',')
        exportItem.functions = adWord.functions.join(',')
        exportItem.matchType = adWord.match_type
        exportItem.flavour = adWord.flavour

        break
    }

    return exportItem
  })

  return itemsToExport
}

/**
 * Creates or append the history item, with the last item values
 * @param referenceItem The current changes to be saved
 * @param imageChanged Flag that indicates that the image have changed
 * @param newItem Flag indicating it is a new item
 * @param orgItemData The old reference item data
 */
export const prepareHistoryInformation = (
  referenceItem: ISelfServiceRequest,
  imageChanged: boolean,
  newItem: boolean,
  orgItemData?: AdsResult[] | FeaturedResult | DidYouMeanItem
): void => {
  const isNewItem =
    referenceItem.status === RequestStatus.Draft ? true : newItem

  // Generate the history item and use the old history
  // item in case the last action was a submit
  let historyItem: RequestHistory = {
    isNewItem: isNewItem
  }

  let appendNewHistory = false

  if (
    referenceItem.status === RequestStatus.Submitted &&
    referenceItem.historyData
  ) {
    historyItem = referenceItem.historyData
    historyItem.isNewItem = isNewItem
    appendNewHistory = true
  }

  if (referenceItem.requestType === RequestType.AdWord) {
    historyItem.data = historyItem.data ? historyItem.data : []
    historyItem.imageChanged = historyItem.imageChanged || imageChanged
    const changedItems: AdsResultHistory[] = []

    const referenceAds = referenceItem.itemData as AdsResult[]

    referenceAds.forEach((ad: AdsResult) => {
      let languageChange = false

      let adHistory: AdsResultHistory = {
        language: ad.language
      }

      if (appendNewHistory && historyItem.data) {
        const existingHistoryEntry = (historyItem.data as AdsResult[]).find(
          (a: AdsResult) => a.language === ad.language
        )

        if (existingHistoryEntry) {
          adHistory = existingHistoryEntry
          languageChange = true
        }
      }

      const orgAdWord = (orgItemData as AdsResult[]).find((_ad) => {
        return _ad.language === ad.language
      })

      if (!isNewItem && orgAdWord) {
        adWordHistoryKeys.forEach((key: string) => {
          // Only save title, text and link_text for the languages
          // Any thing else can only be changed in language en
          if (
            ad.language === 'en' ||
            key === 'title' ||
            key === 'text' ||
            key === 'link_text'
          ) {
            const referenceAd = ad as any
            const orgAd = orgAdWord as any
            const historyAd = adHistory as any

            // Check for changes and add the old value
            if (referenceAd[key] !== orgAd[key]) {
              historyAd[key] = orgAd[key]
              languageChange = true
            }
          }
        })
      }

      if (languageChange) {
        changedItems.push(adHistory)
      }
    })

    historyItem.data = changedItems.length > 0 ? changedItems : undefined
  } else if (referenceItem.requestType === RequestType.FeaturedResult) {
    let hasFeatureChange = false
    const featuredHistory: FeaturedResultHistory = historyItem.data
      ? (historyItem.data as FeaturedResultHistory)
      : {}

    if (historyItem.data) {
      hasFeatureChange = true
    }

    const referenceFeaturedResult = referenceItem.itemData as any
    const orgFeaturedResult = orgItemData as any
    const featureHistory = featuredHistory as any

    if (!isNewItem && referenceFeaturedResult) {
      featuredHistoryKeys.forEach((key: string) => {
        if (referenceFeaturedResult[key] !== orgFeaturedResult[key]) {
          featureHistory[key] = orgFeaturedResult[key]
          hasFeatureChange = true
        }
      })
    }

    historyItem.data = hasFeatureChange ? featuredHistory : undefined
  } else if (referenceItem.requestType === RequestType.SpellingSuggestion) {
    let hasFeatureChange = false
    const didYouMeanItemHistory: DidYouMeanItemHistory = historyItem.data
      ? (historyItem.data as DidYouMeanItemHistory)
      : {}

    if (historyItem.data) {
      hasFeatureChange = true
    }

    const referenceFeaturedResult = referenceItem.itemData as any
    const orgFeaturedResult = orgItemData as any
    const _didYouMeanItemHistory = didYouMeanItemHistory as any

    if (!isNewItem && referenceFeaturedResult) {
      didYouMeanItemHistoryKeys.forEach((key: string) => {
        if (referenceFeaturedResult[key] !== orgFeaturedResult[key]) {
          _didYouMeanItemHistory[key] = orgFeaturedResult[key]
          hasFeatureChange = true
        }
      })
    }

    historyItem.data = hasFeatureChange ? _didYouMeanItemHistory : undefined
  }

  referenceItem.historyData = historyItem
}

export const cleanUpRequestItem = (requestItem: ISelfServiceRequest) => {
  if (requestItem) {
    if (requestItem._etag) {
      delete requestItem._etag
    }

    delete requestItem.hasChanged
    delete requestItem.hasCommentChanges
    delete requestItem.expiresSoon
    delete requestItem.duplicates
    delete requestItem.imageFileName
    delete requestItem.imagePreview

    if ((requestItem as any).tableData) {
      delete (requestItem as any).tableData
    }
  }
}

const generateDateValue = (dateString: null | string | undefined): string => {
  let formatedDate = ''
  if (dateString) {
    let parsedDate = new Date(dateString)

    formatedDate = new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
      parsedDate
    )
  }

  return formatedDate
}

export const generateShareMail = (
  rowData: ISelfServiceRequest
): { body: string; subject: string } => {
  const mailInfo = {
    body: '{requestType} details below:\n\n',
    subject: ''
  }
  // Bug 2134487 - Cut off the description in case it is to long, so mail can be generated (char limit is arround 2000 for the whole body)
  if (rowData.description.length + rowData.title.length > 1000) {
    mailInfo.body += `Title: ${rowData.title}\n`
    mailInfo.body += `Description: ${rowData.description.substring(
      0,
      700
    )}...\n`
  } else {
    mailInfo.body += `Title: ${rowData.title}\n`
    mailInfo.body += `Description: ${rowData.description}\n`
  }

  mailInfo.body += `ID: ${rowData.id}\n`
  mailInfo.body += `Type: ${rowData.requestType}\n`
  mailInfo.body += `Status: ${rowData.status}\n`

  mailInfo.subject = 'Share Enterprise Search - Self Service Request'

  switch (rowData.requestType) {
    case RequestType.AdWord:
      mailInfo.body = mailInfo.body.replace('{requestType}', 'AdWord')
      if (
        rowData.itemData &&
        Array.isArray(rowData.itemData) &&
        rowData.itemData.length > 0
      ) {
        const adWord = rowData.itemData[0]

        mailInfo.body += `Start: ${generateDateValue(adWord.start)}\n`
        mailInfo.body += `End: ${generateDateValue(adWord.end)}\n`
        if (adWord.link) {
          mailInfo.body += `Link: ${adWord.link}\n`
        }
        mailInfo.body += `Keywords: ${
          adWord.search_terms ? adWord.search_terms.join(', ') : ''
        }\n`
      }

      break
    case RequestType.FeaturedResult:
      mailInfo.body = mailInfo.body.replace('{requestType}', 'Featured Result')
      if (rowData.itemData) {
        const featureResult = rowData.itemData as FeaturedResult

        mailInfo.body += `Start: ${generateDateValue(
          featureResult.BestBetStartDate
        )}\n`
        mailInfo.body += `End: ${generateDateValue(
          featureResult.BestBetEndDate
        )}\n`
        mailInfo.body += `Link: ${featureResult.BestBetUrl}\n`
        mailInfo.body += `Keywords: ${
          featureResult.BestBetKeywords
            ? featureResult.BestBetKeywords.join(', ')
            : ''
        }\n`
      }
      break
    case RequestType.SpellingSuggestion:
      mailInfo.body = mailInfo.body.replace(
        '{requestType}',
        'Spelling Suggestion'
      )
      break
  }

  mailInfo.body = encodeURIComponent(mailInfo.body.replaceAll('\\n', '\n'))

  return mailInfo
}
