import React, { useState, useEffect, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import ConfirmationModal from 'components/contents/common/Dialog'
import {
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarCloseReason
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'
import { findItemChild } from 'utils/admin/adminFormUtils'
import EditFormBase from '../common/EditFormBase'
import {
  getHighestId,
  getHighestOrder,
  groupItemsById
} from 'utils/admin/adminSettingsUtils'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import { updateContactUsTileDefaultValues } from 'utils/admin/adminContentQuality'
import { ContactUsTile, initialContactUsTile } from 'models/ContactUsTile'
import ContactUsStore from 'store/ContactUs'
import ContactUsTilesTable from './ContactUsTilesTable'
import { TableStateContactUsTiles } from 'models/TableStates'
import { AdminItemChange } from 'models/AdminItemChange'
import {
  cleanDraftStatusAfterPublish,
  cleanDraftStatusAfterPublishAll,
  deleteItemsAndUpdateState,
  updateItemsAndUpdateSate
} from 'utils/admin/adminTableState'

export interface ContactUsTilesProps {
  setHasChanges: (changes: boolean) => void
}

type AllContactUsTilesProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ContactUsTilesProps

function ContactUsTiles(props: AllContactUsTilesProps): JSX.Element {
  const {
    setHasChanges,
    fetchAllContactUsTiles,
    upsertContactUsTile,
    deleteContactUsTile,
    allContactUsTiles,
    hasContactUsTilesBeenFetched,
    hasContactUsTileChangesError
  } = props
  const classes = getStylesAdminSettings()

  const [isLoading, setIsLoading] = useState(true)
  const [isModalVisible, toggleModalVisibility] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [state, setState] = useState<TableStateContactUsTiles>({
    data: [],
    tableView: []
  })
  const [changes, setChanges] = useState<AdminItemChange[]>([])
  const [pageHasActiveChanges, setPageHasActiveChanges] = useState(false)
  const [currentRow, setCurrentRow] = useState<ContactUsTile | null>(null)
  const [formOpen, setFormOpen] = useState(false)
  const [createNewItem, setCreateNewItem] = useState(false)
  const [contactUsTilesChangesApplied, setContactUsTilesChangesApplied] =
    useState(false)

  const loadData = () => {
    fetchAllContactUsTiles()
  }

  useEffect(() => {
    if (hasContactUsTilesBeenFetched) {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasContactUsTilesBeenFetched])

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    if (pageHasActiveChanges) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHasActiveChanges])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNonInitialEffect(() => {
    if (hasContactUsTilesBeenFetched && allContactUsTiles) {
      let data = []
      let tableView = []

      updateContactUsTileDefaultValues(allContactUsTiles)
      data = allContactUsTiles
      tableView = groupItemsById(allContactUsTiles) as ContactUsTile[]

      setState({
        data: data,
        tableView: tableView
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasContactUsTilesBeenFetched])

  useNonInitialEffect(() => {
    if (contactUsTilesChangesApplied) {
      if (hasContactUsTileChangesError) {
        setSnackbarMessage('There was an error publishing the changes.')
        toggleSnackbarVisibility(true)
      } else {
        setSnackbarMessage('The changes have been published.')
        toggleSnackbarVisibility(true)
      }
    }
  }, [contactUsTilesChangesApplied, hasContactUsTileChangesError])

  const handleClickOpen = () => {
    toggleModalVisibility(true)
  }

  const handleClose = () => {
    toggleModalVisibility(false)
  }

  const handleFormClose = () => {
    setCurrentRow(null)
    setCreateNewItem(false)
    setFormOpen(false)
  }

  const handleConfirm = () => {
    const promiseArray: Array<Promise<void>> = []

    setContactUsTilesChangesApplied(false)

    // Iterate through changes, applying them
    changes.forEach((change: AdminItemChange) => {
      switch (change.changeType) {
        case 'add':
        case 'update':
          promiseArray.push(
            upsertContactUsTile(change.changes as ContactUsTile)
          )
          break
        case 'delete':
          const currentChange = change.changes as ContactUsTile
          promiseArray.push(
            deleteContactUsTile(currentChange.id, currentChange.language)
          )
          break
      }

      toggleModalVisibility(false)
    })

    if (promiseArray && promiseArray.length > 0) {
      Promise.all(promiseArray).then(() => {
        setState((prevState: any) => {
          const data = [...prevState.data]
          let tableView = [...prevState.tableView]

          cleanDraftStatusAfterPublishAll(changes, data)

          tableView = groupItemsById(data)

          return { ...prevState, data, tableView }
        })

        setContactUsTilesChangesApplied(true)

        setChanges([])
        setPageHasActiveChanges(false)
      })
    } else {
      toggleModalVisibility(false)
    }
  }

  const handleSnackbarClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    toggleSnackbarVisibility(false)
  }

  const handleFormOpen = (row: ContactUsTile) => {
    setCurrentRow(row)
    setFormOpen(true)
  }

  const getCurrentRowData = (isNewItem: boolean): ContactUsTile[] | null => {
    if (!currentRow) return null

    if (isNewItem) {
      return [currentRow as ContactUsTile]
    } else {
      return [
        ...[currentRow as ContactUsTile],
        ...findItemChild(
          currentRow as ContactUsTile,
          state.data as ContactUsTile[]
        )
      ]
    }
  }

  const addRowUpdateForContactUsTiles = (newDataList: ContactUsTile[]) => {
    const newChanges = changes

    setState((prevState: any) => {
      const data = [...prevState.data]
      let tableView = [...prevState.tableView]

      updateItemsAndUpdateSate(newDataList, data, newChanges)

      tableView = groupItemsById(data)

      return { ...prevState, data, tableView }
    })

    //set changes
    setPageHasActiveChanges(true)
    setChanges(newChanges)

    //cleanup modal
    setCurrentRow(null)
    setFormOpen(false)
    setCreateNewItem(false)
  }

  const publishContactUsTile = (cutlist: ContactUsTile[]) => {
    setContactUsTilesChangesApplied(false)
    const promiseArray: Array<Promise<void>> = []

    cutlist.forEach((item: ContactUsTile) => {
      promiseArray.push(upsertContactUsTile(item))
    })

    Promise.all(promiseArray).then(() => {
      // clear active change
      const newChanges = changes.filter(
        (itemChanges: AdminItemChange) =>
          !cutlist.find(
            (itemNewAd: ContactUsTile) => itemChanges.id === itemNewAd.id
          )
      )

      setState((prevState: any) => {
        const data = [...prevState.data]
        let tableView = [...prevState.tableView]

        cleanDraftStatusAfterPublish(cutlist, data)

        tableView = groupItemsById(data)

        return { ...prevState, data, tableView }
      })

      if (newChanges.length === 0) {
        setPageHasActiveChanges(false)
      }

      setContactUsTilesChangesApplied(true)
      setChanges(newChanges)
    })
  }

  const handleRowAdd = () => {
    let currentHighestId = -1
    let currentHighestOrder = -1

    currentHighestId = getHighestId(state.data)
    currentHighestOrder = getHighestOrder(state.data as ContactUsTile[])

    let newId = '1'

    if (currentHighestId !== -1) {
      newId = (currentHighestId + 1).toString()
    }
    let newOrder = -1
    if (currentHighestOrder !== -1) {
      newOrder = currentHighestOrder + 1
    }
    setCreateNewItem(true)

    const newContactUsTile = Object.assign({}, initialContactUsTile)
    handleFormOpen({
      ...newContactUsTile,
      id: newId,
      order: newOrder
    } as ContactUsTile)
  }

  const handleRowDelete = (deleteItem: ContactUsTile) =>
    new Promise((resolve) => {
      const newChanges = [...changes]

      setState((prevState: any) => {
        const data = [...prevState.data]
        let tableView = [...prevState.tableView]

        let deleteItemList = [deleteItem]

        deleteItemList = [
          ...findItemChild(deleteItem as ContactUsTile, data),
          ...deleteItemList
        ]

        deleteItemsAndUpdateState(deleteItemList, data, newChanges)

        tableView = groupItemsById(data)

        return { ...prevState, data, tableView: tableView }
      })

      setPageHasActiveChanges(newChanges && newChanges.length > 0)
      setChanges(newChanges)

      resolve(true)
    })

  return (
    <>
      <div className={`${classes.container} ${classes.scrollfix}`}>
        {!isLoading ? (
          <ContactUsTilesTable
            state={state}
            handleRowDelete={handleRowDelete}
            handleRowUpdate={addRowUpdateForContactUsTiles}
            handleRowAdd={handleRowAdd}
            handleFormOpen={handleFormOpen}
          />
        ) : (
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress className={classes.loadingSpinner} size={50} />
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          className={classes.button}
          disabled={!pageHasActiveChanges}
          style={{ width: '100%' }}
        >
          Go Live!
        </Button>
        <ConfirmationModal
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          isModalOpen={isModalVisible}
          title={'Are you sure to publish all changes?'}
          message={
            'Changes will be immediately deployed and will be visible to all KPMG users'
          }
          hideCancelButton={false}
          confirmBtnText={'Yes'}
          cancelBtnText={'No'}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={isSnackbarVisible}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                handleSnackbarClose()
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
      {formOpen && currentRow && (
        <EditFormBase
          rowData={getCurrentRowData(createNewItem)}
          isNewItem={createNewItem}
          isOpen={formOpen}
          handleClose={handleFormClose}
          handleRowUpdate={addRowUpdateForContactUsTiles}
          publishItem={publishContactUsTile}
          adminSettingType={adminSettingTypes.contactustiles}
          allContactUsTiles={allContactUsTiles}
          changes={changes}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    allContactUsTiles: ContactUsStore.selectors.getContactUsAllTiles(state),
    hasContactUsTilesBeenFetched:
      ContactUsStore.selectors.hasAllBeenFetched(state),
    hasContactUsTileChangesError:
      ContactUsStore.selectors.hasChangesError(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAllContactUsTiles: () =>
      dispatch(ContactUsStore.actions.fetchContactUsTiles('all')),
    upsertContactUsTile: (contactUsTile: ContactUsTile) =>
      dispatch(ContactUsStore.actions.upsertContactUsTile(contactUsTile)),
    deleteContactUsTile: (
      contactUsTileId: string,
      contactUsTileLanguage: string
    ) =>
      dispatch(
        ContactUsStore.actions.deleteContactUsTile(
          contactUsTileId,
          contactUsTileLanguage
        )
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsTiles)
