import { Reducer } from 'redux'
import {
  AuthActions,
  AuthActionTypes,
  ISetAuthToken,
  ISetAADInfo,
  IFetchAdminPagesAuthorizationSuccess
} from './actions'
import { IAdminPagesAuthorization } from 'utils/authorization'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'

export interface IAADState {
  initializing: boolean
  initialized: boolean
  accessToken: string
  accounts: AccountInfo[] | null
  instance: IPublicClientApplication | null
}

export interface IAuthStore {
  adminPagesAuthorization: IAdminPagesAuthorization
  adminPagesAuthorizationFetched: boolean
  aad: IAADState
}

const initialAdminPageAuthorizationState: IAdminPagesAuthorization = {
  value: []
}

const initialState: IAuthStore = {
  adminPagesAuthorization: initialAdminPageAuthorizationState,
  adminPagesAuthorizationFetched: false,
  aad: {
    initializing: true,
    initialized: false,
    accessToken: '',
    accounts: null,
    instance: null
  }
}

const reducers: Reducer<IAuthStore, AuthActions> = (
  state: IAuthStore = initialState,
  action
) => {
  switch (action.type) {
    case AuthActionTypes.SET_AUTH_TOKEN:
      const { authToken } = (action as ISetAuthToken).payload
      return {
        ...state,
        aad: {
          ...state.aad,
          accessToken: authToken
        }
      }
    case AuthActionTypes.SET_AAD_INFO:
      const { instance, accounts } = (action as ISetAADInfo).payload
      return {
        ...state,
        aad: {
          ...state.aad,
          initializing: false,
          initialized: true,
          instance: instance,
          accounts: accounts
        }
      }
    case AuthActionTypes.FETCH_ADMIN_PAGES_AUTHORIZATION_REQUEST:
      return {
        ...state,
        adminPagesAuthorizationFetched: false,
        adminPagesAuthorization: initialAdminPageAuthorizationState
      }
    case AuthActionTypes.FETCH_ADMIN_PAGES_AUTHORIZATION_NOTFOUND:
      return {
        ...state,
        adminPagesAuthorizationFetched: true,
        adminPagesAuthorization: initialAdminPageAuthorizationState
      }
    case AuthActionTypes.FETCH_ADMIN_PAGES_AUTHORIZATION_FAILURE:
      return {
        ...state,
        adminPagesAuthorizationFetched: true,
        adminPagesAuthorization: initialAdminPageAuthorizationState
      }
    case AuthActionTypes.FETCH_ADMIN_PAGES_AUTHORIZATION_SUCCESS:
      const { adminPagesAuthorization } = (
        action as IFetchAdminPagesAuthorizationSuccess
      ).payload
      return {
        ...state,
        adminPagesAuthorizationFetched: true,
        adminPagesAuthorization: adminPagesAuthorization
      }
    default:
      return state
  }
}

export default reducers
