import { ISelfServiceRequest } from 'models/SelfServiceRequest'
import { Reducer } from 'redux'
import {
  IFetchAllRequestsSuccess,
  IFetchMyRequestsSuccess,
  IUpdateRequestSuccess,
  SelfServiceActions,
  SelfServiceActionTypes
} from './actions'

export interface ISelfServiceStore {
  myRequests: ISelfServiceRequest[]
  hasMyRequestsBeenFetched: boolean
  hasMyRequestsError: boolean
  allRequests: ISelfServiceRequest[]
  hasAllRequestsBeenFetched: boolean
  hasAllRequestsBeenUpdated: boolean
  hasAllRequestsError: boolean
  upsertRequestError: boolean
}

const initialState: ISelfServiceStore = {
  myRequests: [],
  hasMyRequestsBeenFetched: false,
  hasMyRequestsError: false,
  allRequests: [],
  hasAllRequestsBeenFetched: false,
  hasAllRequestsBeenUpdated: false,
  hasAllRequestsError: false,
  upsertRequestError: false
}

const reducers: Reducer<ISelfServiceStore, SelfServiceActions> = (
  state: ISelfServiceStore = initialState,
  action: SelfServiceActions
) => {
  switch (action.type) {
    case SelfServiceActionTypes.FETCH_MYREQUESTS_REQUEST:
      return {
        ...state,
        hasMyRequestsBeenFetched: false,
        myRequests: [],
        hasMyRequestsError: false
      }

    case SelfServiceActionTypes.FETCH_MYREQUESTS_SUCCESS:
      const { myRequests } = (action as IFetchMyRequestsSuccess).payload
      return {
        ...state,
        hasMyRequestsBeenFetched: true,
        myRequests: myRequests,
        hasMyRequestsError: false
      }
    case SelfServiceActionTypes.FETCH_MYREQUESTS_FAILED:
      return {
        ...state,
        hasMyRequestsBeenFetched: true,
        hasMyRequestsError: true
      }
    case SelfServiceActionTypes.FETCH_ALLREQUESTS_REQUEST:
      return {
        ...state,
        hasAllRequestsBeenFetched: false,
        allRequests: [],
        hasAllRequestsError: false
      }

    case SelfServiceActionTypes.FETCH_ALLREQUESTS_SUCCESS:
      const { allRequests } = (action as IFetchAllRequestsSuccess).payload
      return {
        ...state,
        hasAllRequestsBeenFetched: true,
        allRequests: allRequests,
        hasAllRequestsError: false
      }
    case SelfServiceActionTypes.FETCH_ALLREQUESTS_FAILED:
      return {
        ...state,
        hasAllRequestsBeenFetched: true,
        hasAllRequestsError: true
      }
    case SelfServiceActionTypes.START_UPDATE_REQUESTS:
      return {
        ...state,
        hasAllRequestsBeenUpdated: false
      }
    case SelfServiceActionTypes.UPDATE_REQUESTS_SUCCESS:
      const { newRequests, deletedRequests } = (action as IUpdateRequestSuccess)
        .payload

      let updateAllRequests = state.allRequests
      updateAllRequests = updateAllRequests.filter(
        (request: ISelfServiceRequest) => {
          return !deletedRequests.some(
            (deletedRequest: ISelfServiceRequest) =>
              deletedRequest.id === request.id &&
              deletedRequest.upn === request.upn
          )
        }
      )

      newRequests.forEach((newRequest: ISelfServiceRequest) => {
        if ((newRequest as any).tableData) {
          ;(newRequest as any).tableData.checked = false
        }

        updateAllRequests.push(newRequest)
      })

      return {
        ...state,
        allRequests: updateAllRequests,
        hasAllRequestsBeenUpdated: true
      }
    default:
      return state
  }
}

export default reducers
