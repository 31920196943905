import {
  Box,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Autocomplete,
  createFilterOptions
} from '@mui/material'
import { WarningTwoTone } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import {
  renderSelect,
  editFormErrorKeys,
  checkIsValidContactUsTile,
  determineIsContactUsTile
} from 'utils/admin/adminFormUtils'
import createDOMPurify from 'dompurify'
import { TabContext, TabPanel } from '@mui/lab'
import { ContactUsTile } from 'models/ContactUsTile'
import * as MuiIcons from '@mui/icons-material'
import { IErrorListObject, IErrorObject } from 'models/FormError'

export interface IEditFormProps {
  itemList: ContactUsTile[]
  isNewItem: boolean
  setIsValid: (isValid: boolean) => void
  changedLanguages: string[]
  setChangedLanguages: (languages: string[]) => void
  setItemList: (newItems: ContactUsTile[]) => void
  allContactUsTiles: ContactUsTile[]
}

export default function EditFormContactUsTiles(
  props: IEditFormProps
): JSX.Element {
  const {
    itemList,
    isNewItem,
    setIsValid,
    changedLanguages,
    setChangedLanguages,
    setItemList,
    allContactUsTiles
  } = props
  const DOMPurify = createDOMPurify(window)
  const [scope, setScope] = useState(
    itemList[0] && itemList[0].scope ? itemList[0].scope : 'All'
  )

  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())
  const [loaded, setLoaded] = useState(false)
  const classes = getStylesAdminForm()
  const [errorState, setErrorState] = useState<IErrorListObject[]>([])

  useEffect(() => {
    setItemList(createMissingLanguages(itemList))
    validateItem()
    setLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const materialUiIconList = Object.keys(MuiIcons).map((iconName: string) => ({
    label: iconName
  }))

  const materialUiIconListFilterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 20
  })

  ///error
  const getError = (locale: string, key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error &&
        errorItem.locale === locale &&
        errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }
  const hasLocaleError = (locale: string, key: string[]) => {
    for (let index = 0; index < key.length; index++) {
      const error = getError(locale, key[index])
      if (error.error) return true
    }

    return false
  }

  const validateItem = (): void => {
    let allValidated = true
    let errorList: IErrorListObject[] = []
    const enItem = getCurrentObjectByLanguage('en')
    if (!enItem) {
      allValidated = false
      return
    }

    supportedLanguages.forEach((lanuage: SupportedLanguage) => {
      const item = getCurrentObjectByLanguage(lanuage.locale)
      if (!item) {
        allValidated = false
        return
      }
      errorList = [
        ...errorList,
        ...checkIsValidContactUsTile(item, enItem, allContactUsTiles)
      ]

      if (!allValidated) return
    })

    setIsValid(!errorList.find((error: IErrorListObject) => error.error.error))
    setErrorState(errorList)
  }

  const validateItemAndCheckLanguages = (checkLanguages = true): void => {
    validateItem()
    if (checkLanguages) {
      const changedLanguages: string[] = []

      supportedLanguages.forEach((lang: SupportedLanguage) => {
        const foundLanguage = changedLanguages.find(
          (locale: string) => locale === lang.locale
        )

        if (!foundLanguage) {
          changedLanguages.push(lang.locale)
        }
      })

      setChangedLanguages([...changedLanguages, ...changedLanguages])
    }
  }

  const [tabIndex, setTabIndex] = React.useState(0)

  const getCurrentObjectByLanguage = (locale: string) => {
    const localeChild = itemList.find(
      (item: ContactUsTile) => item.language === locale
    )

    if (localeChild) {
      return localeChild
    }
  }

  const createMissingLanguages = (itemListToCheck: ContactUsTile[]) => {
    const changedItems: string[] = []
    const newItemList: ContactUsTile[] = []

    supportedLanguages.forEach((lang: SupportedLanguage) => {
      const localeChild = itemListToCheck.find(
        (item: ContactUsTile) => item.language === lang.locale
      )

      if (localeChild) {
        newItemList.push(localeChild)
      } else {
        const id = itemList[0].id

        const newChild = JSON.parse(JSON.stringify(itemListToCheck[0]))
        newChild.language = lang.locale
        newChild.id = id.toString()

        newItemList.push(newChild)
        changedItems.push(lang.locale)
      }
    })

    setChangedLanguages([...changedLanguages, ...changedItems])
    return newItemList
  }

  const handleLanguageItemChange = (
    oldItem: ContactUsTile,
    newItem: ContactUsTile
  ) => {
    if (
      !changedLanguages.find(
        (changedLanguages: string) => changedLanguages === newItem.language
      )
    )
      setChangedLanguages([...changedLanguages, ...[newItem.language]])

    const changedItems: string[] = []
    const newItemList: ContactUsTile[] = []
    itemList.forEach((item: ContactUsTile) => {
      if (oldItem.language === 'en' && oldItem.language !== item.language) {
        let itemUpdated = false
        if (determineIsContactUsTile(oldItem)) {
          const targetNewItem = newItem
          const targetItem = item
          if (
            oldItem.title === item.title &&
            targetNewItem.title !== targetItem.title
          ) {
            item.title = newItem.title
            itemUpdated = true
          }
          if (
            oldItem.description === targetItem.description &&
            targetNewItem.description !== targetItem.description
          ) {
            targetItem.description = targetNewItem.description
            itemUpdated = true
          }
          if (
            oldItem.link === targetItem.link &&
            targetNewItem.link !== targetItem.link
          ) {
            targetItem.link = targetNewItem.link
            itemUpdated = true
          }
        }
        if (itemUpdated) {
          changedItems.push(item.language)
        }
      }
      newItemList.push(item)
    })

    changedItems.push(newItem.language)

    setChangedLanguages([...changedLanguages, ...changedItems])
    setItemList(newItemList)
  }

  return (
    <>
      {itemList && itemList.length > 0 && loaded && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={(event: any, newValue: number): void => {
                setTabIndex(newValue)
              }}
              variant="scrollable"
              aria-label="languages"
              scrollButtons={true}
            >
              {supportedLanguages.map(
                (lanuage: SupportedLanguage, index: number) => (
                  <Tab
                    className={classes.tab_select}
                    label={
                      <>
                        {changedLanguages.find(
                          (cL: string) => cL === lanuage.locale
                        ) ? (
                          <i>
                            <b>(Draft)&nbsp;</b>
                          </i>
                        ) : (
                          ''
                        )}
                        {hasLocaleError(lanuage.locale, [
                          editFormErrorKeys.title,
                          editFormErrorKeys.description,
                          editFormErrorKeys.link
                        ]) && <WarningTwoTone style={{ color: 'red' }} />}
                        <span>{lanuage.name}</span>
                      </>
                    }
                    key={index}
                  />
                )
              )}
            </Tabs>
          </Box>
          <TabContext value={tabIndex + ''}>
            {supportedLanguages.map(
              (lanuage: SupportedLanguage, languageIndex: number) => {
                const currentSelectedObject = getCurrentObjectByLanguage(
                  lanuage.locale
                ) as ContactUsTile
                return (
                  <TabPanel
                    value={languageIndex + ''}
                    className={classes.tab_panel}
                    key={languageIndex}
                  >
                    <TextField
                      variant="standard"
                      required
                      id="id-required"
                      className={classes.tab_item}
                      label="Id"
                      defaultValue={currentSelectedObject.id}
                      disabled
                    />
                    <TextField
                      variant="standard"
                      id="title-required"
                      className={classes.tab_item}
                      label="Title *"
                      defaultValue={currentSelectedObject.title}
                      onChange={(event) => {
                        const oldItem = Object.assign({}, currentSelectedObject)

                        currentSelectedObject.title = event.target.value
                          ? DOMPurify.sanitize(event.target.value, {
                              USE_PROFILES: { html: false }
                            })
                          : event.target.value
                        handleLanguageItemChange(oldItem, currentSelectedObject)
                        validateItem()
                      }}
                      onBlur={() => {
                        setCurrentFormState(new Date().getTime())
                      }}
                      error={
                        getError(lanuage.locale, editFormErrorKeys.title).error
                      }
                      helperText={
                        getError(lanuage.locale, editFormErrorKeys.title)
                          .helperText
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      key={`title_${currentFormState}`}
                    />
                    <TextField
                      variant="standard"
                      className={classes.tab_item}
                      multiline
                      id="description"
                      label="Description *"
                      defaultValue={currentSelectedObject.description}
                      rows={5}
                      onChange={(event) => {
                        const oldItem = Object.assign({}, currentSelectedObject)
                        currentSelectedObject.description = event.target.value
                          ? DOMPurify.sanitize(event.target.value, {
                              USE_PROFILES: { html: false }
                            })
                          : event.target.value
                        handleLanguageItemChange(oldItem, currentSelectedObject)
                        validateItem()
                      }}
                      onBlur={() => {
                        setCurrentFormState(new Date().getTime())
                      }}
                      error={
                        getError(lanuage.locale, editFormErrorKeys.description)
                          .error
                      }
                      helperText={
                        getError(lanuage.locale, editFormErrorKeys.description)
                          .helperText
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      key={`desc_${currentFormState}`}
                    />
                  </TabPanel>
                )
              }
            )}
          </TabContext>
          <TextField
            variant="standard"
            id="link"
            label="Link"
            defaultValue={itemList[0].link}
            onChange={(event) => {
              if (determineIsContactUsTile(itemList[0])) {
                itemList[0].link = event.target.value
                  ? DOMPurify.sanitize(event.target.value.replace(/ /g, '%20'))
                  : event.target.value
                validateItemAndCheckLanguages()
              }
            }}
            onBlur={(event) => {
              validateItem()
            }}
            InputLabelProps={{
              shrink: true
            }}
            error={
              getError(itemList[0].language, editFormErrorKeys.link).error ||
              getError(itemList[0].language, editFormErrorKeys.url).error
            }
            helperText={
              getError(itemList[0].language, editFormErrorKeys.link)
                .helperText ||
              getError(itemList[0].language, editFormErrorKeys.url).helperText
            }
          />
          <Autocomplete
            id="image"
            options={materialUiIconList}
            filterOptions={materialUiIconListFilterOptions}
            value={itemList[0].image}
            sx={{ width: '100%' }}
            renderInput={(params) => {
              let Icon
              try {
                Icon =
                  MuiIcons[params.inputProps.value as keyof typeof MuiIcons]
              } catch (e: any) {}

              return (
                <TextField
                  variant="standard"
                  label="Image"
                  {...params}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: Icon && (
                      <Icon style={{ width: '20px', height: '20px' }} />
                    )
                  }}
                  error={
                    getError(itemList[0].language, editFormErrorKeys.image)
                      .error
                  }
                  helperText={
                    getError(itemList[0].language, editFormErrorKeys.image)
                      .helperText
                  }
                />
              )
            }}
            renderOption={(props: any, option: any) => {
              let Icon
              try {
                Icon = MuiIcons[option.label as keyof typeof MuiIcons]
              } catch (e: any) {}

              return (
                <div {...props}>
                  <div style={{ display: 'inline-block' }}>
                    {Icon && <Icon style={{ width: 16, height: 16 }} />}
                  </div>
                  {option.label}
                </div>
              )
            }}
            onBlur={(event) => {
              validateItem()
            }}
            onChange={(event, value: any) => {
              itemList[0].image = value.label
                ? DOMPurify.sanitize(value.label.replace(/ /g, '%20'))
                : value.label
              validateItemAndCheckLanguages()
            }}
          />
          <TextField
            variant="standard"
            id="order"
            label="Order"
            defaultValue={isNaN(itemList[0].order) ? '' : itemList[0].order}
            onChange={(event) => {
              itemList[0].order = parseInt(event.target.value)
              validateItemAndCheckLanguages()
            }}
            type="number"
            error={
              getError(itemList[0].language, editFormErrorKeys.order).error
            }
            helperText={
              getError(itemList[0].language, editFormErrorKeys.order).helperText
            }
            inputProps={{ step: 1, min: 1, max: 99999, type: 'number' }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <FormLabel id="formLabelActivelabel" style={{ fontSize: '0.8rem' }}>
            Active
          </FormLabel>
          <RadioGroup
            aria-label="active"
            name="active"
            value={itemList[0].isActive ? 'Yes' : 'No'}
            onChange={(event) => {
              itemList[0].isActive = event.target.value === 'Yes' ? true : false
              validateItemAndCheckLanguages()
            }}
          >
            <FormControlLabel
              style={{ fontSize: '0.8rem' }}
              value={'Yes'}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              style={{ fontSize: '0.8rem' }}
              value={'No'}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>

          {renderSelect(
            scope,
            setScope,
            ['OI', 'KPMGFind', 'All'],
            false,
            'Scope',
            'scope',
            itemList[0],
            isNewItem,
            validateItemAndCheckLanguages
          )}
        </>
      )}
    </>
  )
}
