import { Column } from '@material-table/core'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'
import AdminTableTooltipTitle from '../common/AdminTableTooltipTitle'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import createDOMPurify from 'dompurify'
import React from 'react'

const DOMPurify = createDOMPurify(window)

/**
 * Generate popup columns list
 * @param changes AdminItemChange list
 * @returns columns list
 */
export const subscriptionsTableColumns =
  (): Column<INewsLetterSubscription>[] => {
    const classes = getStylesAdminSettings()
    const columnDataSubscriptions: Column<INewsLetterSubscription>[] = []

    columnDataSubscriptions.push({
      title: 'Id',
      field: 'id',
      type: 'string',
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      filtering: false
    })

    columnDataSubscriptions.push({
      title: 'Email',
      field: 'upn',
      type: 'string',
      cellStyle: {
        maxWidth: 200
      },
      headerStyle: {
        maxWidth: 200
      },
      render: (rowData: INewsLetterSubscription) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          unsafeHTML={`${rowData.draft ? '<i><b>(Draft)</b></i> ' : ''}${
            rowData.upn ? DOMPurify.sanitize(rowData.upn) : ''
          }`}
          maxLine={4}
        />
      ),
      filtering: false
    })

    columnDataSubscriptions.push({
      title: 'Member Firm',
      field: 'memberFirm',
      type: 'string',
      cellStyle: {
        maxWidth: 200
      },
      headerStyle: {
        maxWidth: 200
      },
      filtering: false
    })

    return columnDataSubscriptions
  }
