import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesCommentArea(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    tab_item: {
      width: '100%'
    },
    commentMetaData: {
      color: 'gray',
      fontSize: '14px'
    },
    commentBody: {
      padding: '5px'
    },
    commentContainer: {
      position: 'relative'
    },
    commentScrollableArea: {
      maxHeight: '400px',
      overflowY: 'auto'
    },
    expandButton: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    expandButtonLatest: {
      position: 'absolute',
      top: 135,
      right: 0
    },
    commentItemContainer: {
      borderLeft: '1px solid rgba(0, 0, 0, 0.18)',
      margin: '5px 0px',
      padding: '0px 5px',
      borderRadius: '4px'
    },
    commentTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px'
    },
    commentArea: {
      padding: '10px',
      maxWidth: '500px',
      minWidth: '350px'
    },
    newCommentArea: {
      marginBottom: '10px'
    },
    newCommentSubmit: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      paddingBottom: '10px',
      marginBottom: '10px'
    },
    metricsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      marginBottom: '10px',
      '&> div > span': {
        fontWeight: 'bold'
      }
    },
    loadingSpinner: {
      width: '21px',
      height: '21px'
    }
  }))
}
