import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Tab,
  Tabs,
  TextField
} from '@mui/material'
import { WarningTwoTone } from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import { countriesISO } from 'constants/countriesISO'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import {
  SupportedFunction,
  supportedFunctions
} from 'constants/supportedFunctions'
import {
  convertToArray,
  renderSelect,
  editFormErrorKeys,
  checkIsValidAd
} from 'utils/admin/adminFormUtils'
import createDOMPurify from 'dompurify'
import { TabContext, TabPanel } from '@mui/lab'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { createDefaultDate } from 'utils/date'
import { filterSearchTermInput } from 'utils/admin/adminContentQuality'
import { capitalizeFirstLetter } from 'utils/string'
import { ISelfServiceRequest, RequestStatus } from 'models/SelfServiceRequest'
import { AdsResult } from 'models/AdsResult'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import {
  ISupportedVertical,
  supportedVerticals
} from 'constants/supportedVerticals'
import { fileTypeFromBuffer } from 'file-type'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { maximumAllowedFileSize } from 'constants/constants'

export interface IEditFormProps {
  item: ISelfServiceRequest
  isNewItem: boolean
  setItemList: (newItems: AdsResult[]) => void
  setIsValid: (isValid: boolean) => void
  setHasImageChange: (hasImageChange: boolean) => void
  tabIndex: number
  setTabIndex: (index: number) => void
}

export default function EditForm(props: IEditFormProps): JSX.Element {
  const {
    isNewItem,
    setItemList,
    item,
    setIsValid,
    setHasImageChange,
    tabIndex,
    setTabIndex
  } = props

  const itemList = item.itemData as AdsResult[]
  const orgImage = useRef(item.imagePreview)
  const DOMPurify = createDOMPurify(window)

  // timezone into start date
  const parsedStartDate =
    itemList[0] && itemList[0].start ? new Date(itemList[0].start) : null
  let startDateString
  if (parsedStartDate) {
    const timeOffsetInMS = parsedStartDate.getTimezoneOffset() * 60000 * -1
    parsedStartDate.setTime(parsedStartDate.getTime() - timeOffsetInMS)
    startDateString = parsedStartDate.toISOString()
  }
  // timezone into end date
  const parsedEndDate =
    itemList[0] && itemList[0].end ? new Date(itemList[0].end) : null
  let endDateString
  if (parsedEndDate) {
    const timeOffsetInMS = parsedEndDate.getTimezoneOffset() * 60000 * -1
    parsedEndDate.setTime(parsedEndDate.getTime() - timeOffsetInMS)
    endDateString = parsedEndDate.toISOString()
  }

  const [startDate, setStartDate] = useState<Date | null | undefined>(
    startDateString ? new Date(startDateString) : null
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    endDateString ? new Date(endDateString) : null
  )

  const [sources, setSources] = useState(
    itemList[0]?.sources.map((dsKey: string) => {
      return {
        key: capitalizeFirstLetter(dsKey),
        name: capitalizeFirstLetter(dsKey)
      }
    })
  )
  const [countries, setCountries] = useState(itemList[0]?.countries)
  const [functions, setFunctions] = useState(itemList[0]?.functions)
  const [matchType, setMatchType] = useState(
    itemList[0] && itemList[0].match_type ? itemList[0].match_type : ''
  )
  const [flavour, setFlavour] = useState(
    itemList[0] && itemList[0].flavour ? itemList[0].flavour : 'small'
  )
  const [scope, setScope] = useState(
    itemList[0] && itemList[0].scope ? itemList[0].scope : 'All'
  )
  const [availableSources, setAvailableSources] = useState<
    ISupportedVertical[]
  >([])
  const [loaded, setLoaded] = useState(false)
  const classes = getStylesAdminForm()

  const [errorState, setErrorState] = useState<IErrorListObject[]>([])

  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())
  const [countryList, setCountryList] = useState<string[]>([])
  const [functionList, setFunctionList] = useState<string[]>([])

  const [rank, setRank] = useState<number>(
    itemList[0] && itemList[0].rank ? itemList[0].rank : 0
  )
  const [uploadCheckError, setUploadCheckError] = useState('')

  const hasChanges =
    item.status === RequestStatus.Submitted &&
    (item.historyData?.data !== undefined || item.historyData?.imageChanged)

  const [showHistory, setShowHistory] = useState(hasChanges)

  useEffect(() => {
    setItemList(createMissingLanguages(itemList))
    updateAvailableSources(itemList)
    setLoaded(true)

    if (!endDate && isNewItem) {
      const dateString = createDefaultDate()
      setEndDate(new Date(dateString))
      itemList[0].end = dateString
    }
    if (!startDate && isNewItem) {
      const dateString = new Date().toISOString()
      setStartDate(new Date(dateString))
      itemList[0].start = dateString
    }

    validateItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loaded) {
      updateAvailableSources(itemList)

      if (scope === 'KPMGFind' && itemList[0].site_url) {
        itemList[0].site_url = ''
        validateItem()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope])

  ///error
  const getError = (locale: string, key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error &&
        errorItem.locale === locale &&
        errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }
  const hasLocaleError = (locale: string, key: string[]) => {
    for (let index = 0; index < key.length; index++) {
      const error = getError(locale, key[index])
      if (error.error) return true
    }

    return false
  }

  const validateItem = (): void => {
    let allValidated = true
    let errorList: IErrorListObject[] = []
    const enItem = Object.assign({}, getCurrentObjectByLanguage('en'))
    enItem.image = item.imagePreview ? item.imagePreview : ''
    if (!enItem) {
      allValidated = false
      return
    }

    supportedLanguages.forEach((lanuage: SupportedLanguage) => {
      const item = getCurrentObjectByLanguage(lanuage.locale)
      if (!item) {
        allValidated = false
        return
      }

      errorList = [
        ...errorList,
        ...checkIsValidAd(item, enItem as AdsResult, true)
      ]

      if (!allValidated) return
    })

    const errors = errorList.find(
      (error: IErrorListObject) => error.error.error
    )

    setIsValid(!errors)
    setErrorState(errorList)
  }

  const updateAvailableSources = (items: AdsResult[]) => {
    let functionList = supportedFunctions.map(
      (item: SupportedFunction) => item.name
    )
    let countryList = countriesISO.map((item) => item.Name)
    let sourcelist = supportedVerticals

    sourcelist = [{ key: 'All', name: 'All' }, ...sourcelist]
    countryList = ['All', ...countryList]
    functionList = ['All', ...functionList]

    setAvailableSources(sourcelist)

    setFunctionList(functionList)
    setCountryList(countryList)
  }

  const getCurrentObjectByLanguage = (locale: string) => {
    const localeChild = itemList.find(
      (item: AdsResult) => item.language === locale
    )

    if (localeChild) {
      return localeChild
    }
  }

  const createMissingLanguages = (itemListToCheck: AdsResult[]) => {
    const changedItems: string[] = []
    const newItemList: AdsResult[] = []

    supportedLanguages.forEach((lang: SupportedLanguage) => {
      const localeChild = itemListToCheck.find(
        (item: AdsResult) => item.language === lang.locale
      )

      if (localeChild) {
        newItemList.push(localeChild)
      } else {
        const id = itemList[0].id

        const newChild = JSON.parse(JSON.stringify(itemListToCheck[0]))
        newChild.language = lang.locale
        newChild.id = id.toString()

        newItemList.push(newChild)
        changedItems.push(lang.locale)
      }
    })

    return newItemList
  }

  const handleLanguageItemChange = (oldItem: AdsResult, newItem: AdsResult) => {
    const newItemList: AdsResult[] = []
    itemList.forEach((item: AdsResult) => {
      if (oldItem.language === 'en' && oldItem.language !== item.language) {
        const targetNewItem = newItem as AdsResult
        const targetItem = item as AdsResult
        if (
          oldItem.title === item.title &&
          targetNewItem.title !== targetItem.title
        ) {
          item.title = newItem.title
        }
        if (
          oldItem.text === targetItem.text &&
          targetNewItem.text !== targetItem.text
        ) {
          targetItem.text = targetNewItem.text
        }

        if (
          oldItem.link_text === targetItem.link_text &&
          targetNewItem.link_text !== targetItem.link_text
        ) {
          targetItem.link_text = targetNewItem.link_text
        }
      }
      newItemList.push(item)
    })
    setItemList(newItemList)
  }

  const validateUploadedFile = async (file: Blob) => {
    const buffer = await file.arrayBuffer()
    const realFileType = await fileTypeFromBuffer(new Uint8Array(buffer))

    if (
      !realFileType ||
      !realFileType.mime.startsWith('image') ||
      (realFileType.ext !== 'jpg' && realFileType.ext !== 'png')
    ) {
      setUploadCheckError(
        'Wrong file format. Only jpg or png images are allowed.'
      )
      return false
    }

    if (
      file &&
      file.size &&
      file.size > 0 &&
      file.size > maximumAllowedFileSize
    ) {
      setUploadCheckError(
        'The file size is too big. Only images with a size less than 0.25MB are allowed'
      )
      return false
    }

    setUploadCheckError('')
    return true
  }

  const getHistoryData = (
    key: string,
    language: string
  ): string | undefined => {
    if (
      !item.historyData ||
      item.historyData.isNewItem ||
      !item.historyData.data
    ) {
      return undefined
    }

    const historyData = (item.historyData.data as AdsResult[]).find(
      (ad: AdsResult) => {
        return ad.language === language
      }
    )

    return historyData ? (historyData as any)[key] : undefined
  }

  const getHistoryDate = (key: string, language: string): Date => {
    const historyData = (item.historyData?.data as AdsResult[]).find(
      (ad: AdsResult) => {
        return ad.language === language
      }
    )

    return historyData ? (historyData as any)[key] : undefined
  }

  return (
    <>
      {itemList && itemList.length > 0 && loaded && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={(event: any, newValue: number): void => {
                setTabIndex(newValue)
              }}
              variant="scrollable"
              aria-label="languages"
              scrollButtons={true}
            >
              {supportedLanguages.map((lanuage: SupportedLanguage) => (
                <Tab
                  className={classes.tab_select}
                  label={
                    <>
                      {hasLocaleError(lanuage.locale, [
                        editFormErrorKeys.title,
                        editFormErrorKeys.linkText,
                        editFormErrorKeys.btnText
                      ]) && <WarningTwoTone style={{ color: 'red' }} />}
                      <span>{lanuage.name}</span>
                    </>
                  }
                />
              ))}
            </Tabs>
          </Box>

          <>
            {hasChanges && (
              <div className={classes.hideHistoryButtonContainer}>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    setShowHistory(!showHistory)
                  }}
                  className={classes.hideHistoryButton}
                >
                  {showHistory ? (
                    <>
                      <div style={{ marginRight: '5px' }}>Hide History</div>
                      <ArrowForwardIosIcon fontSize="inherit" />
                    </>
                  ) : (
                    <>
                      <ArrowBackIosIcon fontSize="inherit" />
                      <div style={{ marginLeft: '5px' }}>Show History</div>
                    </>
                  )}
                </IconButton>
              </div>
            )}
            <TabContext value={tabIndex + ''}>
              {supportedLanguages.map(
                (language: SupportedLanguage, languageIndex: number) => {
                  const currentSelectedObject = getCurrentObjectByLanguage(
                    language.locale
                  ) as AdsResult

                  return (
                    <TabPanel
                      value={languageIndex + ''}
                      className={classes.tab_panel}
                    >
                      <div className={classes.historyContainer}>
                        <TextField
                          variant="standard"
                          id="title-required"
                          className={classes.tab_item}
                          label="Title *"
                          defaultValue={currentSelectedObject.title}
                          onChange={(event) => {
                            const oldItem = Object.assign(
                              {},
                              currentSelectedObject
                            )

                            currentSelectedObject.title = event.target.value
                              ? DOMPurify.sanitize(event.target.value, {
                                  USE_PROFILES: { html: false }
                                })
                              : event.target.value
                            handleLanguageItemChange(
                              oldItem,
                              currentSelectedObject
                            )
                            validateItem()
                          }}
                          onBlur={() => {
                            setCurrentFormState(new Date().getTime())
                          }}
                          error={
                            getError(language.locale, editFormErrorKeys.title)
                              .error
                          }
                          helperText={
                            getError(language.locale, editFormErrorKeys.title)
                              .helperText
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          key={`title_${currentFormState}`}
                        />
                        {hasChanges &&
                          showHistory &&
                          (getHistoryData('title', language.locale) !==
                          undefined ? (
                            <TextField
                              variant="standard"
                              id="title-required"
                              className={classes.tab_item_history}
                              defaultValue={getHistoryData(
                                'title',
                                language.locale
                              )}
                              disabled={true}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          ) : (
                            <div className={classes.tab_item_history}></div>
                          ))}
                      </div>
                      <div className={classes.historyContainer}>
                        <TextField
                          variant="standard"
                          className={classes.tab_item}
                          multiline
                          id="text"
                          label="Description *"
                          defaultValue={currentSelectedObject.text}
                          rows={5}
                          onChange={(event) => {
                            const oldItem = Object.assign(
                              {},
                              currentSelectedObject
                            )
                            currentSelectedObject.text = event.target.value
                              ? DOMPurify.sanitize(event.target.value, {
                                  USE_PROFILES: { html: true },
                                  ADD_ATTR: ['target']
                                })
                              : event.target.value
                            handleLanguageItemChange(
                              oldItem,
                              currentSelectedObject
                            )
                            validateItem()
                          }}
                          onBlur={() => {
                            setCurrentFormState(new Date().getTime())
                          }}
                          error={
                            getError(
                              language.locale,
                              editFormErrorKeys.description
                            ).error
                          }
                          helperText={
                            getError(
                              language.locale,
                              editFormErrorKeys.description
                            ).helperText
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          key={`desc_${currentFormState}`}
                        />
                        {hasChanges &&
                          showHistory &&
                          (getHistoryData('text', language.locale) !==
                          undefined ? (
                            <TextField
                              variant="standard"
                              multiline
                              rows={5}
                              id="title-required"
                              className={classes.tab_item_history}
                              defaultValue={getHistoryData(
                                'text',
                                language.locale
                              )}
                              disabled={true}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          ) : (
                            <div className={classes.tab_item_history}></div>
                          ))}
                      </div>
                      <div className={classes.historyContainer}>
                        <TextField
                          variant="standard"
                          id="link-text"
                          className={classes.tab_item_request}
                          label="Link Text"
                          defaultValue={currentSelectedObject.link_text}
                          onChange={(event) => {
                            const oldItem = Object.assign(
                              {},
                              currentSelectedObject
                            )
                            currentSelectedObject.link_text = event.target.value
                              ? DOMPurify.sanitize(event.target.value, {
                                  USE_PROFILES: { html: false }
                                })
                              : event.target.value
                            handleLanguageItemChange(
                              oldItem,
                              currentSelectedObject
                            )
                            validateItem()
                          }}
                          onBlur={(event) => {
                            setCurrentFormState(new Date().getTime())
                            validateItem()
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          error={
                            getError(
                              language.locale,
                              editFormErrorKeys.linkText
                            ).error
                          }
                          helperText={
                            getError(
                              language.locale,
                              editFormErrorKeys.linkText
                            ).helperText
                          }
                          key={`link_text_${currentFormState}`}
                        />
                        {hasChanges &&
                          showHistory &&
                          (getHistoryData('link_text', language.locale) !==
                          undefined ? (
                            <TextField
                              variant="standard"
                              id="title-required"
                              className={classes.tab_item_history}
                              defaultValue={getHistoryData(
                                'link_text',
                                language.locale
                              )}
                              disabled={true}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          ) : (
                            <div className={classes.tab_item_history}></div>
                          ))}
                      </div>
                    </TabPanel>
                  )
                }
              )}
            </TabContext>
            <div className={classes.historyContainer}>
              <TextField
                variant="standard"
                id="link"
                label="Link"
                defaultValue={itemList[0].link}
                onChange={(event) => {
                  itemList[0].link = event.target.value
                    ? DOMPurify.sanitize(
                        event.target.value.replace(/ /g, '%20')
                      )
                    : event.target.value
                  validateItem()
                }}
                onBlur={(event) => {
                  validateItem()
                }}
                InputLabelProps={{
                  shrink: true
                }}
                error={
                  getError(itemList[0].language, editFormErrorKeys.link)
                    .error ||
                  getError(itemList[0].language, editFormErrorKeys.url).error
                }
                helperText={
                  getError(itemList[0].language, editFormErrorKeys.link)
                    .helperText ||
                  getError(itemList[0].language, editFormErrorKeys.url)
                    .helperText
                }
                className={classes.tab_item_request}
              />
              {hasChanges &&
                showHistory &&
                (getHistoryData('link', 'en') !== undefined ? (
                  <TextField
                    variant="standard"
                    id="title-required"
                    className={classes.tab_item_history}
                    defaultValue={getHistoryData('link', 'en')}
                    disabled={true}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                ) : (
                  <div className={classes.tab_item_history}></div>
                ))}
            </div>
            <div className={classes.historyContainer}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className={classes.tab_item_request}
                  label={'Start *'}
                  format="MM/dd/yyyy"
                  value={startDate || null}
                  onChange={(date: Date | null) => {
                    let dateString = null
                    if (date && date.toString() !== 'Invalid Date') {
                      const day = date.getDate()
                      const month = date.getMonth() + 1
                      const year = date.getFullYear()
                      dateString =
                        year.toString() +
                        '-' +
                        month.toString().padStart(2, '0') +
                        '-' +
                        day.toString().padStart(2, '0') +
                        'T00:00:00.000Z'
                    }

                    itemList[0].start = dateString
                    setStartDate(date ? date : null)

                    validateItem()
                  }}
                  onYearChange={() => {
                    const popperRoot = document.querySelectorAll(
                      '[class*=MuiPickersPopper-root'
                    )
                    if (popperRoot.length > 0) {
                      const transformValue = (popperRoot[0] as HTMLElement)
                        .style.transform
                      setTimeout(() => {
                        ;(popperRoot[0] as HTMLElement).style.transform =
                          transformValue
                      }, 10)
                    }
                  }}
                  onClose={() => {
                    const popperRoot = document.querySelectorAll(
                      '[class*=MuiPickersPopper-root'
                    )
                    if (popperRoot.length > 0) {
                      ;(popperRoot[0] as HTMLElement).style.display = 'none'
                    }
                  }}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      className: classes.datePicker
                    }
                  }}
                  slots={{
                    textField: (textFieldProps) => (
                      <TextField
                        {...textFieldProps}
                        variant="standard"
                        InputLabelProps={{
                          shrink: true
                        }}
                        error={
                          getError(
                            itemList[0].language,
                            editFormErrorKeys.startDate
                          ).error
                        }
                        helperText={
                          getError(
                            itemList[0].language,
                            editFormErrorKeys.startDate
                          ).helperText
                        }
                      />
                    )
                  }}
                />
              </LocalizationProvider>
              {hasChanges &&
                showHistory &&
                (getHistoryData('start', 'en') !== undefined ? (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="MM/dd/yyyy"
                      value={getHistoryDate('start', 'en') || null}
                      onChange={(date: Date | null) => {}}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: classes.datePicker
                        }
                      }}
                      slots={{
                        textField: (textFieldProps) => (
                          <TextField
                            {...textFieldProps}
                            variant="standard"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )
                      }}
                      disabled={true}
                      className={classes.tab_item_history}
                    />
                  </LocalizationProvider>
                ) : (
                  <div className={classes.tab_item_history}></div>
                ))}
            </div>
            <div className={classes.historyContainer}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className={classes.tab_item_request}
                  label={'End *'}
                  format="MM/dd/yyyy"
                  value={endDate || null}
                  onChange={(date: Date | null) => {
                    let dateString = null
                    if (date && date.toString() !== 'Invalid Date') {
                      const day = date.getDate()
                      const month = date.getMonth() + 1
                      const year = date.getFullYear()
                      dateString =
                        year.toString() +
                        '-' +
                        month.toString().padStart(2, '0') +
                        '-' +
                        day.toString().padStart(2, '0') +
                        'T23:59:59.000Z'
                    }

                    itemList[0].end = dateString
                    setEndDate(date ? date : null)

                    validateItem()
                  }}
                  onYearChange={() => {
                    const popperRoot = document.querySelectorAll(
                      '[class*=MuiPickersPopper-root'
                    )
                    if (popperRoot.length > 0) {
                      const transformValue = (popperRoot[0] as HTMLElement)
                        .style.transform
                      setTimeout(() => {
                        ;(popperRoot[0] as HTMLElement).style.transform =
                          transformValue
                      }, 10)
                    }
                  }}
                  onClose={() => {
                    const popperRoot = document.querySelectorAll(
                      '[class*=MuiPickersPopper-root'
                    )
                    if (popperRoot.length > 0) {
                      ;(popperRoot[0] as HTMLElement).style.display = 'none'
                    }
                  }}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      className: classes.datePicker
                    }
                  }}
                  slots={{
                    textField: (textFieldProps) => (
                      <TextField
                        {...textFieldProps}
                        variant="standard"
                        InputLabelProps={{
                          shrink: true
                        }}
                        error={
                          getError(
                            itemList[0].language,
                            editFormErrorKeys.endDate
                          ).error
                        }
                        helperText={
                          getError(
                            itemList[0].language,
                            editFormErrorKeys.endDate
                          ).helperText
                        }
                      />
                    )
                  }}
                />
              </LocalizationProvider>
              {hasChanges &&
                showHistory &&
                (getHistoryData('end', 'en') !== undefined ? (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="MM/dd/yyyy"
                      value={getHistoryDate('end', 'en') || null}
                      onChange={(date: Date | null) => {}}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: classes.datePicker
                        }
                      }}
                      slots={{
                        textField: (textFieldProps) => (
                          <TextField
                            {...textFieldProps}
                            variant="standard"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )
                      }}
                      disabled={true}
                      className={classes.tab_item_history}
                    />
                  </LocalizationProvider>
                ) : (
                  <div className={classes.tab_item_history}></div>
                ))}
            </div>
            {scope !== 'KPMGFind' && (
              <div className={classes.historyContainer}>
                <TextField
                  variant="standard"
                  id="site_url"
                  label="Site Url"
                  defaultValue={itemList[0].site_url}
                  onChange={(event) => {
                    itemList[0].site_url = event.target.value
                      ? DOMPurify.sanitize(
                          event.target.value.replace(/ /g, '%20')
                        )
                      : event.target.value
                    validateItem()
                  }}
                  onBlur={(event) => {
                    validateItem()
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={
                    getError(itemList[0].language, editFormErrorKeys.site_url)
                      .error
                  }
                  helperText={
                    getError(itemList[0].language, editFormErrorKeys.site_url)
                      .helperText
                  }
                  className={classes.tab_item_request}
                />
                {hasChanges &&
                  showHistory &&
                  (getHistoryData('site_url', 'en') !== undefined ? (
                    <TextField
                      variant="standard"
                      id="title-required"
                      className={classes.tab_item_history}
                      defaultValue={getHistoryData('site_url', 'en')}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  ) : (
                    <div className={classes.tab_item_history}></div>
                  ))}
              </div>
            )}
            <div className={classes.historyContainer}>
              <TextField
                variant="standard"
                id="image-url"
                label="Image"
                value={item.imageFileName}
                InputLabelProps={{
                  shrink: true
                }}
                error={
                  getError(itemList[0].language, editFormErrorKeys.image).error
                }
                helperText={
                  getError(itemList[0].language, editFormErrorKeys.image)
                    .helperText
                }
                className={classes.tab_item_request}
              />
              {hasChanges &&
                showHistory &&
                (item.historyData?.imageChanged ? (
                  <TextField
                    variant="standard"
                    id="image-url"
                    label="Image"
                    value={'Image changed'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.tab_item_history_select}
                    disabled={true}
                  />
                ) : (
                  <div className={classes.tab_item_history}></div>
                ))}
            </div>
            <Box className={classes.imageUploadButtonContainer}>
              <Button
                variant="contained"
                component="label"
                className={classes.imageUploadButton}
                style={uploadCheckError ? { border: '1px solid red' } : {}}
              >
                <Box>Upload</Box>
                <input
                  type="file"
                  hidden
                  onChange={(event: any) => {
                    const file = event.target.files[0]
                    if (file) {
                      const fr = new FileReader()
                      fr.onload = async () => {
                        let value = fr.result as string
                        if (value && file.name) {
                          if (await validateUploadedFile(file)) {
                            value = value.substr(5)
                            if (value !== orgImage.current) {
                              setHasImageChange(true)
                            } else {
                              setHasImageChange(false)
                            }
                            item.imagePreview = value
                            item.imageFileName = file.name
                              .replace(/[/\\?%*:|"<>]/g, '')
                              .replace(/\s+/g, '')
                            validateItem()
                          }
                        }
                      }
                      fr.readAsDataURL(file)
                    }
                  }}
                  onClick={(event: any) => {
                    event.target.value = null
                  }}
                />
              </Button>
              <Button
                variant="contained"
                component="label"
                className={classes.imageUploadButton}
                onClick={() => {
                  item.imagePreview = ''
                  item.imageFileName = ''

                  if (orgImage.current) {
                    setHasImageChange(true)
                  } else {
                    setHasImageChange(false)
                  }
                  validateItem()
                }}
                style={{ marginLeft: '5px' }}
              >
                <Box>Clear</Box>
              </Button>
            </Box>
            {uploadCheckError && (
              <FormHelperText
                style={{ color: '#f44336', margin: '0 14px 14px 14px' }}
                className={classes.errorRadioBoxHelpText}
              >
                {uploadCheckError}
              </FormHelperText>
            )}

            <div className={classes.historyContainer}>
              <TextField
                variant="standard"
                id="rank"
                label="Rank"
                value={rank}
                onChange={(event) => {
                  itemList[0].rank = parseInt(event.target.value)
                  setRank(itemList[0].rank)
                  validateItem()
                }}
                type="number"
                error={
                  getError(itemList[0].language, editFormErrorKeys.rank).error
                }
                helperText={
                  getError(itemList[0].language, editFormErrorKeys.rank)
                    .helperText
                }
                InputLabelProps={{
                  shrink: true
                }}
                className={classes.tab_item_request}
              />
              {hasChanges && showHistory && (
                <div className={classes.tab_item_history}></div>
              )}
            </div>
          </>
          <div className={classes.historyContainer}>
            {renderSelect(
              scope,
              setScope,
              ['OI', 'KPMGFind', 'All'],
              false,
              'Scope',
              'scope',
              itemList[0],
              isNewItem,
              validateItem,
              undefined,
              undefined,
              undefined,
              classes.tab_item_request
            )}
            {hasChanges &&
              showHistory &&
              (getHistoryData('scope', 'en') !== undefined ? (
                <>
                  {renderSelect(
                    getHistoryData('scope', 'en'),
                    () => {},
                    ['OI', 'KPMGFind', 'All'],
                    false,
                    'Scope',
                    'scope',
                    itemList[0],
                    isNewItem,
                    () => {},
                    undefined,
                    undefined,
                    true,
                    classes.tab_item_history_select
                  )}
                </>
              ) : (
                <div className={classes.tab_item_history}></div>
              ))}
          </div>
          <div className={classes.historyContainer}>
            {renderSelect(
              sources,
              setSources,
              availableSources,
              true,
              'Sources *',
              'sources',
              itemList[0],
              isNewItem,
              () => {
                validateItem()
              },
              false,
              getError(itemList[0].language, editFormErrorKeys.source),
              undefined,
              classes.tab_item_request
            )}
            {hasChanges &&
              showHistory &&
              (getHistoryData('sources', 'en') !== undefined ? (
                <>
                  {renderSelect(
                    getHistoryData('sources', 'en'),
                    () => {},
                    availableSources,
                    true,
                    'Sources *',
                    'sources',
                    itemList[0],
                    isNewItem,
                    () => {},
                    false,
                    undefined,
                    true,
                    classes.tab_item_history_select
                  )}
                </>
              ) : (
                <div className={classes.tab_item_history}></div>
              ))}
          </div>
          <div className={classes.historyContainer}>
            {renderSelect(
              countries,
              setCountries,
              countryList,
              true,
              'Countries',
              'countries',
              itemList[0],
              isNewItem,
              validateItem,
              undefined,
              undefined,
              undefined,
              classes.tab_item_request
            )}
            {hasChanges &&
              showHistory &&
              (getHistoryData('countries', 'en') !== undefined ? (
                <>
                  {renderSelect(
                    getHistoryData('countries', 'en'),
                    () => {},
                    countryList,
                    true,
                    'Countries',
                    'countries',
                    itemList[0],
                    isNewItem,
                    () => {},
                    undefined,
                    undefined,
                    true,
                    classes.tab_item_history_select
                  )}
                </>
              ) : (
                <div className={classes.tab_item_history}></div>
              ))}
          </div>

          <div className={classes.historyContainer}>
            {renderSelect(
              functions,
              setFunctions,
              functionList,
              true,
              'Functions',
              'functions',
              itemList[0],
              isNewItem,
              validateItem,
              true,
              undefined,
              undefined,
              classes.tab_item_request
            )}
            {hasChanges &&
              showHistory &&
              (getHistoryData('functions', 'en') !== undefined ? (
                <>
                  {renderSelect(
                    getHistoryData('functions', 'en'),
                    () => {},
                    functionList,
                    true,
                    'Functions',
                    'functions',
                    itemList[0],
                    isNewItem,
                    () => {},
                    undefined,
                    undefined,
                    true,
                    classes.tab_item_history_select
                  )}
                </>
              ) : (
                <div className={classes.tab_item_history}></div>
              ))}
          </div>
          <div className={classes.historyContainer}>
            <TextField
              variant="standard"
              multiline
              id="search_terms"
              label="Search terms (provide a comma separated list)"
              defaultValue={itemList[0].search_terms}
              rows={2}
              onChange={(event) => {
                event.target.value = filterSearchTermInput(event.target.value)
                itemList[0].search_terms = event.target.value
                  ? convertToArray(DOMPurify.sanitize(event.target.value))
                  : []
                validateItem()
              }}
              error={
                getError(itemList[0].language, editFormErrorKeys.searchTerms)
                  .error
              }
              helperText={
                getError(itemList[0].language, editFormErrorKeys.searchTerms)
                  .helperText
              }
              InputLabelProps={{
                shrink: true
              }}
              className={classes.tab_item_request}
            />
            {hasChanges &&
              showHistory &&
              (getHistoryData('search_terms', 'en') !== undefined ? (
                <TextField
                  variant="standard"
                  multiline
                  id="search_terms"
                  required
                  defaultValue={getHistoryData('search_terms', 'en')}
                  rows={2}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  className={classes.tab_item_history}
                />
              ) : (
                <div className={classes.tab_item_history}></div>
              ))}
          </div>
          <div className={classes.historyContainer}>
            {renderSelect(
              flavour,
              setFlavour,
              ['large', 'small'],
              false,
              'Flavour',
              'flavour',
              itemList[0],
              isNewItem,
              validateItem,
              undefined,
              undefined,
              undefined,
              classes.tab_item_request
            )}
            {hasChanges &&
              showHistory &&
              (getHistoryData('flavour', 'en') !== undefined ? (
                <>
                  {renderSelect(
                    getHistoryData('flavour', 'en'),
                    () => {},
                    ['large', 'small'],
                    false,
                    'Flavour',
                    'flavour',
                    itemList[0],
                    isNewItem,
                    () => {},
                    true,
                    undefined,
                    true,
                    classes.tab_item_history_select
                  )}
                </>
              ) : (
                <div className={classes.tab_item_history}></div>
              ))}
          </div>
          <div className={classes.historyContainer}>
            {renderSelect(
              matchType,
              setMatchType,
              ['Contains', 'Exact'],
              false,
              'Match Type',
              'match_type',
              itemList[0],
              isNewItem,
              validateItem,
              true,
              undefined,
              undefined,
              classes.tab_item_request
            )}
            {hasChanges &&
              showHistory &&
              (getHistoryData('match_type', 'en') !== undefined ? (
                <>
                  {renderSelect(
                    getHistoryData('match_type', 'en'),
                    () => {},
                    ['Contains', 'Exact'],
                    false,
                    'Match Type',
                    'match_type',
                    itemList[0],
                    isNewItem,
                    () => {},
                    true,
                    undefined,
                    true,
                    classes.tab_item_history_select
                  )}
                </>
              ) : (
                <div className={classes.tab_item_history}></div>
              ))}
          </div>
        </>
      )}
    </>
  )
}
