import { ISearchTip } from 'models/SearchTip'
import { Store } from '..'

const selectors = {
  getSearchTipsAll: (state: Store): ISearchTip[] => state.searchTips.searchTips,
  hasBeenFetched: (state: Store): boolean => state.searchTips.hasBeenFetched,
  hasAllBeenFetched: (state: Store): boolean => state.searchTips.hasBeenFetched,
  hasError: (state: Store): boolean => state.searchTips.hasError,
  hasChangesError: (state: Store): boolean => state.searchTips.hasChangesError
}

export default selectors
