import { FeaturedResult } from 'models/FeaturedResult'
import { Store } from '..'

const selectors = {
  getFeaturedResults: (state: Store): FeaturedResult[] =>
    state.featuredResults.featuredResults,
  hasError: (state: Store): boolean => state.featuredResults.hasError,
  hasFeaturedResultsBeenFetched: (state: Store): boolean =>
    state.featuredResults.hasFeaturedResultsBeenFetched,
  hasChangesError: (state: Store): any => state.featuredResults.hasChangesError
}

export default selectors
