import React, { useRef, useState } from 'react'
import { CardMedia } from '@mui/material'
import MaterialTable from '@material-table/core'
import { Edit } from '@mui/icons-material'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import Logo from 'images/find_logo.png'
import AdToolBar from '../common/AdToolBar'
import { ExportCsv } from '@material-table/exporters'
import { parseContactUsTilesDataToExport } from 'utils/admin/adminExportUtils'
import { tableIcons } from 'utils/admin/adminSettingsUtils'
import { ContactUsTile } from 'models/ContactUsTile'
import { contactUsTileColumns } from './ContactUsTilesTableColumns'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import { TableStateContactUsTiles } from 'models/TableStates'

export interface IContactUsTilesTableProps {
  state: TableStateContactUsTiles
  handleRowDelete: any
  handleRowUpdate: any
  handleRowAdd: any
  handleFormOpen: any
}

export default function ContactUsTilesTable(
  props: IContactUsTilesTableProps
): JSX.Element {
  const {
    state,
    handleRowDelete,
    handleRowUpdate,
    handleRowAdd,
    handleFormOpen
  } = props
  const classes = getStylesAdminSettings()

  const [isFilterSet, setIsFilterSet] = useState(false)

  //force browser scrollbars
  const rootElement = document.getElementById('root')
  if (rootElement) rootElement.style.flexDirection = 'unset'

  const dataColumnsList = useRef(
    contactUsTileColumns(state.data as ContactUsTile[])
  )

  return (
    <>
      <MaterialTable
        title={
          <>
            <div className={classes.header}>
              <CardMedia image={Logo} className={classes.logo} />
              <h2>Contact Us Tiles Configuration</h2>
            </div>
            <div className={classes.information}>
              Any changes being made to the Contact Us Tiles using this
              configuration screen are common for OI and Find applications.
            </div>
          </>
        }
        icons={tableIcons}
        columns={dataColumnsList.current}
        data={state.tableView as ContactUsTile[]}
        options={{
          rowStyle: (data: ContactUsTile) => ({
            fontSize: 12
          }),
          addRowPosition: 'first',
          pageSize: 25,
          pageSizeOptions: [25, 50, 75],
          tableLayout: 'auto',
          filtering: true,
          emptyRowsWhenPaging: false,
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (cols, datas) => {
                if (isFilterSet) {
                  const exportList: ContactUsTile[] = []

                  const allCt = state.data as ContactUsTile[]
                  const exportData = datas

                  exportData.forEach((exportCt: ContactUsTile) => {
                    allCt.forEach((contactUsTile: ContactUsTile) => {
                      if (exportCt.id === contactUsTile.id)
                        exportList.push(contactUsTile)
                    })
                  })

                  ExportCsv(
                    cols,
                    parseContactUsTilesDataToExport(exportList),
                    'ContactUsTiles',
                    ';'
                  )
                } else {
                  ExportCsv(
                    cols,
                    parseContactUsTilesDataToExport(
                      state.data as ContactUsTile[]
                    ),
                    'ContactUsTiles',
                    ';'
                  )
                }
              }
            }
          ],
          exportAllData: true
        }}
        localization={{
          body: {
            editRow: {
              deleteText:
                'Are you sure you want to delete this row? Please remember to publish!'
            }
          }
        }}
        editable={{
          onRowDelete: handleRowDelete
        }}
        actions={[
          {
            icon: Edit,
            onClick: (event: any, rowData: ContactUsTile | ContactUsTile[]) => {
              if (!(rowData instanceof Array)) {
                handleFormOpen(rowData)
              }
            },
            tooltip: 'Edit'
          }
        ]}
        components={{
          Toolbar: (props: any) => (
            <AdToolBar
              {...{
                ...props,
                handleRowAdd: handleRowAdd,
                handleRowUpdate: handleRowUpdate,
                addButtonText: 'Add Contact Us Tile',
                adminSettingType: adminSettingTypes.contactustiles,
                disableBulkUpload: true
              }}
            />
          )
        }}
        onFilterChange={(filters: any) => {
          setIsFilterSet(filters.length > 0)
        }}
      />
    </>
  )
}
