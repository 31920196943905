import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesRequestForm(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    formContainer: {
      display: 'flex',
      gap: '30px'
    },
    leftFormArea: {
      width: '55%'
    },
    rightFormArea: {
      width: '45%'
    },
    form_field: {
      width: '100%'
    },
    rightFormTitle: {
      fontSize: '22px',
      marginBottom: '10px'
    },
    questionArea: {
      marginBottom: '10px'
    },
    questionTitle: { fontSize: '18px', marginBottom: '10px' },
    questionAnswer: { fontSize: '16px', marginBottom: '10px' },
    singleLine: {
      display: 'flex',
      gap: '20px'
    },
    previewArea: {
      marginBottom: '10px'
    },
    previewHeader: {
      fontSize: '20px',
      marginBottom: '10px'
    },
    preview: {
      borderRadius: '4px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      minHeight: '50px'
    },
    adPreview: {
      margin: 'auto',
      width: '50%',
      marginTop: '15px'
    },
    selectChipArea: {
      width: '100%',
      borderRadius: '4px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      minHeight: '123px'
    },
    selectChipAreaError: {
      border: '1px solid #d32f2f'
    },
    selectChipInnerArea: {
      padding: '10px'
    },
    errorRadioBoxHelpText: {
      paddingLeft: '4px',
      paddingBottom: '1em',
      color: '#d32f2f'
    },
    addKeyWordField: {
      width: '100%'
    },
    addKeyWordFieldAddButton: {
      cursor: 'pointer'
    },
    keyWordChip: {
      marginRight: '5px',
      marginTop: '5px'
    },
    keyWordChipWarning: {
      border: '1px solid rgba(234,170,0,1)'
    },
    languageLabelContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    languageDeleteButton: {
      padding: '0px',
      minWidth: '20px',
      width: '20px'
    },
    languageDeleteIcon: {
      fontSize: '18px'
    },
    iamgeSizeWarning: {
      color: 'rgba(234,170,0,1)',
      fontWeight: 400,
      fontSize: '14px',
      marginBottom: '5px'
    },
    datePicker: {
      width: '100%',
      backgroundColor: 'transparent !important',
      '& button:hover': {
        backgroundColor: 'transparent'
      },
      '& label': {
        fontSize: '13px'
      },
      '& input': {
        fontSize: '13px'
      }
    }
  }))
}
