import React, { useState } from 'react'
import { ISelfServiceRequest, RequestComment } from 'models/SelfServiceRequest'
import { IconButton, TextField } from '@mui/material'
import { getStylesCommentArea } from 'styles/requests/commentArea'
import { dateFormatOptions } from 'constants/constants'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import createDOMPurify from 'dompurify'

export interface IEditFormProps {
  item: ISelfServiceRequest
  newComment: RequestComment
}

export default function CommentAreaForm(props: IEditFormProps): JSX.Element {
  const { item, newComment } = props
  const [expand, setExpand] = useState(false)
  const classes = getStylesCommentArea()
  const maxShownCommentLength = 3
  const DOMPurify = createDOMPurify(window)
  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())

  return (
    <>
      <TextField
        variant="standard"
        id="comments"
        className={classes.tab_item}
        label="Comments"
        defaultValue={newComment.text}
        onChange={(event) => {
          if (newComment)
            newComment.text = DOMPurify.sanitize(event.target.value, {
              USE_PROFILES: { html: false }
            })
        }}
        InputLabelProps={{
          shrink: true
        }}
        onBlur={() => {
          setCurrentFormState(new Date().getTime())
        }}
        maxRows={5}
        multiline={true}
        key={`text_${currentFormState}`}
      />
      <div className={classes.commentContainer}>
        <div className={classes.expandButton}>
          {maxShownCommentLength <
            (item.comments ? item.comments.length : 0) && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setExpand(!expand)}
            >
              {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </div>
        {item.comments
          ?.slice(0, expand ? item.comments?.length : maxShownCommentLength)
          .map((comment: RequestComment, index: number) => {
            return (
              <div className={classes.commentItemContainer} key={index}>
                <div className={classes.commentMetaData}>{`${
                  comment.modifiedByDisplayName
                } - ${new Intl.DateTimeFormat(
                  'en-US',
                  dateFormatOptions
                ).format(new Date(comment.modifiedDate))}`}</div>
                <div
                  className={classes.commentBody}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(comment.text).replace(
                      /\n/g,
                      '</br>'
                    )
                  }}
                ></div>
              </div>
            )
          })}
      </div>
    </>
  )
}
