import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  defaultSelfServiceRequest,
  ISelfServiceRequest,
  ISelfServiceUpdateResponse,
  RequestComment,
  RequestStatus,
  RequestType
} from 'models/SelfServiceRequest'

import { AdsResult } from 'models/AdsResult'
import SelfServiceStore from 'store/SelfService'
import { connect } from 'react-redux'
import {
  generateShareMail,
  prepareHistoryInformation,
  updateBaseProperties
} from 'utils/admin/selfServiceUtils'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import CloseIcon from '@mui/icons-material/Close'
import EditFormAdWordSteps from './EditFormAdWordSteps'
import EditFormFeaturedResultSteps from './EditFormFeaturedResultSteps'
import {
  INotificatonRequest,
  getNotificationRequest
} from 'models/NotificationTypes'
import { FeaturedResult } from 'models/FeaturedResult'
import EditFormSpellingSuggestionSteps from './EditFormSpellingSuggestionSteps'
import { DidYouMeanItem } from 'models/DidYouMean'
import { Share } from '@mui/icons-material'

export interface IEditFormRequestBaseProps {
  rowData: ISelfServiceRequest
  isOpen: boolean
  isNewItem: boolean
  handleClose: () => void
  handleRowUpdate: (newData: ISelfServiceRequest, delayClose?: boolean) => void
  toggleSnackbarVisibility: (visible: boolean) => void
  setSnackbarMessage: (message: string) => void
}

type IEditFormRequestProps = ReturnType<typeof mapDispatchToProps> &
  IEditFormRequestBaseProps

function EditFormRequestBase(props: IEditFormRequestProps): JSX.Element {
  const {
    rowData,
    isOpen,
    isNewItem,
    handleClose,
    handleRowUpdate,
    upsertMyRequest,
    toggleSnackbarVisibility,
    setSnackbarMessage
  } = props

  const [referenceRowData, setRefereneRowData] = useState<ISelfServiceRequest>(
    defaultSelfServiceRequest
  )
  const [activeStep, setActiveStep] = useState(0)
  const [hasActiveChanges, setHasActiveChanges] = useState(false)
  const hasChanges = useRef(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [hasImageChange, setHasImageChange] = useState(false)
  const [loading, setLoading] = useState(false)
  const classes = getStylesAdminForm()
  const intl = useIntl()

  useEffect(() => {
    const deepCopy = JSON.parse(
      JSON.stringify(rowData ? rowData : defaultSelfServiceRequest)
    )
    setRefereneRowData(deepCopy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePageClose = (event: BeforeUnloadEvent) => {
    if (hasChanges.current) {
      event.returnValue =
        'Are you sure you want to leave this page? All changes will be lost.'
    }
  }

  const setHasChanges = (newValue: boolean) => {
    setHasActiveChanges(newValue)
    hasChanges.current = newValue
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handlePageClose)

    return () => {
      window.removeEventListener('beforeunload', handlePageClose)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setBaseProperties = () => {
    referenceRowData.modifiedDate = new Date().toISOString()
    referenceRowData.createdDate = referenceRowData.createdDate
      ? referenceRowData.createdDate
      : new Date().toISOString()
    referenceRowData.upn = referenceRowData.upn
      ? referenceRowData.upn
      : ESSettingsGlobalVariables.getUPN()
    referenceRowData.modifiedBy = ESSettingsGlobalVariables.getDisplayName()
    referenceRowData.createdBy = referenceRowData.createdBy
      ? referenceRowData.createdBy
      : ESSettingsGlobalVariables.getDisplayName()
  }

  const saveItem = (
    newStatus?: RequestStatus,
    newComment?: RequestComment | null,
    orgItem?: AdsResult[] | FeaturedResult | DidYouMeanItem,
    isNewItem?: boolean
  ) => {
    setLoading(true)
    const orgRequestItem = Object.assign({}, referenceRowData)

    if (referenceRowData.requestType === RequestType.AdWord) {
      const adItemList = referenceRowData.itemData as AdsResult[]
      const newItemList: AdsResult[] = []
      adItemList.forEach((item: AdsResult) => {
        newItemList.push({
          ...adItemList[0],
          ...{
            language: item.language,
            title: item.title ? item.title : adItemList[0].title,
            text: item.text ? item.text : adItemList[0].text,
            link_text: item.link_text
              ? item.link_text
              : adItemList[0].link_text,
            countries:
              item.countries.length === 0 ? ['All'] : adItemList[0].countries,
            functions:
              item.functions.length === 0 ? ['All'] : adItemList[0].functions
          }
        })
      })

      referenceRowData.itemData = newItemList
    }

    setBaseProperties()

    const notificationRequest = getNotificationRequest(
      referenceRowData,
      newStatus,
      newComment && newComment.text ? newComment.text : undefined
    )

    if (newStatus) {
      if (orgItem && newStatus === RequestStatus.Submitted) {
        prepareHistoryInformation(
          referenceRowData,
          hasImageChange,
          isNewItem ? isNewItem : false,
          orgItem
        )
      }

      referenceRowData.status = newStatus
    }

    updateBaseProperties(referenceRowData, true)

    upsertMyRequest(
      referenceRowData,
      notificationRequest,
      (isNewItem && referenceRowData.requestType === RequestType.AdWord) ||
        hasImageChange
    ).then((upsertResponse: ISelfServiceUpdateResponse) => {
      setLoading(false)
      if (!upsertResponse.hasError) {
        if (upsertResponse.etag) {
          referenceRowData._etag = upsertResponse.etag
        }

        handleRowUpdate(referenceRowData, true)
        if (upsertResponse.imageError) {
          setSnackbarMessage(
            intl.formatMessage({
              id: 'form_message_image_failed',
              defaultMessage: 'The image upload failed.'
            })
          )
        } else if (newStatus) {
          setSnackbarMessage(
            intl.formatMessage({
              id: 'form_message_submit_success',
              defaultMessage: 'Successfully submitted the request.'
            })
          )
        } else {
          setSnackbarMessage(
            intl.formatMessage({
              id: 'form_message_save_success',
              defaultMessage: 'Successfully saved the request.'
            })
          )
        }

        toggleSnackbarVisibility(true)
      } else {
        setRefereneRowData(orgRequestItem)
        if (upsertResponse.status === 412) {
          setSnackbarMessage(
            intl.formatMessage({
              id: 'form_message_save_conflict',
              defaultMessage:
                'Save Conflict: Your changes conflict with those made concurrently by another user. If you want your changes to be applied, refresh the page and resubmit your changes.'
            })
          )
        } else if (newStatus) {
          setSnackbarMessage(
            intl.formatMessage({
              id: 'form_message_submit_error',
              defaultMessage: 'There was an error submitting the request.'
            })
          )
        } else {
          setSnackbarMessage(
            intl.formatMessage({
              id: 'form_message_save_error',
              defaultMessage: 'There was an error saving the request.'
            })
          )
        }

        toggleSnackbarVisibility(true)
      }
    })
  }

  const getDialogTitle = (): JSX.Element => {
    switch (referenceRowData.requestType) {
      case RequestType.AdWord:
        switch (activeStep) {
          case 0:
            return (
              <FormattedMessage
                id="form_adword_headline_basic"
                defaultMessage="Basic Details"
              />
            )
          case 1:
            return (
              <FormattedMessage
                id="form_adword_headline_advanced"
                defaultMessage="Advanced Details"
              />
            )
          case 2:
            return (
              <FormattedMessage
                id="form_adword_headline_translations"
                defaultMessage="Translations"
              />
            )
          case 3:
            return (
              <FormattedMessage
                id="form_adword_headline_scope"
                defaultMessage="AdWord Scope"
              />
            )
          default:
            return isNewItem ? (
              <FormattedMessage
                id="form_adword_new"
                defaultMessage="New Adword"
              />
            ) : (
              <FormattedMessage
                id="form_adword_edit"
                defaultMessage="Edit Adword"
              />
            )
        }
      case RequestType.FeaturedResult:
        switch (activeStep) {
          case 0:
            return (
              <FormattedMessage
                id="form_featured_headline_basic"
                defaultMessage="Basic Details"
              />
            )
          case 1:
            return (
              <FormattedMessage
                id="form_featured_headline_advanced"
                defaultMessage="Advanced Details"
              />
            )
          case 2:
            return (
              <FormattedMessage
                id="form_featured_headline_scope"
                defaultMessage="Featured Result Scope"
              />
            )
          default:
            return isNewItem ? (
              <FormattedMessage
                id="form_featured_new"
                defaultMessage="New Featured Result"
              />
            ) : (
              <FormattedMessage
                id="form_featured_edit"
                defaultMessage="Edit Featured Result"
              />
            )
        }
      case RequestType.SpellingSuggestion:
        switch (activeStep) {
          default:
            return isNewItem ? (
              <FormattedMessage
                id="form_spellingsuggestion_new"
                defaultMessage="New 'Did You Mean' Suggestion"
              />
            ) : (
              <FormattedMessage
                id="form_spellingsuggestion_edit"
                defaultMessage="Edit 'Did You Mean' Suggestion"
              />
            )
        }
    }
  }

  const getDialogContent = () => {
    switch (referenceRowData.requestType) {
      case RequestType.AdWord:
        return (
          <>
            <EditFormAdWordSteps
              item={referenceRowData}
              isNewItem={isNewItem}
              saveItem={saveItem}
              setItemList={updateAdWordItemDataInRequest}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              hasActiveChanges={hasActiveChanges}
              setHasActiveChanges={setHasChanges}
              setHasImageChange={setHasImageChange}
              loading={loading}
            />
          </>
        )
      case RequestType.FeaturedResult:
        return (
          <>
            <EditFormFeaturedResultSteps
              item={referenceRowData}
              isNewItem={isNewItem}
              saveItem={saveItem}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              hasActiveChanges={hasActiveChanges}
              setHasActiveChanges={setHasChanges}
              loading={loading}
            />
          </>
        )
      case RequestType.SpellingSuggestion:
        return (
          <>
            <EditFormSpellingSuggestionSteps
              item={referenceRowData}
              isNewItem={isNewItem}
              saveItem={saveItem}
              hasActiveChanges={hasActiveChanges}
              setHasActiveChanges={setHasChanges}
              loading={loading}
            />
          </>
        )
      default:
        return <></>
    }
  }

  const handleCloseWithConfirm = () => {
    if (hasActiveChanges) {
      setConfirmDialogOpen(true)
    } else {
      handleClose()
    }
  }

  const updateAdWordItemDataInRequest = (newList: AdsResult[]) => {
    referenceRowData.itemData = newList
    setRefereneRowData(referenceRowData)
  }

  const handleSharing = (rowData: ISelfServiceRequest): void => {
    const mailInfo = generateShareMail(rowData)
    window.location.href = `mailto:?body=${mailInfo.body}&subject=${mailInfo.subject}`
  }

  return (
    <>
      <Dialog
        open={isOpen && rowData !== null}
        onClose={(event: object, reason: string) => {
          if (reason !== 'backdropClick') handleCloseWithConfirm()
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="confirmation-modal-title">
          <div className={classes.dialogHeader}>
            <div>{getDialogTitle()}</div>
            <div style={{ display: 'flex' }}>
              <div>
                <Tooltip
                  title={intl.formatMessage({
                    id: 'table_actions_share_request',
                    defaultMessage: 'Share'
                  })}
                >
                  <IconButton
                    onClick={() => handleSharing(referenceRowData)}
                    className={classes.detailSelectionContainer}
                  >
                    <Share />
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <IconButton
                  onClick={handleCloseWithConfirm}
                  className={classes.detailSelectionContainer}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>{getDialogContent()}</DialogContent>
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle id="editform_confirm_dialog">
          {intl.formatMessage({
            id: 'form_confirm_title',
            defaultMessage: 'There are active changes!'
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="editform_confirm_dialog_description">
            {intl.formatMessage({
              id: 'form_confirm_message',
              defaultMessage:
                'Are you sure you want to close the request form? All changes will be lost.'
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDialogOpen(false)}
            color="primary"
            autoFocus
          >
            {intl.formatMessage({
              id: 'form_button_no',
              defaultMessage: 'No'
            })}
          </Button>
          <Button
            onClick={() => {
              setConfirmDialogOpen(false)
              handleClose()
            }}
            color="primary"
          >
            {intl.formatMessage({
              id: 'form_button_yes',
              defaultMessage: 'Yes'
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    upsertMyRequest: (
      request: ISelfServiceRequest,
      notificationRequest: INotificatonRequest,
      imageUpdated: boolean
    ) =>
      dispatch(
        SelfServiceStore.actions.upsertMyRequest(
          request,
          notificationRequest,
          imageUpdated
        )
      )
  }
}

export default connect(null, mapDispatchToProps)(EditFormRequestBase)
