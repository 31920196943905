import { AdsResult, intialAdsResult } from 'models/AdsResult'
import { ContactUsTile, initialContactUsTile } from 'models/ContactUsTile'
import { FeaturedResult, intialFeaturedResult } from 'models/FeaturedResult'
import { initialPopupResult, PopupResult } from 'models/PopupResult'
import { initialSearchTip, ISearchTip } from 'models/SearchTip'

/**
 * Filter the "search term" input fields
 * @param input
 * @returns
 */
export const filterSearchTermInput = (input: string) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /[`~!#$§%^*()|\=?;:'".<>\{\}\[\]\\\/]/gi
  return input.replace(regex, '')
}

/**
 * Fill adwords with default values
 * @param ads
 */
export const updateAdDefaultValues = (ads: AdsResult[] | AdsResult) => {
  if (Array.isArray(ads)) {
    ads.forEach((ad: AdsResult) => {
      doUpdateAdDefaultValues(ad)
    })
  } else {
    doUpdateAdDefaultValues(ads)
  }
}

const doUpdateAdDefaultValues = (ad: AdsResult) => {
  if (!ad.language) ad.language = intialAdsResult.language
  if (!ad.title) ad.title = intialAdsResult.title
  if (!ad.text) ad.text = intialAdsResult.text
  if (!ad.link) ad.link = intialAdsResult.link
  if (!ad.link_text) ad.link_text = intialAdsResult.link_text
  if (!ad.site_url) ad.site_url = intialAdsResult.site_url
  if (!ad.start) ad.start = intialAdsResult.start
  if (!ad.end) ad.end = intialAdsResult.end
  if (!ad.rank) ad.rank = intialAdsResult.rank

  if (!ad.search_terms || ad.search_terms.length === 0)
    ad.search_terms = intialAdsResult.search_terms

  if (!ad.rank) ad.rank = intialAdsResult.rank

  if (!ad.sources || ad.sources.length === 0)
    ad.sources = intialAdsResult.sources

  if (!ad.countries || ad.countries.length === 0)
    ad.countries = intialAdsResult.countries

  if (!ad.functions || ad.functions.length === 0)
    ad.functions = intialAdsResult.functions

  if (!ad.match_type) ad.match_type = intialAdsResult.match_type
  if (!ad.flavour) ad.flavour = intialAdsResult.flavour
  if (!ad.scope) ad.scope = intialAdsResult.scope
}

/**
 * Fill featured results with default values
 * @param frs
 */
export const updateFeaturedResultDefaultValues = (
  frs: FeaturedResult[] | FeaturedResult
) => {
  if (Array.isArray(frs)) {
    frs.forEach((fr: FeaturedResult) => {
      doUpdateFeaturedResultDefaultValues(fr)
    })
  } else {
    doUpdateFeaturedResultDefaultValues(frs)
  }
}

const doUpdateFeaturedResultDefaultValues = (fr: FeaturedResult) => {
  if (!fr.BestBetTitle) fr.BestBetTitle = intialFeaturedResult.BestBetTitle
  if (!fr.BestBetUrl) fr.BestBetUrl = intialFeaturedResult.BestBetUrl

  if (!fr.BestBetDescription)
    fr.BestBetDescription = intialFeaturedResult.BestBetDescription

  if (!fr.BestBetRank) fr.BestBetRank = intialFeaturedResult.BestBetRank

  if (!fr.BestBetStartDate)
    fr.BestBetStartDate = intialFeaturedResult.BestBetStartDate

  if (!fr.BestBetEndDate)
    fr.BestBetEndDate = intialFeaturedResult.BestBetEndDate

  if (!fr.BestBetSiteUrl)
    fr.BestBetSiteUrl = intialFeaturedResult.BestBetSiteUrl

  if (!fr.BestBetKeywords)
    fr.BestBetKeywords = intialFeaturedResult.BestBetKeywords

  if (!fr.BestBetCountry || fr.BestBetCountry.length === 0)
    fr.BestBetCountry = intialFeaturedResult.BestBetCountry

  if (!fr.BestBetCity || fr.BestBetCity.length === 0)
    fr.BestBetCity = intialFeaturedResult.BestBetCity

  if (!fr.BestBetFunction || fr.BestBetFunction.length === 0)
    fr.BestBetFunction = intialFeaturedResult.BestBetFunction

  if (!fr.BestBetMatchType)
    fr.BestBetMatchType = intialFeaturedResult.BestBetMatchType

  if (!fr.BestBetDataSources || fr.BestBetDataSources.length === 0)
    fr.BestBetDataSources = intialFeaturedResult.BestBetDataSources

  if (!fr.BestBetScope) fr.BestBetScope = intialFeaturedResult.BestBetScope
  if (!fr.type) fr.type = intialFeaturedResult.type
}

/**
 * Fill popups with default values
 * @param pos
 */
export const updatePopupDefaultValues = (pos: PopupResult[] | PopupResult) => {
  if (Array.isArray(pos)) {
    pos.forEach((po: PopupResult) => {
      doUpdatePopupDefaultValues(po)
    })
  } else {
    doUpdatePopupDefaultValues(pos)
  }
}

const doUpdatePopupDefaultValues = (popup: PopupResult) => {
  if (!popup.title) popup.title = initialPopupResult.title
  if (!popup.image) popup.image = initialPopupResult.image
  if (!popup.content) popup.content = initialPopupResult.content
  if (!popup.language) popup.language = initialPopupResult.language
  if (!popup.button_link) popup.button_link = initialPopupResult.button_link
  if (!popup.button_text) popup.button_text = initialPopupResult.button_text

  if (!popup.sources || popup.sources.length === 0)
    popup.sources = initialPopupResult.sources

  if (!popup.functions || popup.functions.length === 0)
    popup.functions = initialPopupResult.functions

  if (!popup.countries || popup.countries.length === 0)
    popup.countries = initialPopupResult.countries

  if (!popup.display) popup.display = initialPopupResult.display
  if (!popup.dateStart) popup.dateStart = initialPopupResult.dateStart
  if (!popup.dateEnd) popup.dateEnd = initialPopupResult.dateEnd

  if (!popup.intervalHours)
    popup.intervalHours = initialPopupResult.intervalHours
  if (!popup.scope) popup.scope = initialPopupResult.scope
}

/**
 * Fill contact us tiles with default values
 */
export const updateContactUsTileDefaultValues = (
  contactUsTiles: ContactUsTile[] | ContactUsTile
) => {
  if (Array.isArray(contactUsTiles)) {
    contactUsTiles.forEach((contactUsTile: ContactUsTile) => {
      doUpdateContactUsTileDefaultValue(contactUsTile)
    })
  } else {
    doUpdateContactUsTileDefaultValue(contactUsTiles)
  }
}

const doUpdateContactUsTileDefaultValue = (contactUsTile: ContactUsTile) => {
  if (!contactUsTile.language)
    contactUsTile.language = initialContactUsTile.language
  if (!contactUsTile.title) contactUsTile.title = initialContactUsTile.title
  if (!contactUsTile.description)
    contactUsTile.description = initialContactUsTile.description
  if (!contactUsTile.link) contactUsTile.link = initialContactUsTile.link
  if (!contactUsTile.image) contactUsTile.image = initialContactUsTile.image
  if (!contactUsTile.isActive)
    contactUsTile.isActive = initialContactUsTile.isActive
  if (!contactUsTile.order) contactUsTile.order = initialContactUsTile.order
  if (!contactUsTile.scope) contactUsTile.scope = initialContactUsTile.scope
}

/**
 * Fill search tips with default values
 */
export const updateSearchTipDefaultValues = (
  searchTips: ISearchTip[] | ISearchTip
) => {
  if (Array.isArray(searchTips)) {
    searchTips.forEach((contactUsTile: ISearchTip) => {
      doUpdateSearchTipDefaultValue(contactUsTile)
    })
  } else {
    doUpdateSearchTipDefaultValue(searchTips)
  }
}

const doUpdateSearchTipDefaultValue = (searchTips: ISearchTip) => {
  if (!searchTips.language) searchTips.language = initialSearchTip.language
  if (!searchTips.title) searchTips.title = initialSearchTip.title
  if (!searchTips.description)
    searchTips.description = initialSearchTip.description
  if (!searchTips.link) searchTips.link = initialSearchTip.link
  if (!searchTips.isActive) searchTips.isActive = initialSearchTip.isActive
  if (!searchTips.order) searchTips.order = initialSearchTip.order
  if (!searchTips.scope) searchTips.scope = initialSearchTip.scope
}
