import { Store } from '..'
import { IAdminPagesAuthorization } from 'utils/authorization'
import { IAADState } from './reducers'

const selectors = {
  getAccessToken: (state: Store): any => state.auth.aad.accessToken,
  getAADInfo: (state: Store): IAADState => state.auth.aad,
  getAdminPagesAuthorizationFetched: (state: Store): boolean =>
    state.auth.adminPagesAuthorizationFetched,
  getAdminPagesAuthorization: (state: Store): IAdminPagesAuthorization =>
    state.auth.adminPagesAuthorization
}

export default selectors
