import { DidYouMeanItem } from 'models/DidYouMean'
import { Store } from '..'

const selectors = {
  getDidYouMeanItems: (state: Store): DidYouMeanItem[] =>
    state.didYouMean.didYouMeanItems,
  hasBeenFetched: (state: Store): boolean => state.didYouMean.hasBeenFetched,
  hasError: (state: Store): boolean => state.didYouMean.hasError,
  hasChangesError: (state: Store): boolean => state.didYouMean.hasChangesError
}

export default selectors
