import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterBar(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    root: {
      marginTop: '94px',
      // [`${theme.breakpoints.down(1024)}`]: {
      //   marginTop: '100px',
      //   marginBottom: '20px',
      //   padding: '0 0px'
      // },
      [`${theme.breakpoints.down(1024)}`]: {
        marginTop: '10px',
        marginBottom: '0px',
        padding: '0 0px'
      },
      '@media (orientation: portrait) ': {},
      '@media (orientation: landscape)': {}
    },
    gridList: {
      zIndex: 2,
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
      backgroundColor: 'white',
      borderBottom: 'solid 1px #dfe1e5',
      overflow: 'hidden',
      overflowX: 'auto',
      flexGrow: 0,
      flexShrink: 0,
      '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,

      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      // [`${theme.breakpoints.down(1024)}`]: {
      //   display: 'flex',
      //   flex: 1
      // },
      [`${theme.breakpoints.down(1024)}`]: {
        marginBottom: 0,
        flexWrap: 'nowrap',
        display: 'flex',
        overflowX: 'auto',
        borderBottom: 'none',
        maxHeight: '30px',
        boxShadow: '0 3px 3px rgba(32, 33, 36, 0.1)',
        alignItems: 'flex-end'
      }
    },
    spaceLeft: {
      marginBottom: 0,
      // [`${theme.breakpoints.down(1024)}`]: {
      //   marginLeft: 155
      // },
      [`${theme.breakpoints.down(1024)}`]: {
        marginLeft: '0px'
      },
      [`${theme.breakpoints.down(401)}`]: {
        marginLeft: '0px'
      }
    },
    spaceLeftTwo: {
      marginLeft: 163,
      // [`${theme.breakpoints.down(1024)}`]: {
      //   marginLeft: 163,
      //   marginBottom: '2vw'
      // },
      [`${theme.breakpoints.down(1024)}`]: {
        marginLeft: '8px',
        marginBottom: '16px'
      },
      [`${theme.breakpoints.down(501)}`]: {
        marginLeft: '8px'
      },
      [`${theme.breakpoints.down(401)}`]: {
        marginLeft: '8px'
      }
    },
    iconMenu: {
      width: '3px'
    },
    iconContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      overflowX: 'auto'
    },
    endIconContainer: {
      margin: 0,
      padding: 0,
      display: 'flex',
      flexWrap: 'nowrap',
      marginLeft: '5px'
    },
    startIconContainer: {
      marginLeft: 155,
      [`${theme.breakpoints.down(1024)}`]: {
        marginLeft: 0
      },
      [`${theme.breakpoints.down(1024)}`]: {
        marginLeft: 10
      },
      margin: 0,
      padding: 0,
      display: 'flex',
      flexWrap: 'nowrap'
    },
    seperator: {
      display: 'none',
      [`${theme.breakpoints.down(1024)}`]: {
        borderRight: '1px solid lightgrey',
        display: 'inline-block'
      }
    },
    icon: {
      float: 'left',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'none'
      }
    },
    resultContainer: {
      flex: 1,
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'wrap',
      overflowY: 'hidden',
      overflowX: 'auto',
      marginTop: '8px',
      width: 'unset',
      [`${theme.breakpoints.up(825)}`]: {
        height: '50px',
        marginTop: '0px'
      }
    },
    resultContainerOnDesktop: {
      flexDirection: 'column',
      height: '50px',
      marginLeft: '163px',
      marginTop: '0px'
    },
    resultContainerVisible: {
      height: '50px'
    },
    result: {
      zIndex: 1,
      flex: 1,
      height: '50px',
      lineHeight: '50px',
      color: 'grey',
      fontSize: 14,

      display: 'inline-block',
      whiteSpace: 'nowrap',
      position: 'absolute',
      [`${theme.breakpoints.down(1024)}`]: {
        marginLeft: 15
      }
    },
    toolBox: {
      zIndex: 1,
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      flexWrap: 'nowrap',
      overflowX: 'auto',
      overflowY: 'hidden',
      height: 50,

      [`${theme.breakpoints.down(1024)}`]: {
        marginLeft: '16px',
        marginRight: 15,
        fontSize: 12
      },
      [`${theme.breakpoints.down(411)}`]: {
        marginLeft: '16px',
        marginRight: 15,
        fontSize: 12
      },
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    toolContainer: {
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      msUserSelect: 'none',
      flex: '1 0 21%',
      marginLeft: 10,
      color: 'lightgrey',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: 12,
        marginTop: 0
      },
      [`${theme.breakpoints.down(411)}`]: {
        fontSize: 12,
        marginLeft: 10,
        marginTop: 5,
        marginBottom: 5
      }
    },
    toolsButton: {
      textTransform: 'none',
      fontSize: 13,
      cursor: 'pointer',
      marginTop: 3,
      marginBottom: 3,
      [`${theme.breakpoints.down(1024)}`]: {
        marginRight: 5,
        fontSize: 12,
        marginTop: 0,
        marginBottom: 0
      },
      [`${theme.breakpoints.down(411)} and (orientation: portrait)`]: {
        marginLeft: 10,
        marginRight: 5,
        fontSize: 12
      }
    },
    countryNotification: {
      width: '50%',
      flexShrink: 0,
      overflow: 'hidden',
      marginLeft: 'auto',
      textAlign: 'right',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'none'
      }
    },
    resetButton: {
      textDecoration: 'none',
      color: '#005eb8',
      display: 'inline',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    placeholder: {
      color: 'grey',
      fontSize: '13px',
      marginTop: '16px'
    },
    clearFilterButton: {
      paddingRight: 30,
      color: 'grey',
      fontSize: '13px',
      margin: '15px 0',
      cursor: 'pointer',
      whiteSpace: 'nowrap'
    },
    pinIcon: {
      height: '16px',
      width: '16px',
      margin: 'auto 0',
      marginLeft: 16
    }
  }))
}
