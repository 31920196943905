import { adFlavour, adminScopes } from 'constants/adminSettingTypes'

export type AdsResult = {
  id: string
  language: string
  title: string
  text: string
  link: string
  link_text: string
  image: string
  start?: string | null
  end?: string | null
  site_url: string
  search_terms: string[]
  rank: number
  sources: string[]
  functions: string[]
  countries: string[]
  match_type: string
  flavour?: adFlavour
  foundBySearchTerm?: boolean
  scope?: adminScopes
  requestId?: string
  requestUser?: string
  draft?: boolean
  childs?: AdsResult[]
  mapToRequestItem?: boolean
}

export const intialAdsResult: AdsResult = {
  id: '',
  language: 'en',
  title: '',
  text: '',
  link: '',
  link_text: '',
  site_url: '',
  image: '',
  start: null,
  end: null,
  search_terms: [],
  rank: 0,
  sources: ['All'],
  functions: ['All'],
  countries: ['All'],
  match_type: 'Contains',
  flavour: 'small',
  scope: 'All'
}
