import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField
} from '@mui/material'
import PeopleSearch from 'components/contents/common/PeopleSearch'
import { IPeopleResult } from 'models/People'
import {
  ISelfServiceAdminReAssignResponse,
  ISelfServiceRequest
} from 'models/SelfServiceRequest'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import { reAssignRequests } from 'store/SelfService/actions'
import { getStylesAdminReAssignConfiguration } from 'styles/admin/AdminReAssignConfiguration'
import AuthStore from 'store/Auth'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'

interface IAdminReAssignConfigurationProps {
  setShowReAssignConfiguration: (showAlert: boolean) => void
  showReAssignConfiguration: boolean
  itemsToReAssign: ISelfServiceRequest[]
  closeAndReset: () => void
}

type IAllAdminReAssignConfigurationProps = ReturnType<
  typeof mapDispatchToProps
> &
  IAdminReAssignConfigurationProps &
  ReturnType<typeof mapStateToProps>

function AdminReAssignConfiguration(
  props: IAllAdminReAssignConfigurationProps
): JSX.Element {
  const {
    setShowReAssignConfiguration,
    showReAssignConfiguration,
    itemsToReAssign,
    reAssignItemsLocal,
    closeAndReset,
    aadInfo
  } = props

  const [selectedUser, setSelectedUser] = useState<IPeopleResult | null>(null)
  const [showPeoplePicker, setShowPeoplePicker] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] =
    useState<ISelfServiceAdminReAssignResponse | null>(null)

  const classes = getStylesAdminReAssignConfiguration()

  const handleReAssignConfirm = () => {
    setShowConfirmDialog(true)
  }

  const handleReAssignItems = () => {
    setShowConfirmDialog(false)

    if (!selectedUser) {
      return
    }

    setLoading(true)

    reAssignItemsLocal(
      itemsToReAssign,
      selectedUser.userPrincipalName,
      selectedUser.displayName
    ).then((response: ISelfServiceAdminReAssignResponse) => {
      setLoading(false)

      setResponse(response)
    })
  }

  const getAssignedUsers = () => {
    const assignedUsers: string[] = []

    itemsToReAssign.forEach((request) => {
      if (assignedUsers.indexOf(request.upn) === -1) {
        assignedUsers.push(request.upn)
      }
    })

    return assignedUsers
  }

  const setUserValue = (user: IPeopleResult) => {
    setSelectedUser(user)
    setShowPeoplePicker(false)
  }

  return (
    <>
      {showReAssignConfiguration && (
        <Dialog
          open={showReAssignConfiguration}
          onClose={() => setShowReAssignConfiguration(false)}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="admin-reassign-settings-tile">
            {!response
              ? 'Configure and reassign selected request(s)'
              : `Reassign requests to '${
                  selectedUser ? selectedUser.displayName : ''
                }'`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!itemsToReAssign || itemsToReAssign.length < 1 ? (
                <div style={{ position: 'relative' }}>
                  No Feature result or AdWord is selected
                </div>
              ) : (
                <div style={{ position: 'relative', minHeight: '100px' }}>
                  {!response && (
                    <>
                      {showPeoplePicker && (
                        <PeopleSearch
                          aadInfo={aadInfo}
                          handleClose={() => {
                            setShowPeoplePicker(false)
                          }}
                          addUser={setUserValue}
                          forcedTitle={'Select user to reassign request(s)'}
                          forcedAddButtonLabel={'Select'}
                        />
                      )}
                      {!loading && (
                        <TextField
                          variant="outlined"
                          size="small"
                          id="displayName"
                          className={classes.tab_item}
                          label="Selected New User"
                          value={selectedUser ? selectedUser.displayName : ''}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className={classes.addUserButton}
                                onClick={() => {
                                  setShowPeoplePicker(true)
                                }}
                              >
                                <PersonSearchIcon />
                              </InputAdornment>
                            )
                          }}
                          style={{ marginTop: '10px' }}
                          disabled={true}
                        />
                      )}
                      {loading && (
                        <div className={classes.overlaySpinner}>
                          <div className={classes.overlaySpinnerText}>
                            Running
                          </div>
                          <CircularProgress size={80} />
                        </div>
                      )}
                    </>
                  )}
                  {response && (
                    <div>
                      <div>
                        <div style={{ fontWeight: 'bold' }}>
                          Successful changes:
                        </div>
                        <div>
                          <ul>
                            {itemsToReAssign
                              .filter(
                                (request: ISelfServiceRequest) =>
                                  !response.failedItems.some(
                                    (failedRequest: any) =>
                                      failedRequest.id === request.id &&
                                      failedRequest.upn === request.upn
                                  )
                              )
                              .map((request: ISelfServiceRequest) => {
                                return (
                                  <li>{`Id: ${request.id} - Created By: ${request.upn}`}</li>
                                )
                              })}
                          </ul>
                        </div>
                      </div>
                      <div>
                        <div style={{ fontWeight: 'bold' }}>
                          Failed changes:
                        </div>
                        <div>
                          <ul>
                            {response.failedItems.map((request: any) => {
                              return (
                                <li>{`Id: ${request.id} - Created By: ${request.upn}`}</li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!response && (
              <>
                <Button
                  onClick={() => setShowReAssignConfiguration(false)}
                  color="primary"
                  autoFocus
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleReAssignConfirm()}
                  color="primary"
                  disabled={
                    loading ||
                    !selectedUser ||
                    !itemsToReAssign ||
                    itemsToReAssign.length < 1
                  }
                >
                  Submit
                </Button>
              </>
            )}
            {response && (
              <Button onClick={() => closeAndReset()} color="primary" autoFocus>
                Close
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
      {showConfirmDialog && (
        <Dialog
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
        >
          <DialogTitle id="admin_change_confirm_dialog">
            {'Confirm reassign selected items!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>
                <div>
                  {getAssignedUsers().length > 1
                    ? `Are you sure to reassign items from different users (${getAssignedUsers().join(
                        ', '
                      )})`
                    : 'Are you sure you want to reassign the selected'}
                </div>
                <div>
                  <ul>
                    {itemsToReAssign.map((request: ISelfServiceRequest) => {
                      return (
                        <li>{`Id: ${request.id} - Created By: ${request.upn}`}</li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowConfirmDialog(false)}
              color="primary"
              autoFocus
            >
              No
            </Button>
            <Button onClick={() => handleReAssignItems()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    reAssignItemsLocal: (
      itemsToReassign: ISelfServiceRequest[],
      upn: string,
      displayName: string
    ) => dispatch(reAssignRequests(itemsToReassign, upn, displayName))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReAssignConfiguration)
