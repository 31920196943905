import React, { useState, useEffect, useRef, SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import TranslationsStore from 'store/Translations'
import ConfirmationModal from 'components/contents/common/Dialog'
import {
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarCloseReason
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'
import { AdminItemChange } from 'models/AdminItemChange'
import { supportedLanguages } from 'constants/supportedLanguages'
import EditFormBase from '../common/EditFormBase'
import {
  SingleTranslationResult,
  TranslationResult
} from 'models/TranslationResult'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import TranslationsTable from './TranslationsTable'
import { TableStateTranslations } from 'models/TableStates'
import { deleteItemAndUpdateState } from 'utils/admin/adminTableState'

export interface TranslationsProps {
  adminSettingType: adminSettingTypes
  resetChanges: boolean
  setHasChanges: (changes: boolean) => void
}

type AllTranslationsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  TranslationsProps

function Translations(props: AllTranslationsProps): JSX.Element {
  const {
    fetchAllGlobalTranslations,
    fetchAllGlobalOITranslations,
    fetchAllSettingsTranslations,
    deleteTranslation,
    upsertTranslation,
    hasAllTranslationsBeenFetched,
    hasOITranslationsBeenFetched,
    hasSettingsTranslationsBeenFetched,
    hasChangesBeenApplied,
    hasTranslationChangesError,
    upsertVersion,
    hasUpdateVersionError,
    adminSettingType,
    allTranslations,
    allOITranslations,
    allSettingsTranslations,
    resetChanges,
    setHasChanges
  } = props
  const classes = getStylesAdminSettings()

  const [isLoading, setIsLoading] = useState(true)
  const [isModalVisible, toggleModalVisibility] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [state, setState] = useState<TableStateTranslations>({
    data: []
  })
  const [changes, setChanges] = useState<AdminItemChange[]>([])
  const [pageHasActiveChanges, setPageHasActiveChanges] = useState(false)
  const [currentRow, setCurrentRow] = useState<TranslationResult | null>(null)
  const [formOpen, setFormOpen] = useState(false)
  const [createNewItem, setCreateNewItem] = useState(false)
  const [updateRunning, setUpdateRunning] = useState(false)
  const currentType = useRef(adminSettingType)

  const loadData = () => {
    if (adminSettingType === adminSettingTypes.translations) {
      fetchAllGlobalTranslations()
    } else if (adminSettingType === adminSettingTypes.oitranslations) {
      fetchAllGlobalOITranslations()
    } else if (adminSettingType === adminSettingTypes.settingstranslations) {
      fetchAllSettingsTranslations()
    }
  }

  useEffect(() => {
    if (
      (adminSettingType === adminSettingTypes.translations &&
        hasAllTranslationsBeenFetched) ||
      (adminSettingType === adminSettingTypes.oitranslations &&
        hasOITranslationsBeenFetched) ||
      (adminSettingType === adminSettingTypes.settingstranslations &&
        hasSettingsTranslationsBeenFetched)
    ) {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasAllTranslationsBeenFetched,
    hasOITranslationsBeenFetched,
    hasSettingsTranslationsBeenFetched
  ])

  useEffect(() => {
    setIsLoading(true)
  }, [adminSettingType])

  useEffect(() => {
    if (resetChanges) {
      setChanges([])
      setPageHasActiveChanges(false)
    }
  }, [resetChanges])

  useEffect(() => {
    if (pageHasActiveChanges) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHasActiveChanges])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNonInitialEffect(() => {
    setState({
      data: []
    })
    loadData()
    currentType.current = adminSettingType
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminSettingType])

  useNonInitialEffect(() => {
    if (
      hasAllTranslationsBeenFetched ||
      hasOITranslationsBeenFetched ||
      hasSettingsTranslationsBeenFetched
    ) {
      let data = []
      if (adminSettingType === adminSettingTypes.translations) {
        data = allTranslations
      } else if (adminSettingType === adminSettingTypes.oitranslations) {
        data = allOITranslations
      } else if (adminSettingType === adminSettingTypes.settingstranslations) {
        data = allSettingsTranslations
      }

      setState({
        data: data
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasAllTranslationsBeenFetched,
    hasOITranslationsBeenFetched,
    hasSettingsTranslationsBeenFetched
  ])

  useNonInitialEffect(() => {
    if (hasChangesBeenApplied) {
      if (hasUpdateVersionError) {
        setSnackbarMessage(
          'There was an error publishing the changes. The version could not be changed, so updates are not visible for the users.'
        )
        toggleSnackbarVisibility(true)
      } else if (hasTranslationChangesError) {
        setSnackbarMessage('There was an error publishing the changes.')
        toggleSnackbarVisibility(true)
      } else if (!hasTranslationChangesError && !hasUpdateVersionError) {
        setSnackbarMessage('The changes have been published.')
        toggleSnackbarVisibility(true)
      }
    }
  }, [hasChangesBeenApplied, hasUpdateVersionError, hasTranslationChangesError])

  const handleClickOpen = () => {
    toggleModalVisibility(true)
  }

  const handleClose = () => {
    toggleModalVisibility(false)
  }

  const handleFormClose = () => {
    setCurrentRow(null)
    setCreateNewItem(false)
    setFormOpen(false)
  }

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  async function handleConfirm(): Promise<void> {
    setUpdateRunning(true)
    const promiseArray: Array<Promise<void>> = []

    let count = 0

    // Iterate through changes, applying them
    for (const change of changes) {
      switch (change.changeType) {
        case 'add':
        case 'update':
          if (Array.isArray(change.changes)) {
            for (const c of change.changes) {
              count++
              if (count % 20 === 0) {
                await delay(3000)
              }
              promiseArray.push(upsertTranslation(c, adminSettingType))
            }
          }
          break
        case 'delete':
          if (Array.isArray(change.changes)) {
            for (const c of change.changes) {
              count++
              if (count % 20 === 0) {
                await delay(3000)
              }
              promiseArray.push(
                deleteTranslation(c.id, adminSettingType, c.language)
              )
            }
          }
          break
      }

      toggleModalVisibility(false)
    }

    if (promiseArray && promiseArray.length > 0) {
      Promise.all(promiseArray).then(() => {
        setState((prevState: any) => {
          const data = [...prevState.data]

          changes.forEach((change: AdminItemChange) => {
            const index = data
              .map((ad: TranslationResult) => ad.id)
              .indexOf(change.id)

            if (index > -1) {
              //update item in state
              const translation = data[index] as TranslationResult
              translation.draft = false
              data[index] = translation
            }
          })

          return { ...prevState, data }
        })

        if (adminSettingType === adminSettingTypes.oitranslations) {
          let allChanged = false
          let teamsChanged = false
          let oiChanged = false
          changes.forEach((change: AdminItemChange) => {
            if (
              change.changes &&
              Array.isArray(change.changes) &&
              change.changes.length > 0
            ) {
              const firstItem = change.changes[0]

              if (!firstItem.scope) {
                allChanged = true
              } else {
                if (firstItem.scope === 'OI') {
                  oiChanged = true
                } else if (firstItem.scope === 'Teams') {
                  teamsChanged = true
                }
              }
            }
          })

          if (teamsChanged && !oiChanged && !allChanged) {
            upsertVersion('Translations-Global-Teams')
          } else if (oiChanged && !teamsChanged && !allChanged) {
            upsertVersion(adminSettingTypes.oitranslations)
          } else {
            upsertVersion('Translations-Global-OIAndTeams')
          }
        } else {
          upsertVersion(adminSettingType)
        }

        setChanges([])
        setPageHasActiveChanges(false)
        setUpdateRunning(false)
      })
    } else {
      setUpdateRunning(false)
      toggleModalVisibility(false)
    }

    return
  }

  const handleSnackbarClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    toggleSnackbarVisibility(false)
  }

  const handleFormOpen = (row: TranslationResult) => {
    setCurrentRow(row)
    setFormOpen(true)
  }

  const addRowUpdateForTranslations = (newData: TranslationResult) => {
    const newChanges = changes

    let idNumber = getNewId()
    newData.translations.forEach((translation: SingleTranslationResult) => {
      if (translation.id === '-1') {
        translation.id = idNumber.toString()
        idNumber++
      }

      if (newData.scope) {
        translation.scope = newData.scope
      } else {
        delete translation.scope
      }
    })

    setState((prevState: any) => {
      const data = [...prevState.data]
      const index = data
        .map((ad: TranslationResult) => ad.id)
        .indexOf(newData.id)

      newData.draft = true

      const changeIndex = newChanges
        .map((change: AdminItemChange) => change.id)
        .indexOf(newData.id)

      const newTranslationChanges = newData.translations
        .filter((t: SingleTranslationResult) => {
          if (index === -1) {
            return true
          } else {
            const orgData = data[index].translations.find(
              (dt: SingleTranslationResult) => dt.language === t.language
            )
            if (!orgData) {
              return true
            } else {
              return orgData.value !== t.value || orgData.scope !== t.scope
            }
          }
        })
        .map((t) => {
          t.key = newData.key
          return t
        })

      if (changeIndex !== -1) {
        const oldChange: AdminItemChange = newChanges[changeIndex]

        if (Array.isArray(oldChange.changes)) {
          oldChange.changes.forEach((ch) => {
            if (!newTranslationChanges.find((tc) => tc.id === ch.id)) {
              newTranslationChanges.push(ch)
            }
          })
        }
      }

      // Build changes
      const newChange = {
        id: index === -1 ? newData.id : data[index].id,
        changeType: index === -1 ? 'add' : 'update',
        changes: newTranslationChanges
      }

      if (index === -1) {
        data.unshift(newData)
      } else {
        data[index] = newData
      }

      if (changeIndex === -1) {
        newChanges.push(newChange)
      } else {
        newChanges[changeIndex] = {
          ...newChange,
          changeType: newChanges[changeIndex].changeType
        }
      }

      return { ...prevState, data }
    })

    setPageHasActiveChanges(true)
    setChanges(newChanges)

    setCurrentRow(null)
    setFormOpen(false)
    setCreateNewItem(false)
  }

  const publishTranslationResult = (item: TranslationResult) => {
    const promiseArray: Array<Promise<void>> = []

    item.translations.forEach((singleTranslation: SingleTranslationResult) => {
      singleTranslation.key = item.key
      if (item.scope) {
        singleTranslation.scope = item.scope
      } else {
        delete singleTranslation.scope
      }

      promiseArray.push(upsertTranslation(singleTranslation, adminSettingType))
    })

    Promise.all(promiseArray).then(() => {
      if (adminSettingType === adminSettingTypes.oitranslations) {
        if (item.scope === 'Teams') {
          upsertVersion('Translations-Global-Teams')
        } else if (item.scope === 'OI') {
          upsertVersion(adminSettingTypes.oitranslations)
        } else {
          upsertVersion('Translations-Global-OIAndTeams')
        }
      } else {
        upsertVersion(adminSettingType)
      }

      setState((prevState: any) => {
        const data = [...prevState.data]

        //find item index in prevState
        const index = data
          .map((ad: TranslationResult) => ad.id)
          .indexOf(item.id)

        if (index > -1) {
          //update item in state
          const translation = data[index] as TranslationResult
          translation.draft = false
          data[index] = translation
        }

        return { ...prevState, data }
      })

      // clear active change
      const newChanges = changes.filter(
        (itemChanges: AdminItemChange) => !(itemChanges.id === item.id)
      )
      if (newChanges.length === 0) {
        setPageHasActiveChanges(false)
      }
      setChanges(newChanges)
    })
  }

  const getNewId = (): number => {
    let itemWithMaxId = undefined

    itemWithMaxId = (state as TableStateTranslations).data.reduce(function (
      prev: TranslationResult,
      current: TranslationResult
    ) {
      return parseInt(prev.id) > parseInt(current.id) ? prev : current
    })

    let newId = 1
    if (itemWithMaxId) {
      newId = parseInt(itemWithMaxId.id) + 1
    }

    return newId
  }

  const handleRowAdd = () => {
    setCreateNewItem(true)

    let idNumber = getNewId()

    const translations: SingleTranslationResult[] = []
    supportedLanguages.forEach((lang) => {
      translations.push({
        id: idNumber.toString(),
        language: lang.locale,
        value: ''
      })

      idNumber++
    })
    handleFormOpen({
      id: (idNumber--).toString(),
      key: '',
      translations: translations
    } as TranslationResult)
  }

  const handleRowDelete = (deleteItem: TranslationResult) =>
    new Promise((resolve) => {
      const newChanges = [...changes]

      setState((prevState: any) => {
        const data = [...prevState.data]

        deleteItemAndUpdateState(deleteItem, data, newChanges)

        return { ...prevState, data }
      })

      setPageHasActiveChanges(newChanges && newChanges.length > 0)
      setChanges(newChanges)

      resolve(true)
    })

  return (
    <>
      <div className={`${classes.container} ${classes.scrollfix}`}>
        {!isLoading ? (
          <TranslationsTable
            state={state}
            handleRowDelete={handleRowDelete}
            handleTranslationUpdate={addRowUpdateForTranslations}
            handleRowAdd={handleRowAdd}
            handleFormOpen={handleFormOpen}
            adminSettingType={currentType.current}
          />
        ) : (
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress className={classes.loadingSpinner} size={50} />
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          className={classes.button}
          disabled={!pageHasActiveChanges}
          style={{ width: '100%' }}
        >
          <>
            {'Go Live!'}
            {updateRunning && (
              <CircularProgress
                size={15}
                style={{ marginInlineStart: '10px', color: 'white' }}
              />
            )}
          </>
        </Button>
        <ConfirmationModal
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          isModalOpen={isModalVisible}
          title={'Are you sure to publish all changes?'}
          message={
            'Changes will be immediately deployed and will be visible to all KPMG users'
          }
          hideCancelButton={false}
          confirmBtnText={'Yes'}
          cancelBtnText={'No'}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={isSnackbarVisible}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                handleSnackbarClose()
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
      {formOpen && currentRow && (
        <EditFormBase
          rowData={currentRow as TranslationResult}
          isNewItem={createNewItem}
          isOpen={formOpen}
          handleClose={handleFormClose}
          handleRowUpdate={addRowUpdateForTranslations}
          publishItem={publishTranslationResult}
          adminSettingType={adminSettingType}
          changes={changes}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    allTranslations: TranslationsStore.selectors.getAllTranslations(state),
    allOITranslations: TranslationsStore.selectors.getAllOITranslations(state),
    allSettingsTranslations:
      TranslationsStore.selectors.getAllSettingsTranslations(state),
    hasAllTranslationsBeenFetched:
      TranslationsStore.selectors.hasAllTranslationsBeenFetched(state),
    hasOITranslationsBeenFetched:
      TranslationsStore.selectors.hasOITranslationsBeenFetched(state),
    hasSettingsTranslationsBeenFetched:
      TranslationsStore.selectors.hasAllSettingsTranslationsBeenFetched(state),
    hasTranslationChangesError:
      TranslationsStore.selectors.hasChangesError(state),
    hasChangesBeenApplied:
      TranslationsStore.selectors.hasChangesBeenApplied(state),
    hasUpdateVersionError:
      TranslationsStore.selectors.hasUpdateVersionError(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAllGlobalTranslations: () =>
      dispatch(TranslationsStore.actions.fetchAllGlobalTranslations()),
    fetchAllGlobalOITranslations: () =>
      dispatch(TranslationsStore.actions.fetchAllGlobalOITranslations()),
    fetchAllSettingsTranslations: () =>
      dispatch(TranslationsStore.actions.fetchAllSettingsTranslations()),
    upsertTranslation: (
      translation: SingleTranslationResult,
      translationType: string
    ) =>
      dispatch(
        TranslationsStore.actions.upsertTranslation(
          translation,
          translationType
        )
      ),
    deleteTranslation: (
      translationId: string,
      translationType: string,
      translationLanguage: string
    ) =>
      dispatch(
        TranslationsStore.actions.deleteTranslation(
          translationId,
          translationType,
          translationLanguage
        )
      ),
    upsertVersion: (translationType: string, maxId?: number) =>
      dispatch(TranslationsStore.actions.upsertVersion(translationType, maxId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Translations)
