import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Store } from '..'
import * as Config from '../../config'
import AuthStore from '../Auth'
import { trackEvents, trackException } from 'utils/tracking'
import { IFindResponse } from 'utils/api'
import { renewAuthorizationToken } from 'authentication/token'
import { ISearchTip } from 'models/SearchTip'

export enum SearchTipsActionTypes {
  FETCH_SEARCH_TIPS_ALL_REQUEST = 'searchTips/FETCH_SEARCH_TIPS_ALL_REQUEST',
  FETCH_SEARCH_TIPS_ALL_SUCCESS = 'searchTips/FETCH_SEARCH_TIPS_ALL_SUCCESS',
  FETCH_SEARCH_TIPS_ALL_FAILURE = 'searchTips/FETCH_SEARCH_TIPS_ALL_FAILURE',
  UPSERT_SEARCH_TIP_SUCCESS = 'searchTips/UPSERT_SEARCH_TIP_SUCCESS',
  CHANGE_SEARCH_TIP_FAILURE = 'searchTips/CHANGE_SEARCH_TIP_FAILURE',
  DELETE_SEARCH_TIP_SUCCESS = 'searchTips/DELETE_SEARCH_TIP_SUCCESS'
}

export type IFetchSearchTipsAllRequest = Action<SearchTipsActionTypes>
export const fetchSearchTipsAllRequest = (): IFetchSearchTipsAllRequest => ({
  type: SearchTipsActionTypes.FETCH_SEARCH_TIPS_ALL_REQUEST
})

export type IFetchSearchTipsFailure = Action<SearchTipsActionTypes>
export const fetchSearchTipsFailure = (): IFetchSearchTipsFailure => ({
  type: SearchTipsActionTypes.FETCH_SEARCH_TIPS_ALL_FAILURE
})

export interface IFetchSearchTipsAllSuccess
  extends Action<SearchTipsActionTypes> {
  payload: {
    searchTipsAllResponse: any
  }
}
export const fetchSearchTipsAllSuccess = (
  searchTipsAllResponse: ISearchTip[]
): IFetchSearchTipsAllSuccess => ({
  type: SearchTipsActionTypes.FETCH_SEARCH_TIPS_ALL_SUCCESS,
  payload: { searchTipsAllResponse }
})

export interface IUpsertSearchTipSuccess extends Action<SearchTipsActionTypes> {
  payload: {
    upsertedSearchTip: ISearchTip
  }
}
export const upsertSearchTipSuccess = (
  upsertedSearchTip: ISearchTip
): IUpsertSearchTipSuccess => ({
  type: SearchTipsActionTypes.UPSERT_SEARCH_TIP_SUCCESS,
  payload: { upsertedSearchTip }
})

export type IChangeUpsertSearchTipFailure = Action<SearchTipsActionTypes>
export const changeSearchTipFailure = (): IChangeUpsertSearchTipFailure => ({
  type: SearchTipsActionTypes.CHANGE_SEARCH_TIP_FAILURE
})

export interface IDeleteSearchTipSuccess extends Action<SearchTipsActionTypes> {
  payload: {
    searchTipId: string
    searchTipLanguage: string
  }
}
export const deleteSearchTipSuccess = (
  searchTipId: string,
  searchTipLanguage: string
): IDeleteSearchTipSuccess => ({
  type: SearchTipsActionTypes.DELETE_SEARCH_TIP_SUCCESS,
  payload: { searchTipId, searchTipLanguage }
})

export const fetchSearchTips = (): ThunkAction<
  Promise<void>,
  Store,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      trackEvents('getSearchTips', {})

      dispatch(fetchSearchTipsAllRequest())

      const apiUrl = `${
        Config.APIM_BASE_URL
      }searchtipsapi/getsearchtips?language=${'all'}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (data.status !== 200 && data.status !== 201) {
        dispatch(fetchSearchTipsFailure())
        trackException(
          'Error in fetching search tips options',
          new Error('Error in data object after fetching search tips options')
        )
        return Promise.resolve()
      }

      let results: IFindResponse
      results = {
        hasError: false,
        responseJSON: await data.json()
      }

      if (!results || !results.responseJSON.Documents) {
        dispatch(fetchSearchTipsFailure())
        trackException(
          'Error in getting documents out of search tips options',
          new Error('Error in data object after fetching search tips options')
        )
        return Promise.resolve()
      }

      const result: ISearchTip[] = []
      results.responseJSON.Documents.forEach((co: ISearchTip) => {
        result.push(co)
      })

      result.sort((a: ISearchTip, b: ISearchTip) => {
        return a.order > b.order ? 1 : -1
      })

      dispatch(fetchSearchTipsAllSuccess(result))
    } catch (error) {
      dispatch(fetchSearchTipsFailure())
      trackException('Error in fetching search tips options', error)
    }
  }
}

export const upsertSearchTip = (
  searchTipData: ISearchTip
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      const searchTip = JSON.parse(JSON.stringify(searchTipData))
      if (searchTip.tableData) {
        delete searchTip.tableData
      }
      if (searchTip.draft) {
        delete searchTip.draft
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}searchtipsapi/upsertsearchtip?language=${searchTip.language}`

      const utf8Bytes = encodeURIComponent(JSON.stringify(searchTip)).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode(Number('0x' + p1))
        }
      )

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body: '{"content":"' + btoa(utf8Bytes) + '"}'
      })

      if (data.status !== 200 && data.status !== 201) {
        trackException(
          'Error in upsert search tip',
          new Error('Error in data object after upserting search tip')
        )
        dispatch(changeSearchTipFailure())
        return
      }

      dispatch(upsertSearchTipSuccess(searchTip))
    } catch (error) {
      trackException('Error in upsert search tip action', error)
      dispatch(changeSearchTipFailure())
    }
  }
}

export const deleteSearchTip = (
  searchTipId: string,
  searchTipLanguage: string
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    try {
      const apiUrl = `${Config.APIM_BASE_URL}searchtipsapi/deletesearchtip?docId=${searchTipId}&language=${searchTipLanguage}`

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const data = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })
      if (data.status !== 204) {
        trackException(
          'Error in delete search tips',
          new Error('Error in data object after deleting search tips')
        )
        dispatch(changeSearchTipFailure())
        return
      }

      dispatch(deleteSearchTipSuccess(searchTipId, searchTipLanguage))
    } catch (error) {
      trackException('Error in delete search tips action', error)
      dispatch(changeSearchTipFailure())
    }
  }
}

export type SearchTipsActions =
  | IFetchSearchTipsAllSuccess
  | IFetchSearchTipsFailure
  | IUpsertSearchTipSuccess
  | IDeleteSearchTipSuccess
