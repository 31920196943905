import { Reducer } from 'redux'
import {
  TranslationsActions,
  TranslationsActionTypes,
  IFetchAllGlobalOITranslationsSuccess,
  IFetchAllAdsTranslationsSuccess,
  IDeleteTranslationSuccess,
  IUpsertTranslationSuccess,
  IUpsertImageSuccess,
  IUpsertImageRequest,
  IUpsertImageResult,
  IFetchAllPopupsTranslationsSuccess,
  IFetchAllGlobalTranslationsSuccess,
  IFetchVersionTranslationsSuccess,
  IFetchAllSettingsTranslationsSuccess,
  IFetchSettingsTranslationsSuccess
} from './actions'
import { AdsResult } from 'models/AdsResult'
import { PopupResult } from 'models/PopupResult'
import { TranslationResult } from 'models/TranslationResult'
import * as Constants from 'constants/constants'

export interface ITranslationsStore {
  settingsTranslations: any
  settingsTranslationsVersion: any
  hasSettingsTranslationsBeenFetched: boolean
  hasSettingsError: boolean
  allAdsTranslations: AdsResult[]
  hasAllAdTranslationsBeenFetched: boolean
  popupTranslationsVersion: any
  popupTranslationsMaxId: any
  allPopupsTranslations: PopupResult[]
  hasAllPopupTranslationsBeenFetched: boolean
  allGlobalTranslations: TranslationResult[]
  allGlobalOITranslations: TranslationResult[]
  allSettingsTranslations: TranslationResult[]
  hasAllTranslationsBeenFetched: boolean
  hasAllSettingsTranslationsBeenFetched: boolean
  hasTranslationsBeenFetched: boolean
  hasOITranslationsBeenFetched: boolean
  hasError: boolean
  hasChangesBeenApplied: boolean
  hasChangesError: boolean
  validTranslations: boolean
  upsertImageUrl: IUpsertImageResult | null
  hasUpsertImageError: boolean
  upsertImageExists: boolean
  upsertImageValidation: boolean
  hasUpdateVersionError: boolean
}

export const initialSettingsTranslationsState = (): any => []
export const initialAdsTranslationsState = (): any => []
export const initialPopupsTranslationsState = (): any => []
export const initialAllGlobalTranslationsState = (): any => []
export const initialAllGlobalOITranslationsState = (): any => []
export const initialAllSettingsTranslationsState = (): any => []

const initialState: ITranslationsStore = {
  settingsTranslations: initialSettingsTranslationsState(),
  settingsTranslationsVersion: '0',
  hasSettingsTranslationsBeenFetched: false,
  hasSettingsError: false,
  allAdsTranslations: initialAdsTranslationsState(),
  hasAllAdTranslationsBeenFetched: false,
  popupTranslationsVersion: '0',
  popupTranslationsMaxId: '-1',
  allPopupsTranslations: initialPopupsTranslationsState(),
  hasAllPopupTranslationsBeenFetched: false,
  allGlobalTranslations: initialAllGlobalTranslationsState(),
  allGlobalOITranslations: initialAllGlobalOITranslationsState(),
  allSettingsTranslations: initialAllSettingsTranslationsState(),
  hasTranslationsBeenFetched: false,
  hasAllTranslationsBeenFetched: false,
  hasAllSettingsTranslationsBeenFetched: false,
  hasOITranslationsBeenFetched: false,
  hasError: false,
  hasChangesBeenApplied: false,
  hasChangesError: false,
  validTranslations: false,
  upsertImageUrl: null,
  hasUpdateVersionError: false,
  hasUpsertImageError: false,
  upsertImageExists: false,
  upsertImageValidation: false
}

const reducers: Reducer<ITranslationsStore, TranslationsActions> = (
  state: ITranslationsStore = initialState,
  action: TranslationsActions
) => {
  switch (action.type) {
    case TranslationsActionTypes.FETCH_VERSION_TRANSLATIONS_REQUEST:
      return {
        ...state
      }
    case TranslationsActionTypes.FETCH_VERSION_TRANSLATIONS_SUCCESS:
      const { translationType, version, maxid } = (
        action as IFetchVersionTranslationsSuccess
      ).payload
      switch (translationType) {
        case Constants.translationTypes.settings:
          return {
            ...state,
            settingsTranslationsVersion: version
          }
        case Constants.translationTypes.popups:
          return {
            ...state,
            popupTranslationsVersion: version,
            popupTranslationsMaxId: maxid
          }
        default:
          return {
            ...state
          }
      }
    case TranslationsActionTypes.FETCH_SETTINGS_TRANSLATIONS_REQUEST:
      return {
        ...state,
        settingsTranslations: initialSettingsTranslationsState(),
        hasSettingsError: false
      }
    case TranslationsActionTypes.FETCH_SETTINGS_TRANSLATIONS_SUCCESS:
      const { settingsResponse } = (action as IFetchSettingsTranslationsSuccess)
        .payload
      return {
        ...state,
        hasSettingsTranslationBeenFetched: true,
        settingsTranslations: settingsResponse,
        hasSettingsError: false
      }
    case TranslationsActionTypes.FETCH_SETTINGS_TRANSLATIONS_FAILURE:
      return {
        ...state,
        hasSettingsTranslationBeenFetched: true,
        hasSettingsError: true
      }
    case TranslationsActionTypes.FETCH_ALL_ADS_TRANSLATIONS_REQUEST:
      return {
        ...state,
        allAdsTranslations: initialAdsTranslationsState(),
        hasTranslationsBeenFetched: false,
        hasAllAdTranslationsBeenFetched: false,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_ALL_GLOBAL_TRANSLATIONS_REQUEST:
      return {
        ...state,
        allGlobalTranslations: initialAllGlobalTranslationsState(),
        hasAllTranslationsBeenFetched: false,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_ALL_GLOBAL_OI_TRANSLATIONS_REQUEST:
      return {
        ...state,
        allGlobalOITranslations: initialAllGlobalOITranslationsState(),
        hasOITranslationsBeenFetched: false,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_ALL_SETTINGS_TRANSLATIONS_REQUEST:
      return {
        ...state,
        allSettingsTranslations: initialAllSettingsTranslationsState(),
        hasAllSettingsTranslationsBeenFetched: false,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_ALL_POPUPS_TRANSLATIONS_REQUEST:
      return {
        ...state,
        allPopupsTranslations: initialPopupsTranslationsState(),
        hasTranslationsBeenFetched: false,
        hasAllPopupTranslationsBeenFetched: false,
        validTranslations: false
      }

    case TranslationsActionTypes.FETCH_ALL_GLOBAL_TRANSLATIONS_SUCCESS:
      const { allGlobalResponse } = (
        action as IFetchAllGlobalTranslationsSuccess
      ).payload
      return {
        ...state,
        hasAllTranslationsBeenFetched: true,
        allGlobalTranslations: allGlobalResponse,
        hasError: false,
        validTranslations: allGlobalResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_ALL_GLOBAL_OI_TRANSLATIONS_SUCCESS:
      const { allGlobalOIResponse } = (
        action as IFetchAllGlobalOITranslationsSuccess
      ).payload
      return {
        ...state,
        hasOITranslationsBeenFetched: true,
        allGlobalOITranslations: allGlobalOIResponse,
        hasError: false,
        validTranslations: allGlobalOIResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_ALL_SETTINGS_TRANSLATIONS_SUCCESS:
      const { allSettingsResponse } = (
        action as IFetchAllSettingsTranslationsSuccess
      ).payload
      return {
        ...state,
        hasAllSettingsTranslationsBeenFetched: true,
        allSettingsTranslations: allSettingsResponse,
        hasError: false,
        validTranslations: allSettingsResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_ALL_ADS_TRANSLATIONS_SUCCESS:
      const { allAdsResponse } = (action as IFetchAllAdsTranslationsSuccess)
        .payload
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        allAdsTranslations: allAdsResponse,
        hasAllAdTranslationsBeenFetched: true,
        hasError: false,
        validTranslations: allAdsResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_ALL_POPUPS_TRANSLATIONS_SUCCESS:
      const { allPopupsResponse } = (
        action as IFetchAllPopupsTranslationsSuccess
      ).payload
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        hasAllPopupTranslationsBeenFetched: true,
        allPopupsTranslations: allPopupsResponse,
        hasError: false,
        validTranslations: allPopupsResponse ? true : false
      }
    case TranslationsActionTypes.FETCH_TRANSLATIONS_FAILURE:
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        hasError: true,
        validTranslations: false
      }
    case TranslationsActionTypes.FETCH_TRANSLATIONS_NOTFOUND:
      return {
        ...state,
        hasTranslationsBeenFetched: true,
        hasError: false,
        validTranslations: false
      }
    case TranslationsActionTypes.DELETE_TRANSLATION_SUCCESS:
      const { translationId } = (action as IDeleteTranslationSuccess).payload
      const allAdsTranslations = state.allAdsTranslations
      if (allAdsTranslations.length > 0) {
        const tIndex = allAdsTranslations.findIndex(
          (t: any) => t.id === translationId
        )
        if (tIndex >= 0) {
          allAdsTranslations.splice(tIndex, 1)
        }
      }
      return {
        ...state,
        allAdsTranslations: allAdsTranslations,
        hasChangesError: false
      }
    case TranslationsActionTypes.UPSERT_TRANSLATION_SUCCESS:
      const { upsertedTranslation } = (action as IUpsertTranslationSuccess)
        .payload
      const afterUpdateTrans = state.allAdsTranslations
      if (afterUpdateTrans.length > 0) {
        const tIndex = afterUpdateTrans.findIndex(
          (t: any) => t.id === upsertedTranslation.id
        )
        if (tIndex >= 0) {
          afterUpdateTrans[tIndex] = upsertedTranslation
        } else {
          afterUpdateTrans.unshift(upsertedTranslation)
        }
      }
      return {
        ...state,
        allAdsTranslations: afterUpdateTrans,
        hasChangesError: false
      }
    case TranslationsActionTypes.UPSERT_IMAGE_FAILURE:
      return {
        ...state,
        upsertImageUrl: null,
        hasUpsertImageError: true,
        upsertImageExists: false,
        upsertImageValidation: false
      }
    case TranslationsActionTypes.UPSERT_IMAGE_SUCCESS:
      const { imageSuccess } = (action as IUpsertImageSuccess).payload

      return {
        ...state,
        upsertImageUrl: imageSuccess,
        hasUpsertImageError: false,
        upsertImageExists: false,
        upsertImageValidation: false
      }
    case TranslationsActionTypes.UPSERT_IMAGE_REQUEST:
      const { imageRequest } = (action as IUpsertImageRequest).payload
      return {
        ...state,
        upsertImageUrl: imageRequest,
        hasUpsertImageError: false,
        upsertImageExists: false,
        upsertImageValidation: false
      }
    case TranslationsActionTypes.UPSERT_IMAGE_EXISTS:
      return {
        ...state,
        upsertImageUrl: null,
        hasUpsertImageError: true,
        upsertImageExists: true,
        upsertImageValidation: false
      }
    case TranslationsActionTypes.UPSERT_IMAGE_VALIDATION:
      return {
        ...state,
        upsertImageUrl: null,
        hasUpsertImageError: true,
        upsertImageValidation: true,
        upsertImageExists: false
      }
    case TranslationsActionTypes.CHANGE_TRANSLATIONS_FAILURE:
      return {
        ...state,
        upsertImageUrl: null,
        hasChangesError: true
      }
    case TranslationsActionTypes.UPSERT_VERSION_REQUEST:
      return {
        ...state,
        hasUpdateVersionError: false,
        hasChangesBeenApplied: false
      }
    case TranslationsActionTypes.UPSERT_VERSION_SUCCESS:
      return {
        ...state,
        hasUpdateVersionError: false,
        hasChangesBeenApplied: true
      }
    case TranslationsActionTypes.UPSERT_VERSION_FAILURE:
      return {
        ...state,
        hasUpdateVersionError: true,
        hasChangesBeenApplied: true
      }
    default:
      return state
  }
}

export default reducers
