import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesAdImageUpload(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    imageUploadButtonContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    imageUploadButton: {
      width: '50%'
    },
    imageOverwriteCheckBox: {
      marginLeft: 30
    }
  }))
}
