import React, { useState } from 'react'
import {
  Chip,
  ChipPropsColorOverrides,
  ChipPropsSizeOverrides,
  ChipPropsVariantOverrides,
  Popover,
  Tooltip
} from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { IItemMetrics, ISelfServiceRequest } from 'models/SelfServiceRequest'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import CommentCallOut from 'components/requests/requestForms/CommentCallout'
import NewReleases from '@mui/icons-material/NewReleases'
import UserSettingsStore from 'store/UserSettings'
import { connect } from 'react-redux'
import { Store } from 'store'
import { useIntl } from 'react-intl'

interface IStatusClipRequestProps {
  label: string
  color?: OverridableStringUnion<
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    ChipPropsColorOverrides
  >
  maxWidth?: number
  size?: OverridableStringUnion<'small' | 'medium', ChipPropsSizeOverrides>
  variant?: OverridableStringUnion<
    'filled' | 'outlined',
    ChipPropsVariantOverrides
  >
  additionalProps?: any
  rowData: ISelfServiceRequest
  additionalTooltip?: string
  handleRowUpdate: (request: ISelfServiceRequest) => void
  isAdminRequest: boolean
  toggleSnackbarVisibility: (visible: boolean) => void
  setSnackbarMessage: (message: string) => void
  handleRowRemoveCommentChangeNotification: (
    rowData: ISelfServiceRequest
  ) => void
}

type AllStatusClipRequestProps = ReturnType<typeof mapStateToProps> &
  IStatusClipRequestProps

function StatusClipRequest(props: AllStatusClipRequestProps): JSX.Element {
  const {
    label,
    color,
    maxWidth,
    size,
    variant,
    additionalProps,
    rowData,
    additionalTooltip,
    handleRowUpdate,
    isAdminRequest,
    toggleSnackbarVisibility,
    setSnackbarMessage,
    handleRowRemoveCommentChangeNotification
  } = props

  const [expanded, setExpanded] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [itemMetrics, setItemMetrics] = useState<IItemMetrics | null>(null) //keep values ​​here, CommentCallOut will be reinitialized
  const intl = useIntl()

  const handleTableClipClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExpanded(!expanded)
    setAnchorEl(event.currentTarget)
  }

  const handlePopUpClose = () => {
    setExpanded(false)
    handleRowRemoveCommentChangeNotification(rowData)
  }

  const handleRemoveCommentNotification = () => {}

  return (
    <Tooltip
      title={!expanded ? (additionalTooltip ? additionalTooltip : label) : ''}
      aria-label={label}
      enterDelay={500}
    >
      <div>
        <Chip
          label={label}
          style={maxWidth ? { maxWidth: maxWidth } : {}}
          {...(color && { color: color })}
          {...(size && { size: size })}
          {...(variant && { variant: variant })}
          {...(additionalProps && { ...additionalProps })}
          icon={!expanded ? <ExpandMore /> : <ExpandLess />}
          onClick={handleTableClipClick}
          {...(rowData.hasCommentChanges && {
            onDelete: handleRemoveCommentNotification
          })}
          {...(rowData.hasCommentChanges && {
            deleteIcon: (
              <Tooltip
                title={intl.formatMessage({
                  id: 'table_column_status_newcomment_available',
                  defaultMessage: 'New comments available'
                })}
                aria-label={label}
                enterDelay={500}
                placement={'top'}
                arrow={true}
              >
                <NewReleases style={{ color: '#bc204b', fontSize: '18x' }} />
              </Tooltip>
            )
          })}
        />
        {expanded && (
          <Popover
            id={rowData.id}
            open={expanded}
            anchorEl={anchorEl}
            onClose={handlePopUpClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <CommentCallOut
              item={rowData}
              isAdminRequest={isAdminRequest}
              handleRowUpdate={handleRowUpdate}
              toggleSnackbarVisibility={toggleSnackbarVisibility}
              setSnackbarMessage={setSnackbarMessage}
              itemMetrics={itemMetrics}
              setItemMetrics={setItemMetrics}
            />
          </Popover>
        )}
      </div>
    </Tooltip>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    hasUserSettingsBeenFetched:
      UserSettingsStore.selectors.hasUserSettingsBeenFetched(state)
  }
}

export default connect(mapStateToProps)(StatusClipRequest)
